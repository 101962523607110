<template>
  <punt-modal size="md:max-w-xl max-w-[92%] p-4 " :showClose="true">
    <template #title>
      <div class="flex flex-col">
        <span class="font-bold text-lg">Create New Giveaway</span>
      </div>
    </template>
    <template #body>
      <div
        class="modal is-active flex min-h-[300px] flex-col text-left space-y-2 text-slate-300">
        <form
          id="add-new-challenges"
          class="new-challenges p-6 left"
          @submit.prevent="submitnewgiveaway">
          <div class="mb-4 relative">
            <label for="prize" class="block text-white font-medium">Choose Prize</label>
            <select
              name="prize"
              v-model="formData.prize"
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">
              <option v-for="index in 100" :key="index" :value="index">
                {{ index }} SC + {{ index * 10000 }} GC
              </option>
            </select>
          </div>
          <div class="mb-4">
            <label for="multiplier" class="block text-white font-medium">
              Number of Winners
            </label>
            <select
              v-model="formData.number_of_winner"
              title="Please enter number of winner"
              id="number_of_winner"
              name="number_of_winner"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">
              <option v-for="index in 500" :key="index" :value="index">
                {{ index }} {{ index > 1 ? 'Winners' : 'Winner' }}
              </option>
            </select>
          </div>

          <div class="flex justify-center mt-6">
            <button
              type="submit"
              class="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
              :disabled="isLoading">
              <span v-if="isLoading" class="animate-spin">Loading...</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { mapGetters } from 'vuex';

import { GiveawayStores } from '@/store/pinia/giveawayStore';
export default {
  name: 'GiveawayCreat',
  components: { PuntModal },
  props: {
    onSuccess: {
      default: null,
    },
  },
  data() {
    return {
      searchText: null,
      formData: {
        prize: null,
        number_of_winner: '',
      },

      errors: {},

      isLoading: false,
    };
  },
  setup() {
    return { giveaways: GiveawayStores() };
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    async submitnewgiveaway() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { data } = await this.giveaways.createNew(this.formData);

          if (data.success == true) {
            if (this.onSuccess) {
              this.onSuccess();
            }
            this.closeModal();
            this.$toast.success('Giveaway created successfully!');
          } else {
            this.$toast.warning('Some error please try again');
          }
        } catch (e) {
          if (typeof e.data.errors != 'undefined') {
            Object.keys(e.data.errors).forEach((key) => {
              this.$toast.error(e.data.errors[key][0]);
            });
          }
          if (typeof e.data.error != 'undefined') {
            this.$toast.error(e.data.error);
          }
        }
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$router.go(-1);
    },
  },
};
</script>
