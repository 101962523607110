<template>
  <div class="rainbot-wrap my-2 mx-2">
    <div class="header">
      <!--      <div class="bg">
        <i v-for="i in 150" :key="i" class="rain">
        </i>
      </div>-->
      <div>
        <span class="title flex flex-row justify-start items-center gap-2"
          >RainBot

          <svg
            id="cloud"
            style="enable-background: new 0 0 1000 1000"
            version="1.1"
            viewBox="0 0 1000 1000"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            y="0px">
            <circle class="st0" cx="434.1" cy="269.4" r="209.1" />
            <circle class="st0" cx="654.7" cy="414.1" r="144.7" />
            <circle class="st0" cx="804.8" cy="503.7" r="153.9" />
            <circle class="st0" cx="277.9" cy="421" r="236.6" />
            <path
              class="st0 rectangle"
              d="M593.5,446.3H489.2c-116.7,0-211.3,94.6-211.3,211.3v0h527v0C804.8,540.9,710.2,446.3,593.5,446.3z" />
            <line class="st1" x1="262.3" x2="38.9" y1="714.6" y2="938.1" />
            <line class="st1" x1="396.5" x2="173" y1="714.6" y2="938.1" />
            <line class="st1" x1="530.6" x2="307.1" y1="714.6" y2="938.1" />
            <line class="st1" x1="664.7" x2="441.3" y1="714.6" y2="938.1" />
            <line class="st1" x1="793.1" x2="569.7" y1="714.6" y2="938.1" />
          </svg>
        </span>
      </div>
    </div>
    <p class="text-sm text-slate-300 p-2" v-html="msgText"></p>
  </div>
</template>

<script>
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';

export default {
  name: 'RainBotMessage',
  props: {
    message: String,
  },
  setup() {
    return { balS: balanceStore() };
  },
  computed: {
    msgText() {
      let msg = this.message.replace('[RainBot]', '');
      for (const cur of this.balS.currencies) {
        if (msg.includes(cur.name)) {
          msg = msg.replace(
            cur.name,
            '<img src="' +
              getBalanceIcon(cur.name) +
              '" class="inline h-4 w-4"  alt=""/>',
          );
        }
      }
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
svg#cloud {
  width: 6%;
  height: 20px;
  margin-bottom: -5px;

  .rectangle {
    /*   display: none; */
    fill: #c2c1c0;
    animation-delay: 0;
  }

  .st1 {
    fill: none;
    stroke-dasharray: 120;
    stroke-dashoffset: 250;
    stroke: #41b8ea;
    stroke-width: 52.3493;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    animation: rain 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  @keyframes rain {
    0% {
      stroke-dashoffset: 250;
    }
    100% {
      stroke-dashoffset: -230;
    }
  }

  .st0 {
    transform-origin: bottom;
    fill: #c2c1c0;
    transform: translateY(75px);
    animation: cloud 1s;
    animation-iteration-count: once;
    animation-fill-mode: forwards;
  }

  .st0:nth-child(1) {
    animation-delay: 0.2s;
  }

  .st0:nth-child(2) {
    animation-delay: 0.1s;
  }

  .st0:nth-child(3) {
    transform: scale(0.7);
    transform-origin: center bottom;
    animation: scale 1s;
    animation-fill-mode: forwards;
  }

  .st0:nth-child(4) {
    transform: scale(0.7);
    transform-origin: center bottom;
    animation: scale 1s;
    animation-fill-mode: forwards;
  }

  .st0:nth-child(5) {
    transform: translateY(0);
    animation: none;
  }

  @keyframes cloud {
    0% {
      transform: translateY(75px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
}

.rainbot-wrap {
  margin-top: 0.25rem;
  padding: 0 0 0.75rem;
  border: 1px solid rgb(38, 68, 79);
  border-radius: 8px;
  background: rgb(38, 68, 79);

  .header {
    position: relative;
    padding: 0.75rem 1rem;
    border-bottom: 1px outset #ddd;
    overflow: hidden;

    .bg {
      position: absolute;
      width: 400px;
      height: 200px;
      margin-top: -5rem;
      transform: rotate(15deg);
    }

    .title {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .body {
    position: relative;
    padding: 0.5rem 1rem;

    font-size: 1.05rem;
  }
}

.rain {
  background: white;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 50px;
  position: absolute;
  width: 1px;
}
</style>
/* $rain: 150; @for $i from 1 through $rain { $top: (random(50) + 50) * 1%; $left:
random(100) * 1%; $opacity: (random(30) + 30) * 0.01; $delay: random(20) - 1s;
.rain:nth-of-type(#{$i}) { animation-name: rain-#{$i}; animation-delay: $delay;
animation-duration: random(6) + 4s; animation-iteration-count: infinite; left: $left;
opacity: $opacity; top: -$top; } @keyframes rain-#{$i} { 0% { left: $left; opacity:
$opacity; top: -$top; } 100% { opacity: 0; top: $top + 40%; } } }*/
