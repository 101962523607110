import bet from '../../assets/media/start_plinko.mp3';
import hit from '../../assets/media/hit_plinko_bucket.mp3';

const betSound = new Audio(bet);
const hitSound = new Audio(hit);

betSound.load();
hitSound.load();

export { betSound, hitSound };
