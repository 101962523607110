<template>
  <div class="w-full h-screen">
    <search v-if="generalS.showSearch" />
  </div>
</template>

<script>
import Search from './Search.vue';
import { generalStore } from '@/store/pinia/generalStore';
export default {
  components: {
    Search,
  },
  setup() {
    const generalS = generalStore;
    return { generalS };
  },
};
</script>
