<template lang="">
  <button
    id="trigger"
    v-on:click="copyToClipboard()"
    class="bg-transparent flex items-center justify-center relative rounded-full hover:opacity-70 focus:bg-transparent outline-none">
    <svg
      class="absolute left-0 right-0"
      :class="isCopied ? 'hidden' : 'block'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <rect
        x="128"
        y="128"
        width="336"
        height="336"
        rx="57"
        ry="57"
        style="fill: none; stroke: #fff; stroke-linejoin: round; stroke-width: 32px" />
      <path
        d="M383.5,128l.5-24a56.16,56.16,0,0,0-56-56H112a64.19,64.19,0,0,0-64,64V328a56.16,56.16,0,0,0,56,56h24"
        style="
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 32px;
        " />
    </svg>
    <svg
      class="checkmark"
      :class="isCopied ? 'fadeIn' : 'hidden'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52">
      <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    copyToClipboard() {
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #00a86b;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: #00a86b;
  fill: none;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 70;
  stroke-dashoffset: 70;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 100px #00a86b;
  }
}

.checkmark.fadeIn {
  opacity: 1;
  transition: all 0.8s ease;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark.fadeIn .checkmark-circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark.fadeIn .checkmark-check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
</style>
