export const initializeDice = function (container, canvasId, setRollDiceCallbacks) {
  let canvas = $t.id(canvasId);
  $t.dice.use_true_random = false;

  let box = new $t.dice.dice_box(canvas, { w: 200, h: 100 });
  box.animate_selector = false;

  $t.bind(window, 'resize', function () {
    canvas.style.width = '100%';
    canvas.style.height = '100%'; //canvas.innerHeight - 2 + 'px';
    box.reinit(canvas, { w: 200, h: 100 });
  });

  function rollDice(result1, result2, onRollFinish = () => {}) {
    const beforeRoll = (vectors, notation, callback) => {
      callback([result1, result2]);
    };
    box.start_throw(notation_getter, beforeRoll, onRollFinish);
  }
  function notation_getter() {
    return $t.dice.parse_notation('2d6');
  }

  setRollDiceCallbacks(rollDice, () => box.clear());
};
