import { defineStore } from 'pinia';
import axios from '../lib/axiosConfig';

export const chatStore = defineStore('chat', {
  state: () => {
    return {
      selectedMessage: null,
    };
  },
  getters: {},
  actions: {
    async delete(id) {
      let res = await axios.delete('api/chat/' + id);
      return res.data;
    },
    toggleMessage(id) {
      if (id === this.selectedMessage) {
        this.selectedMessage = null;
      } else {
        this.selectedMessage = id;
      }
    },
  },
});
