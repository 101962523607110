import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const evolutionStore = defineStore('pragmatic', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async createGameSession(isMobile, gameId, isDemo) {
      const platform = isMobile ? 'mobile' : 'desktop';
      const url = '/games/evo/' + (isDemo ? 'demo' : 'session');
      const custom_ip =   (document.getElementById('custom_ip') != null)?document.getElementById('custom_ip').value:'0.0.0.0';

      return await axios.post(url, { platform, gameId, custom_ip });
    },
    async createSession(currency, amount, address, tag) {
      return await axios.post('/games/evo/session', {
        currency,
        amount,
        address,
        tag,
      });
    },
    
    async fetchGame(identifier) {
      return await axios.get('/games/evo/games/' + identifier);
    },
    async toggleFavourite(data) {
      return await axios.post('favourite', data);
    },
    async checkFavourite(data) {
      return await axios.post('favourite/check', data);
    },
  },
});
