<template>
    <h3 class="section-title" v-if="title" >{{ title }}</h3>
</template>

<script>
export default {
  name: 'SectionTitle',
  props : ["title"]
}
</script>

<style lang="scss" scoped>
    .section-title{
        font-size: 36px;
        line-height: 42px;
        font-weight: 700;
        margin-bottom: 40px;
        text-align: center;
    }
    @media screen and (max-width:992px){
        .section-title{
            font-size: 20px;
            margin-bottom: 25px;
        }
    }
</style>
