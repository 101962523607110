import { getFetch } from './http';

export default {
  state: {
    lastFetched: 0,
    refreshing: false,
    stats: false,
  },
  actions: {
    refreshStats({ commit, state }) {
      if (state.refreshing) {
        return;
      }

      commit('stats/start-refresh');
      return getFetch('stats', 'updating user stats').then(({ data }) => {
        commit('stats/finish-refresh', data);
        return data;
      });
    },
  },
  getters: {
    lastStatsRefresh: (state) => state.lastFetched,
    isRefreshingStats: (state) => state.refreshing,
    userStats: (state) => state.stats,
  },
  mutations: {
    'stats/start-refresh'(state) {
      state.refreshing = true;
    },
    'stats/finish-refresh'(state, stats) {
      state.refreshing = false;
      state.lastFetched = Date.now();
      state.stats = { ...stats };
    },
  },
};
