<template>
  <div class="flex w-full flex-col items-center justify-center">
    <button
      class="text-secondary-text flex flex-col items-center justify-center px-4 py-3 hover:opacity-75"
      v-on:click="back">
      <svg
        class="h-6 w-6"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <span>Back to Bundles</span>
    </button>
  </div>

  <div v-if="!shouldRender">
    <identity-verification-form />
  </div>
  <div v-else>
    <!-- Display pending transaction message if there is a pending transaction -->
    <div v-if="hasPendingTransaction" class="mb-4 bg-yellow-300 p-4">
      There is a pending transaction for {{ lastTransactionAmount }}
      {{ lastTransactionCurrency }}. Please wait for it to complete.
    </div>
    <div class="mx-auto w-full max-w-sm">
      <div class="flex justify-center bg-black bg-opacity-25">
        <h2 class="text-xl font-bold">Secure Payment</h2>
      </div>
      <!-- Order Summary -->
      <div class="mx-auto w-full max-w-sm">
        <div class="flex items-center justify-between">
          <h2 class="mb-4 mr-4 text-xl font-semibold">Order Summary:</h2>
          <h2 class="mb-4 text-xl font-semibold">&dollar; {{ this.amount }}</h2>
        </div>

        <div class="mb-4 rounded bg-black bg-opacity-25 px-8 pb-8 pt-6 shadow-md">
          <div>
            <span class="flex items-center justify-center space-x-2">
              <img alt="Gold Coin" class="h-8 w-8" src="../../assets/coins/gold.png" />
              <span class="font-bold text-slate-300"
                >{{ balS.formatCrypto(this.amount * 10000, true) }} Gold Coins</span
              >
            </span>
            <br />
            <span class="flex items-center justify-center space-x-2">
              <img alt="USD Coin" class="h-8 w-8" src="../../assets/coins/cash.png" />
              <span class="font-bold text-slate-300"
                >{{ balS.formatCrypto(this.amount, true) }} Sweeps Coins For Free</span
              >
            </span>
          </div>
        </div>
      </div>
      <!-- ACH Payment Form for bundles -->
      <div>
        <div class="mx-auto w-full max-w-sm">
          <h2 class="mb-4 text-xl font-semibold">Payment Form</h2>

          <form
            @submit.prevent="submitForm"
            class="mb-4 rounded bg-black bg-opacity-25 px-8 pb-8 pt-6 shadow-md">
            <div v-if="!showFlinks">
              <div class="mb-4">
                <label class="mb-2 block text-sm font-bold text-gray-700" for="amount"
                  >Amount</label
                >
                <input
                  v-model="form.amount"
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="amount"
                  type="number"
                  step="any"
                  placeholder="Enter amount"
                  readonly
                  required />
              </div>
              <div class="mb-4">
                <label class=".input-bet" for="currency">Currency</label>
                <select
                  v-model="form.currency"
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="currency"
                  required>
                  <option value="">Select currency</option>
                  <option value="USD">USD</option>
                  <!-- <option value="EUR">EUR</option> -->
                  <!-- <option value="GBP">GBP</option> -->
                </select>
              </div>
              <div class="mb-4">
                <label class="mb-2 block text-sm font-bold text-gray-700" for="remarks"
                  >Remarks</label
                >
                <textarea
                  v-model="form.remarks"
                  class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="remarks"
                  placeholder="Enter remarks"></textarea>
              </div>
              <!-- <button @click="checkB">Close</button> -->
              <div class="mb-4">
                <button
                  class="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  @click="aptPayFlink"
                  :disabled="isLoading">
                  <span v-if="isLoading" class="animate-spin">Loading...</span>
                  <span v-else>Next</span>
                </button>
              </div>
            </div>
            <div>
              <div class="" v-if="showFlinks">
                <div
                  style="absolute width:100vw;height:50vh;overflow:hidden"
                  id="AptPayFlinksId"></div>
              </div>
            </div>
            <!-- <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="routingNumber">Routing Number</label>
          <input v-model="form.routingNumber" minlength="9" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="routingNumber" type="text" placeholder="Enter routing number" required>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="accountNumber">Account Number</label>
          <input v-model="form.accountNumber" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="accountNumber" type="text" placeholder="Enter account number" required>
        </div>
      -->
            <!-- Submit button for form -->
            <!-- <div>
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="submitPayment" :disabled="isLoading">
            <span v-if="isLoading" class="animate-spin">Loading...</span>
          <span v-else>Submit</span>
          </button>
        </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balanceStore } from '@/store/pinia/balanceStore';
import { aptPay } from '@/store/pinia/aptPay';
import { reactive, toRefs } from 'vue';
import { computed } from '@vue/reactivity';
import IdentityVerificationForm from './IdentityVerificationForm.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  inheritAttrs: false,
  name: 'PaymentForm',
  components: {
    IdentityVerificationForm,
  },
  // data() {
  //   return {
  //     selectedPaymentMethod: 'achDebit',
  //     achDebit: {
  //       bankName: '',
  //       accountNumber: '',
  //       routingNumber: ''
  //     },
  //     card: {
  //       cardNumber: '',
  //       expiryDate: '',
  //       cvc: ''
  //     },
  //     eft: {
  //       bankName: '',
  //       accountNumber: '',
  //       routingNumber: ''
  //     }
  //   };
  // },
  setup() {
    const balS = balanceStore();
    const aptP = aptPay();
    const isLoading = computed(() => aptP.isLoading);

    return { balS, aptP, isLoading };
  },
  computed: {
    ...mapGetters(['me']),
    hasPendingTransaction() {
      return this.lastTransactionAmount > 0;
    },
  },
  created() {
    this.aptP.getlatestAptPayVerify();
    this.aptP.identityCheck();
    window.toast = this.$toast;
    this.form.amount = this.balS.bundleSelected;
    this.showFlinks = false;
    setTimeout(() => {
      this.subscribe_aptpaynotification();
      this.shouldRender = this.aptP.isAptPayVerified;
    }, 200);
  },
  mounted() {
    this.form.amount = this.balS.getBundleSelected;
    // this.form.amount = this.form.amount??this.amount; update the form amount either from store or route path
    this.form.amount = this.form.amount ?? this.amount;
  },
  data() {
    return {
      form: {
        amount: 5,
        currency: 'USD',
        verification: true,
        embedVerification: true,
        // routingNumber: '',
        // accountNumber: '',
        remarks: '',
      },
      showFlinks: false,
      lastTransactionAmount: 0,
      lastTransactionCurrency: '',
      shouldRender: false,
    };
  },
  methods: {
    checkB() {
      setTimeout(() => {
        window.location.reload();
        this.$router.push({ path: '/' }).then(() => {
          this.$router.go();
        });
      }, 2000);
    },
    subscribe_aptpaynotification() {
      if (this.me && typeof this.me.id !== 'undefined') {
        try {
          window.Echo.private('App.User.' + this.me.id).listen(
            'AptpayNotification',
            ({ aptpaystatus }) => {
              if (aptpaystatus == 'PAYEE_VERIFICATION_COMPLETED') {
                this.shouldRender = true;
              }
            },
          );
        } catch (error) {
          console.log('AptpayNotification failed');
        }
      }
    },
    back() {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: 'bank', page: 'deposit', action: 'bundles' },
      });
    },
    async submitPayment() {
      // this.state.isLoading=this.aptP.isLoading;
      // Handle payment submission logic here
      try {
        let response = await this.aptP.payment(JSON.parse(JSON.stringify(this.form)));
        // Toast notification for payment responses.
        if (response.status == 422 && typeof response.data.error != 'undefined') {
          this.$toast.warning(response.data.error);
        }
        if (response.data['status']) {
          if (response.data['status'] == 'ACCEPTED') {
            this.$toast.success('Transaction Started');
          } else {
            this.$toast.success(response.data['status']);
          }
          /*
          setTimeout(() => {
            window.location.reload();
            this.$router.go(-1);
          }, 5000);
          */
        }
        if (typeof response.data.error_code != 'undefined') {
          this.$toast.error(response.data.errors['0']);
        }
        return response;
        // this.$toast.success('payment success')
      } catch (error) {}
      // return this.redirectToHomepage();
      // setTimeout(() => {
      //   this.state.isLoading=this.aptP.isLoading;
      // },10000);
    },
    async aptPayFlink() {
      this.showFlinks = true;
      try {
        let response = await this.submitPayment();
        if (response.status == 200) {
          let url = response.data['verificationUrl'];
          let refid = response.data['referenceId'];
          let result = null;
          let last_step = '';
          var oTerms = new AptPayFlinks({
            elementId: 'AptPayFlinksId',
            url: url,
            callback: function (agreed) {
              last_step = agreed;
              result = agreed;
              //here you can process the response. Flinks process is done.
            },
          });
          const waitForMyVar = () => {
            return new Promise((resolve) => {
              const checkMyVar = () => {
                if (result) {
                  resolve();
                } else {
                  setTimeout(checkMyVar, 100);
                }
              };
              checkMyVar();
            });
          };

          // use the promise to wait for myVar to be updated
          waitForMyVar().then(() => {
            // continue with the rest of your code here
            if (
              result &&
              result.hasOwnProperty('accountNumber') &&
              !result.hasOwnProperty('error')
            ) {
              /*let response = this.aptP.flinksVerification(
                this.form.amount,
                'flinks_done',
                refid
              );*/
              this.$toast.success('Purchase Complete');
              // fbq('track', 'Purchase', { value: this.form.amount, currency: 'USD' });
              if(import.meta.env.VITE_NODE_ENV == 'production')
              {
                // tracking purchase event on GTM
                this.$gtm.trackEvent({
                  event: 'purchase',
                  value: this.form.amount,
                  email: this.me.email,
                });
              }
            } else if (result && result.hasOwnProperty('error')) {
              this.$toast.error(
                'There is some error please try again error code: ' +
                  result.error +
                  ' ' +
                  result['error-message'],
              );
            }

            setTimeout(() => {
              window.location.reload();
              this.$router.push({ path: '/' }).then(() => {
                this.$router.go();
              });
            }, 3000);
          });
        }
        // else{
        // if(response.data.error_code){
        //   this.$toast.error(response.data.errors['0'])
        // }
        // }
      } catch (error) {}
    },
  },
  watch: {
    '$route.query.bundle': {
      handler(amount) {
        this.amount = amount && !isNaN(amount) ? amount : 5;
      },
      // force eager callback execution
      immediate: true,
    },
  },
};
</script>

<style scoped>
.animate-spin {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  to {
    opacity: 0.3;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    opacity: 0.3;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
