<template>
  <div
    class="max-w-[35px] md:max-w-[70px] flex items-center max-h-[35px] md:max-h-[70px]">
    <img src="../../../assets/new/icons/slots/rocket.png" />
  </div>
</template>

<script>
export default {
  name: 'SlotsGreyCat',
};
</script>
