<template>
  <punt-modal
    size="md:max-w-xl max-w-[92%] p-4 "
    :showClose="true"
    :onClose="closeModal"
    v-if="rollitnow">
    <template #body>
      <div
        class="modal is-active flex min-h-[300px] flex-col text-left space-y-2 text-slate-300">
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          You are about to do a {{ giveaway.sc_amount }} SC + {{ giveaway.gc_amount }} GC
          Giveaway
        </p>
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          Number of Winners: {{ giveaway.number_of_winner }}.
        </p>
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          Total Prize Value: {{ giveaway.number_of_winner * giveaway.sc_amount }} SC &
          {{ giveaway.number_of_winner * giveaway.gc_amount }} GC.
        </p>

        <div class="text-center mt-5" v-if="giveaway.inprogress">
          Rollout is in progress please wait...<br />
          Paid user {{ winnerLoadmore.total }} from {{ giveaway.number_of_winner }}
        </div>
        <div class="text-center mt-5" v-else-if="giveaway.winner_declared != null">
          Giveaway has ended! Winner has been declared on
          {{ this.formattedTime(giveaway.winner_declared) }}
        </div>
        <div
          class="text-center"
          v-else-if="
            !isLoading &&
            (giveaway.completed_at == null ||
              (giveaway.inprogress == false && giveaway.winner_declared == null))
          ">
          <button
            @click="closeModal"
            class="mr-4 bg-secondary-light text-white rounded rounded-2xl hover:opacity-75 px-10 py-3 mt-3">
            Cancel
          </button>
          <button
            @click="rollit"
            class="bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-10 py-3 mt-3">
            Roll it!
          </button>
        </div>
        <div class="text-center" v-else>Please wait untill...</div>
      </div>
    </template>
  </punt-modal>
  <div
    class="mt-4 flex-col md:flex-row flex flex-row p-5 items-center"
    v-if="giveaway.id">
    <div class="w-[100%] md:w-[20%] md:pr-6 flex flex-col">
      <h2 class="py-4 text-2xl font-bold text-slate-300">Subscribers</h2>

      <perfect-scrollbar
        ref="giveawayscrollsubscriber"
        @scroll="checkscrollSubscriber"
        v-chat-scroll="{ always: true }"
        class="h-[500px] flex-grow overflow-y-scroll overflow-x-hidden">
        <ul class="givewaylist-user">
          <li v-for="(subscriber, i) in subscribers" :key="i">
            {{ subscriber.username }}
          </li>
        </ul>
      </perfect-scrollbar>
      <div
        v-if="this.subscriberLoadmore.current_page < this.subscriberLoadmore.last_page"
        class="mt-5 text-sm">
        Scroll down to see more subscribers
      </div>
    </div>
    <div
      class="w-[100%] mt-10 mb-10 md:mt-0 md:mb-0 md:grow flex flex-col items-center p-5 w-full justify-center h-full giveaway-details shadow-accent">
      <div class="rollit-content text-center">
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          You are about to do a {{ giveaway.sc_amount }} SC + {{ giveaway.gc_amount }} GC
          Giveaway
        </p>
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          Number of Winners: {{ giveaway.number_of_winner }}.
        </p>
        <p class="text-white font-bold mb-5 mt-5 text-1xl text-center">
          Total Prize Value: {{ giveaway.number_of_winner * giveaway.sc_amount }} SC &
          {{ giveaway.number_of_winner * giveaway.gc_amount }} GC.
        </p>

        <button
          v-if="giveaway.completed_at == null && giveaway.inprogress == false"
          @click="rollitconfirm"
          class="bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3">
          Roll it!
        </button>
        <div v-else-if="giveaway.inprogress">
          Giveaway rollout is in progress please wait.. <br />
          Paid user {{ winnerLoadmore.total }} from {{ giveaway.number_of_winner }}
        </div>
        <div v-else>
          Giveaway has ended! Winner has been declared on
          {{ this.formattedTime(giveaway.winner_declared) }}
        </div>
        <div v-if="giveaway.completed_at == null" class="mt-5">
          Share URL : {{ this.getShareUrl() }}
          <button v-on:click="copyUrl" style="vertical-align: middle">
            <c-clipboard class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
    <div class="w-[100%] md:w-[20%] md:pl-6 flex flex-col">
      <h2 class="py-4 text-2xl font-bold text-slate-300">Winners</h2>

      <perfect-scrollbar
        ref="giveawayscrollwinner"
        @scroll="checkscrollWinner"
        v-chat-scroll="{ always: true }"
        class="h-[500px] flex-grow overflow-y-scroll overflow-x-hidden">
        <ul class="givewaylist-user">
          <li v-for="(winner, i) in winners" :key="i">
            {{ winner.username }}
          </li>
        </ul>
      </perfect-scrollbar>
      <div
        v-if="this.winnerLoadmore.current_page < this.winnerLoadmore.last_page"
        class="mt-5 text-sm">
        Scroll down to see more winners
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import { mapGetters } from 'vuex';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { GiveawayStores } from '@/store/pinia/giveawayStore';
import PuntModal from '@/components/Shared/PuntModal.vue';
import CClipboard from '../Shared/CopyToClipboard.vue';
import { copyToClipboard } from '@/lib/utils';
export default {
  name: 'GiveawayDetails',
  components: { PerfectScrollbar, PuntModal, CClipboard },
  props: {},
  data() {
    return {
      giveaway: {},
      id: null,
      errors: {},
      winners: [],

      subscriberCount: 0,
      rollitnow: false,
      subscribers: [],
      isLoading: false,
      shareUrl: `${location.protocol}//${location.host}/giveaways/active?join=`,
      winnerLoadmore: {
        current_page: 0,
        per_page: 0,
        last_page: 0,
        total: 0,
      },
      subscriberLoadmore: {
        current_page: 0,
        per_page: 0,
        last_page: 0,
        total: 0,
      },
    };
  },
  setup() {
    return { GiveawayStores: GiveawayStores() };
  },
  mounted() {
    if (window.Echo) {
      this.bindEcho();
    }
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    checkscrollSubscriber() {
      if (
        this.$refs.giveawayscrollsubscriber.$el.scrollTop >
        this.$refs.giveawayscrollsubscriber.$el.scrollHeight -
          this.$refs.giveawayscrollsubscriber.$el.offsetHeight -
          400
      ) {
        this.fetchMoreSubscriber();
        return false;
      }
    },
    checkscrollWinner() {
      if (
        this.$refs.giveawayscrollwinner.$el.scrollTop >
        this.$refs.giveawayscrollwinner.$el.scrollHeight -
          this.$refs.giveawayscrollwinner.$el.offsetHeight -
          400
      ) {
        this.fetchmoreWinner();
        return false;
      }
    },

    async fetchMoreSubscriber() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          let page = this.subscriberLoadmore.current_page + 1;
          const data = await this.GiveawayStores.fetchSubscriber(this.id, {
            page,
          });
          const current_data = this.subscribers;
          this.subscribers = [...data.data.data, ...current_data];
          this.subscriberLoadmore.current_page = data.data.current_page;
          this.subscriberLoadmore.per_page = data.data.per_page;
          this.subscriberLoadmore.last_page = data.data.last_page;
          this.subscriberLoadmore.total = data.data.total;
        } catch (error) {
          console.log('fetchmoresubsriber');
        }

        this.isLoading = false;
      }
    },
    async fetchmoreWinner() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          let page = this.winnerLoadmore.current_page + 1;
          const data = await this.GiveawayStores.fetchWinners(this.id, {
            page,
          });
          const current_data = this.winners;
          this.winners = [...current_data, ...data.data.data];
          this.winnerLoadmore.current_page = data.data.current_page;
          this.winnerLoadmore.per_page = data.data.per_page;
          this.winnerLoadmore.last_page = data.data.last_page;
          this.winnerLoadmore.total = data.data.total;
        } catch (error) {
          console.log('erro fetching more');
        }
        this.isLoading = false;
      }
    },
    formattedTime(timestamp) {
      return dateFormat(new Date(timestamp), 'yyyy/mm/dd h:MM:ss TT');
    },
    async init() {
      try {
        const data = await this.GiveawayStores.fetch(this.id);

        this.giveaway = data.data.giveaway;

        this.subscribers = data.data.subscribers.data;
        this.subscriberLoadmore.current_page = data.data.subscribers.current_page;
        this.subscriberLoadmore.per_page = data.data.subscribers.per_page;
        this.subscriberLoadmore.last_page = data.data.subscribers.last_page;
        this.subscriberLoadmore.total = data.data.subscribers.total;

        this.winners = data.data.winners.data;
        this.winnerLoadmore.current_page = data.data.winners.current_page;
        this.winnerLoadmore.per_page = data.data.winners.per_page;
        this.winnerLoadmore.last_page = data.data.winners.last_page;
        this.winnerLoadmore.total = data.data.winners.total;

        setTimeout(() => {
          try {
            this.$refs.giveawayscrollsubscriber.$el.scrollTop = 0;
          } catch (e) {}
        }, 50);
      } catch (e) {
        this.$toast.error('Giveaway not found');
      }
    },
    async rollit() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const data = await this.GiveawayStores.rollit(this.id);

          if (data.data.success == true) {
            this.$toast.success(data.data.msg);

            this.giveaway = data.data.giveaway;
            this.rolloutstarted = true;
          } else if (typeof data.data.error != 'undefined') {
            this.$toast.warning(data.data.error);
          }
        } catch (e) {
          if(typeof e.data.error != 'undefined')
          this.$toast.error(e.data.error);
        }
        this.isLoading = false;
      }
    },
    rollitconfirm() {
      this.rollitnow = true;
    },
    getdescription() {
      return (
        'You are about to do a ' +
        this.giveaway.sc_amount +
        ' SC + ' +
        this.giveaway.gc_amount +
        ' GC Giveaway  <br>Number of Winners: ' +
        this.giveaway.number_of_winner +
        '.<br>Total Prize Value: ' +
        this.giveaway.number_of_winner * this.giveaway.sc_amount +
        ' SC & ' +
        this.giveaway.number_of_winner * this.giveaway.gc_amount +
        ' GC.'
      );
    },
    copyUrl() {
      copyToClipboard(this.getShareUrl());
    },
    getShareUrl() {
      return `${location.protocol}//${location.host}/giveaways/active?join=${this.giveaway.id}`;
    },
    bindEcho() {
      if (this.me && typeof this.me.id !== 'undefined') {
        try {
          window.Echo.private('App.User.' + this.me.id)
            .listen('GiveawaySubscribed', (data) => {
              if (data.subscriber.giveaway_id == this.giveaway.id) {
                if (this.subscribers.length > this.subscriberLoadmore.per_page)
                  this.subscribers.pop();

                this.subscribers.unshift(data.subscriber);
                this.subscriberLoadmore.total++;
                setTimeout(() => {
                  try {
                    this.$refs.giveawayscrollsubscriber.$el.scrollTop = 0;
                  } catch (e) {}
                }, 50);
              }
            })
            .listen('GiveawayRolledOut', (data) => {
              if (data.data.giveaway.id == this.giveaway.id) {
                if (data.data.status == true) {
                  this.$toast.success(data.data.msg);
                  this.giveaway = data.data.giveaway;

                  /*this.winners =data.data.winners.data;
                              this.winnerLoadmore.current_page = data.data.winners.current_page;
                              this.winnerLoadmore.per_page = data.data.winners.per_page;
                              this.winnerLoadmore.last_page = data.data.winners.last_page;
                              this.winnerLoadmore.total = data.data.winners.total;*/
                  this.init();

                  this.closeModal();
                } else {
                  this.$toast.warning(data.data.msg);
                }
              }
            })
            .listen('GiveawaySubscribedWon', (data) => {
              if (data.winner && data.winner.giveaway_id == this.giveaway.id) {
                if (data.winner) {
                  this.winnerLoadmore.total++;
                }
              }
            });
        } catch (Error) {
          console.log('giveaway subscription pusher failed');
        }
      }
    },
    leaveECho() {},
    closeModal() {
      this.rollitnow = false;
    },
  },
  watch: {
    '$route.params.id': {
      handler: async function (newState) {
        this.id = newState;
        await this.init();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
