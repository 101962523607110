import { generateHexString } from '@/lib/random';
import axios from '@/lib/myAxios';

import { getFetch, postFetch } from './http';
import { balanceStore } from '@/store/pinia/balanceStore';

const BlackjackMutation = {
  SET_NONCE: 'SET_NONCE',
  SET_SERVER_SEED_HASH: 'SET_SERVER_SEED_HASH',
  UPDATE_CLIENT_SEED: 'UPDATE_CLIENT_SEED',
  UPDATE_WAGER: 'UPDATE_WAGER',
  START_PLAY: 'START_PLAY',
  END_PLAY: 'END_PLAY',
};

export default {
  namespaced: true,
  state: {
    showProvablyFair: false,
    seed: {
      client: '',
      serverHash: '',
      nonce: 1,
    },
    initialized: false,
    playStarted: false,
    wager: 0.01,
  },
  getters: {
    clientSeed: (state) => state.seed.client,
    serverSeedHash: (state) => state.seed.serverHash,
    seedPairNonce: (state) => state.seed.nonce,
    showProvablyFair: (state) => state.showProvablyFair,
    wager: (state) => state.wager,
    playStarted: (state) => state.playStarted,
  },
  actions: {
    autoClientSeed({ dispatch, state }) {
      return;
      let p = Promise.resolve();

      if (!state.seed.serverHash) {
        p = dispatch('updateSeedInfo');
      }

      return p
        .then(() => {
          if (!state.seed.client) {
            return dispatch('setNewClientSeed', {
              seed: generateHexString(30),
            });
          }
        })
        .catch(() => {
          p = dispatch('refreshSeeds');
        });
    },
    updateSeedInfo({ commit }) {
      return getFetch('blackjack/seeds', 'refreshing seed information').then(
        ({ data }) => {
          commit(BlackjackMutation.SET_NONCE, data.nonce);
          commit(BlackjackMutation.SET_SERVER_SEED_HASH, data.server_seed_hash);
          commit(BlackjackMutation.UPDATE_CLIENT_SEED, data.client_seed);
        },
      );
    },
    updateServerSeedHash(ctx, seed) {
      ctx.commit(BlackjackMutation.SET_SERVER_SEED_HASH, seed);
    },
    updateClientSeed(ctx, seed) {
      if (ctx.state.seed.client === '') {
        ctx.commit(BlackjackMutation.UPDATE_CLIENT_SEED, seed);
      } else {
        ctx.actions
          .refreshSeeds(ctx)
          .then(() => ctx.commit(BlackjackMutation.UPDATE_CLIENT_SEED, seed));
      }
    },
    promptClientSeed({ commit, dispatch }) {
      window
        .swal({
          title: 'Create a new seed pair',
          text: 'Enter a phrase below to use as your client seed.',
          input: 'text',
          showCancelButton: true,
          // closeOnConfirm: false,
          animation: 'slide-from-top',
          inputPlaceholder: 'Client seed',
          inputValue: generateHexString(30),
        })
        .then((inputValue) => {
          if (inputValue === false) {
            return false;
          }

          if (inputValue === '') {
            window.swal('Whoops!', 'You need to write something!', 'error');
            return false;
          }

          dispatch('setNewClientSeed', { seed: inputValue }).then(({ clientSeed }) => {
            dispatch('hideProvablyFair');
            setTimeout(() => {
              window.swal.closeModal();
            }, 2000);
            window.swal({
              text: 'Your client seed is now set to ' + clientSeed,
              type: 'success',
            });
          });
        })
        .catch((err) => {
          if (err !== 'cancel' && err !== 'overlay') {
            window.swal({
              title: 'Unable to change the seed!',
              text: err.message || err,
              type: 'error',
            });
          }
        });
    },
    setNewClientSeed({ commit }, { seed }) {
      return postFetch(
        'blackjack/seeds',
        { action: 'update_client', client_seed: seed },
        'changing seed',
      ).then(({ data }) => {
        commit(BlackjackMutation.SET_NONCE, data.nonce);
        commit(BlackjackMutation.SET_SERVER_SEED_HASH, data.server_seed_hash);
        commit(BlackjackMutation.UPDATE_CLIENT_SEED, data.client_seed);
        return data;
      });
    },
    refreshSeeds({ commit, dispatch }) {
      return postFetch(
        'blackjack/seeds',
        { action: 'refresh' },
        'refreshing the server seed',
      ).then(() => {
        dispatch('updateSeedInfo');
      });
    },
    hideProvablyFair({ commit }) {
      commit(BlackjackMutation.HIDE_PF);
    },
    showProvablyFair({ commit }) {
      commit(BlackjackMutation.SHOW_PF);
    },
    updateWager({ commit, getters, state, rootState }, wager) {
      if (state.playStarted) {
        return;
      }
      commit(BlackjackMutation.UPDATE_WAGER, wager);
    },
    setPlayStarted({ commit }) {
      commit(BlackjackMutation.START_PLAY);
    },
    setPlayEnded({ commit }) {
      commit(BlackjackMutation.END_PLAY);
    },
    updateNonce({ commit, getters }) {
      commit(BlackjackMutation.SET_NONCE, getters.seedPairNonce + 1);
    },

    startGame({ commit, dispatch, getters }) {
      if (getters.wager < 0) {
        window.swal('Uh-oh', 'Total Play cannot be less than zero');
        return;
      }

      const balS = balanceStore();
      const wager = balS.reverseExchangeCurrency(getters.wager);
      commit(BlackjackMutation.START_PLAY);
      return axios
        .post('blackjack', { action: 'BET', wager })
        .catch(({ data, status }) => {
          // commit(BlackjackMutation.ENABLE_BETTING)
          if (
            typeof data.error != 'undefined' &&
            status === 412 &&
            data.error === 'Client seed must be set!'
          ) {
            return dispatch('promptClientSeed');
          } else {
            if (typeof data.error != 'undefined' && data.error === 'Unauthenticated.') {
              window.location.replace(window.location.href + '?tab=login');
            } else {
              window.swal(
                'Uh-oh',
                'Something went wrong when placing your play: ' +
                  (typeof data.error != 'undefined' ? data.error : ''),
                'error',
              );
            }
          }
        });
    },
  },
  mutations: {
    [BlackjackMutation.SET_NONCE](state, nonce) {
      state.seed.nonce = nonce;
    },
    [BlackjackMutation.SET_SERVER_SEED_HASH](state, seedHash) {
      state.seed.serverHash = seedHash;
    },
    [BlackjackMutation.UPDATE_CLIENT_SEED](state, seed) {
      state.seed.client = seed;
    },
    [BlackjackMutation.HIDE_PF](state) {
      state.showProvablyFair = false;
    },
    [BlackjackMutation.SHOW_PF](state) {
      state.showProvablyFair = true;
    },
    [BlackjackMutation.UPDATE_WAGER](state, wager) {
      if (wager === undefined) return;
      state.wager = wager;
    },
    [BlackjackMutation.START_PLAY](state) {
      state.playStarted = true;
    },
    [BlackjackMutation.END_PLAY](state) {
      state.playStarted = false;
    },
  },
};
