const OrderMutations = {
  CANCEL_ORDER: 'orders/CANCEL_ORDER',
  COMPLETE_ORDER: 'orders/COMPLETE_ORDER',
  OPEN_ORDER: 'orders/OPEN_ORDER',
  UPDATE_ORDER: 'orders/UPDATE_ORDER',
  RESET: 'orders/RESET',
  SET_AMOUNT: 'orders/SET_AMOUNT',
  SET_RSN: 'orders/SET_RSN',
  SET_TYPE: 'orders/SET_TYPE',
};

const DEFAULT_AMOUNT = 500e3;

export default {
  namespaced: true,

  state: {
    form: {
      amount: DEFAULT_AMOUNT,
      rsn: '',
      type: null,
    },
    completedOrders: [],
    incompleteOrders: [],
  },

  getters: {
    activeOrder: (state) => state.incompleteOrders[0],
    hasActiveOrder: (state) => state.incompleteOrders.length > 0,
  },

  actions: {
    cancelOrder(x, orderId) {
      return Vue.http.delete(`orders/${orderId}`);
    },
    async refreshOrders({ state, commit }) {
      const orders = await Vue.http.get('orders').then(({ data }) => data);
      orders.forEach((order) => commit(OrderMutations.UPDATE_ORDER, order));
    },
    async placeOrder({ state }) {
      return Vue.http.post('orders', {
        amount: state.form.amount,
        rsn: state.form.rsn,
        type: state.form.type.toLowerCase(),
      });
    },
    resetForm({ commit }) {
      commit(OrderMutations.RESET);
    },
  },

  mutations: {
    [OrderMutations.SET_AMOUNT](state, amount) {
      state.form.amount = amount;
    },
    [OrderMutations.SET_RSN](state, rsn) {
      state.form.rsn = rsn;
    },
    [OrderMutations.SET_TYPE](state, type) {
      state.form.type = type;
    },
    [OrderMutations.RESET](state, type) {
      state.form = {
        amount: DEFAULT_AMOUNT,
        rsn: '',
        type: null,
      };
    },
    [OrderMutations.CANCEL_ORDER](state, order) {
      state.incompleteOrders = [
        ...state.incompleteOrders.filter((x) => x.id !== order.id),
      ];
    },
    [OrderMutations.OPEN_ORDER](state, order) {
      state.incompleteOrders = [
        order,
        ...state.incompleteOrders.filter((x) => x.id !== order.id),
      ];
    },
    [OrderMutations.COMPLETE_ORDER](state, order) {
      state.incompleteOrders = [
        ...state.incompleteOrders.filter((x) => x.id !== order.id),
      ];
    },
    [OrderMutations.UPDATE_ORDER](state, order) {
      if (order.is_active) {
        state.incompleteOrders = [
          order,
          ...state.incompleteOrders.filter((x) => x.id !== order.id),
        ];
      } else {
        state.completedOrders = [
          order,
          ...state.completedOrders.filter((x) => x.id !== order.id),
        ];
      }
    },
  },
};
