<template>
  <div v-if="filteredGames.length > 0" class="flex flex-col md:px-6 px-2 py-2">
    <div class="px-2 md:py-4 flex flex-row justify-between items-center max-[575px]:px-0">
      <router-link
        :to="'/casino/group/' + slug"
        class="flex items-center flex-grow justify-start gap-2 py-4">
        <img class="h-12" :src="icon" />
        <h2 class="font-semibold text-md break-all md:text-xl">{{ title }}</h2>
      </router-link>
      <div class="flex flex-row">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-4 py-2 text-gray-300 border border-slate-600 rounded-l-xl hover:opacity-60 cursor-pointer">
          <img src="../../assets/3d/right-arrow.png" class="h-5 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-4 py-2 text-gray-300 border border-slate-600 rounded-r-xl hover:opacity-60 cursor-pointer">
          <img src="../../assets/3d/right-arrow.png" class="h-5 md:h-8" />
        </button>
      </div>
    </div>
    <!--    <vue-scroll :ref="slug" @handle-scroll-complete="handleComplete" class="srcoll-fix">-->
    <div
      :ref="this.slug"
      class="grid grid-cols-none grid-flow-col auto-cols-max gap-6 no-scrollbar overflow-x-scroll grid-rows-1 w-full px-2 py-2">
      <div v-for="(game, i) in filteredGames" class="">
        <router-link
          class="flex flex-col items-center md:transition md:ease-in-out md:delay-0 md:hover:scale-105 md:hover:rotate-1 md:duration-150 srcoll-fix"
          :to="getLink(game)"
          :key="i">
          <img
            v-lazy="getImageUrl(game)"
            class="rounded-md shadow-md w-28 md:w-56"
            alt="Game Tile" />
          <span
            class="text-slate-300 text-center font-semibold text-md mt-1 py-1 px-4 rounded-2xl"
            >{{ game.provider_name }}</span
          >
        </router-link>
      </div>
    </div>
    <!--    </vue-scroll>-->
  </div>
</template>

<script>
import { clamp } from 'lodash';
import V2 from '@/assets/svg/tiles/png/v2test.png';
import Test from '@/assets/svg/tiles/png/test.png';
import Secondary from '@/assets/svg/tiles/png/secondaryTest.png';

const acceptanceTiles = {
  v2test: V2,
  secondary_test: Secondary,
  test: Test,
};

export default {
  name: 'SoftswissHomeTiles',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { scrollAmount: 0 };
  },
  computed: {
    filteredGames() {
      return this.games
        .filter((game) => this.filterGames(game))
        .filter((game) => this.testsFilter(game))
        .sort((a, b) => parseFloat(b.rank) - parseFloat(a.rank));
    },
  },
  methods: {
    getImageUrl(game) {
      if (game.provider === 'acceptance') {
        return acceptanceTiles[game.identifier2];
      }
      const identifier = this.getIdentifierName(game.identifier);
      return `https://cdn.softswiss.net/i/s4/${game.provider}/${identifier}.png`;
    },
    testsFilter(game) {
      const isProdMode = import.meta.env.MODE === 'punt_prod';
      return isProdMode ? game.provider !== 'acceptance' : true;
    },
    filterGames(game) {
      const liveFilter = ['live', 'table-games'].includes(this.slug)
        ? game.has_live === true
        : game.has_live === false;
      return (
        (this.categories.includes(game.category) || this.categories.length === 0) &&
        liveFilter
      );
    },
    getLink(game) {
      const gameName = this.getIdentifierName(game.identifier);
      return `/casino/games/${game.provider}-${gameName}`;
    },
    getIdentifierName(gameName) {
      return gameName.split(':')[1];
    },
    scroll(isRight = true) {
      const menu = this.$refs[this.slug];
      const width = menu.scrollWidth - menu.clientWidth;
      this.scrollAmount = clamp(
        isRight ? (this.scrollAmount += 200) : (this.scrollAmount -= 200),
        0,
        width,
      );
      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
  },
};
</script>
