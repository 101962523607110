import Btc from '@/assets/svg/wallet/btc.svg';
import Eth from '@/assets/svg/wallet/eth.svg';
import Ltc from '@/assets/svg/wallet/ltc.svg';
import Doge from '@/assets/svg/wallet/dodge.svg';
import Bch from '@/assets/svg/wallet/bch.svg';
import Xrp from '@/assets/svg/wallet/xrp.svg';
import Trx from '@/assets/svg/wallet/trx.svg';
import Eos from '@/assets/svg/wallet/eos.svg';
import Sol from '@/assets/svg/wallet/sol.svg';
import BNB from '@/assets/svg/wallet/bnb.svg';
import USDT from '@/assets/svg/wallet/usdt.svg';

import Brl from '@/assets/svg/fiat/brl.svg';
import Cad from '@/assets/svg/fiat/cad.svg';
import Cny from '@/assets/svg/fiat/cny.svg';
import Dkk from '@/assets/svg/fiat/dkk.svg';
import Euro from '@/assets/svg/fiat/euro.svg';
import Idr from '@/assets/svg/fiat/idr.svg';

import Inr from '@/assets/svg/fiat/inr.svg';
import Jpy from '@/assets/svg/fiat/jpy.svg';
import Krw from '@/assets/svg/fiat/krw.svg';
import Mxn from '@/assets/svg/fiat/mxn.svg';
import Php from '@/assets/svg/fiat/euro.svg';
import Rub from '@/assets/svg/fiat/rub.svg';
import Usd from '@/assets/svg/fiat/usd.svg';
import GC from '@/assets/coins/gold.png';
import SC from '@/assets/coins/cash.png';

const balanceIcons = [
  {
    name: 'SC',
    icon: SC,
  },
  {
    name: 'GC',
    icon: GC,
  },
  {
    name: 'BTC',
    icon: Btc,
  },
  {
    name: 'ETH',
    icon: Eth,
  },
  {
    name: 'LTC',
    icon: Ltc,
  },
  {
    name: 'DOGE',
    icon: Doge,
  },
  {
    name: 'BCH',
    icon: Bch,
  },
  {
    name: 'XRP',
    icon: Xrp,
  },
  {
    name: 'TRX',
    icon: Trx,
  },
  {
    name: 'EOS',
    icon: Eos,
  },
  {
    name: 'SOL',
    icon: Sol,
  },
  {
    name: 'USDT',
    icon: USDT,
  },
  {
    name: 'USDTT',
    icon: USDT,
  },
  {
    name: 'USDTE',
    icon: USDT,
  },
  {
    name: 'BNB-BSC',
    icon: BNB,
  },
  {
    name: 'BNB',
    icon: BNB,
  },
  {
    name: 'EUR',
    icon: Euro,
  },
  {
    name: 'BRL',
    icon: Brl,
  },
  {
    name: 'CAD',
    icon: Cad,
  },
  {
    name: 'CNY',
    icon: Cny,
  },
  {
    name: 'JPY',
    icon: Jpy,
  },
  {
    name: 'KRW',
    icon: Krw,
  },
  {
    name: 'MXN',
    icon: Mxn,
  },
  {
    name: 'PHP',
    icon: Php,
  },
  {
    name: 'RUB',
    icon: Rub,
  },
  {
    name: 'USD',
    icon: Usd,
  },
  {
    name: 'INR',
    icon: Inr,
  },
  {
    name: 'DKK',
    icon: Dkk,
  },
  {
    name: 'IDR',
    icon: Idr,
  },
];

export const getBalanceIcon = (name) => {
  const icon = balanceIcons.find((item) => item.name === name);
  return icon ? icon.icon : null;
};

export const getCurrencies = () => {
  return balanceIcons.map((item) => item.name);
};
