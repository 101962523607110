<template>
  <!-- section-social-games -->
  <section id="games" class="section section-social-games">
    <!--    CASINO STYLE GAMES SECTION -->
    <div class="section-top">
      <div class="container">
        <div class="innersection-top flex">
          <div class="left-part md:w-2/4">
            <h2 class="section-title max-w-[580px] mb-[96px]">
              PLAY <span>FREE-TO-PLAY</span> GAMES!
            </h2>
          </div>
          <div class="right-part md:w-2/4 text-right pt-[54px] desktop">
            <button
              class="social-slider-prev navigation-arr mr-[16px] pointer"
              @click="socialgameprev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M4 12L10 6M4 12L10 18M4 12H20"
                  stroke="#031618"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
            <button
              class="social-slider-next navigation-arr pointer"
              @click="socialgamenext">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M20 12L14 6M20 12L14 18M20 12H4"
                  stroke="#031618"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>
        <div class="innersection-bottom">
          <div class="social-slider" ref="slickslider">
            <div class="social-slide">
              <img :src="getImageUrl('pragmatic/vs20fruitsw.webp')" class="mb-6" alt="slider image" />
              <!-- <h3 class="text-nowrap overflow-hidden text-ellipsis slider-title">
                Sweet Bonanza
              </h3> -->
            </div>
            <div class="social-slide">
              <img :src="getImageUrl('pragmatic/vs20olympgate.webp')" class="mb-6" alt="slider image" />
              <!-- <h3 class="text-nowrap overflow-hidden text-ellipsis slider-title">
                Gates of Olympus
              </h3> -->
            </div>
            <div class="social-slide">
              <img :src="getImageUrl('relax/wdoraw.webp')" class="mb-6" alt="slider image" />
              <!-- <h3 class="text-nowrap overflow-hidden text-ellipsis slider-title">
                Wanted dead or a wild
              </h3> -->
            </div>
            <div class="social-slide">
              <img :src="getImageUrl('relax/bananatown.webp')" class="mb-6" alt="slider image" />
              <!-- <h3 class="text-nowrap overflow-hidden text-ellipsis slider-title">
                Banana Town
              </h3> -->
            </div>
            <div class="social-slide">
              <img :src="getImageUrl('pragmatic/vs20dhcluster.webp')" class="mb-6" alt="slider image" />
              <!-- <h3 class="text-nowrap overflow-hidden text-ellipsis slider-title">
                Twilight princess
              </h3> -->
            </div>
          </div>
        </div>
        <div class="mobile-buttons">
          <div class="right-part md:w-2/4 text-right pt-[54px]">
            <button
              class="social-slider-prev navigation-arr mr-[16px] pointer"
              @click="socialgameprev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M4 12L10 6M4 12L10 18M4 12H20"
                  stroke="#031618"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
            <button
              class="social-slider-next navigation-arr pointer"
              @click="socialgamenext">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M20 12L14 6M20 12L14 18M20 12H4"
                  stroke="#031618"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--LATEST WINNER SLIDE-->
<!--    <div id="latestWinner" class="section-bottom">-->
<!--      <h2 class="section-title text-center mb-[32px]">Latest winners</h2>-->
<!--      <p class="section-desc m-auto max-w-[358px] text-center mb-[129px] uppercase">-->
<!--        Dive into the excitement with our 500 games-->
<!--      </p>-->

<!--      <div class="winner-slider" ref="winnersliderrow1">-->

<!--        <div class="winner-slide" v-for="(bet, key) in recentBets2"-->
<!--        v-bind:key="key">-->
<!--          <div-->
<!--            class="left-part"-->
<!--            :style="'background-image: url(' + getGCImageUrl(bet) + ')'">-->
<!--            <img :src="getGCImageUrl(bet)" alt="" />-->
<!--          </div>-->
<!--          <div class="right-part">-->
<!--            <h3 class="title">{{ bet.title }}</h3>-->
<!--            <h4 class="name">{{ bet.username }}</h4>-->
<!--            <div class="price">-->
<!--              <img :src="getImage('coin.png')" alt="" />-->
<!--              {{ bet.wager }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!-- -->
<!--      </div>-->

<!--      <div class="winner-slider sm:block hidden" ref="winnersliderrow2" dir="rtl">-->
<!--        <div class="winner-slide" v-for="(bet, key) in recentBets2"-->
<!--        v-bind:key="key" dir="ltr">-->
<!--          <div-->
<!--            class="left-part"-->
<!--            :style="'background-image: url(' + getGCImageUrl(bet) + ')'">-->
<!--            <img :src="getGCImageUrl(bet)" alt="" />-->
<!--          </div>-->
<!--          <div class="right-part">-->
<!--            <h3 class="title">{{ bet.title }}</h3>-->
<!--            <h4 class="name">{{ bet.username }}</h4>-->
<!--            <div class="price">-->
<!--              <img :src="getImage('coin.png')" alt="" />-->
<!--              {{ bet.wager }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
  <!-- section-social-games -->
</template>

<script>
import { mapGetters } from 'vuex';
import lady_background from '@/assets/home/lady.webp';
import axios from '../../../lib/myAxios';
import $ from 'jquery';
import { isMobile } from 'mobile-device-detect';
export default {
  name: 'SocialGames',
  components: {},
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data() {
    return {
      lady_background,
      recentBets: [],
      recentBets2: [],
    };
  },
  async created() {
    await this.getGcBets();
  },
  mounted: function () {
    $(this.$refs.slickslider).slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      dots: false,

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
   
  },
  methods: {
    initSlider()
    {
      $(this.$refs.winnersliderrow1).slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2,
      cssEase: 'linear',
      speed: 5000,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      dots: false,

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            speed: 3000,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            speed: 3000,
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
    $(this.$refs.winnersliderrow2).slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2,
      cssEase: 'linear',
      speed: 5000,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      rtl: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            speed: 3000,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            speed: 3000,
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
    },
    getGCImageUrl(game) {
      if (game.type == 'relax') {
        return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
      }

      if (game.type == 'softswiss') {
        return `${location.protocol}//${location.host}/images/softswiss/${game.identifier}.webp`;
      }

      if (game.type == 'pragmatic') {
        return `${location.protocol}//${location.host}/images/pragmatic/${game.identifier}.webp`;
      }
    },
    async getGcBets() {
      try {
        let data = await axios.get(`gettopgc`);
        this.recentBets = data.data;
        if (this.recentBets.length > 0) {
          const divide_factor = this.recentBets.length / 2;
          this.recentBets2 = this.recentBets.slice(divide_factor, this.recentBets.length);
          this.recentBets = this.recentBets.slice(0, divide_factor);

          setTimeout(() => {
            this.initSlider();
          }, 200);
        }
      } catch(error) {
        console.log('error while getting top gc');
      }
    },
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
    getImageUrl(image) {
      return `${location.protocol}//${location.host}/images/${image}`;
    },
    socialgameprev() {
      $(this.$refs.slickslider).slick('slickPrev');
    },
    socialgamenext() {
      $(this.$refs.slickslider).slick('slickNext');
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },

    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      console.log('handleAfterChange', event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
      console.log('handleBreakpoint', event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
      console.log('handleDestroy', event, slick);
    },
    handleEdge(event, slick, direction) {
      console.log('handleEdge', event, slick, direction);
    },
    handleInit(event, slick) {
      console.log('handleInit', event, slick);
    },
    handleReInit(event, slick) {
      console.log('handleReInit', event, slick);
    },
    handleSetPosition(event, slick) {
      console.log('handleSetPosition', event, slick);
    },
    handleSwipe(event, slick, direction) {
      console.log('handleSwipe', event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
      console.log('handleLazyLoaded', event, slick, image, imageSource);
    },
    handleLazyLoadError(event, slick, image, imageSource) {
      console.log('handleLazeLoadError', event, slick, image, imageSource);
    },
  },
};
</script>
