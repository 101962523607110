<template>
  <div class="mines-wrap">
    <div class="mines-container">
      <div v-if="completed && profit > 0" class="game-result bg-secondary-light/70">
        <div class="game-result-content">
          <span class="text-2xl font-bold">{{ multiplier.toFixed(2) }}x</span>
          <span class="mt-2 flex gap-2 font-semibold">
            {{ balS.exchangeAndFormatAmount(profit, game_mode) }}
            <img class="h-5 w-5" :src="getBalanceIcon(game_mode)" />
          </span>
        </div>
      </div>
      <div class="mines-inner">
        <div class="mines-options w-full 2xl:w-[80%] gap-2 md:gap-4">
          <mines-grid-item
            v-for="i in 25"
            v-bind:key="i"
            :ref="'button' + i"
            :completed="completed"
            :index="i"
            :isManual="isManualMode"
            :currentState="currentState"
            :isMine="fields[i].isMine"
            :loading="fields[i].loading"
            :onclick="playNumber"
            :selected="fields[i].selected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertGameMode, defaultConvertor } from '../../lib/rsgp';
import { debounce } from 'lodash';
import MinesGridItem from './MinesGridItem.vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'minesGrid',
  components: {
    MinesGridItem,
  },
  props: {
    currentState: {
      default: 'notStarted',
    },
    fields: {},
    playNumber: {},
    numberOfSelected: {
      default: 0,
    },
    completed: {},
    profit: {},
    multiplier: {},
    game_mode: {},
    isManualMode: {},
  },
  setup() {
    return {
      balS: balanceStore(),
      getBalanceIcon,
    };
  },
  created() {
    for (let i = 1; i <= 25; i++) {
      this.debouncedPlay[i] = debounce(() => this.playNumber(i), 10, {
        trailing: false,
        leading: true,
      });
    }
  },
  data: () => ({
    hover: false,
    debouncedPlay: {},
  }),
  methods: {
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    convertGameMode(x) {
      return convertGameMode(x);
    },
  },
  computed: {
    numberOfCorrect() {
      let res = 0;
      for (let i = 1; i <= 25; ++i) {
        let field = this.fields[i];
        res += field.selected && !field.isMine;
      }
      return res;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .option-button {
    min-height: 20px;
    max-height: 30px;
  }
  .mines-inner {
  }
}

@media (min-width: 769px) and (max-width: 1369px) {
  .mines-inner {
    height: 100%;
  }
}

@media (min-width: 1370px) {
  .mines-inner {
    height: 100%;
  }
}

.game-result-content {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  opacity: 1;
  animation-name: animate_game_result;
  -moz-animation-name: animate_game_result;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.game-result {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ee563;
  border-radius: 0.5rem;
  z-index: 2;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  max-width: 100%;
  min-width: 150px;
  min-height: 70px;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  border: 4px solid #1ee563;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
}

@keyframes animate_game_result {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mines-wrap {
  height: 100%;
  background-color: transparent;
}

.mines-options {
  position: relative;
  display: grid;
  max-width: 780px;
  grid-template-columns: repeat(5, auto);
}

.mines-container {
  background-color: transparent;
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  user-select: none;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.mines-inner {
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 1em;
  gap: 1em;
}
</style>
