<template>
  <section class="section section-video-block">
    <div class="bg-1">
      <img :src="getImage('video-bg-1.png')" alt="" />
    </div>
    <div class="bg-2">
      <img :src="getImage('video-bg-2.png')" alt="" />
    </div>
    <div class="container">
      <div class="top-part">
        <h2 class="section-title max-w-[315px] mb-[32px] text-center m-auto">
          Real people real <span>wins</span>
        </h2>
        <p class="section-desc text-center uppercase">Fun should always be free</p>
      </div>
    </div>
    <div class="slider-part">
      <div class="video-slider carousel" ref="videoslider">
        <div class="inner-wrapper carousel-item">
          <img :src="getImage('ezgif-1.gif')" alt="" />
        </div>
        <div class="inner-wrapper carousel-item">
          <img v-lazy="getImage('ezgif-2.gif')" alt="" />
        </div>
        <div class="inner-wrapper carousel-item">
          <img v-lazy="getImage('ezgif-3.gif')" alt="" />
        </div>
        <div class="inner-wrapper carousel-item">
          <img :src="getImage('slide-1.png')" alt="" />
        </div>
        <div class="inner-wrapper carousel-item">
          <img :src="getImage('slide-2.png')" alt="" />
        </div>
        <div class="inner-wrapper carousel-item">
          <img :src="getImage('slide-3.png')" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import M from 'materialize-css';
import $ from 'jquery';
import './carousel.scss';

export default {
  name: 'VideoSection',
  components: {},
  mounted: function () {
    M.AutoInit();

    // $(this.$refs.videoslider).carousel() ;
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
    testimonialprev() {
      $(this.$refs.testimonialslider).slick('slickPrev');
    },
    testimonialenext() {
      $(this.$refs.testimonialslider).slick('slickNext');
    },
  },
};
</script>
