<template>
  <div
    class="max-w-[35px] flex items-center md:max-w-[50px] max-h-[35px] md:max-h-[70px]">
    <img src="../../../assets/new/icons/slots/key.png" />
    <svg
      class="absolute -top-3 left-3 md:top-1 md:left-3 z-20 w-[40px] md:w-[80px]"
      width="150"
      height="101"
      viewBox="0 0 150 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_89_10014)">
        <path
          d="M36.895 50.8088C38.0675 50.9904 38.9664 51.4615 39.5917 52.2222C40.243 52.9475 40.5687 53.9649 40.5687 55.2744C40.5687 58.3027 39.3702 60.9529 36.9732 63.2249C34.6022 65.4889 31.5408 67.2034 27.789 68.3683L12 73.271L17.4714 42.9264L31.9707 38.4242C35.1754 37.4291 37.6115 37.2729 39.279 37.9555C40.9465 38.6108 41.7803 40.0298 41.7803 42.2123C41.7803 43.8219 41.3504 45.374 40.4906 46.8687C39.6308 48.3361 38.4323 49.6494 36.895 50.8088ZM25.2487 47.2636L24.428 51.7744L29.1178 50.3182C31.15 49.6872 32.1662 48.5396 32.1662 46.8754C32.1662 46.3025 31.9577 45.9307 31.5408 45.76C31.124 45.5894 30.5247 45.6254 29.7431 45.8681L25.2487 47.2636ZM28.2971 61.4584C29.235 61.1671 29.9906 60.7143 30.5638 60.0998C31.137 59.458 31.4236 58.7143 31.4236 57.8685C31.4236 57.2683 31.2021 56.8733 30.7592 56.6835C30.3423 56.4855 29.8082 56.4877 29.1569 56.6899L23.2164 58.5345L22.3566 63.3029L28.2971 61.4584Z"
          fill="url(#paint0_linear_89_10014)" />
        <path
          d="M53.0159 62.3751C50.8349 63.0523 48.9189 63.231 47.2679 62.9113C45.6373 62.5639 44.3736 61.7718 43.4767 60.5349C42.6003 59.2917 42.1621 57.6884 42.1621 55.7248C42.1621 53.121 42.7328 50.6281 43.8742 48.2462C45.036 45.8365 46.6361 43.7496 48.6744 41.9856C50.733 40.2152 53.0566 38.9281 55.6452 38.1243C57.8262 37.4471 59.732 37.2822 61.3626 37.6296C63.0136 37.9493 64.2773 38.7307 65.1538 39.9739C66.0506 41.2108 66.4991 42.811 66.4991 44.7745C66.4991 47.3783 65.9181 49.8851 64.7563 52.2947C63.6149 54.6767 62.0149 56.7529 59.9562 58.5233C57.9179 60.2874 55.6045 61.5713 53.0159 62.3751ZM53.6274 56.0705C54.7688 55.7161 55.7574 55.0997 56.593 54.2212C57.4491 53.3365 58.1014 52.3016 58.5498 51.1165C58.9982 49.9315 59.2224 48.7201 59.2224 47.4822C59.2224 46.1589 58.8453 45.2195 58.0912 44.664C57.3574 44.1022 56.3383 44.0238 55.0338 44.4289C53.8923 44.7833 52.8936 45.4029 52.0375 46.2876C51.2018 47.1661 50.5598 48.1978 50.1113 49.3828C49.6629 50.5679 49.4387 51.7793 49.4387 53.0172C49.4387 54.3404 49.8056 55.283 50.5394 55.8448C51.2935 56.4003 52.3229 56.4756 53.6274 56.0705Z"
          fill="url(#paint1_linear_89_10014)" />
        <path
          d="M93.5817 29.7438L89.0221 55.031L82.7039 56.9928L76.1577 47.9082L74.0408 59.6828L66.5502 62.0087L71.1097 36.7215L77.4279 34.7597L83.9741 43.8443L86.091 32.0697L93.5817 29.7438Z"
          fill="url(#paint2_linear_89_10014)" />
        <path
          d="M104.476 47.65C102.435 48.2837 100.665 48.4808 99.1671 48.2412C97.6907 47.9721 96.5508 47.303 95.7475 46.2338C94.9658 45.1579 94.575 43.7333 94.575 41.96C94.575 41.2325 94.6619 40.3643 94.8356 39.3556L97.3433 25.4476L105.029 23.061L102.554 36.8224C102.424 37.4995 102.359 38.0994 102.359 38.6223C102.359 39.6909 102.619 40.4057 103.14 40.7668C103.662 41.1279 104.421 41.1533 105.42 40.8432C106.549 40.4926 107.429 39.8445 108.058 38.8987C108.688 37.953 109.176 36.5395 109.524 34.6583L111.999 20.8969L119.555 18.5508L116.982 32.82C116.287 36.6505 114.898 39.8101 112.813 42.2989C110.729 44.7876 107.95 46.5713 104.476 47.65Z"
          fill="url(#paint3_linear_89_10014)" />
        <path
          d="M125.905 46.2904C123.929 46.9039 122.072 47.2643 120.335 47.3717C118.598 47.4563 117.165 47.3102 116.036 46.9333L119 40.0793C121.454 40.7952 124.016 40.7385 126.686 39.9093C127.663 39.6059 128.412 39.2483 128.933 38.8364C129.454 38.4245 129.715 38.0026 129.715 37.5706C129.715 37.1614 129.444 36.916 128.901 36.8345C128.38 36.7462 127.544 36.733 126.393 36.7947C124.873 36.8574 123.614 36.8392 122.615 36.7401C121.616 36.6183 120.737 36.2434 119.977 35.6154C119.239 34.958 118.87 33.9131 118.87 32.4809C118.87 30.6848 119.326 28.9746 120.238 27.3501C121.171 25.6961 122.507 24.2357 124.244 22.9688C125.98 21.6792 128.032 20.667 130.399 19.9322C132.092 19.4063 133.667 19.0994 135.121 19.0115C136.598 18.894 137.868 18.9998 138.932 19.3288L136.163 26.0539C135.273 25.7392 134.285 25.6026 133.2 25.6442C132.136 25.679 131.039 25.8716 129.91 26.2222C128.846 26.5526 128.021 26.9679 127.435 27.4682C126.849 27.9685 126.556 28.4346 126.556 28.8666C126.556 29.2986 126.816 29.5587 127.338 29.647C127.88 29.7285 128.76 29.7283 129.976 29.6463C131.452 29.5516 132.69 29.5538 133.688 29.6529C134.709 29.7453 135.588 30.1088 136.326 30.7435C137.064 31.3782 137.434 32.4003 137.434 33.8099C137.434 35.5832 136.967 37.2854 136.033 38.9167C135.1 40.5479 133.764 42.0083 132.027 43.2979C130.29 44.5648 128.249 45.5623 125.905 46.2904Z"
          fill="url(#paint4_linear_89_10014)" />
      </g>
      <defs>
        <filter
          id="filter0_d_89_10014"
          x="-10.8847"
          y="0.243054"
          width="172.701"
          height="100.488"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="4.57693" />
          <feGaussianBlur stdDeviation="11.4423" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0431373 0 0 0 0 0.0470588 0 0 0 0 0.0823529 0 0 0 0.5 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_89_10014" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_89_10014"
            result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_89_10014"
          x1="75.4659"
          y1="18.5508"
          x2="75.4659"
          y2="73.271"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_89_10014"
          x1="75.4659"
          y1="18.5508"
          x2="75.4659"
          y2="73.271"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_89_10014"
          x1="75.4659"
          y1="18.5508"
          x2="75.4659"
          y2="73.271"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_89_10014"
          x1="75.4659"
          y1="18.5508"
          x2="75.4659"
          y2="73.271"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_89_10014"
          x1="75.4659"
          y1="18.5508"
          x2="75.4659"
          y2="73.271"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SlotsScatter',
};
</script>
