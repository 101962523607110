<template>
  <div class="backdrop" v-on:click.self="$emit('close')">
    <div class="content">
      <header class="header">
        <h2 style="margin: 0; text-align: center; font-weight: bold">War</h2>

        <h4 style="margin-top: 0.5rem; text-align: center">Fairness</h4>

        <hr />
      </header>

      <div>
        <div class="form-group">
          <span>Client Seed</span>

          <input type="text" disabled class="form-control" :value="clientSeed" />
        </div>

        <div class="form-group">
          <span>Server Seed Hash</span>

          <input type="text" disabled class="form-control" :value="serverSeedHash" />
        </div>

        <div class="form-group">
          <span>Seed Pair Nonce</span>

          <input type="text" disabled class="form-control" :value="seedPairNonce" />
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <button
            v-on:click="onNewSeedPair"
            style="line-height: 1.6rem; margin-right: 0.5rem">
            <i class="fa fa-refresh"></i> New Seed Pair
          </button>

          <button v-on:click="$emit('close')">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FairnessModal',
  created() {
    //this.refreshSeedInfo()
  },
  computed: {
    ...mapGetters('War', ['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  methods: {
    ...mapActions('War', ['refreshSeeds', 'promptClientSeed', 'refreshSeedInfo']),
    onNewSeedPair() {
      this.refreshSeeds().then(() => {
        this.promptClientSeed();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.backdrop {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 10rem 100rem 10rem 10rem rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 300ms ease-in;
  .content {
    background: rgb(18, 34, 46);
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 14px 4px black;
    font-size: 1.1rem;
    margin-top: 2rem;
    min-width: 350px;
    height: 400px;
    max-height: 100vh;
    button {
      font-size: 1.4rem;
      background: rgba(0, 0, 0, 0.25);
      color: #ffffff;
      border: none;
      text-align: center;
      padding: 0.5rem 1rem;
      display: inline-block;
      margin: auto;
      cursor: pointer;
    }
  }
}

.form-group {
  span {
    font-size: 1.2rem;
    display: block;
  }
  input {
    display: block;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    width: 100%;
    font-size: 1.2rem;
    color: #ffffff;
    border-radius: 0.25rem;
    border-color: #2e2e2e;
    background: rgba(0, 0, 0, 0.45);
  }
}
</style>
