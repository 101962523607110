<template>
  <punt-modal show-close="true" size="max-w-2xl" class="rules_title">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-lg font-bold">Game ID {{ betId.toLocaleString() }}</h1>
      </div>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-card text-slate-300">
          <section class="modal-card-body">
            <div v-if="!betInfo" class="text-center my-8">Loading...</div>
            <div v-else>
              <p class="text-center mb-1">
                <strong>{{ bet.game_name }}</strong> <br />
              </p>
              <p class="text-center mb-4">
                <span v-if="user?.username != null">
                  Placed by <strong>{{ user?.username }}</strong></span
                >
              </p>

              <div class="flex flex-row text-center mt-4">
                <div class="flex-1">
                  <h2 class="subtitle font-bold">Play Amount</h2>
                  <h5 class="inline font-medium">
                    {{ rsgp(bet.wager) }}
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(bet.game_mode)" />
                  </h5>
                </div>
                <div class="flex-1">
                  <h2 class="subtitle font-bold">Play Payout</h2>
                  <h5 class="subtitle font-medium">{{ bet.multiplier }}x</h5>
                </div>
                <div class="flex-1">
                  <h2 class="subtitle font-bold">Profit Amount</h2>
                  <h5 class="inline font-medium">
                    <span
                      class="inline"
                      :style="{ color: bet.profit > 0 ? 'green' : 'red' }"
                      >{{ rsgp(bet.profit) }}</span
                    >
                    <img
                      class="ml-1 inline h-4 w-4"
                      :src="balS.getBalanceIcon(bet.game_mode)" />
                  </h5>
                </div>
              </div>
              <p class="text-xs opacity-50 text-center mt-4">Ref: {{ bet.game_id }}</p>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp, convertGameMode } from '@/lib/rsgp';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { copyToClipboard } from '@/lib/utils';

export default {
  components: { PuntModal },
  setup() {
    return {
      balS: balanceStore(),
    };
  },
  data() {
    return {
      betInfo: false,
      betId: null,
      error: null,
      fetching: false,
      showVerify: false,
    };
  },
  created() {
    this.fetchBetInfo();
  },
  computed: {
    bet() {
      return this.betInfo;
    },
    currency() {
      return (convertGameMode(this.user.game) || '').toUpperCase();
    },
    interactiveUrl() {
      return `${JSBIN}?cs=${this.seed.client_seed}&ss=${this.seed.server_seed}&nonce=${this.betInfo.bet.nonce}`;
    },
    seed() {
      return this.betInfo.seed;
    },
    user() {
      return this.betInfo.user;
    },
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    fetchBetInfo() {
      if (!this.betId) return;

      this.fetching = true;
      this.$http
        .get('history/show/' + this.betId)
        .then(({ data }) => {
          this.betInfo = data;
          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    copyClient() {
      copyToClipboard(this.seed.client_seed);
    },
    copyServer() {
      copyToClipboard(this.seed.server_seed);
    },
    copyServerHashed() {
      copyToClipboard(this.seed.server_seed_hashed);
    },
    copyNonce() {
      copyToClipboard(this.seed.nonce);
    },
    rsgp(x) {
      return this.balS.exchangeAndFormatAmount(x, this.betInfo.game_mode);
    },
  },
  watch: {
    '$route.params.gameId': {
      handler: function (betId) {
        this.betId = betId;
        this.fetchBetInfo();
      },
      deep: true,
      immediate: true,
    },
    '$route.query.history': {
      handler: function (betId) {
        this.betId = betId;
        this.fetchBetInfo();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
