<template>
  <div>
    <punt-modal v-if="showLossbackModal" size="max-w-xl" show-close="true">
      <template #title>
        <div class="w-full">
          <span class="flex items-center p-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9971 1.99902C10.9331 1.99902 9.94508 2.37102 9.09107 2.99902C8.05907 3.16302 7.08707 3.59602 6.34107 4.34302C5.59407 5.09002 5.16107 6.06102 4.99707 7.09302C4.36907 7.94702 3.99707 8.93502 3.99707 9.99902C3.99707 11.063 4.36907 12.051 4.99707 12.905C5.16107 13.937 5.59407 14.908 6.34107 15.655C6.53107 15.845 6.78108 15.943 6.99708 16.093V20.999C6.99708 21.551 7.44508 21.999 7.99708 21.999H8.99708C9.10408 21.999 9.20809 21.971 9.30909 21.937L11.9971 21.03L14.6851 21.937C14.7861 21.97 14.8901 21.999 14.9971 21.999H15.9971C16.5491 21.999 16.9971 21.551 16.9971 20.999V16.093C17.2131 15.943 17.4631 15.845 17.6531 15.655C18.4001 14.908 18.8331 13.937 18.9971 12.905C19.6251 12.051 19.9971 11.063 19.9971 9.99902C19.9971 8.93502 19.6251 7.94702 18.9971 7.09302C18.8331 6.06102 18.4001 5.09002 17.6531 4.34302C16.9061 3.59602 15.9351 3.16302 14.9031 2.99902C14.0491 2.37102 13.0611 1.99902 11.9971 1.99902ZM11.9971 3.99902C15.3111 3.99902 17.9971 6.68502 17.9971 9.99902C17.9971 13.313 15.3111 15.999 11.9971 15.999C8.68308 15.999 5.99707 13.313 5.99707 9.99902C5.99707 6.68502 8.68308 3.99902 11.9971 3.99902ZM11.9971 5.99902C9.78808 5.99902 7.99708 7.79002 7.99708 9.99902C7.99708 12.208 9.78808 13.999 11.9971 13.999C14.2061 13.999 15.9971 12.208 15.9971 9.99902C15.9971 7.79002 14.2061 5.99902 11.9971 5.99902Z"
                fill="white" />
            </svg>

            Weekly Bonus
          </span>
          <hr class="w-full h-px my-2 border-0 dark:bg-gray-700" />
        </div>
      </template>
      <template #body>
        <div class="px-4 py-4 text-white">
          <table class="w-full table-auto md:table-fixed">
            <thead class="">
              <tr>
                <th class="px-6 py-3 text-sm text-left text-gray-400" scope="col">
                  Amount
                </th>

                <th class="px-6 py-3 text-sm text-left text-gray-400" scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in lossbackRecords" v-bind:key="index">
                <td class="px-6 py-4 text-sm font-medium text-slate-300">
                  <span class="inline mt-1 text-sm text-white">
                    {{ item.lossback_amount }}
                    <img
                      :src="getIcon(item.currency)"
                      alt="Currency Icon"
                      class="inline w-4 h-4" />
                  </span>
                </td>
                <td>
                  <span
                    v-if="item.is_claimed"
                    class="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 rounded-md bg-gray-50 ring-1 ring-inset ring-gray-600/10">
                    Claimed
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/10">
                    Available
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center justify-center">
            <button
              v-on:click.prevent="handleClaimBonus"
              :disabled="
                lossbackRecords && lossbackRecords.length === 0 && isLoading
                  ? true
                  : false
              "
              class="flex-inline flex h-10 flex-row items-center justify-center gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto md:px-5 md:py-3 bg-prim-highlight shadow-btn shadow-accent px-2 max-[400px]:text-[14px]">
              Claim Bonus
            </button>
          </div>
        </div>
      </template>
    </punt-modal>
  </div>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { mapGetters, mapActions } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import axios from '../lib/myAxios';

export default {
  name: 'Lossback',
  components: { PuntModal },
  data() {
    return {
      showLossbackModal: false,
      lossbackRecords: null,
      isUnclaimedRecord: false,
      code: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },

  mounted() {
    this.fetchUser().then(() => {
      this.fetchRecords();
    });
  },

  methods: {
    fetchRecords() {
      this.code = this.$route.params.code ?? null;
      if (this.code && this.me.id) {
        this.getLossbackRecords({ userId: this.me.id, code: this.code })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.lossbackRecords = res.data;
            } else {
              this.lossbackRecords = [];
            }
            this.showLossbackModal = true;
          })
          .catch((err) => {
            this.$toast.error(err.data.message);
          });
      } else {
        this.$toast.error(`Please try again`);
      }
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    handleClaimBonus() {
      try {
        if (this.isLoading === false) {
          this.isLoading = true;
          axios
            .post(`/users/${this.me.id}/claim/lossback/${this.code}`)
            .then((res) => {
              this.$toast.success('Claimed Bonus Successfully');
              this.showLossbackModal = false;
              this.isLoading = false;
              this.$router.push('/');
            })
            .catch((err) => {
              this.$toast.error('Cannot claimed bonus! <br>' + err.data.message);
              this.showLossbackModal = false;
              this.isLoading = false;
              this.$router.push('/');
            });
        }
      } catch (e) {
        this.$toast.error('Cannot claimed bonus! <br>' + e.message);
        this.isLoading = false;
      }
    },
    ...mapActions(['getLossbackRecords', 'fetchUser']),
  },
};
</script>
