<template>
  <div class="p-4">
    <div class="">
      <h5 class="mb-5 text-2xl font-bold tracking-tight text-white-900 dark:text-white">
        Recognize The Signs
      </h5>

      <p class="mt-6 mb-3 text-lg font-bold">
        How can my social gaming activities affect me?
      </p>
      <p class="mb-4 font-normal text-white-700 dark:text-white">
        Often we find it is easier identifying social gaming becoming a problem in others,
        but harder to recognise when this affects us as individuals. Although it can often
        result in financial problems – the impact usually stretches much further than
        this. Recognizing the effects of dependency is crucial in identifying and
        addressing the problem before it escalates further.
      </p>

      <p class="mt-6 mb-3 text-lg font-bold">
        Is my social gaming activities affecting my mental health?
      </p>
      <p class="mb-3 font-normal text-white-700 dark:text-white">
        Does any of these statements sound familiar to you?
      </p>
      <ul class="list-disc ml-8 mb-3">
        <li>I have extreme emotions or mood swings</li>
        <li>I feel that social social gaming is the only thing I really enjoy</li>
        <li>I have difficulty sleeping</li>
        <li>I often feel depressed or anxious</li>
        <li>I have suicidal thoughts</li>
        <li>I use my social social gaming activities to avoid difficulties</li>
      </ul>

      <p class="mt-6 mb-3 text-lg font-bold">
        Is my social social gaming activities affecting my relationships?
      </p>
      <p class="mb-3 font-normal text-white-700 dark:text-white">
        Social social gaming addictions can have a serious impact on relationships which
        can make it even harder to deal with. Consider the following:
      </p>
      <ul class="list-disc ml-8 mb-3">
        <li>
          I’m arguing more with my partner or family – especially about money, budgeting
          and debt.
        </li>
        <li>
          I’m preoccupied with my social gaming activities and finding it difficult to
          focus on other things.
        </li>
        <li>I’m spending less time with my friends and more time social gaming.</li>
        <li>
          I’m not able to tell people the truth about how much I have spent or lost on my
          social gaming activities.
        </li>
        <li>
          I’m stealing money from friends and family to fund my social gaming activities.
        </li>
      </ul>

      <p class="mt-6 mb-3 text-lg font-bold">
        Is my social gaming activities affecting my finances?
      </p>
      <p class="mb-3 font-normal text-white-700 dark:text-white">
        One of the most obvious ways in which social gaming activities can become
        problematic is when debt begins to mount or when savings or money meant for other
        things is being spent on social gaming.
      </p>
      <p class="mb-3 font-normal text-white-700 dark:text-white">
        Do any of these statements describe your situation?.
      </p>
      <ul class="list-disc ml-8 mb-3">
        <li>I am struggling to pay my bills on time.</li>
        <li>I am juggling my credit card debt.</li>
        <li>I have taken out a payday loan.</li>
        <li>I am using business money to fund my social gaming activities.</li>
        <li>I have depleted all my savings on social gaming activities.</li>
        <li>I am hiding my financial situation from those close to me.</li>
      </ul>

      <p class="mt-6 mb-3 text-lg font-bold">Help Organizations</p>
      <p class="mb-3 font-normal text-white-700 dark:text-white">
        If your social gaming activities may have had, or is at risk of having, a negative
        impact on your mental health, finances or relationships with friends or family, we
        encourage you to get in touch with the following help and support organizations:
      </p>
      <ul class="list-disc ml-8 mb-3">
        <li>
          <a
            target="_blank"
            href="https://www.gamingaddictsanonymous.org/"
            class="underline"
            >Gaming Addicts Anonymous (GAA)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a fellowship of people who support each other in recovering from the problems
          resulting from excessive game playing.
        </li>
        <li>
          <a target="_blank" href="https://fcaa.org/" class="underline"
            >Financial Counseling Association of America (FCAA)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a professional association of financial counseling agencies that assist
          consumers with financial counseling services of all kinds, as well as debt
          management plans for the repayment of unsecured debts.
        </li>
        <li>
          <a target="_blank" href="https://www.nfcc.org/" class="underline"
            >National Foundation for Credit Counseling (NFCC)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a non-profit financial counseling agency. The NFCC helps people to defeat
          their debt and look forward with confidence.
        </li>
      </ul>

      <p class="mt-6 text-lg font-bold">
        *REMINDER: The self-assessment will help you figure out if there is, or you are
        developing a problem. If you answered yes to one or more of the above questions,
        you should seek help.
      </p>
    </div>
  </div>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';

export default {
  name: 'RecognizeSigns.vue',
  components: { PuntModal },
};
</script>

<style scoped></style>
