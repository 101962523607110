<template>
  <div class="flex flex-row md:w-auto w-full">
    <TabGroup
      :selectedIndex="selectedTab"
      @change="setActiveTab"
      as="div"
      class="flex flex-col-reverse md:flex-col w-full max-w-[1200px]">
      <div class="flex flex-col w-full mt-2 md:mt-0">
        <TabList
          class="flex flex-row px-2 w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            v-for="(tab, i) in tabs"
            :key="i"
            v-slot="{ selected }"
            disabldsed="inProgress || autoBetInProgress"
            as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg py-2 my-2  hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-2">
        <TabPanel key="1" class="h-full">
          <div class="flex flex-col-reverse md:flex-col h-full">
            <div class="flex flex-col h-full">
              <div class="flex-none mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color" />
              </div>
              <div class="flex-1 gap-2">
                <div
                  class="flex flex-row gap-2 max-[767px]:flex-col max-[767px]:mb-3 max-[1279px]:w-full">
                  <div class="grid grid-cols-1 grid-rows-1 max-[1279px]:w-full">
                    <div>
                      <span
                        class="mt-1 gap-2 inline text-sm font-medium text-text-dim-color"
                        for="">
                        Chip Value
                        {{ balS.formatCrypto(selectedValue, true) }}
                        <img :src="balanceIcon" class="w-4 h-4 inline" />
                      </span>
                      <div class="relative flex mt-2">
                        <button
                          :disabled="currentState === 'inProgress' || autoBetInProgress"
                          class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-l-lg text-sm px-3 py-2 bg-secondary-light hover:bg-opacity-70 float-left pointer-events-auto"
                          type="button"
                          v-on:click="doScroll('left')">
                          <svg
                            fill="none"
                            height="15"
                            viewBox="0 0 7 15"
                            width="7"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.83336 13.9793C5.08336 14.0303 5.35236 14.0083 5.58136 13.8543C6.03836 13.5483 6.16636 12.9083 5.86136 12.4483L2.24937 7.01128L5.86136 1.57331C6.16635 1.11431 6.03836 0.473304 5.58136 0.167305C5.12336 -0.138694 4.48536 -0.0106886 4.18036 0.44831L0.194379 6.4483C-0.0286209 6.78429 -0.0286208 7.23729 0.194379 7.57329L4.18036 13.5733C4.33236 13.8033 4.58336 13.9293 4.83336 13.9793Z"
                              fill="#69879C" />
                          </svg>
                        </button>
                        <div
                          id="chips-bag"
                          class="bac-chips-wrap-content xl:max-w-[250px] w-full bg-primary p-1 max-[1279px]:justify-center">
                          <div class="bac-chips-wrap-content-slider max-h-10">
                            <chip
                              v-for="(item, i) in chipsValues"
                              :key="i"
                              :color="item.color"
                              :display="item.displayValue"
                              :onclick="onChipSelected"
                              :selected="selected"
                              :value="item.value" />
                          </div>
                        </div>
                        <button
                          :disabled="currentState === 'inProgress' || autoBetInProgress"
                          class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-r-lg text-sm px-3 py-2 inset-x-full bg-secondary-light hover:bg-opacity-70 float-right pointer-events-auto"
                          type="button"
                          v-on:click="doScroll('right')">
                          <svg
                            fill="none"
                            height="15"
                            viewBox="0 0 7 15"
                            width="7"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.16664 1.02072C1.91664 0.96972 1.64764 0.99172 1.41864 1.14572C0.961643 1.45172 0.833644 2.09172 1.13864 2.55172L4.75063 7.98872L1.13864 13.4267C0.833644 13.8857 0.961643 14.5267 1.41864 14.8327C1.87664 15.1387 2.51464 15.0107 2.81964 14.5517L6.80562 8.55171C7.02862 8.21571 7.02862 7.76271 6.80562 7.42671L2.81964 1.42672C2.66764 1.19673 2.41664 1.07072 2.16664 1.02072Z"
                              fill="#69879C" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    :disabled="
                      currentState === 'inProgress' ||
                      totalWager <= 0 ||
                      autoBetInProgress
                    "
                    class="btn-bet flex-1 block md:hidden"
                    v-on:click="debouncedPrimaryAction()">
                    Play
                  </button>
                </div>

                <div>
                  <label
                    class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color"
                    for="">
                    Total Play
                  </label>
                  <div class="relative">
                    <input
                      id=""
                      :disabled="currentState !== 'notStarted'"
                      :value="balS.formatCryptoRaw(totalWager, true)"
                      class="input-bet"
                      placeholder="0.0000"
                      readonly
                      type="text" />
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                      <button
                        :disabled="currentState === 'inProgress' || autoBetInProgress"
                        class="btn-multiplier"
                        type="button"
                        v-on:click="scaleWager(0.5)">
                        1/2
                      </button>
                      <button
                        :disabled="currentState === 'inProgress' || autoBetInProgress"
                        class="btn-multiplier"
                        type="button"
                        v-on:click="scaleWager(2.0)">
                        2x
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex-none hidden md:block">
              <button
                :disabled="
                  currentState === 'inProgress' || totalWager <= 0 || autoBetInProgress
                "
                class="btn-bet"
                v-on:click="debouncedPrimaryAction()">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse md:flex-col h-full">
            <div class="flex-1 flex flex-col h-full">
              <div class="mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div class="flex-1 gap-2">
                <div>
                  <span
                    class="mt-1 gap-2 inline text-sm font-medium text-text-dim-color"
                    for="">
                    Chip Value
                    {{ balS.formatCrypto(selectedValue, true) }}
                    <img :src="balanceIcon" class="w-4 h-4 inline" />
                  </span>
                  <div class="relative flex mt-2">
                    <button
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-l-lg text-sm px-3 py-2 bg-secondary-light hover:bg-opacity-70 float-left pointer-events-auto"
                      type="button"
                      v-on:click="doScroll('left')">
                      <svg
                        fill="none"
                        height="15"
                        viewBox="0 0 7 15"
                        width="7"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.83336 13.9793C5.08336 14.0303 5.35236 14.0083 5.58136 13.8543C6.03836 13.5483 6.16636 12.9083 5.86136 12.4483L2.24937 7.01128L5.86136 1.57331C6.16635 1.11431 6.03836 0.473304 5.58136 0.167305C5.12336 -0.138694 4.48536 -0.0106886 4.18036 0.44831L0.194379 6.4483C-0.0286209 6.78429 -0.0286208 7.23729 0.194379 7.57329L4.18036 13.5733C4.33236 13.8033 4.58336 13.9293 4.83336 13.9793Z"
                          fill="#69879C" />
                      </svg>
                    </button>
                    <div
                      id="chips-bag"
                      class="bac-chips-wrap-content xl:max-w-[250px] w-full bg-primary p-1">
                      <div class="bac-chips-wrap-content-slider max-h-10">
                        <chip
                          v-for="(item, i) in chipsValues"
                          :key="i"
                          :color="item.color"
                          :display="item.displayValue"
                          :onclick="onChipSelected"
                          :selected="selected"
                          :value="item.value" />
                      </div>
                    </div>
                    <button
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="text-text-dim-color focus:outline-none focus:ring-1 font-medium rounded-r-lg text-sm px-3 py-2 inset-x-full bg-secondary-light hover:bg-opacity-70 float-right pointer-events-auto"
                      type="button"
                      v-on:click="doScroll('right')">
                      <svg
                        fill="none"
                        height="15"
                        viewBox="0 0 7 15"
                        width="7"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.16664 1.02072C1.91664 0.96972 1.64764 0.99172 1.41864 1.14572C0.961643 1.45172 0.833644 2.09172 1.13864 2.55172L4.75063 7.98872L1.13864 13.4267C0.833644 13.8857 0.961643 14.5267 1.41864 14.8327C1.87664 15.1387 2.51464 15.0107 2.81964 14.5517L6.80562 8.55171C7.02862 8.21571 7.02862 7.76271 6.80562 7.42671L2.81964 1.42672C2.66764 1.19673 2.41664 1.07072 2.16664 1.02072Z"
                          fill="#69879C" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color"
                  for="">
                  Total Play
                </label>
                <div class="relative">
                  <input
                    :disabled="currentState !== 'notStarted'"
                    :value="balS.exchangeAndFormatAmount(totalWager)"
                    class="input-bet"
                    placeholder="0.0000"
                    readonly
                    type="text" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWager(0.5)">
                      1/2
                    </button>
                    <button
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="btn-multiplier"
                      type="button"
                      v-on:click="scaleWager(2.0)">
                      2x
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="inProgress"
                      class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="loading"
                      class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="inProgress || onWinSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :class="[
                        onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="autoBet"
                      class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      :disabled="autoBet"
                      class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="currentState === 'inProgress' || autoBetInProgress"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-2">
                  <p class="mt-1 text-slate-300">Remaining: {{ remainingAutoBets }}</p>
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.formatCrypto(totalProfit, true) }}
                    <img :src="balS.mainIcon" alt class="h-4 w-4 inline" />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-none">
              <button
                :disabled="
                  totalWager <= 0 ||
                  buttonDisabled ||
                  !numOfBets ||
                  Number.parseInt(numOfBets) <= 0 ||
                  (currentState === 'inprogress' && !this.autoBet)
                "
                class="btn-bet"
                type="button"
                v-on:click="onAutoBetClicked">
                {{ autoBetInProgress ? 'Stop Autoplay' : 'Start Autoplay' }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { defaultConvertor } from '@/lib/rsgp';
import { formatFloat } from '@/lib/utils';
import BaccaratCountDown from './BaccaratCountDown.vue';
import Chip from './Chip.vue';
import { CHIPS } from './PlayingCards';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { ref } from 'vue';

const TABS = ['Manual', 'Auto'];

const CHIP_VALUE = 1 / 1e8;

export default {
  name: 'BaccaratBets',
  components: {
    BaccaratCountDown,
    Chip,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    updateSelectedChipValue: {},
    currentState: {},
    startGame: {},
    wagers: {},
    limits: {},
    setActiveMode: {},
    scaleWager: {},
    totalProfit: {},
    remainingAutoBets: {},
    placeBet: {},
    stopAutoBet: {},
    placeAutoBet: {},
    autoBetInProgress: {},
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { tabs: TABS, balS, formatFloat, CHIP_VALUE, getBalanceIcon };
  },
  created() {
    // init defaults
    this.debouncedPrimaryAction = debounce(this.onPrimaryAction, 300, {
      leading: true,
      trailing: false,
    });
    this.debouncedAutoBet = debounce(this.startAutoBet, 300, {
      leading: true,
      trailing: false,
    });
    this.debouncedStopAutoBet = debounce(this.stopAutoBet, 300, {
      leading: true,
      trailing: false,
    });
  },
  data() {
    return {
      selected: 1000000,
      wager: 0.01,
      active: 'Manual',
      gamePrivacy: 'PUBLIC',
      chipsValues: CHIPS,
      numOfBets: 0,
      selectedTab: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      increaseOnWin: false,
      onLossSelected: 'Reset',
      increaseOnLoss: false,
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
      loading: false,
      debouncedPrimaryAction: () => {},
      debouncedStopAutoBet: () => {},
      debouncedAutoBet: () => {},
      debouncedBet: () => {},
    };
  },
  deactivated() {
    this.Bet = false;
  },
  watch: {
    limits: {
      handler: function (newW, oldW) {
        if (newW) {
          this.wager = this.rsgp(newW.min_wager);
        }
      },
      immediate: true,
    },
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),

    totalWager() {
      return (this.wagers.player + this.wagers.tie + this.wagers.banker) * CHIP_VALUE;
    },
    formattedTotalWager() {
      return this.balS.displayInFiat
        ? this.totalWager
        : this.balS.normalizeWager(this.totalWager);
    },
    canAutoBet() {
      return this.currentState === 'notStarted' && !this.autoBetInProgress;
    },
    balanceIcon() {
      return getBalanceIcon(this.balS.mainCurrency);
    },
    inProgress() {
      return this.currentState === 'inProgress';
    },
    selectedValue() {
      return this.selected * CHIP_VALUE;
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
  },
  methods: {
    onAutoBetClicked() {
      if (this.autoBetInProgress) {
        this.debouncedStopAutoBet();
      } else {
        this.debouncedAutoBet();
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    async startAutoBet() {
      await this.placeAutoBet(
        this.numOfBets,
        this.stopOnProfitAmount,
        this.stopOnLossAmount,
        this.onWinPercent,
        this.onLossPercent,
        this.increaseOnWin,
        this.increaseOnLoss,
      );
    },
    setActive(active) {
      if (this.currentState !== 'notStarted') return;
      this.active = active;
      this.autoBet = false;
      this.setActiveMode(active);
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
      this.increaseOnWin = selected !== 'Reset';
      if (selected === 'Reset') {
        this.onWinPercent = 0;
      }
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
      this.increaseOnLoss = selected !== 'Reset';
      if (selected === 'Reset') {
        this.onLossPercent = 0;
      }
    },
    doScroll(direction) {
      let amount = 150 * (direction === 'left' ? -1 : 1);
      document.getElementById('chips-bag').scrollLeft += amount;
    },
    onChipSelected(value) {
      this.selected = value;
      this.updateSelectedChipValue(value);
    },

    onPrimaryAction() {
      if (this.currentState === 'notStarted') {
        this.placeBet();
      }
    },
    setActiveTab(index) {
      this.selectedTab = index;
      this.activeTab = this.tabs[index];
      this.setActive(this.activeTab);
    },
  },
};
</script>

<style scoped>
.bac-chips-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-width: 100%;
  width: 20%;
}

.bac-chips-wrap-content {
  display: flex;
  align-items: center;
  gap: 3px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bac-chips-wrap-content-slider {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
</style>
