<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-col md:flex-row p-4 md:p-8 gap-4 w-full max-w-[1200px]">
      <div class="md:w-[200px] flex flex-col">
        <div class="space-y-2 py-2 px-2 sm:px-0 bg-secondary h-auto rounded-lg">
          <span
            class="font-medium w-full mx-2 max-[480px]:mx-0 max-[480px]:text-sm"
            v-for="item in policies">
            <router-link
              :to="'/transactions/' + item.url"
              :active-class="'text-slate-400 border-b-4 md:border-b-0 md:border-l-4 border-onprimary max-[575px]:border-b-2'"
              class="inline-block px-2 py-3 sm:p-4 max-[400px]:px-[0.45rem] font-semibold text-white">
              {{ item.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="bg-secondary p-4 flex-1 rounded-lg">
        <Deposits v-if="activeTab === 'purchases'" />
        <Withdrawals v-if="activeTab === 'redemptions'" />
      </div>
    </div>
  </div>
</template>

<script>
import Withdrawals from '@/components/Transactions/Withdrawals.vue';
import Deposits from '@/components/Transactions/Deposits.vue';
export default {
  name: 'MyTransactions',
  components: { Withdrawals, Deposits },
  setup() {
    return {
      policies: [
        {
          name: 'Purchases',
          url: 'purchases',
        },
        {
          name: 'Redemptions',
          url: 'redemptions',
        },
      ],
    };
  },
  data() {
    return {
      activeTab: 'purchases',
    };
  },
  watch: {
    '$route.params.name': {
      handler: function (newState) {
        if (this.policies.some((item) => item.url === newState)) {
          this.activeTab = newState;
        } else {
          this.activeTab = 'purchases';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
