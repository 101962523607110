<template>
  <div
    class="flex flex-col h-full transition-all duration-200 ease-in-out overflow-x-hidden chat-window-mobile">
    <chat-header />
    <perfect-scrollbar
      ref="cml"
      v-chat-scroll="{ always: true }"
      class="flex-grow overflow-y-scroll overflow-x-hidden"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false">
      <template v-for="(message, i) in filteredChatMessages">
        <rain-bot-message
          v-if="
            isRainbot(message.message.body) &&
            message.sender.username.toLowerCase() === 'runebet'
          "
          :key="message.message.id"
          :message="message.message.body" />

        <!--<races-message
            v-else-if="isRace(message.message.body)"
            :key="message.message.id"
            :message="message.message.body"
        />-->

        <game-action-message
          v-else-if="
            message.sender.username.toLowerCase() === 'runebet' &&
            isGame(message.message.body) !== null
          "
          :key="message.message.id"
          :game="isGame(message.message.body)" />

        <announcement-message
          v-else-if="message.sender.username.toLowerCase() === 'runebet'"
          :message="message.message.body" />

        <chat-message v-else :message="message" :key="i" />
      </template>
    </perfect-scrollbar>
    <chat-footer />
  </div>
</template>

<script>
import ChatHeader from '@/components/Chat/ChatHeader.vue';
import ChatFooter from '@/components/Chat/ChatFooter.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import ChatMessage from '@/components/Chat/ChatMessage.vue';
import { mapActions, mapGetters } from 'vuex';
import RainBotMessage from '@/components/Chat/RainBotMessage.vue';
/*import RacesMessage from "@/components/Chat/RacesMessage.vue";*/

import GameActionMessage from '@/components/Chat/GameActionMessage.vue';
import AnnouncementMessage from '@/components/Chat/AnnouncementMessage.vue';
import { log } from 'debug/src/browser';

export default {
  name: 'ChatBar',
  components: {
    AnnouncementMessage,
    GameActionMessage,
    /*RacesMessage, */ RainBotMessage,
    ChatMessage,
    ChatFooter,
    ChatHeader,
    PerfectScrollbar,
  },
  data() {
    return {
      hovering: false,
      hide: false,
      giveawayId: null,
      notifications: false,
      showServer: true,
    };
  },
  computed: {
    ...mapGetters([
      'chatMessages',
      'me',
      'isLoggedIn',
      'activeRoom',
      'chatRooms',
      'rosterVisible',
      'sidebarTab',
    ]),
    isAdminIsh() {
      return this.me.rank !== 'user' || this.me.chatTitle === 'helper';
    },
    shouldPromptNotifications() {
      return !this.notifications && 'Notification' in window;
    },
    isMobile() {
      return this.$route.name === 'Chat';
    },
    filteredChatMessages() {
      if (this.showServer) {
        return this.chatMessages;
      }

      const res = [];
      for (let message of this.chatMessages) {
        if (message.sender.id !== 50) {
          res.push(message);
        }
      }
      return res;
    },
  },
  mounted() {
    if ('Notification' in window) {
      this.notifications = window.Notification.permission === 'granted';
    }

    if (window.Echo) {
      this.bindEcho();
    } else {
      /*pubsub.$on('rebuilt', () => {
        this.bindEcho()
      })*/
    }
    try {
      this.fetchScrollback('lobby').then(() => this.scrollMessages());
    } catch (error) {
      console.log('issue with fetchscrollback');
    }
  },
  created() {
    window.locked = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        try {
          this.$refs.cml.update();
        } catch (e) {}
      }, 50);
    });
  },
  methods: {
    isRainbot(message) {
      return /^\[RainBot\]/g.test(message);
    },
    isRace(message) {
      return /^\[Races\]/g.test(message);
    },
    isGame(message) {
      try {
        return JSON.parse(message);
      } catch (e) {
        return null;
      }
    },
    resetEcho() {
      try {
        this.fetchScrollback('lobby').then(() => this.scrollMessages());
      } catch (error) {
        console.log('issue with fetching');
      }

      window.Echo.leave('public-chat');
      this.bindEcho();
    },
    bindEcho() {
      if (!this.isLoggedIn) {
        window.Echo.channel('game-history').listen('ChatMessagePublished', (msg) => {
          this.addMessage(msg);
        });
      }

      window.Echo.join('public-chat')
        .here((users) => {
          this.createRoom({ id: 'lobby', name: 'Lobby' });
          this.roomJoined({ roomId: 'lobby', users });
        })
        .listen('MessageHidden', ({ roomId, messageId }) => {
          this.hideMessage({ roomId, messageId });
        })
        .listen('GiveawayConcluded', ({ giveawayId }) => {
          if (this.giveawayId === giveawayId) {
            this.giveawayId = null;
          }
        })
        .listen('GiveawayPublished', ({ giveawayId }) => {
          this.giveawayId = giveawayId;
          if (this.notifications) {
            const $vm = this;
            let n = new window.Notification('Giveaway Started', {
              icon: 'https://www.gambleh.com/static/icons/256x256.png',
              body: 'A new giveaway has started!',
            });
            n.onclick = function () {
              $vm.$router.push({ name: 'Giveaway', params: { giveawayId } });
              n.close();
            };
          }
        })
        .listen('ChatMessagePublished', (msg) => {
          this.addMessage(msg);
          this.scrollMessages(false);
        })
        .joining((user) => {
          this.joinChat({ roomId: 'lobby', user });
        })
        .leaving((user) => {
          this.leaveChat({ roomId: 'lobby', user });
        })
        .error((error) => {});
    },
    enableNotifications() {
      if (!('Notification' in window)) {
        return window.swal({
          title: 'Aww shucks',
          text: 'Your browser does not support desktop notifications.',
          type: 'error',
        });
      }

      const Notification = window.Notification;
      if (Notification.permission === 'granted') {
        this.notifications = true;
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((result) => {
          this.notifications = result === 'granted';
        });
      }
    },
    checkGiveaway() {
      this.$http.get('giveaways/active').then(({ data }) => {
        this.giveawayId = data.id;
        this.hide = data.entered;
      });
    },
    scrollMessages(force = true) {
      if (this.hovering) {
        return;
      }

      const scroll = () => {
        setTimeout(() => {
          try {
            this.$refs.cml.$el.scrollTop = this.$refs.cml.$el.scrollHeight;
          } catch (e) {}
        }, 50);
      };
      scroll();
      if (force) {
        this.$nextTick(() => {
          scroll();
        });
      }
    },
    handleChatAction(action, username) {
      if (action === 'tip') {
        let parts = username.split(' ');
        if (parts.length !== 2 || !parts[0].match(/^[0-9a-zA-Z_-]+$/)) {
          return;
        }
        window
          .swal({
            title: 'Confirm tipping',
            html: `You are sending <strong style="color: #ffffff">${parts[1]}</strong> to <strong style="color: #ffffff">${parts[0]}</strong>, are you sure that you have spelled the Recipient's name correctly.<br>This transfer is <strong style="color: #ffffff">NOT</strong> reversable.`,
            showCancelButton: true,
            animation: 'slide-from-top',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          })
          .then(() => this.postChatAction(action, username))
          .catch(() => {});
      } else if (['ban', 'mute', 'pmute', 'ipban'].includes(action)) {
        if (!this.isAdminIsh) return;
        window
          .swal({
            title: `Action "${action}" Confirmation`,
            text: 'Enter a reason below',
            input: 'textarea',
            showCancelButton: true,
            animation: 'slide-from-top',
            inputPlaceholder: 'Reason...',
            inputValue: '',
          })
          .then((reason) => this.postChatAction(action, username, { reason }))
          .catch(() => {});
      } else {
        this.postChatAction(action, username);
      }
    },
    postChatAction(action, username, meta = {}) {
      this.$http
        .post('chat_actions', { action, username, meta })
        .then((response) => {
          window.swal({
            text: response.data.message,
            type: 'info',
            showCancelButton: false,
          });
        })
        .catch((response) => {
          window.swal({
            text: response.data.message,
            type: 'error',
            showCancelButton: false,
          });
        });
    },
    ...mapActions([
      'sendChatMessage',
      'hideMessage',
      'addMessage',
      'joinChat',
      'leaveChat',
      'roomJoined',
      'changeRoom',
      'createRoom',
      'fetchScrollback',
      'toggleRoster',
    ]),
    sendMessage(message) {
      message = message.trim();
      if (!message) {
        return;
      }

      if (message.charAt(0) === '/') {
        let matches = message.match(/^\/(?<action>\w+)\s*(?<username>.+)\s*$/);

        if (matches) {
          this.handleChatAction(matches[1], matches[2]);
        }

        return;
      }

      this.sendChatMessage(message);
    },
  },
  watch: {
    hovering(flag) {
      window.locked = !flag;
    },
    activeRoom() {
      this.scrollMessages();
    },
    giveawayId(x) {
      if (x) {
        this.scrollMessages();
      }
    },
    rosterVisible(flag) {
      if (!flag) {
        this.scrollMessages();
      }
    },
    sidebarTab(tab) {
      const cml = this.$refs.cml;
      if (tab === 'chat' && cml && cml.$el) {
        this.scrollMessages();
      }
    },
  },
};
</script>
