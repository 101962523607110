<template>
  <router-view></router-view>
  <div
    class="flex h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px]">
      <div
        class="blackjack flex h-auto flex-col-reverse justify-center md:min-h-[auto] xl:flex-row">
        <game-actions
          :game-data="{ name: 'Blackjack', id: null }"
          :pre-extras="gameActions"
          class="block rounded-bl-xl rounded-br-xl bg-secondary md:hidden border-t border-line-color-bright px-[14px] py-[8px]" />

        <div
          class="mt-2 flex w-full min-w-[300px] flex-col-reverse bg-side-color px-2 py-2 pb-4 md:mt-0 md:flex-col md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl md:px-3 md:py-3 xl:w-[300px]">
          <!-- Side Menu -->
          <div class="flex w-full flex-col gap-2 game_button_wrapper">
            <WagerInput
              class="w-full grid-cols-2"
              :disabled="loading || playStarted"
              :game="game"
              :onChange="onWagerChange"
              :wager-value="wager">
            </WagerInput>

            <button
              v-on:click.prevent="debouncedPlaceBet()"
              :disabled="loading || playStarted"
              class="game_play_button mt-2 flex flex-row items-center justify-center rounded-md bg-onprimary text-black/90 p-3 font-semibold shadow hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-50 md:w-full md:gap-2">
              <span>Play</span>
            </button>
          </div>

          <div
            v-show="loading && !playStarted"
            class="mt-4 flex items-center justify-center">
            <svg
              class="-ml-1 mr-3 h-8 w-8 animate-spin text-slate-300 opacity-60"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>

          <div
            class="mt-4 grid grid-cols-2 grid-rows-2 gap-2"
            v-show="(loading || playStarted) && !showInsurance">
            <button
              v-on:click.prevent="onAction('HIT')"
              :disabled="loading || !playStarted"
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-onprimary text-black/90 p-3 font-semibold shadow hover:scale-95 hover:opacity-90 disabled:scale-100 disabled:cursor-not-allowed disabled:opacity-50">
              Hit
            </button>

            <button
              v-on:click.prevent="onAction('STAND')"
              :disabled="loading || !playStarted"
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-onprimary text-black/90 p-3 font-semibold shadow hover:scale-95 hover:opacity-90 disabled:scale-100 disabled:cursor-not-allowed disabled:opacity-50">
              Stand
            </button>

            <button
              v-on:click.prevent="onAction('SPLIT')"
              :disabled="loading || !playStarted || !showSplit"
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-onprimary text-black/90 p-3 font-semibold shadow hover:scale-95 hover:opacity-90 disabled:scale-100 disabled:cursor-not-allowed disabled:opacity-70">
              Split
            </button>

            <button
              v-on:click.prevent="onAction('DOUBLE')"
              :disabled="loading || !playStarted"
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-onprimary text-black/90 p-3 font-semibold shadow hover:scale-95 hover:opacity-90 disabled:scale-100 disabled:cursor-not-allowed disabled:opacity-70">
              Double
            </button>
          </div>
          <div
            class="mt-4 grid grid-cols-2 gap-4"
            v-show="(loading || playStarted) && showInsurance">
            <p
              class="col-span-2"
              style="color: #ffffff; text-align: center; font-size: 1.2rem">
              Do you want to buy insurance?
            </p>
            <button
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-[#00B300] p-3 font-semibold text-line-color shadow hover:scale-95 hover:opacity-90 disabled:opacity-70"
              :disabled="loading || !playStarted"
              v-on:click.prevent="onAction('INSURANCE')">
              Yes
            </button>
            <button
              class="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-[#FFB733] p-3 font-semibold text-line-color shadow hover:scale-95 hover:opacity-90 disabled:opacity-70"
              :disabled="loading || !playStarted"
              v-on:click.prevent="rejectInsurance">
              No
            </button>
          </div>
        </div>

        <!-- Game -->
        <div
          class="flex h-auto flex-col items-center bg-secondary md:w-full md:rounded-tr-xl max-[767px]:min-h-[50vh]">
          <div
            class="blackjack-table !m-0 bg-common bg-no-repeat bg-cover bg-center pt-2 md:pt-5 max-[767px]:h-full max-[767px]:flex-[0_0_100%] max-[767px]:flex-grow">
            <div class="deck !scale-75 md:!scale-100">
              <img src="../../assets/logo.png" alt="RuneBet" />
            </div>

            <div class="flex flex-col">
              <div class="card-wrap dealer-hand min-h-[120px] md:min-h-[200px]">
                <template v-for="hand in dealerHands">
                  <div
                    class="split mt-0"
                    v-for="hand in dealerHands"
                    :key="hand.id"
                    v-if="hand && hand.length"
                    :class="{ 'card-inserting': dealerCardInserting }">
                    <span class="hand-value text-sm md:text-lg">
                      <span>{{ dealerValue.hard }}</span>
                      <span v-if="dealerValue.hard !== dealerValue.soft"
                        >, {{ dealerValue.soft }}</span
                      >
                    </span>

                    <Card
                      v-for="card in hand"
                      :revealed="card.revealed"
                      :value="card.value"
                      :suit="card.suit"
                      :class="{ 'in-deck': card.decked }"
                      :key="card.id" />
                  </div>
                </template>
              </div>

              <div class="table-emboss !scale-50 md:my-2 md:!scale-100">
                <span class="ribbon">
                  <span class="content">BLACKJACK PAYS 3 TO 2</span>
                </span>
                <span>INSURANCE PAYS 2 TO 1</span>
              </div>

              <div
                class="card-wrap player-hand mb-1 min-h-[150px] md:mb-5 md:min-h-[200px]"
                :class="{ 'is-double-down': double }">
                <template v-for="(hand, $index) in playerHands">
                  <div
                    class="split"
                    :key="$index"
                    v-if="hand && hand.length"
                    :class="{
                      'card-inserting': playerCardInserting[$index],
                      'is-bust': results[$index] === 'bust',
                      'is-push': results[$index] === 'push',
                      'is-win': results[$index] === 'win',
                      'is-blackjack': results[$index] === 'blackjack',
                    }">
                    <span
                      class="hand-value text-sm md:text-lg"
                      :class="{ disabled: activeHand !== $index }">
                      <span>{{ playerValues[$index].hard }}</span>
                      <span v-if="playerValues[$index].hard !== playerValues[$index].soft"
                        >, {{ playerValues[$index].soft }}</span
                      >
                    </span>

                    <Card
                      :revealed="card.revealed"
                      :value="card.value"
                      :suit="card.suit"
                      :class="{ 'in-deck': card.decked }"
                      v-for="card in hand"
                      :key="card.id" />
                  </div>
                </template>
              </div>
            </div>
          </div>

          <game-actions
            :game-data="{ name: 'Blackjack', id: null }"
            :pre-extras="gameActions"
            class="hidden rounded-br-lg bg-tertiary md:block" />
        </div>
      </div>
      <game-details
        game="Blackjack"
        edge="0.57"
        provider="Punt Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce, orderBy } from 'lodash';
import { defaultConvertor } from '@/lib/rsgp';
import axios from '@/lib/myAxios';

import WagerInput from './WagerInput.vue';
import Card from './Card.vue';
import FairnessModal from './FairnessModal.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';

import {
  betSound,
  dealSound,
  flipSound,
  looseSound,
  muckSound,
  winSound,
} from './sounds';

import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'Blackjack',
  components: {
    Card,
    WagerInput,
    FairnessModal,
    GameHistoryTable,
    GameActions,
    GameDetails,
  },
  data: () => ({
    showFairness: false,
    loading: true,
    insured: false,
    game: null,
    double: false,
    showSplit: false,
    showInsurance: false,
    dealerHands: [[]],
    dealerCardInserting: false,
    playerHands: [[], []],
    results: ['', ''],
    activeHand: 0,
    playerCardInserting: [false, false],
    plays: [],
    page: 1,
    pageLength: 10,
    debouncedPlaceBet: () => {},
    debouncedAction: () => {},
    tab: 'all',
    gameActions: [
      ['/casino/blackjack/rules', 'Rules'],
      // ['/casino/blackjack/fairness', 'Fairness'],
    ],
    tables: {
      mine: [],
      all: [],
      big: [],
    },
  }),
  created() {
    this.debouncedPlaceBet = debounce(this.onStartGame, 300, {
      leading: false,
      trailing: true,
    });
    this.debouncedAction = debounce(this.executeAction, 300, {
      leading: false,
    });
    this.fetchActivePlay();
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Blackjack', ['clientSeed', 'playStarted', 'wager']),
    ...mapGetters('Sound', ['isSoundMuted']),
    currentPage() {
      const offset = (this.page - 1) * this.pageLength;
      return this.currentTable.slice(offset, offset + this.pageLength);
    },
    pages() {
      return Math.ceil(this.currentTable.length / this.pageLength);
    },
    currentTable() {
      if (this.tab === 'big') {
        return orderBy(this.tables.big, ['wager'], ['desc']);
      }
      return orderBy(this.tables[this.tab], ['id'], ['desc']);
    },
    playerValues() {
      return this.playerHands.map((hand) => {
        let values = this.handValue(hand);
        return {
          hard: values[0],
          soft: values[1],
        };
      });
    },
    dealerValue() {
      let values = this.handValue(this.dealerHands[0]);

      return {
        hard: values[0],
        soft: values[1],
      };
    },
  },
  methods: {
    ...mapActions('Blackjack', [
      'updateWager',
      'startGame',
      'setPlayStarted',
      'setPlayEnded',
      'updateNonce',
    ]),
    ...mapActions('Sound', ['toggleSound']),
    handValue(hand) {
      let value = 0;
      let aces = 0;

      hand.forEach((card) => {
        if (card.value < 0) {
        } else if (card.value === 1) {
          aces++;
        } else if (!isNaN(card.value)) {
          value += this.cardValue(card.value);
        }
      });

      if (aces === 0) {
        return [value, value];
      }

      let softValue = value;
      for (let i = 0; i < aces; i++) {
        if (softValue + 11 > 21) {
          softValue += 1;
        } else {
          softValue += 11;
        }
      }

      return [value + aces, softValue];
    },
    cardValue(card) {
      switch (card) {
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 4;
        case 5:
          return 5;
        case 6:
          return 6;
        case 7:
          return 7;
        case 8:
          return 8;
        case 9:
          return 9;
        case 10:
        case 11:
        case 12:
        case 13:
          return 10;
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    fetchActivePlay() {
      try {
        axios
          .get('blackjack/active')
          .then(({ data }) => {
            this.game = data;
            this.updateWager(data.initial_wager);
            data.player.map((hand, j) => {
              hand.cards.map((card, i) => {
                this.playerHands[j][i] = {
                  id: i,
                  value: card.rank,
                  suit: card.suit,
                  revealed: true,
                  decked: false,
                };
              });
            });
            data.dealer.map((card, i) => {
              this.dealerHands[0][i] = {
                id: i,
                value: card !== null ? card.rank : -1,
                suit: card !== null ? card.suit : -1,
                revealed: card !== null,
                decked: false,
              };
            });

            this.activeHand = data.active_hand;
            this.showSplit = data.state === 'PLAYER_SPLIT';
            this.showInsurance = data.state === 'INSURANCE';

            this.plays = data.plays;
            this.double = data.double;

            setTimeout(() => (this.loading = false), 500);
            this.setPlayStarted();
          })
          .catch(() => {
            this.loading = false;
            this.setPlayEnded();
          });


      } catch(error) {
        this.loading = false;
        this.setPlayEnded();
      }
    },
    onWagerChange(value) {
      this.updateWager(value);
    },
    rejectInsurance() {
      this.showInsurance = false;

      this.onAction('NO_INSURANCE');
    },
    executeAction(x) {
      this.loading = true;
      try {
        axios
          .post('blackjack', { action: x })
          .then(({ data }) => {
            let delay = this.diffHands(data.plays);
            this.double = data.double;

            this.game = data;

            this.activeHand = data.active_hand;
            this.showSplit = data.state === 'PLAYER_SPLIT';
            this.showInsurance = data.state === 'INSURANCE';

            if (data.completed) {
              setTimeout(() => {
                this.handleDecision(data.results);

                this.setPlayEnded();
              }, delay);
            }
            setTimeout(() => (this.loading = false), delay * 0.75);
          })
          .catch(({ data, status }) => {
            this.loading = false;

            window.swal(
              'Uh-oh',
              'Something went wrong when running your play : ' +
                (typeof data.error != 'undefined' ? data.error : ''),
              'error',
            );
          });
      } catch (error) {
        console.log('blackjack has issue');
      }
    },
    onAction(x) {
      this.debouncedAction(x);
    },
    handleDecision(results) {
      results.forEach((x, i) => {
        switch (x) {
          case 'PUSH':
            this.playSound(muckSound);
            this.results[i] = 'push';
            break;

          case 'BUST':
            this.playSound(looseSound);
            this.results[i] = 'bust';
            break;

          case 'WIN':
            this.playSound(winSound);
            this.results[i] = 'win';
            break;

          case 'BLACKJACK':
            this.playSound(winSound);
            this.results[i] = 'blackjack';
            break;
        }
      });
    },
    onStartGame() {
      this.playSound(betSound);
      this.loading = true;
      this.game = null;
      this.results = ['', ''];
      this.plays = [];
      this.playerHands = [[], []];
      this.dealerHands = [[]];
      this.activeHand = 0;
      try {
        this.startGame()
          .then(({ data }) => {
            this.game = data;
            this.updateNonce();
            this.updateWager(data.initial_wager);

            let delay = this.diffHands(data.plays);
            this.double = data.double;

            // this.activeHand = data.active_hand
            this.showSplit = data.state === 'PLAYER_SPLIT';
            this.showInsurance = data.state === 'INSURANCE';

            if (data.completed) {
              setTimeout(() => {
                this.handleDecision(data.results);

                this.setPlayEnded();
              }, delay);
            }
            setTimeout(() => {
              this.loading = false;
            }, 400);
          })
          .catch((res) => {
            this.setPlayEnded();
            this.loading = false;
          });
      } catch (error) {
        this.setPlayEnded();
        this.loading = false;
        console.log('failed to start game blackjack');
      }
    },
    diffHands(newPlays) {
      if (newPlays.length > this.plays.length) {
        const curLength = this.plays.length;
        let i = 0;
        for (i = curLength; i < newPlays.length; i++) {
          const play = newPlays[i];

          this.plays.push(newPlays[i]);

          setTimeout(
            () => {
              if (play.action === 'DEAL_HOLE') {
                play.card = {
                  id: i,
                  value: -1,
                  suit: -1,
                  revealed: false,
                  decked: true,
                };
                this.dealerHands[0].push(play.card);

                this.dealerCardInserting = true;

                //
              } else if (play.action === 'REVEAL_HOLE') {
                play.card.id = i;
                play.card.value = play.card.rank;
                play.card.decked = false;
                play.card.revealed = true;

                this.dealerHands[0].pop();
                this.dealerHands[0].push(play.card);

                //
              } else if (play.action === 'DEAL_PLAYER') {
                play.card.id = i;
                play.card.value = play.card.rank;
                play.card.decked = true;
                play.card.revealed = false;

                this.playerHands[play.active_hand].push(play.card);

                this.playerCardInserting[play.active_hand] = true;

                //
              } else if (play.action === 'DEAL_DEALER') {
                play.card.id = i;
                play.card.value = play.card.rank;
                play.card.decked = true;
                play.card.revealed = false;

                this.dealerHands[0].push(play.card);

                this.dealerCardInserting = true;
              } else if (play.action === 'PLAYER_SPLIT') {
                let card = this.playerHands[0].pop();
                this.playerHands[1].push(card);

                this.playSound(dealSound);
              } else if (play.action === 'SWITCH') {
                this.activeHand = 1;
              }

              setTimeout(() => {
                if (play.card && play.card.decked) {
                  this.$nextTick(() => {
                    play.card.decked = false;
                    if (play.action === 'DEAL_PLAYER') {
                      this.playerCardInserting[play.active_hand] = false;
                    } else {
                      this.dealerCardInserting = false;
                    }
                    this.$forceUpdate();
                    this.playSound(dealSound);
                  });
                }
              }, 200);

              setTimeout(() => {
                this.$nextTick(() => {
                  if (play.card) {
                    play.card.revealed = play.action !== 'DEAL_HOLE';
                    this.$forceUpdate();
                    if (play.action === 'REVEAL_HOLE') {
                      this.playSound(flipSound);
                    }
                  }
                });
              }, 400);
            },
            (i + 1 - curLength) * 400,
          );
        }

        return (i + 4 - curLength) * 400;
      }

      return 0;
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin button-theme-color($color) {
  background: lighten($color, 5%);
  border: 0.5px solid darken($color, 5%);
  box-shadow: 0 0.25rem 0 0 darken($color, 5%);

  transition: all 150ms ease-in;

  &:active,
  &.active {
    background: lighten($color, 12%);
    box-shadow:
      0 0.05rem 0 0 darken($color, 5%),
      0 0.08rem 0 0 lighten($color, 80%),
      inset 0 0.05rem 0 0 darken($color, 15%);
    transform: translateY(0.15rem);
  }

  &:hover {
    background: lighten($color, 10%);
  }
}

.main-content {
  display: flex;
  flex-direction: column;

  > .is-fullwidth {
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .blackjack-apparatus {
    min-height: 0px !important;
    max-height: 100% !important;
  }

  .blackjack {
    flex-direction: column-reverse;
    display: flex;
  }

  .controls {
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    min-height: 350px;
  }

  .blackjack-table {
    border-radius: 1rem 1rem 0 0;
  }
}

@media (min-width: 769px) {
  .controls {
    max-width: 300px;
    min-width: 250px;
    border-radius: 1rem 0 0 1rem;
    margin-left: 20px;
  }

  .blackjack-table {
    border-radius: 0 1rem 1rem 0;
  }
}

.blackjack {
  position: relative;
  display: flex;
  /**flex-direction: row;**/
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  border-radius: 1rem;

  .settings {
    button {
      background: none;
      border: none;

      position: absolute;
      top: 1rem;

      left: 5rem;

      z-index: 2;

      color: #ffffff;
      font-size: 1rem;
      outline: none;

      cursor: pointer;

      &.fairness-hint {
        left: 9.5rem;
        color: red;
      }

      &:hover {
        color: whitesmoke;
      }

      i {
        line-height: 1.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .blackjack-table {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    overflow: hidden;

    transform: rotate(0deg);

    .deck {
      position: absolute;
      top: -4.98rem;
      right: 2rem;

      width: 76px;
      height: 120px;

      border-radius: 0.5rem;

      background-color: #3579f6;
      border: 0.25rem solid #ffffff;

      display: flex;
      justify-content: center;
      flex-direction: column;

      box-shadow:
        0 0.02rem 0 0 #d6d6d6,
        0 0.1rem 0 0 #ffffff,
        0 0.12rem 0 0 #d6d6d6,
        0 0.24rem 0 0 #ffffff,
        0 0.26rem 0 0 #d6d6d6,
        0 0.34rem 0 0 #ffffff,
        0 0.36rem 0 0 #d6d6d6,
        0 0.44rem 0 0 #ffffff,
        0 0.46rem 0 0 #d6d6d6,
        0 0.54rem 0 0 #ffffff,
        0 0.56rem 0 0 #d6d6d6;
    }

    .card-wrap {
      position: relative;
      display: flex;
      justify-content: space-around;
      perspective: 800px;

      width: auto;
      z-index: 2;

      .hand-value {
        position: absolute;
        color: #fff;
        background: #2e2e2e;
        padding: 0.1rem 1rem;
        border-radius: 0.75rem;
        font-weight: bold;
        z-index: 99;
        overflow: visible;
        box-shadow: 0 2px 1px 0 rgba(50, 45, 45, 0.5);
        opacity: 0;
        animation: 0.6s fadeIn;
        animation-fill-mode: forwards;

        &.disabled {
          background: #666666;
          box-shadow: 0 2px 1px 0 #999999;
        }
      }

      @keyframes fadeIn {
        80% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .card-inserting .playing-card:not(.in-deck) {
        /*transform: translateX(-1.25rem);*/
      }

      .playing-card {
        z-index: 1;
        transition:
          position 400ms linear,
          top 400ms linear,
          right 400ms ease-in,
          tansform 500ms ease-in;

        transform: translateX(0);

        top: 0;
        right: 0;

        @for $i from 2 through 10 {
          &:nth-child(#{$i}) {
            z-index: #{$i};

            margin-left: -2.5rem;
            margin-top: 1.25rem * ($i - 1);
          }
        }

        /*&.in-deck {
          position: fixed;
          margin-left: 0;
          margin-top: 0;

          .playing-card-inner {
            transform: rotateY(180deg) !important;
          }
        }*/
      }

      &.player-hand.is-double-down .split .playing-card:nth-of-type(3):not(.in-deck) {
        transform: rotateZ(-90deg) translateY(1.5rem);
      }

      &.dealer-hand {
        .playing-card.in-deck {
          top: -8.85rem;
          right: -12.5rem;
        }
      }

      &.player-hand {
        .playing-card.in-deck {
          top: -29.55em;
          right: -12.5rem;
        }
      }

      .split {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    .split.is-bust .playing-card {
      border: 4px solid red;
      border-radius: 12px;
    }

    .split.is-win .playing-card {
      border: 4px solid lime;
      border-radius: 12px;
    }

    .split.is-blackjack .playing-card {
      border: 4px solid lime;
      border-radius: 12px;
    }

    .split.is-push .playing-card {
      border: 4px solid yellow;
      border-radius: 12px;
    }

    .table-emboss {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #dedede;
      font-weight: bold;

      .ribbon {
        width: 20rem;

        position: relative;
        display: block;
        margin: auto;

        font-size: 1rem;
        line-height: 2rem;
        text-align: center;
        text-transform: uppercase;

        z-index: 1;

        .content {
          width: 100%;
          height: 100%;
          background: #26444f;
          box-shadow:
            0 0.05rem 0 0.05rem #1b323a,
            -0.005rem 0 0 0.05rem #1b323a,
            0.005rem 0 0 0.05rem #1b323a;
          border-radius: 0.5rem;

          display: inline-block;
          padding: 0.25rem 0.5rem;
        }

        &::before,
        &::after {
          box-sizing: border-box;
          display: block;
          content: '';
          width: 2rem;
          height: 2rem;

          position: absolute;
          top: 1.25rem;
          z-index: -1;
        }

        &:before {
          left: -1.75rem;
          border: 1.25rem solid #26444f;
          border-left-color: transparent;
          border-radius: 0.5rem;
        }

        &:after {
          right: -1.75rem;
          border: 1.25rem solid #26444f;
          border-right-color: transparent;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .controls {
    background: #263741;
    padding: 2.5rem 1.5rem;

    flex-grow: 1;

    .control-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1rem;
      grid-column-gap: 0.5rem;

      button {
        border: none;
        border-radius: 0.25rem;
        background: black;
        padding: 0.75rem 1rem;

        color: #ffffff;
        font-size: 1rem;

        outline: none;
        cursor: pointer;

        @include button-theme-color(#2e2e2e);

        &.btn-green {
          @include button-theme-color(green);
        }

        &.btn-green-alt {
          @include button-theme-color(lighten(green, 10%));
        }

        &.btn-yellow {
          @include button-theme-color(orange);
        }

        &:disabled {
          cursor: not-allowed;
          transform: translateY(0.1rem);
          box-shadow: 0 0.05rem 0 0 black;

          &.btn-green {
            box-shadow: 0 0.05rem 0 0 darken(green, 5%);
          }

          &.btn-green-alt {
            box-shadow: 0 0.05rem 0 0 lighten(green, 5%);
          }

          &.btn-yellow {
            box-shadow: 0 0.05rem 0 0 darken(orange, 5%);
          }
        }
      }

      .span-full {
        grid-column: 1 / span 2;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.main-content {
  .blackjack {
    .blackjack-table {
      .card-wrap {
        .playing-card {
          &.in-deck {
            .playing-card-inner {
              transform: translateX(0) rotateY(0);
            }
          }
        }
      }
    }
  }
}
</style>
