<template>
  <fairness
    game="Roulette"
    :client-seed="clientSeed"
    :server-seed="serverSeedHash"
    :nonce="seedPairNonce"
    :fetch-seed="refreshSeedInfo"
    :update-seed="changeSeed" />

  <div class="hidden">
    <div class="modal-background" v-on:click.prevent="hideModal()"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="content space-y-4">
          <p>
            <strong>Client Seed</strong>:<br />
            {{ clientSeed }}
          </p>
          <p>
            <strong>Server Seed Hash</strong>:<br />
            {{ serverSeedHash }}
          </p>
          <p><strong>Seed Pair Nonce</strong>: {{ seedPairNonce }}</p>
        </div>
      </section>
      <footer class="flex flex-row justify-left space-x-4 mt-4">
        <button
          v-on:click="createPair"
          class="flex flex-row gap-2 bg-chat-top-color min-w-96 px-4 py-3 rounded-md hover:opacity-70 font-semibold flex flex-inline items-center transition ease-in-out delay-0 hover:scale-105 duration-150">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clip-rule="evenodd" />
          </svg>
          New Seed Pair
        </button>

        <button
          v-if="!clientSeed"
          v-on:click="promptClientSeed"
          class="flex flex-row gap-2 bg-chat-top-color min-w-96 px-4 py-3 rounded-md hover:opacity-70 font-semibold flex flex-inline items-center transition ease-in-out delay-0 hover:scale-105 duration-150">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clip-rule="evenodd" />
          </svg>
          New Client Seed
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Fairness from '@/components/Shared/Fairness.vue';

export default {
  components: { Fairness },
  computed: {
    ...mapGetters({
      visible: 'shouldDisplayPF',
    }),
    ...mapGetters(['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  methods: {
    ...mapActions(['refreshSeedInfo', 'setNewClientSeed']),
    changeSeed(seed) {
      return this.setNewClientSeed({ seed });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  p,
  strong,
  h2,
  h5,
  label {
    color: #ffffff !important;
  }

  input {
    background-color: #0d1f28 !important;
    border-color: #0d1f28 !important;

    color: #ffffff !important;
  }
}

a.button {
  color: #182429 !important;
}
</style>
