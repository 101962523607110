<template>
  <div class="modal" :class="{ 'is-active': visible }">
    <div class="modal-background" v-on:click.prevent="hideModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Provably Fair</p>
        <button class="delete" v-on:click.prevent="hideModal()"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p><strong>Client Seed</strong>: {{ clientSeed }}</p>
          <p><strong>Server Seed Hash</strong>: {{ serverSeedHash }}</p>
          <p><strong>Seed Pair Nonce</strong>: {{ seedPairNonce }}</p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-primary" v-on:click.prevent="createPair()">New Seed Pair</a>
        <a
          class="button is-primary"
          v-if="!clientSeed"
          v-on:click.prevent="promptClientSeed()"
          >New Client Seed</a
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      visible: 'shouldDisplayPF',
    }),
    ...mapGetters(['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  watch: {
    visible() {
      this.refreshSeedInfo()
        .catch(() => this.refreshSeeds())
        .then(() => this.refreshSeedInfo());
    },
  },
  methods: {
    ...mapActions(['refreshSeedInfo']),
    ...mapActions({
      promptClientSeed: 'promptClientSeed',
      refreshSeeds: 'refreshSeeds',
      hideProvablyFair: 'hideProvablyFair',
      showModal: 'showProvablyFair',
    }),
    hideModal() {
      this.$emit('closed');
      this.hideProvablyFair();
    },
    createPair() {
      this.refreshSeeds().then(() => this.promptClientSeed());
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  background-color: #182429 !important;
  p,
  strong,
  h2,
  h5,
  label {
    color: #ffffff !important;
  }
  input {
    background-color: #192c38 !important;
    border-color: #192c38 !important;

    color: #ffffff !important;
  }
}
a.button {
  color: #182429 !important;
}
</style>
