import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/win.mp3';
import lose from '../../assets/media/loose.mp3';
import deal from '../../assets/media/deal.mp3';
import place from '../../assets/media/place.mp3';
import flip from '../../assets/media/flip.mp3';

import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const loseSound = new Howl({
  src: [lose],
});

const dealSound = new Howl({
  src: [deal],
});

const placeSound = new Howl({
  src: [place],
});

const winSound = new Howl({
  src: [win],
});

const flipSound = new Howl({
  src: [flip],
});

export { betSound, dealSound, loseSound, winSound, placeSound, flipSound };
