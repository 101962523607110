const DuelMutations = {
  PUSH_DUEL_HISTORY: 'duels/PUSH_DUEL_HISTORY',
  REMOVE_DUEL: 'duels/REMOVE_DUEL',
  SET_ACTIVE_DUEL: 'duels/SET_ACTIVE_DUEL',
  SET_DUELS: 'duels/SET_DUELS',
  SET_DUEL_HISTORY: 'duels/SET_DUEL_HISTORY',
  UPDATE_DUEL: 'duels/UPDATE_DUEL',
  UPDATE_SEED: 'duels/UPDATE_SEED',
};

export default {
  state: {
    activeDuelId: null,
    duels: [],
    history: [],
  },
  getters: {
    activeDuel: (state) => state.duels.find((x) => x.id === state.activeDuelId),
    duelHistory: (state) => state.history,
    joinableDuels: (state) =>
      state.duels.filter((x) => !x.joiner && x.seeds[x.user_id].locked),
  },
  actions: {
    cancelDuel({ commit, dispatch }, duelId) {
      Vue.http
        .delete(`duels/${duelId}`)
        .then(({ data }) => {
          commit(DuelMutations.SET_ACTIVE_DUEL, null);
          dispatch('refreshDuels');
        })
        .catch(() => {
          commit(DuelMutations.SET_ACTIVE_DUEL, null);
          dispatch('refreshDuels');
        });
    },
    updateDuel({ commit, dispatch }, duelId) {
      Vue.http
        .get(`duels/${duelId}`)
        .then(({ data }) => {
          commit(DuelMutations.UPDATE_DUEL, data);
        })
        .catch(() => {
          dispatch('refreshDuels');
        });
    },
    lockDuelSeed({ commit }, { duelId, userId, seed }) {
      Vue.http
        .patch(`duels/${duelId}/seed`, {
          seed,
          locked: true,
        })
        .then(({ data }) => {
          commit(DuelMutations.UPDATE_SEED, {
            duelId,
            userId,
            seed: data,
          });
        });
    },
    removeDuel({ commit }, duelId) {
      commit(DuelMutations.REMOVE_DUEL, duelId);
    },
    finishDuel({ commit, dispatch }, duelId) {
      Vue.http
        .post(`duels/${duelId}/finish`)
        .then(({ data }) => {
          commit(DuelMutations.SET_ACTIVE_DUEL, null);
          dispatch('refreshDuels');
          // TODO: goto results page/set watcher for when duel is finished
        })
        .catch(() => {
          commit(DuelMutations.SET_ACTIVE_DUEL, null);
          dispatch('refreshDuels');
        });
    },
    createDuel({ commit, dispatch }, pot) {
      Vue.http.post('duels', { pot }).then(({ data }) => {
        dispatch('refreshDuels');
        // debug('Duel successfully created with a pot of', pot, 'resp:', data)
      });
    },
    joinDuel({ commit, dispatch }, duelId) {
      Vue.http.post(`duels/${duelId}/join`).then(({ data }) => {
        dispatch('refreshDuels');
        commit(DuelMutations.SET_ACTIVE_DUEL, duelId);
      });
    },
    refreshDuels({ commit, getters }) {
      Vue.http.get('duels').then(({ data }) => {
        commit(DuelMutations.SET_DUELS, data);
        const myId = getters.me.id;
        data.forEach((x) => {
          if (x.joiner && x.joiner.id === myId) {
            commit(DuelMutations.SET_ACTIVE_DUEL, x.id);
          } else if (x.user.id === myId) {
            commit(DuelMutations.SET_ACTIVE_DUEL, x.id);
          }
        });
      });
    },
    updateDuelSeed({ commit, dispatch, globalGetters }, { duelId, userId, seed }) {
      Vue.http.patch(`duels/${duelId}/seed`, { seed }).then(({ data }) => {
        commit(DuelMutations.UPDATE_SEED, { duelId, userId, seed: data });
        // this.seeds[this.myId] = { ...this.seeds[this.myId], ...data }
      });
    },
  },
  mutations: {
    [DuelMutations.UPDATE_SEED](state, { duelId, userId, seed }) {
      const duel = state.duels.find((x) => x.id === duelId);

      if (seed.nonce < duel.seeds[userId].nonce) {
        return;
      }

      // update the stored duel
      state.duels = state.duels
        .filter((x) => x.id !== duelId)
        .concat([
          {
            ...duel,
            seeds: {
              ...duel.seeds,
              [userId]: seed,
            },
          },
        ]);
    },
    [DuelMutations.SET_ACTIVE_DUEL](state, duelId) {
      state.activeDuelId = duelId;
    },
    [DuelMutations.REMOVE_DUEL](state, duelId) {
      state.duels = state.duels.filter((x) => x.id !== duelId);
    },
    [DuelMutations.SET_DUELS](state, duels) {
      state.duels = duels;
    },
    [DuelMutations.UPDATE_DUEL](state, duel) {
      state.duels = [duel].concat(state.duels.filter((x) => x.id !== duel.id));
    },
    [DuelMutations.SET_DUEL_HISTORY](state, duels) {
      state.history = duels;
    },
    [DuelMutations.PUSH_DUEL_HISTORY](state, duel) {
      state.history = [duel].concat(state.history);
    },
  },
};
