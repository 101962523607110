<template>
  <div class="bets-per-chip">
    <label for="bets-per-chip">Bets Per Chip</label>
    <div class="input-group">
      <div class="input-wrap">
        <span class="number">{{ number }}{{ modifier }}</span>
        <input
          type="text"
          name="bets-per-chip"
          id="bets-per-chip"
          @keydown.prevent="onInput"
          @blur.prevent="processNumber(value)" />
      </div>
      <button class="btn" v-on:click="multiplyWager(0.5)">&#189;x</button>
      <button class="btn" v-on:click="multiplyWager(2)">2x</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetPerChipInput',
  data: () => ({
    number: '1',
    modifier: '',
  }),
  props: {
    disabled: {
      default: false,
    },
    maxValue: {
      default: false,
    },
    chips: {
      default: 0,
    },
  },
  computed: {
    multiplier: function () {
      if (this.modifier === 'K') {
        return 1e3;
      }

      if (this.modifier === 'M') {
        return 1e6;
      }

      if (this.modifier === 'B') {
        return 1e9;
      }

      return 1;
    },
    value: function () {
      let num =
        this.number === undefined || this.number === null || this.number.length === 0
          ? '0'
          : this.number;
      return parseFloat(num) * this.multiplier;
    },
  },
  watch: {
    number: function (n, o) {
      if (n < 0) {
        this.number = o;
      }
      this.$emit('change', { value: this.value });
    },
    modifier: function () {
      this.$emit('change', { value: this.value });
    },
  },
  methods: {
    processNumber(num) {
      if (num >= 1e9) {
        this.number = `${num / 1e9}`;
        this.modifier = 'B';
      } else if (num >= 1e6) {
        this.number = `${num / 1e6}`;
        this.modifier = 'M';
      } else if (num >= 1e3) {
        this.number = `${num / 1e3}`;
        this.modifier = 'K';
      } else {
        this.number = `${num}`;
        this.modifier = '';
      }
    },
    onInput(e) {
      if (this.disabled) {
        return;
      }

      const allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        'k',
        'K',
        'm',
        'M',
        'b',
        'B',
        'Backspace',
      ];

      let key = e.key || e.keyCode;

      if (['ArrowUp', '+'].includes(key)) {
        this.processNumber(this.value + this.multiplier);
      }

      if (['ArrowDown', '-'].includes(key)) {
        if (this.value > this.multiplier) {
          this.processNumber(this.value - this.multiplier);
        } else {
          let nextMultiplier = this.multiplier / 1e3;
          this.processNumber(this.value - (nextMultiplier > 1 ? nextMultiplier : 1));
        }
      }

      if (!allowedKeys.includes(key)) {
        return;
      }

      if (key === 'k' || key === 'K') {
        this.modifier = 'K';
        return;
      }

      if (key === 'm' || key === 'M') {
        this.modifier = 'M';
        return;
      }

      if (key === 'b' || key === 'B') {
        this.modifier = 'B';
        return;
      }

      if (key === '.') {
        if (!this.number.includes('.')) {
          this.number = `${this.number}.`;
        }
        return;
      }

      if (key === 'Backspace') {
        if (this.number.length === 1) {
          this.number = '';
        }

        if (this.number.length === 0) {
          this.number = '';
          this.modifier = '';
        }

        this.number = this.number.slice(0, -1);
        return;
      }

      if (this.number.length >= 6) {
        return;
      }

      this.number = `${this.number}${key}`;
    },
    multiplyWager(x) {
      if (this.disabled) {
        return;
      }

      if (this.chips * this.value * x > this.maxValue) {
        return;
      }

      let num = Math.round(this.value * x * 100) / 100;
      this.processNumber(num);
    },
  },
};
</script>

<style scoped lang="scss">
label {
  color: #ffffff;
  font-weight: 700;
}

input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;

  color: #ffffff;
  font-size: 1.75rem;
  line-height: 1.5rem;
  text-align: right;

  user-select: none;
}

.input-group {
  display: flex;

  .btn:not(:last-child) {
    border-radius: 0;
  }

  :first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  :last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.input-wrap {
  width: 100%;
  background-color: #2e2e2e;
  border-radius: 0.5rem;

  display: flex;
  flex-flow: row nowrap;
  position: relative;

  border: 0.5px solid darken(#2e2e2e, 5%);
  box-shadow:
    0 0.25rem 0 0 darken(#2e2e2e, 5%),
    0 0.26rem 0 0 lighten(#2e2e2e, 80%);

  .number,
  .modifier {
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1rem;
    text-align: right;
    font-family: monospace;

    padding: 0.75rem 5px;

    background-color: transparent;
  }

  .number {
    flex: 1 1 100%;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;

    padding-right: 5px;

    font-family: monospace;
  }
}
</style>

<style lang="scss">
@import 'variables';

.theme-dark .bets-per-chip {
  .input-wrap {
    background-color: $theme-black !important;
    box-shadow: 0 0.15rem 0 0 darken($theme-dark-black, 5%) !important;
  }

  button {
    box-shadow:
      0 0.12rem 0 0 darken($theme-green, 5%),
      0 0.13rem 0 0 lighten($theme-green, 80%);

    &:active,
    &.active {
      background: $theme-dark-green !important;
      box-shadow:
        0 0.05rem 0 0 darken($theme-green, 5%),
        0 0.08rem 0 0 lighten($theme-green, 80%),
        inset 0 0.05rem 0 0 darken($theme-green, 15%);
      transform: translateY(0.08rem);
    }

    &:hover {
      background: lighten($theme-green, 10%);
    }
  }
}
</style>
