<template>
  <fairness
    game="Limbo"
    :client-seed="clientSeed"
    :server-seed="serverSeedHash"
    :nonce="seedPairNonce"
    :fetch-seed="refreshSeedInfo"
    :update-seed="changeSeed" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Fairness from '@/components/Shared/Fairness.vue';

export default {
  name: 'FairnessModal',
  components: { Fairness },
  computed: {
    ...mapGetters('Limbo', ['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  methods: {
    ...mapActions('Limbo', ['refreshSeedInfo', 'setNewClientSeed']),
    changeSeed(seed) {
      return this.setNewClientSeed({ seed });
    },
  },
};
</script>

>
