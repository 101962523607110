<template>
  <div class="w-full">
    <div class="flex flex-row w-full">
      <div class="flex-none hidden md:flex py-2">
        <div class="relative h-full pr-2">
          <div class="numbers" style="top: 10%">
            <span
              class="label is-unselectable is-small"
              @mouseover="extraShow = 3"
              @mouseleave="extraShow = 21"
              >4</span
            >
          </div>
          <div class="numbers" style="top: 16%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 9"
              @mouseleave="extraShow = 21"
              >10</span
            >
          </div>
          <div class="numbers" style="top: 22%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 1"
              @mouseleave="extraShow = 21"
              >2</span
            >
          </div>
          <div class="numbers" style="top: 28%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 5"
              @mouseleave="extraShow = 21"
              >6</span
            >
          </div>
          <div class="numbers" style="top: 43%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 11"
              @mouseleave="extraShow = 21"
              >12</span
            >
          </div>
          <div class="numbers" style="top: 50%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 0"
              @mouseleave="extraShow = 21"
              >1</span
            >
          </div>
          <div class="numbers" style="top: 58%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 7"
              @mouseleave="extraShow = 21"
              >8</span
            >
          </div>
          <div class="numbers" style="top: 70%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 6"
              @mouseleave="extraShow = 21"
              >7</span
            >
          </div>
          <div class="numbers" style="top: 76%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 2"
              @mouseleave="extraShow = 21"
              >3</span
            >
          </div>
          <div class="numbers" style="top: 82%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 10"
              @mouseleave="extraShow = 21"
              >11</span
            >
          </div>
          <div class="numbers" style="top: 88%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 4"
              @mouseleave="extraShow = 21"
              >5</span
            >
          </div>
        </div>
      </div>
      <div class="flex-1 flex w-full">
        <div class="game-wrap w-full">
          <div class="slots-wrap flex">
            <div class="slots-frames flex w-full">
              <SlotsFrame />
            </div>
            <div class="reels-container" ref="rc">
              <!--              <resize-observer @notify="handleResize" />-->
              <div
                v-for="(reel, idx) in expandedReels"
                class="reel"
                v-bind:key="idx"
                :style="'height: ' + reelHeight + 'px'">
                <SlotsSymbol
                  v-for="(symbol, jdx) in reel"
                  v-bind:key="idx + '-' + jdx"
                  :type="Number.parseInt(symbol)"
                  :currentOffset="offsets[idx] * 10"
                  :hidden="!shouldShow(idx, jdx)"
                  :reel="idx + 1"
                  :blury="blury[idx]" />
              </div>
            </div>

            <div class="line-container">
              <div v-if="show[0] || extraShow == 0" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="135"
                    x2="500"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[1] || extraShow == 1" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="45"
                    x2="500"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[2] || extraShow == 2" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="225"
                    x2="500"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[3] || extraShow == 3" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="10"
                    y1="9"
                    x2="250"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="225"
                    x2="490"
                    y2="9"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[4] || extraShow == 4" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="10"
                    y1="245"
                    x2="250"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="45"
                    x2="490"
                    y2="245"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[5] || extraShow == 5" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="45"
                    x2="150"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="45"
                    x2="350"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="225"
                    x2="500"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[6] || extraShow == 6" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="225"
                    x2="150"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="225"
                    x2="350"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="45"
                    x2="500"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[7] || extraShow == 7" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="180"
                    x2="150"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="45"
                    x2="350"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="225"
                    x2="500"
                    y2="90"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[8] || extraShow == 8" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="90"
                    x2="150"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="225"
                    x2="350"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="45"
                    x2="500"
                    y2="180"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[9] || extraShow == 9" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="10"
                    y1="9"
                    x2="150"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="135"
                    x2="350"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="135"
                    x2="490"
                    y2="245"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[10] || extraShow == 10" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="10"
                    y1="245"
                    x2="150"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="135"
                    x2="350"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="135"
                    x2="490"
                    y2="9"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[11] || extraShow == 11" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="180"
                    x2="150"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="45"
                    x2="250"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="45"
                    x2="490"
                    y2="245"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[12] || extraShow == 12" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="90"
                    x2="150"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="225"
                    x2="250"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="225"
                    x2="490"
                    y2="9"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[13] || extraShow == 13" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="135"
                    x2="150"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="135"
                    x2="250"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="45"
                    x2="490"
                    y2="245"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[14] || extraShow == 14" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="135"
                    x2="150"
                    y2="135"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="135"
                    x2="250"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="225"
                    x2="490"
                    y2="9"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[15] || extraShow == 15" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="45"
                    x2="150"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="45"
                    x2="350"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="225"
                    x2="500"
                    y2="90"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[16] || extraShow == 16" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="225"
                    x2="150"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="225"
                    x2="350"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="45"
                    x2="500"
                    y2="180"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[17] || extraShow == 17" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="180"
                    x2="150"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="45"
                    x2="350"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="225"
                    x2="500"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[18] || extraShow == 18" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="90"
                    x2="150"
                    y2="225"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="150"
                    y1="225"
                    x2="350"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="350"
                    y1="45"
                    x2="500"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
              <div v-if="show[19] || extraShow == 19" class="line">
                <svg viewBox="0 0 500 270">
                  <line
                    x1="0"
                    y1="45"
                    x2="250"
                    y2="45"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                  <line
                    x1="250"
                    y1="45"
                    x2="490"
                    y2="245"
                    style="stroke: rgb(255, 255, 255); stroke-width: 2" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-none hidden md:block py-5 pl-2">
        <div class="relative h-full hidden md:block">
          <div class="numbers" style="top: 10%">
            <span
              class="label is-unselectable is-small"
              @mouseover="extraShow = 14"
              @mouseleave="extraShow = 21"
              >15</span
            >
          </div>
          <div class="numbers" style="top: 18%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 12"
              @mouseleave="extraShow = 21"
              >13</span
            >
          </div>
          <div class="numbers" style="top: 26%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 18"
              @mouseleave="extraShow = 21"
              >19</span
            >
          </div>
          <div class="numbers" style="top: 46%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 15"
              @mouseleave="extraShow = 21"
              >16</span
            >
          </div>
          <div class="numbers" style="top: 52%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 16"
              @mouseleave="extraShow = 21"
              >17</span
            >
          </div>
          <div class="numbers" style="top: 58%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 8"
              @mouseleave="extraShow = 21"
              >9</span
            >
          </div>
          <div class="numbers" style="top: 74%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 17"
              @mouseleave="extraShow = 21"
              >18</span
            >
          </div>
          <div class="numbers" style="top: 82%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 19"
              @mouseleave="extraShow = 21"
              >20</span
            >
          </div>
          <div class="numbers" style="top: 90%">
            <span
              class="label is-unselectable"
              @mouseover="extraShow = 13"
              @mouseleave="extraShow = 21"
              >14</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlotsFrame from './SlotsFrame.vue';
import SlotsSymbol from './Symbols/SlotsSymbol.vue';
import { winSound } from './sounds';

export default {
  name: 'SlotsCanvas',
  components: {
    SlotsFrame,
    SlotsSymbol,
  },
  props: {
    currentState: {
      default: 'finished',
    },
    inModal: {
      default: false,
    },
    reels: {},
    spins: {},
    stateHandler: {},
    infoHandler: {},
    lines: {},
    soundHandler: {},
  },
  data: () => ({
    currentIndex: 0,
    bonusRounds: 0,
    multiplier: 0.0,
    shown: [],
    offsets: [20, 20, 20, 20, 20],
    show: [],
    blury: [],
    extraShow: 21,
    reelHeight: 0,
  }),
  created() {
    for (let i = 0; i < 20; ++i) {
      this.show.push(0);
      this.blury.push(false);
    }
    this.$nextTick(() => {
      this.reelHeight = this.$refs.rc.clientHeight;
    });
  },
  watch: {
    currentState: {
      handler: function (newState, oldState) {
        for (let x = 0; x < 20; ++x) {
          this.show.splice(x, 1, 0);
        }
      },
      immediate: true,
    },
    spins: {
      handler: function (newState, oldState) {
        this.shown = [];
        this.currentIndex = 0;
        this.bonusRounds = 0;
        this.multiplier = 0.0;
        this.infoHandler(this.multiplier, this.bonusRounds, this.currentIndex);

        let i = 0;
        let delay = 0;
        for (i = 1; i <= newState.length; ++i) {
          const spin = this.spins[i - 1];
          setTimeout(
            function (self, spin) {
              for (let x = 0; x < 20; ++x) {
                self.show.splice(x, 1, 0);
              }
              for (let r = 0; r < self.reels.length; ++r) {
                self.blury.splice(r, 1, true);
                setTimeout(
                  function (self, idx, realPosition) {
                    self.blury.splice(idx, 1, false);
                    self.offsets.splice(idx, 1, realPosition);
                  },
                  2000 + r * 200,
                  self,
                  r,
                  spin.positions[r] + 2,
                );
              }
            },
            delay,
            this,
            spin,
          );

          delay += 3200;
          setTimeout(
            function (self, spin, winSound) {
              if (spin.winnings.length > 0) {
                self.soundHandler(winSound);
              }
            },
            delay,
            this,
            spin,
            winSound,
          );

          spin.winnings.forEach((obj) => {
            setTimeout(
              function (self) {
                if (
                  Number.parseInt(obj.symbol) === 12 &&
                  Number.parseInt(obj.count) >= 3
                ) {
                  self.bonusRounds = Math.min(self.bonusRounds + 15, 180);
                }
                self.multiplier += Number.parseFloat(obj.winning);
                if (typeof obj.line !== 'undefined') {
                  const newShown = [];
                  for (let x = 0; x < self.reels.length; ++x) {
                    newShown.push(self.positions[x] + self.lines[obj.line][x] - 1);
                  }
                  for (let x = 0; x < 20; ++x) {
                    self.show.splice(x, 1, obj.line === x ? 1 : 0);
                  }
                  self.shown = newShown;
                } else {
                  for (let x = 0; x < 20; ++x) {
                    self.show.splice(x, 1, 0);
                  }
                  self.shown = 'scatter';
                }
                self.infoHandler(self.multiplier, self.bonusRounds, self.currentIndex);
              },
              delay,
              this,
            );
            delay += 1500;
          });
          setTimeout(
            function (self, i) {
              self.shown = [];
              self.currentIndex = Math.min(self.currentIndex + 1, self.spins.length - 1);
              self.infoHandler(self.multiplier, self.bonusRounds, self.currentIndex);

              if (i === self.spins.length) {
                self.stateHandler('finished');
              }
            },
            delay,
            this,
            i,
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    moveReels(positions) {},
    shouldShow(idx, jdx) {
      if (typeof this.shown === 'string') {
        return this.expandedReels[idx][jdx] === 12;
      } else {
        if (this.shown.length === 0) {
          return true;
        } else {
          const pos = this.shown[idx] + 2;
          return pos === jdx || pos + this.reels[idx].length === jdx;
        }
      }
    },
    handleResize() {
      this.reelHeight = this.$refs.rc.clientHeight;
    },
  },
  computed: {
    expandedReels() {
      return this.reels.map((reel) => {
        const newReel = [...reel];
        newReel.unshift(reel[reel.length - 2], reel[reel.length - 1]);
        newReel.push(reel[0], reel[1]);
        return newReel;
      });
    },
    positions() {
      if (this.spins.length === 0) {
        return this.reels.map((reel) => Math.floor(Math.random() * reel.length));
      } else {
        return this.spins[this.currentIndex].positions;
      }
    },
    realOffsets() {
      return this.offsets.map((o) => Math.floor(o / 10));
    },
  },
};
</script>

<style type="text/css" scoped>
.numbers {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  color: rgb(255, 255, 255);
}

.numbers > span {
  font-size: 12px;
}

.reels-container {
  top: 4%;
  bottom: 4%;
  left: 2%;
  right: 2%;
  /*top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;*/
  display: grid;
  grid-template-columns: repeat(5, 20%);

  /*top: 4%;
  bottom: 4%;
  left: 2%;
  right: 2%;*/
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(52, 80, 91, 0.8) 0%,
    rgba(22, 35, 40, 0.8) 100%
  );
  border: 5px solid rgba(23, 35, 43, 0.02);
  box-shadow: inset 0px 0px 100px rgba(10, 18, 23, 0.8);
  border-radius: 18px;
}

.line {
  top: 14%;
  bottom: 5%;
  left: 2%;
  right: 2%;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  z-index: 0;
}

.game-wrap {
  position: relative;
}

.reel {
  top: 5%;
  left: 0%;

  width: 100%;
  height: 100%;
  position: relative;
  min-width: 64px;
  min-height: 0;
}

.no-boundries {
  padding-bottom: 0;
  margin: 0;
}

.multiplier-container {
  color: rgb(255, 255, 255);
  background-color: #1a2b32;
  font-size: 1.2em;
  font-weight: 600;

  padding: 0.5em 0.75em;
  border-radius: 0.25em 0.25em 0px 0px;
}

@media (max-width: 768px) {
  .multiplier-container {
    border-radius: 0.25em 0.25em 0.25em 0.25em;
  }
}

@media (min-width: 769px) {
  .multiplier-container {
    border-radius: 0.25em 0.25em 0px 0px;
  }
}
</style>
