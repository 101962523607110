<template>
  <div>
    <form @submit.stop.prevent="() => tip()">
      <label
        for="username"
        class="block mt-1 my-2 text-sm font-medium text-text-dim-color"
        >Username</label
      >
      <div class="relative">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
              fill="white" />
            <path
              d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
              fill="white" />
          </svg>
        </div>
        <input
          v-model="form.username"
          required
          type="text"
          id="username"
          class="input-with-icon"
          placeholder="Username here" />
      </div>

      <label
        for="email-address"
        class="block my-2 text-sm font-medium text-text-dim-color"
        >Amount</label
      >
      <div class="relative">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <img :src="icon" class="h-5 w-6" />
        </div>
        <input
          v-model="form.amount"
          required
          type="text"
          id="amount"
          class="input-with-icon"
          placeholder="Amount here" />
      </div>
      <button :disabled="!canTip || loading" class="btn-bet">Send Tip</button>
    </form>
  </div>
</template>

<script>
import Balance from '@/components/Bank/Balance.vue';
import { ordersStore } from '@/store/pinia/ordersStore';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { getErrorCodeMessage } from '@/utils';

export default {
  name: 'Tip',
  setup() {
    const orderS = ordersStore();
    const balS = balanceStore();
    return { balS, getBalanceIcon, orderS };
  },
  components: { Balance },
  data() {
    return {
      form: { username: '', amount: null },
      loading: false,
    };
  },
  computed: {
    icon() {
      return getBalanceIcon(this.currency);
    },
    currency() {
      return this.balS.displayInFiat ? this.balS.defaultFiat : this.balS.default;
    },
    canTip() {
      return (
        this.form.username &&
        this.form.username.length > 0 &&
        !isNaN(this.form.amount) &&
        this.form.amount > 0
      );
    },
  },
  methods: {
    async tip() {
      if (this.form !== null) {
        let curr = this.balS.displayInFiat ? this.balS.defaultFiat : this.balS.default;
        this.$swal({
          title: 'Confirm tipping',
          html: `You are sending <strong style="color: grey">${this.form.amount} ${curr}</strong> to <strong style="color: grey">${this.form.username}</strong>, are you sure that you have spelled the Recipient's name correctly!<br>This transfer is <strong style="color: grey">NOT</strong> reversable.`,
          showCancelButton: true,
          animation: 'slide-from-top',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let form = { ...this.form };
            const rate = this.balS.displayInFiat
              ? this.balS.exchangeRate(this.balS.default)
              : 1;
            form.amount = parseFloat(this.form.amount / rate);
            try {
              const { data } = await this.orderS.sendtip(form, curr);
              this.form.amount = '';
              this.$swal('', `${data.message}`, 'success');
            } catch (e) {
              const { message } = getErrorCodeMessage(e, 'Unable to send tip!');
              this.$swal('Uh-oh', 'Something went wrong: ' + message, 'error');
            } finally {
              this.loading = false;
            }
          }
        })
        .catch((error) => {});
      }
    },
  },
};
</script>
