<template>
  <div
    class="bg-slots-bg bg-primary/50 bg-fill w-full flex flex-col px-4 rounded-xl"
    id="js-canvas-root">
    <div class="w-full flex flex-col items-center mt-10 z-10 xl:top-5 md:-top-2">
      <div
        v-if="bonusRounds > 0"
        class="h-10 -mb-7 flex items-center bg-secondary-light rounded-2xl p-4">
        <label class="text-sm px-2">Round {{ currentRound }} / {{ bonusRounds }}</label>
      </div>
      <div class="flex -mb-7 items-center h-10 bg-secondary-light rounded-2xl p-4">
        <label class="text-sm px-2"
          >Total Payout {{ balS.formatCrypto(winning, true) }}</label
        >
        <img :src="balanceIcon" />
      </div>
    </div>
    <div>
      <SlotsCanvas
        :currentState="currentState"
        :reels="reels"
        :spins="spins"
        :lines="lines"
        :stateHandler="stateHandler"
        :infoHandler="infoHandler"
        :soundHandler="soundHandler" />
    </div>
  </div>
</template>

<script>
import SlotsCanvas from './SlotsCanvas.vue';
import { defaultConvertor } from '../../lib/rsgp';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
export default {
  name: 'SlotsGrid',
  components: {
    SlotsCanvas,
  },
  setup() {
    const balS = balanceStore();
    return { balS };
  },
  props: {
    currentState: {
      type: String,
      default: 'notStarted',
    },
    reels: {
      default: [],
    },
    lines: {},
    spins: {},
    currency: {},
    stateHandler: {},
    winning: {
      default: 0,
    },
    bonusRounds: {},
    currentRound: {},
    infoHandler: {},
    soundHandler: {},
  },
  computed: {
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
  },
  methods: {
    rsgp(x) {
      return x;
    },
  },
};
</script>

<style scoped>
.grid-wrap {
  height: 100%;
}

.no-boundries {
  padding-bottom: 0;
  margin: 0;
}

.multiplier-container {
  color: rgb(255, 255, 255);
  background-color: #1a2b32;
  font-size: 1.2em;
  font-weight: 600;

  padding: 0.5em 0.75em;
  border-radius: 0.25em 0.25em 0px 0px;
}

@media (max-width: 768px) {
  .multiplier-container {
    border-radius: 0.25em 0.25em 0.25em 0.25em;
  }
}

@media (min-width: 769px) {
  .multiplier-container {
    border-radius: 0.25em 0.25em 0px 0px;
  }
}
</style>
