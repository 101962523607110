<template>
  <div class="card-slide-inner swiper-slide">

    <div v-if="tab" v-on:click="$router.push({ path: $route.path, query: { tab: tab } })" class="left-part">
      <img :src="image" alt="Slide Image" />
    </div>

    <div v-else class="left-part">
      <a :href="link" v-if="link">
        <img v-if="image" :src="image" alt="Slide Image" />
      </a>
      <img v-else :src="image" alt="Slide Image" />
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'BannerSlide',
  props: {
    image: {
      default: '',
    },
    link: {
      default: false
    },
    tab: {
      default: false
    }
  },
};
</script>

<style scoped></style>
