<template>
  <AptPayWithdraw :type="type" />
</template>

<script>
import AptPayWithdraw from '@/components/Bank/AptPayWithdraw.vue';

export default {
  name: 'TestComponent',
  components: { AptPayWithdraw },
  props: {
    type: {
      default: 'max-w-md',
    },
  },
};
</script>
