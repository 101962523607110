<template>
  <div class="section-preloader" :class="{ 'loading-modal': !this.showProgress }">
    <div class="container">
      <div class="inner-content">
        <img :src="'../../images/pre-loader-logo.svg'" alt="" />
        <h6 class="p-title" v-if="this.showProgress">America's #1 Social Casino.</h6>
        <h6 class="p-title" v-else>Loading...</h6>
      </div>
    </div>
    <div v-if="this.showProgress" class="progress-bar">
      <h4 class="progressbar-number">{{ width }}%</h4>
      <div class="progress-bar-line" :style="'width:' + width + '%'"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  created() {
    this.now = new Date().getTime();
    this.page_load_time = this.now - window.performance.timing.navigationStart;
  },
  setup() {
    return {
      page_load_time: '',
      now: '',
    };
  },
  props: {
    showProgress: {
      default: false,
    },
    height: {
      type: String,
      default: '20px',
    }
  },
  data() {
    return {
      intervalid1: 0,
      width: 0,
    };
  },
  mounted: function () {
    this.calculated();
  },
  methods: {
    onLoad() {},
    calculated() {
      var width = 100; // width of a progress bar in percentage
      const perfData = window.performance.timing; // The PerformanceTiming interface
      const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart); // Calculated Estimated Time of Page Load which returns negative value.
      const time = parseInt((EstimatedTime / 1000) % 60) * 100; //Converting EstimatedTime from miliseconds to seconds.
      const second_per_pec = width / time;

      this.intervalid1 = setInterval(() => {
        this.width += 1;

        if (this.width >= 100) {
          clearInterval(this.intervalid1);
        }
      }, 1);
    },
  },
};
</script>

<style scoped>
/* if we need to add rotating image */
/* .rotate {
  animation: rotation 5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */

.section-preloader.loading-modal {
  height: 100%;
  padding: 5% 0;
}
</style>
