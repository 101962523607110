<template>
  <canvas
    class="roulette-wheel max-w-[250px] ,max-h-[250px]"
    width="600"
    height="600"
    ref="rouletteWheel">
  </canvas>
</template>

<script>
import RouletteLayout, { RouletteLayouts } from './RouletteLayouts';
import RouletteWheelRenderer, { THEMES } from './RouletteWheelRenderer';

export default {
  name: 'RouletteWheel',
  data: () => ({
    wheel: null,
  }),
  props: {
    finalSlot: Number,
    theme: String,
  },
  watch: {
    finalSlot: function (n) {
      this.dropBall(n);
    },
    theme: function (t) {
      if (t.indexOf('dark') !== -1) {
        this.wheel.setTheme(THEMES.DARK_MODE);
      } else {
        this.wheel.setTheme(THEMES.CLASSIC);
      }
    },
  },
  methods: {
    dropBall(num) {
      if (num >= 0 && num <= 37) {
        this.$emit('beforeBallDrop');
        const totalTime = this.wheel.dropBall(num);

        setTimeout(() => {
          this.$emit('afterBallStopped');
        }, totalTime);
      }
    },
  },
  mounted() {
    const canvas = this.$refs.rouletteWheel;
    const context = canvas.getContext('2d');

    const layout = new RouletteLayout(RouletteLayouts.AMERICAN);
    let theme = this.theme.indexOf('dark') === -1 ? THEMES.CLASSIC : THEMES.DARK_MODE;

    this.wheel = new RouletteWheelRenderer(canvas, context, layout, theme);
    this.wheel.init();
  },
};
</script>

<style scoped lang="scss">
@import 'variables';

.roulette-wheel {
  margin: -1rem 1rem 1rem;
  box-sizing: content-box;
}
</style>
