<template>
  <div class="mx-2 my-2" :class="`announcement-wrap announcement-wrap-${theme.style}`">
    <div class="header rounded-t-lg bg-inherit">
      <div class="lobby-parent">
        <div class="lobby-child">
          <!--          <img class="hidden" :src="import(`../../assets/svg/${theme.icon}`)" style="height: 20px; margin-right: 10px" />-->
          <p :style="`color: ${theme.color}; line-height: 1.25rem; font-weight: bold;`">
            Punt
          </p>
        </div>
        <div class="lobby-child"></div>
      </div>
    </div>
    <div style="padding: 7px">
      <div class="lobby-parent">
        <div class="lobby-child">
          <div class="inline text-sm" id="alertD" style="color: #ffffff">
            <span
              class="inline"
              v-tooltip="`User has enabled private mode`"
              v-if="isPrivate"
              style="color: #aaaaaa !important"
              >Hidden</span
            >
            <span class="inline" v-html="hiddenMessage"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCurrencies, getBalanceIcon } from '@/lib/icons';

export default {
  name: 'AnnouncementMessage',
  props: {
    message: {},
  },
  setup() {
    const currencies = getCurrencies();
    return {
      currencies,
    };
  },
  computed: {
    ...mapGetters(['me']),

    isPrivate() {
      return this.message.startsWith('#hidden');
    },
    hiddenMessage() {
      let message = this.message.replace('#hidden', '');
      for (const currency of this.currencies) {
        if (message.includes(currency)) {
          message = message.replace(
            currency,
            `<img src="${this.getIcon(currency)}" class="inline h-4 w-4"  alt=""/>`,
          );
        }
      }
      return message;
    },
  },

  data: () => ({
    startTimeInterval: null,
    startTimeCounter: 0,
    theme: 'danger',
  }),
  created() {
    this.theme = {
      style: 'danger',
      color: '#FF0000',
      icon: 'megaphone.svg',
    };
  },
  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-bet:hover {
  background-color: #00b100 !important;
}

.lobby-parent {
  display: flex;
  min-width: 100%;
  align-items: center;
  /* Align Items Vertically */
  justify-content: space-between;
  /* Align Items Horizontally (with equal space in between each of them */
}

.lobby-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.announcement-wrap-warning {
  border: 1px solid rgb(255, 221, 87);
}

.announcement-wrap-danger {
  border: 1px solid rgb(113, 37, 43);
}

.announcement-wrap {
  margin-top: 0.25rem;
  border-radius: 8px;
  background: rgba(113, 37, 43, 0.2);

  .header {
    position: relative;
    padding: 0.25rem 0.5rem;
    border-bottom: 0.5px outset rgb(174, 168, 168, 0.5);
    overflow: hidden;
  }
}

.race {
  width: 8px;
  height: 12px;
  position: absolute;
}
</style>
