<template>
  <div>
    <div
      class="bg-secondary border-b border-[#193B4E] flex-row justify-between py-2 md:h-20 w-full flex hidden">
      <div class="flex flex-row px-2 items-center w-full">
        <div class="flex-1">
          <button
            v-on:click="showChatRules"
            class="bg-secosndary-light p-2 hover:opacity-70 rounded-xl font-semibold flex flex-inline items-center transition ease-in-out delay-0 hover:scale-105 duration-150">
            <img alt="icon" class="h-6 md:h-6" src="../../assets/svg/book.svg" />
          </button>
        </div>
        <div class="flex-1 flex justify-end">
          <button
            v-on:click="generalS.showChat = false"
            class="hidden md:flex bg-secondsary-light hover:opacity-70 p-2 rounded-xl font-semibold flex-row items-center justify-center transition ease-in-out delay-0 hover:scale-105 duration-150">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="ic/plus">
                <path
                  id="Vector"
                  d="M21.1875 8.81281L8.81313 21.1872"
                  stroke="#E4E2EB"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  id="Vector_2"
                  d="M8.8125 8.81281L21.1869 21.1872"
                  stroke="#E4E2EB"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
            </svg>
          </button>
          <button
            v-on:click="$router.back()"
            class="flex md:hidden bg-secsondary-light hover:opacity-70 p-2 rounded-xl font-semibold flex flex-row items-center transition ease-in-out delay-0 hover:scale-105 duration-150">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="ic/plus">
                <path
                  id="Vector"
                  d="M21.1875 8.81281L8.81313 21.1872"
                  stroke="#E4E2EB"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  id="Vector_2"
                  d="M8.8125 8.81281L21.1869 21.1872"
                  stroke="#E4E2EB"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      class="bg-secondary border-b border-[#193B4E] flex flex-row justify-end py-2 md:h-20 w-full mst-2 px-2">
      <!--  <div class="flex w-full bg-cadrd-back-color px-4 py-2 rounded-xl justify-between">
      <div class="space-x-4 text-slate-200 flex flex-row items-center">
        <img src="../../assets/svg/gift.svg" class="h-6 md:h-8">
        <div class="flex flex-row space-x-3 items-center text-slate-200">

          <span class="uppercase">
              <button class="hover:opacity-70 rounded-lg flex flex-row items-center justify-center gap-1 text-lg font-bold "   v-on:click="$router.push({ path: $route.path, query: { tab: 'freebies' } })">
                <img alt="icon" :src="balS.getIcon('SC')" class="ml-2 h-4 w-4"/>
                <p class="opacity-80 text-sm">
                 {{ balS.formatCrypto(getAmount, true) }}
                </p>
              </button>
          </span>
         
          <div class="nav-right is-vcentered border-2 !ml-2 border-dashsed px-2 rounded py-1 border-slate-300" style="align-items: center" ref="rooms" @mousewheel.prevent="redirectScroll">
            <div v-if="jackpotRunning" class="nav-item"
                style="height: 100%;font-size: 1em;">
              <span class="jackpot-timer">
                {{ remainingTime }}
              </span>
            </div>
           </div>
          

        </div> 
      </div>
      <button  class="hidden hover:opacity-70 text-slate-200 rounded-lg" v-if="isLoggedIn" v-on:click.prevent="contribute">
         <p class="opacity-80 p-3 text-sm"> + Add</p>
      </button>
    </div>-->
      <div class="flex flex-row items-center justify-center">
        <button
          v-on:click="close"
          class="bg-secondary-light aspect-1 hover:opacity-70 p-2 rounded-xl font-semibold flex-row items-center justify-center transition ease-in-out delay-0 hover:scale-105 duration-150">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="ic/plus">
              <path
                id="Vector"
                d="M21.1875 8.81281L8.81313 21.1872"
                stroke="#E4E2EB"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                id="Vector_2"
                d="M8.8125 8.81281L21.1869 21.1872"
                stroke="#E4E2EB"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defaultConvertor, convertGameMode } from '../../lib/rsgp';
import { generalStore } from '@/store/pinia/generalStore';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'ChatHeader',
  setup() {
    const generalS = generalStore();
    const balS = balanceStore();
    balS.init();
    return { generalS, balS };
  },
  created() {
    setTimeout(this.init, 1500);
    // setTimeout(this.subscribeEcho, 1500)
  },
  data() {
    return {
      running: false,
      amount: 0,
      next: 0,
      // remainingTime: '00:00',
      interval: null,
    };
  },
  computed: {
    maxScroll() {
      return this.$refs.rooms.scrollWidth - this.$refs.rooms.clientWidth;
    },
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
    jackpotRunning() {
      return this.running && this.interval !== null;
    },
    gameMode() {
      return this.isLoggedIn !== undefined && this.isLoggedIn && this.me !== undefined
        ? this.me.game_mode
        : 'osrs';
    },
    getAmount() {
      return this.amount;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    showChatRules() {
      this.$router.push(this.$route.path + '?tab=chatRules');
    },
    redirectScroll(e) {
      const delta = e.wheelDelta || -e.detail;
      this.updateHorizontalScroll(delta);
    },
    currency(mode) {
      return convertGameMode(mode);
    },
    subscribeEcho() {
      /*window.Echo.channel('jackpot').listen('JackpotAmountUpdatedEvent', ({ amount, next, running }) => {
        this.next = next
        this.running = running
        this.amount = amount
        this.startTimer()
      })
      window.Echo.channel('jackpot').listen('JackpotEndedEvent', ({ amount, next, running }) => {
        this.next = next
        this.running = running
        this.amount = amount
        this.startTimer()
      })*/
    },
    updateHorizontalScroll(delta) {
      const $el = this.$refs.rooms;
      $el.scrollLeft = Math.max(0, Math.min(this.maxScroll, $el.scrollLeft + delta));
    },
    ...mapActions(['changeRoom', 'leaveRoom']),
    init() {
      this.$http
        .get('jackpot')
        .then(({ data }) => data)
        .then(({ amount, running, next }) => {
          this.next = next;
          this.running = running;
          this.amount = amount;
          this.startTimer();
        });
    },
    submitContribution(amount) {
      let currency = this.balS.displayInFiat ? this.balS.defaultFiat : '';
      let isFiat = this.balS.displayInFiat ? true : false;

      this.$http
        .post('jackpot/contribute', {
          amount: amount,
          currency: currency,
          isFiat: isFiat,
        })
        .then((res) => {
          this.$toast.success('Freebie amount added!');
        })
        .catch(({ data, status }) => this.onBetException(data, status));
    },
    onBetException(data, status) {
      let message = 'Something went wrong please try again.';
      if (status > 412) {
        message = '';
      } else if (status >= 400 && status <= 412) {
        if (typeof data.error != 'undefined') {
          message = data.error;
        }
      } else {
        if (data !== undefined && typeof data.error != 'undefined') {
          message = data.error;
        }
      }
      if (message.length > 0) {
        //  this.$swal(
        //     'Uh-oh',
        //     message,
        //     'error'
        //   )
        if (message.length > 0) {
          this.$toast.error(message, {
            position: 'bottom',
          });
        }
      }
    },
    contribute() {
      this.$swal({
        title: `Confirmation`,
        text: `Enter the amount you wish to add to the Fun Pot.`,
        showCancelButton: true,
        showCloseButton: true,
        type: 'question',
        confirmButtonText: `SUBMIT`,
        cancelButtonText: `CANCEL`,
        input: 'text',
        inputPlaceholder: 'Amount',
        inputValidator: function (value) {
          return new Promise((resolve, reject) => {
            let amount = defaultConvertor.parse(value);
            if (amount && amount > 0) {
              resolve();
            } else {
              reject(new Error('Amount Must be Greater than Zero'));
            }
          });
        },
      }).then(
        (amount) => {
          this.submitContribution(defaultConvertor.parse(amount.value));
        },
        (mode) => {
          // if (mode === 'cancel') {
          //
          // }
        },
      );
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    startTimer() {
      if (this.interval != null) {
        clearInterval(this.interval);
      }
      /*this.interval = setInterval(function () {
        let currentTime = new Date().getTime()
        let endsIn = this.next * 1000 - currentTime
        //this.formattedTime(endsIn)
        if (endsIn === 0) {
          clearInterval(this.interval)
        }
      }.bind(this), 1000)*/
    },

    formattedTime(microseconds) {
      //let timeLeft = microseconds / 1000
      //if (timeLeft <= 0) timeLeft = 0
      //const minutes = Math.floor(timeLeft / 60)
      //let seconds = Math.floor(timeLeft % 60)
      //this.remainingTime = `${this.formatInt(minutes)}:${this.formatInt(seconds)}`
    },

    formatInt(val) {
      return val >= 0 && val < 10 ? '0' + val : val;
    },
    close() {
      if (this.isMobile) {
        this.$router.push({ path: '/' }).then(() => {
          this.$router.go();
        });
      } else this.generalS.showChat = false;
    },
  },
};
</script>
