import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const twoFactorStore = defineStore('twoFactor', {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async init() {
      return await axios.get('2fa/settings');
    },
    async enable({ password, code }) {
      return await axios.post('2fa/settings', { password, code });
    },
    async delete({ password, code }) {
      return await axios.delete('2fa/settings', { data: { password, code } });
    },
  },
});
