<template>
  <span>{{ formatter(display) }}</span>
</template>

<script>
export default {
  name: 'NumberAnimation',
  props: {
    number: Number,
    formatter: {
      type: Function,
      required: false,
    },
    duration: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      display: this.number,
      interval: false,
    };
  },
  watch: {
    number: {
      handler: function (val, old) {
        let elapsed = 0;
        if (this.duration === 0) {
          this.display = this.number;
          return;
        }
        this.interval && clearInterval(this.interval);
        this.interval = setInterval(() => {
          if (this.display !== this.number) {
            const diff = (this.number - this.display) / 10;
            this.display += diff >= 0 ? Math.ceil(diff) : Math.floor(diff);
          } else {
            clearInterval(this.interval);
          }
          elapsed += 20;
          if (elapsed > this.duration) {
            this.display = this.number;
          }
        }, 20);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
