<template>
  <div class="sm:-mx-6 lg:-mx-8">
    <div class="inline-block w-full py-2 sm:px-6">
      <div class="rounded-lg border-slate-300 px-4 text-sm text-slate-300">
        <div class="box">
          <h2 class="font-bold text-lg my-2" style="margin-top: 0">
            The Importance of Provably Fair
          </h2>
          <p>
            The underlying concept of
            <a href="https://en.wikipedia.org/wiki/Provably_fair" target="_blank"
              >provable fairness</a
            >
            is that players have the ability to prove and verify that their results are
            fair and unmanipulated. This is achieved through the use of a
            <a href="https://en.wikipedia.org/wiki/Commitment_scheme" target="_blank"
              >commitment scheme</a
            >, along with cryptographic hashing.
          </p>
          <p>
            The commitment scheme is used to ensure that the player has an influence on
            all results generated. Cryptographic hashing is used to ensure that the
            website also remains honest to this commitment scheme. Both concepts combined
            creates a trust-less environment when playing online.
          </p>
          <p>This is simplified in the following representation:</p>
          <br />
          <pre
            style="
              display: block;
              overflow-x: auto;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
            "><code>fair result = operators input (hashed) + players input</code></pre>
          <h2 class="font-bold text-lg my-2">3rd Party Verification</h2>
          <p>
            All plays placed on RuneBet can be verified via 3rd party websites who have
            also open sourced the verification procedure. You can find them via a google
            search, or use those provided on the verification section of each game.<br /><br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Overview',
};
</script>
