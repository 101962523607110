/*import Crash from "@/assets/svg/tiles/png/crash.png";
import Dice from "@/assets/svg/tiles/png/dice.png";
import Roulette from "@/assets/svg/tiles/png/roulette.png";
import Slots from "@/assets/svg/tiles/png/slots.png";
import Mines from "@/assets/svg/tiles/png/mines.png";
import Duels from "@/assets/svg/tiles/png/duels.png";
import War from "@/assets/svg/tiles/png/war.png";
import Plinko from "@/assets/svg/tiles/png/plinko.png";
import Blackjack from "@/assets/svg/tiles/png/blackjack.png";
import Keno from "@/assets/svg/tiles/png/keno.png";
import Baccarat from "@/assets/svg/tiles/png/baccarat.png";
import Limbo from "@/assets/svg/tiles/png/limbo.png";*/

//import Crash from "../../assets/svg/plunder/Crash.png";
import Dice from '../../assets/svg/plunder/Dice.png';
import Roulette from '../../assets/svg/plunder/Roulette.png';
import Slots from '../../assets/svg/plunder/Slots.png';
import Mines from '../../assets/svg/plunder/Mines.png';
import Plinko from '../../assets/svg/plunder/Plinko.png';
import Blackjack from '../../assets/svg/plunder/Blackjack.png';
import Keno from '../../assets/svg/plunder/Keno.png';
import Baccarat from '../../assets/svg/plunder/Baccarat.png';
import Limbo from '../../assets/svg/plunder/Limbo.png';
import Tower from '../../assets/svg/plunder/Tower.png';
import Latergator from '../../assets/svg/plunder/latergator.webp';
import CloverCross from '../../assets/svg/plunder/clovercross.webp';

const runebetOriginals = [
  //{ img: Crash, link: "/casino/crash", name: "Crash" },
  { img: Dice, link: '/casino/dice', name: 'Dice' },
  { img: Dice, link: '/casino/dice', name: 'Dicing' },
  { img: Roulette, link: '/casino/roulette', name: 'Roulette' },
  { img: Slots, link: '/casino/slots', name: 'Slots' },
  { img: Mines, link: '/casino/mines', name: 'Mines' },
  { img: Plinko, link: '/casino/plinko', name: 'Plinko' },
  { img: Blackjack, link: '/casino/blackjack', name: 'Blackjack' },
  { img: Keno, link: '/casino/keno', name: 'Keno' },
  { img: Baccarat, link: '/casino/baccarat', name: 'Baccarat' },
  { img: Limbo, link: '/casino/limbo', name: 'Limbo' },
  //{ img: Tower, link: '/casino/latergator', name: 'Later Gator' },
  { img: Latergator, link: '/casino/latergator', name: 'Later Gator' },
  { img: CloverCross, link: '/casino/clovercross', name: 'Clover Cross' },
];

const originals = runebetOriginals.map(function (original) {
  return {
    img: original.img,
    link: original.link,
    name: original.name,
    title: original.name,
    identifier: 'runebet:' + original.name,
    identifier2: original.name,
    provider: 'runebet',
    producer: 'runebet',
    category: 'originals',
    theme: 'other',
    has_freespins: false,
    feature_group: 'basic',
    devices: ['desktop', 'mobile'],
    payout: 94.74,
    description: '',
    hd: true,
    restrictions: {
      default: {
        blacklist: [],
      },
    },
  };
});
export default originals;
