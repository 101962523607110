<template>
  <div class="grid grid-flow-col-dense grid-cols-2 gap-6 grid-rows-none w-full px-8">
    <router-link v-for="(tile, i) in runebetOriginals" :to="tile.link">
      <img :src="tile.img" alt="Game Tile" />
    </router-link>
  </div>
</template>

<script>
import Casino from '../../assets/svg/tiles/main/casino-banner.svg';
import Sport from '../../assets/svg/tiles/main/sports-banner.svg';
export default {
  name: 'GameType',
  setup() {
    const runebetOriginals = [
      { img: Casino, link: '' },
      { img: Sport, link: '' },
    ];
    return { runebetOriginals };
  },
};
</script>
