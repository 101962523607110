const states = {
  us: [
    'New York',
    'California',
    'Illinois',
    'Florida',
    'Texas',
    'Pennsylvania',
    'Georgia',
    'District of Columbia',
    'Massachusetts',
    'Arizona',
    // "Michigan",
    // "Washington",
    'Minnesota',
    'Colorado',
    'Maryland',
    //'Nevada',
    'Missouri',
    'Oregon',
    'Puerto Rico',
    'Ohio',
    'Indiana',
    'North Carolina',
    'Virginia',
    'Wisconsin',
    'Rhode Island',
    'Utah',
    'Tennessee',
    // "Kentucky",
    'Oklahoma',
    'Connecticut',
    'Louisiana',
    'Hawaii',
    'Nebraska',
    'New Mexico',
    'Alabama',
    'South Carolina',
    'Iowa',
    'Kansas',
    'Arkansas',
    // "Idaho",
    'Mississippi',
    'New Jersey',
    'Alaska',
    'New Hampshire',
    'Maine',
    'North Dakota',
    'West Virginia',
    'South Dakota',
    'Delaware',
    'Montana',
    'Vermont',
    'Wyoming',
  ],
  /*'canada':[
        "Ontario",
        // "Quebec",
        "British Columbia",
        "Alberta",
        "Manitoba",
        "Nova Scotia",
        "Saskatchewan",
        "Newfoundland and Labrador",
        "New Brunswick",
        "Prince Edward Island",
        "Yukon",
        "Northwest Territories",
        "Nunavut"
    ]*/
};

export const getState = (country) => {
  if (typeof states[country] !== 'undefined')
    return states[country].sort(function (a, b) {
      var nameA = a.toLowerCase(),
        nameB = b.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });

  return [];
};

export const getUsStatesWithCode = () => {
  return {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  };
};
