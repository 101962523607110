import { echoDisconnect } from '@/lib/echo';
import { login } from '@/lib/auth';
import { createStore } from 'vuex';
import axios from '@/lib/myAxios';

import Chat from './modules/chat';
import Duels from './modules/duels';
import Roulette from './modules/roulette';
import Blackjack from './modules/blackjack';
import Dicing from './modules/dicing';
import Orders from './modules/orders';
import ProvablyFair from './modules/provably-fair';
import Stats from './modules/stats';
import Slots from './modules/slots';
import Limbo from './modules/limbo';
import Keno from './modules/keno';
import Mines from './modules/mines';
import Tower from './modules/tower';
import Baccarat from './modules/baccarat';
import Plinko from './modules/plinko';
//import War from "./modules/war";
import Sound from './modules/sound';
import CloverCross from '@/store/modules/clovercross';
import { deleteAuthLocalStorage } from '@/lib/utils'
//import Sports from "./modules/sports";
//import TvBet from "./modules/tvbet";
import { setCookie,getCookie,deletCookie } from '@/lib/utils';

const Auth = {
  Mutations: {
    LOGIN: 'auth/LOGIN',
    LOGOUT: 'auth/LOGOUT',
  },
};

const token = 'dd-auth' in window.localStorage ? window.localStorage['dd-auth'] : null;

const store = createStore({
  strict: import.meta.env.VITE_NODE_ENV !== 'production',
  modules: {
    Chat,
    Dicing,
    Duels,
    Roulette,
    Blackjack,
    Orders,
    ProvablyFair,
    Stats,
    Slots,
    Limbo,
    Keno,
    Mines,
    Tower,
    Baccarat,
    Sound,
    // Sports,
    // TvBet,
    Plinko,
    // War,
    CloverCross,
  },
  state: {
    auth: {
      token,
      loggedIn: token !== null,
      redirectRoute: null,
      redirectTimestamp: null,
    },
    bzEnabled: false,
    bz: {},
    //pokerBalance: 0,
    duelCount: 0,
    racesCount: 0,
    active_races: [],
    sidebar: {
      tab: 'chat',
    },
    stats: {
      site: {
        wagered: 0,
      },
    },
    user: {
      opt_in_promo_sms: null,
      opt_in_promo_email: null,
    },
    userFetchStatus: 'unfetched', // status list: 'unfetched', 'fetched'
    landingpage: true,
  },
  getters: {
    me: (state) => state.user,
    isUserObjectFetched: (state) => state.userFetchStatus === 'fetched',
    needsPasswordUpdate: (state) =>
      state.user ? state.user.password_change_required : false,
    needsUsernameUpdate: (state) =>
      state.user.email ? !state.user.is_username_set : false,
    iAmAdmin: (state) => state.user && state.user.rank === 'admin',
    iAmMod: (state) => state.user && state.user.rank === 'mod',
    iAmHelper: (state) => state.user && state.user.rank === 'helper',
    iAmStaff: (state) =>
      state.user &&
      (state.user.rank === 'admin' ||
        state.user.rank === 'mod' ||
        state.user.rank === 'helper'),
    authToken: (state) => state.auth.token,
    isLoggedIn: (state) => state.auth.loggedIn,
    sidebarTab: (state) => state.sidebar.tab,
    totalWagered: (state) => state.stats.site.wagered,
    userBalance: (state) => state.user.balance,
    //pokerBalance: (state) => state.pokerBalance,
    landingpage: (state) => state.landingpage,
    isUserOptInPromoSms: (state) => state.user.opt_in_promo_sms,
    isUserOptInPromoEmail: (state) => state.user.opt_in_promo_email,
    curGameMode: (state) => state.user.game_mode,
  },
  mutations: {
    setBzFlag(state, flag) {
      if (state.user.rank === 'admin') {
        state.bzEnabled = flag;
      }
    },
    bz(state, { user, balance, triggered }) {
      const old = state.bz[user.id];
      if (old !== undefined && old.triggered >= triggered) {
        return;
      }

      state.bz = {
        ...state.bz,
        [user.id]: { balance, name: user.name, triggered },
      };
    },
    setJoinableDuelCount(state, count) {
      state.duelCount = count;
    },
    setRacesCount(state, count) {
      state.racesCount = count;
    },
    updateRaces(state, races) {
      state.active_races = races;
    },
    updateLandingpage(state, landingpage) {
      state.landingpage = landingpage;
    },
    'update-total-wagered'(state, wagered) {
      state.stats.site.wagered = wagered;
    },
    'change-tab'(state, tabId) {
      state.sidebar.tab = tabId;
    },
    'update-balance'(state, balance) {
      state.user.balance = balance;
    },
    'update-game-mode'(state, game_mode) {
      state.user.game_mode = game_mode;
    },
    /*"update-poker-balance"(state, balance) {
      state.pokerBalance = balance;
    },*/
    'update-user'(state, user) {
      state.user = { ...user };
    },
    'update-user-fetch-status'(state, status) {
      state.userFetchStatus = status;
    },
    'update-user-phonenumber'(state, phonenumber) {
      state.user.phone_number = phonenumber;
    },
    'update-user-address'(state, address) {
      state.user.address = address;
    },
    'update-user-phonenumber-verified'(state, verified) {
      state.user.phone_number_verified = verified;
    },
    'update-user-purcahses'(state) {
      state.user.total_purchases = state.user.total_purchases + 1;
    },
    'update-user-veriff-verification'(state, verification_id) {
      state.user.verification_id = verification_id;
    },
    [Auth.Mutations.LOGIN](state, token) {
      state.auth.loggedIn = true;
      state.auth.token = token;
    },
    [Auth.Mutations.LOGOUT](state) {
      state.auth.loggedIn = false;
      state.auth.token = null;
    },
    'update-break-type'(state, break_type) {
      state.user.break_type = break_type;
    },
    'update-break-till-date'(state, break_till_date) {
      state.user.break_till_date = break_till_date;
    },
    'update-user-opt-in-promo-sms'(state, opt_in_promo_sms) {
      state.user.opt_in_promo_sms = opt_in_promo_sms;
    },
    'update-user-opt-in-promo-email'(state, opt_in_promo_email) {
      state.user.opt_in_promo_email = opt_in_promo_email;
    },
    'set-redirect-route'(state, route) {
      state.auth.redirectRoute = route;
      state.auth.redirectTimestamp = Date.now();
    },
    'clear-redirect-route'(state) {
      state.auth.redirectRoute = null;
      state.auth.redirectTimestamp = null;
    },
  },
  actions: {
    hideIntercom() {
      // const Intercom = window.Intercom
      // Intercom && Intercom('hide')
    },
    showIntercom({ commit }, message = null) {
      const Intercom = window.Intercom;
      if (Intercom) {
        if (message !== null) {
          Intercom('showNewMessage', message);
        } else {
          Intercom('show');
        }
      }
    },
    changeTab({ commit }, tabId) {
      commit('change-tab', tabId);
    },
    updateBalance({ commit }, balance) {
      commit('update-balance', balance);
    },
    updateGameMode({ commit }, game_mode) {
      commit('update-game-mode', game_mode);
    },
    updateUserPhoneNumber({ commit }, phoneNumber) {
      commit('update-user-phonenumber', phoneNumber);
    },
    updateUserPhoneNumberVerified({ commit }, verified) {
      commit('update-user-phonenumber-verified', verified);
    },
    updateUserPurchases({ commit }) {
      commit('update-user-purcahses');
    },
    updateUserVeriffVerification({ commit }, verification_id) {
      commit('update-user-veriff-verification', verification_id);
    },
    updateUserAccessToken({ commit }, accessToken) {
      commit(Auth.Mutations.LOGIN, accessToken);
    },
    async updateUserOptInPromo(
      { commit },
      { opt_in_promo_sms, opt_in_promo_email } = {},
    ) {
      const request = {};
      if (opt_in_promo_sms !== undefined) {
        request.opt_in_promo_sms = opt_in_promo_sms;
      }
      if (opt_in_promo_email !== undefined) {
        request.opt_in_promo_email = opt_in_promo_email;
      }

      return axios.patch('/user/promo-opt-in-settings', request).then(() => {
        if (opt_in_promo_sms !== undefined) {
          commit('update-user-opt-in-promo-sms', opt_in_promo_sms);
        }
        if (opt_in_promo_email !== undefined) {
          commit('update-user-opt-in-promo-email', opt_in_promo_email);
        }
      });
    },
    /*updatePokerBalance({ commit }, balance) {
      commit("update-poker-balance", balance);
    },*/
    setBzFlag({ commit, state }, flag) {
      commit('setBzFlag', flag);
    },
    fetchRaces({ commit, dispatch }) {
      return axios.get(`races`).then((res) => {
        this.active_races = res.body;
        commit('updateRaces', res.body);
        //commit('setRacesCount', res.body.length)
      });
    },
    fetchLandingpage({ commit, dispatch }) {
      return axios.get(`sitesettings`).then((res) => {
        if (res.status == 200) {
          this.landingpage = res.data[0];
          commit('updateLandingpage', res.data[0]);
        }

        //commit('setRacesCount', res.body.length)
      });
    },
    fetchUser({ commit, dispatch }) {
      try {
        return axios
          .get('user')
          .then(({ data }) => {
            window.dataLayer.push({'user_id': data.id, 'event':'login', 'coin_type':data.game_mode});
            if (window.ga) {
              window.ga('set', 'userId', data.id);
            } 
            if (window.Intercom) {
              window.Intercom('boot', {
                app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                name: data.username,
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                user_id: data.id,
                user_hash: data.intercomHash,
                label: data.label, // custom attributes
                vipRank: data.vipRank
              });
            }
            commit('chat/SET_TITLES', data.chatTitles);
            commit('setJoinableDuelCount', data.joinableDuels);
            commit('update-total-wagered', data.totalWagered);
            //commit("update-poker-balance", data.pokerBalance);
            delete data['totalWagered'];
            this.is_private = false; // data.is_private
            commit('update-user', data);
            commit('update-user-fetch-status', 'fetched');
            window.localStorage['user'] = JSON.stringify({ id: data.id });
          })
          .catch((err) => {
            const { data, status } = err;
            if (
              status === 403 &&
              typeof data.error != 'undefined' &&
              /banned/.test(data.error)
            ) {
              window
                .swal({
                  title: 'Busted!',
                  text: data.error,
                  type: 'warning',
                })
                .then(window.swal.noop, window.swal.noop)
                .then(() => {
                  dispatch('logout');
                });
            } else if (
              status === 401 ||
              status === 500 ||
              (typeof data.error != 'undefined' && data.error === 'Unauthenticated.')
            ) {
              dispatch('logout');
            } else {
              window.Raven && window.Raven.captureException(err);
            }
          });
      } catch (error) {
        console.log('some error while fetching user details');
      }
    },
    logout({ commit }) {
      window.Intercom && window.Intercom('shutdown');
      deleteAuthLocalStorage();
      echoDisconnect();
      commit(Auth.Mutations.LOGOUT);
      setTimeout(() => {
        window.location.href = '/';
      }, 1);
    },
    performRegistration({ commit }, form) {
      if (window.localStorage && 'aid' in window.localStorage) {
        form.aid = window.localStorage['aid'];
      }
      form.custom_ip = (document.getElementById('custom_ip') != null) ? document.getElementById('custom_ip').value : '0.0.0.0';
      if(getCookie('utm_source') && getCookie('utm_source')!='')
      {
        form.utm_source = getCookie('utm_source') ;
      }
      if(getCookie('utm_campaign') && getCookie('utm_campaign')!='')
      {
        form.utm_campaign = getCookie('utm_campaign') ;
      }
      return axios.post('auth/register', {
        form,
        fp: window.fp,
        ref: document.referrer,
      }).then((data)=>{
        
        
        if(data.data.access_token && data.data.access_token!=null){
          deletCookie('utm_source');
          deletCookie('utm_campaign');
        }
        return data;
      });
    },
    async performLogin({ commit }, { username, password, token, custom_ip }) {
      try {
        const data = await login({
          username,
          password,
          recaptcha: token,
          fp: window.fp,
          custom_ip,
        });
        window.localStorage['dd-auth'] = data.access_token;
        commit(Auth.Mutations.LOGIN, data.access_token);
      } catch (error) {
        let message = '';
        if (error.message.includes('access_token')) {
          message = 'Login not possible. Try again in few minutes.';
        } else {
          message = error.message;
        }
        window.swal('Failed to login', message, 'error');
      }
    },
    async setLoginToken({ commit }, data ) {
      window.localStorage['dd-auth'] = data.access_token;
      commit(Auth.Mutations.LOGIN, data.access_token);
      return true;
    },
    performGoogleLogin({ commit }, token) {
      return axios
        .post('google-login', {
          state: token || 'NO',
          fp: window.fp,
          ref: document.referrer,
        })
        .then((data) => {
          const accessToken = data.body.access_token;
          window.localStorage['dd-auth'] = accessToken;
          commit(Auth.Mutations.LOGIN, accessToken);
          //setTimeout(rebuildEcho, 1)
          //setTimeout(reconnectWar, 10)
          window.location.reload();
        });
    },
    sendChatMessage({ commit, state }, message) {
      const body = message.trim();
      return axios
        .post('chat_messages', { body, roomId: state.Chat.currentRoom })
        .then(({ data }) => data)
        .catch(({ status, data }) => {
          if (status === 429) {
            return window.swal({
              title: 'Slow down!',
              text: 'You are sending messages too quickly.',
              type: 'warning',
              timer: 3e3,
            });
          }
        });
    },
    getUserRanks({ commit }, payload) {
      return axios.get(`users/${payload}/ranks`);
    },
    getUserRakeback({ commit }, payload) {
      return axios.get(`users/${payload}/rakeback`);
    },
    claimRakeback({ commit }, payload) {
      return axios.get(`users/${payload}/claim-rakeback`);
    },
    getReferralCodes({ commit }, payload) {
      return axios.get(`users/${payload}/referral-codes`);
    },
    updateBreakType({ commit }, break_type) {
      commit('update-break-type', break_type);
    },
    updateBreakTillDate({ commit }, break_till_date) {
      commit('update-break-till-date', break_till_date);
    },
    getLossbackRecords({ commit }, payload) {
      return axios.get(`users/${payload.userId}/lossback-records/${payload.code}`);
    },
    claimLossbackBonus({ commit }, payload) {
      return axios.get(`users/${payload.userId}/claim/lossback/${payload.code}`);
    },
  },
});

function reconnectWar() {
  window.socket.io.opts.query = `token=Bearer ${
    store.getters.authToken ? store.getters.authToken : 'Test'
  }`;
  window.socket.disconnect();
  window.socket.connect();
}
/*
if (module.hot) {
  module.hot.accept([
    './modules/duels',
    './modules/mines',
    './modules/baccarat',
    './modules/plinko',
    './modules/war',
    './modules/dicing',
    './modules/chat',
    './modules/orders',
    './modules/stats',
    './modules/provably-fair',
    './modules/sports'], () => {
    store.hotUpdate({
      modules: {
        Chat,
        Dicing,
        Duels,
        Roulette,
        Orders,
        ProvablyFair,
        Stats,
        Sports,
      }
    })
  })
}*/

export default store;
