<template>
  <div class="flex w-full max-w-[1200px] flex-col m-auto text-sm">
    <div
      class="relative flex items-center justify-between w-full gap-2 px-4 py-4 bg-secondary/30 md:px-6 max-[767px]:pb-0">
      <h1 class="py-4 text-2xl font-bold text-slate-300">My Game Play</h1>
      <img class="h-[50px] lg:h-[120px] brightness-90 sm:h-[80px]" :src="mybets" alt="" />
    </div>
    <div>
      <p v-if="!bets || bets.length === 0">
        <label
          class="flex flex-row items-center justify-center text-center w-full text-sm">
          <div v-if="isloading" class="pt-2">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </div>
          <div v-else class="pt-2">No bets found.</div>
        </label>
      </p>
      <div
        v-else
        :style="
          'height: ' +
          tableHeight +
          'px; max-height:' +
          tableHeight +
          'px; overflow: hidden;'
        ">
        <div class="flex items-center justify-center h-5" v-if="isloading">
          <svg
            class="h-10"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3" />
            </circle>
          </svg>
        </div>
        <div v-else>
          <div class="flex mt-10 text-center justify-center text-medium md:hidden">
            <button
              class="mr-5 disabled:opacity-50"
              :disabled="currentPage == 1"
              @click="fetchHistory(false)">
              Previous
            </button>
            <button
              class="disabled:opacity-50"
              :disabled="currentPage == lastPage"
              @click="fetchHistory(true)">
              Next
            </button>
          </div>
          <table class="table w-full">
            <thead>
              <tr class="text-slate-300">
                <th class="flex w-[200px]">
                  <label class="px-3"> Game </label>
                </th>
                <th class="hidden lg:table-cell w-[100px]">
                  <label> ID </label>
                </th>

                <th class="hidden xl:table-cell w-[160px]">
                  <label> Time </label>
                </th>
                <th class="hidden xl:table-cell w-[100px] text-right">
                  <label> Play </label>
                </th>
                <th class="hidden xl:table-cell w-[140px] text-right">
                  <label> Multiplier </label>
                </th>
                <th class="w-[160px] text-right">
                  <label class="px-3"> Profit </label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bet, idx) in bets"
                v-bind:key="bet.id"
                :class="idx % 2 == 0 ? 'is-even' : 'bg-secondary-light'">
                <td class="rounded-l-lg max-w-[50px] min-w-[10px] px-3 py-2">
                  <router-link
                    :is="
                      bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                        ? 'router-link'
                        : 'span'
                    "
                    :to="gameNameToGameClass(bet.game_name, bet.game_id, bet.id)"
                    class="flex flex-row items-center justify-start w-full"
                    :title="bet.game_name === 'Dicing' ? 'Dice' : bet.game_name">
                    <img
                      class="h-4 w-4 mr-2"
                      v-if="getGameIcon(bet.game_name)"
                      :src="getGameIcon(bet.game_name)" />
                    <span
                      class="whitespace-nowrap overflow-hidden text-ellipsis block max-w-[140px]"
                      >{{ bet.game_name === 'Dicing' ? 'Dice' : bet.game_name }}</span
                    >
                  </router-link>
                </td>
                <td
                  class="hidden lg:table-cell hover:opacity-70 max-w-[50px] min-w-[10px]">
                  <router-link
                    :is="
                      bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                        ? 'router-link'
                        : 'span'
                    "
                    :to="gameNameToGameClass(bet.game_name, bet.game_id, bet.id)">
                    <span
                      class="flex flex-row items-center justify-center text-center"
                      :style="`opacity:${
                        bet.user !== null || (isLoggedIn && bet.user_id === me.id)
                          ? 1
                          : 0.5
                      }`">
                      {{ bet.id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </span>
                  </router-link>
                </td>

                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-center text-center w-full"
                    style="color: #9097a8">
                    {{ formattedTime(bet.timestamped) }}
                  </label>
                </td>

                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-end text-right w-full"
                    style="color: #9097a8">
                    {{ parseFloat(bet.wager) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                </td>
                <td class="hidden xl:table-cell max-w-[50px] min-w-[10px]">
                  <label
                    class="flex flex-row items-center justify-end text-right w-[120px] 2xl:w-full"
                    style="color: #9097a8"
                    >{{
                      Number.parseFloat(bet.multiplier)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '×'
                    }}
                  </label>
                </td>
                <td class="rounded-r-lg max-w-[50px] min-w-[10px]">
                  <label
                    v-if="bet.profit > 0"
                    class="flex flex-row items-center justify-end text-right w-full pr-2"
                    style="color: #00e405"
                    >{{ parseFloat(bet.profit) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                  <label
                    v-else
                    class="flex flex-row items-center justify-end text-right w-full pr-2"
                    style="color: #9097a8"
                    >{{ parseFloat(bet.profit) }}
                    <img alt="icon" :src="getIcon(bet.game_mode)" class="ml-2 w-4 h-4" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex mt-10 text-center justify-center text-medium">
            <button
              class="mr-5 disabled:opacity-50"
              :disabled="currentPage == 1"
              @click="fetchHistory(false)">
              Previous
            </button>
            <button
              class="disabled:opacity-50"
              :disabled="currentPage == lastPage"
              @click="fetchHistory(true)">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mybets from '../../assets/sidebar/mybets.png';
import axios from '../../lib/myAxios';
import { getBalanceIcon } from '@/lib/icons';
import dice from '@/assets/svg/history/dice.svg';
import limbo from '@/assets/svg/history/limbo.svg';
import diamond from '@/assets/svg/history/diamond.svg';
import duelsIcon from '@/assets/svg/history/duels.svg';
import hilo from '@/assets/svg/history/hilo.svg';
import plinko from '@/assets/svg/history/plinko.svg';
import war from '@/assets/svg/history/war.svg';
import keno from '@/assets/svg/history/keno.svg';
import chip from '@/assets/svg/history/chip.svg';
import bet from '@/assets/svg/history/bet.svg';
export default {
  name: 'MyGamePlay',
  components: {},
  created() {
    this.fetchHistory();
  },
  setup() {
    return {};
  },
  data() {
    return {
      bets: [],
      currentPage: 0,
      nextPage: 1,
      lastPage: 999,
      isloading: false,
      mybets,
    };
  },

  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    getGameIcon(name) {
      switch (name) {
        case 'Poker':
          return hilo;
        case 'Slots':
          return diamond;
        case 'Dicing':
          return dice;
        case 'Blackjack':
          return hilo;
        case 'Baccarat':
          return hilo;
        case 'Duels':
          return duelsIcon;
        case 'Limbo':
          return limbo;
        case 'Mines':
          return diamond;
        case 'Plinko':
          return plinko;
        case 'War':
          return war;
        case 'Roulette':
          return chip;
        case 'Keno':
          return keno;
        /*case 'Crash':
                return crash*/
        default:
          return bet;
      }
    },
    formattedTime(timestamp) {
      const date = new Date(timestamp * 1000);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return (
        hours +
        ':' +
        minutes +
        ' ' +
        ampm +
        ' ' +
        (date.getMonth() + 1) +
        '/' +
        date.getDate() +
        '/' +
        date.getFullYear()
      );
    },

    gameNameToGameClass(game, gameId, betId) {
      let path = '/casino';
      let gamePath = null;
      switch (game) {
        case 'Mines':
        case 'Baccarat':
        case 'Crash':
        case 'Roulette':
        case 'Slots':
        case 'Blackjack':
        case 'War':
        case 'Plinko':
        case 'Keno':
        case 'Limbo':
          gamePath = '/' + game.toLowerCase();
          break;
        case 'Dicing':
          gamePath = '/dice';
          break;
        case 'Duels':
          return {
            path: this.$route.path,
            query: { tab: 'duels', duelId: gameId },
          };
      }
      if (!gamePath)
        return {
          path: this.$route.path,
          query: { tab: 'history', history: betId },
        };
      return path + gamePath + '/history/' + gameId;
    },
    async fetchHistory(next = true) {
      if (!this.isloading) {
        this.isloading = true;
        try {
          let page = next
            ? this.lastPage == this.currentPage
              ? this.lastPage
              : this.currentPage + 1
            : this.currentPage <= 1
              ? 1
              : this.currentPage - 1;

          var data = await axios.get('mybets', {
            params: { page },
          });
          this.bets = data.data.data;
          this.currentPage = data.data.current_page;
          this.lastPage = data.data.last_page;
          this.isloading = false;
        } catch (error) {
          console.log('issue while fetching history');
          this.isloading = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.panel {
  border: none;
}

.table {
  border: none;
}

td {
  border: none;
  color: #ffffff;
}

tr {
  height: 48px;
  border: none;
}

th {
  padding: 15px 0 0 0;
  border: none;
}

table {
  transform-style: preserve-3d;
}

tbody {
  overflow: hidden;
}

thead {
  z-index: 0;
}
</style>
