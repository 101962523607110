<template>
    <div class="section section-vipclub">
        <div class="left">
            <label>Enjoy the exclusive</label>
            <h1>Vip Club</h1>
            <p>You start your gaming way from the 1st Rank. The more you play – the higher your Rank is. It goes up according to the wagered amount while playing on Punt.</p>
            <button v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">Login Now</button>
        </div>
        <div class="right">
            <div class="image">
                <img src="../../assets/vipcrown.png" alt="vip crown">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'VipClub',
}
</script>

<style lang="scss" scoped>
    .section-vipclub{
        background-image: url('../../assets/vipclub-bg.png');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
            h1{
                font-size: 72px;
                line-height: 85px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 22px;
            }
            label{
                background: rgba(255, 255, 255, 0.18);
                border-radius: 8px;
                padding: 10px 18px;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 18px;
                display: block;
                width: fit-content;
            }
            p{
                max-width: 564px;
                margin-bottom: 22px;
            }
            button{
                color:#001011 ;
                background-color: #00FFBA;
                padding: 12.5px 28px;
                border-radius: 10px;
                display: block;
                width: fit-content;
                font-weight: 700;
            }
    }
    @media only screen and (max-width: 768px) {
        .section-vipclub{
            flex-direction: column-reverse;
                h1{
                    font-size: 45px;
                    line-height: 50px;
                }
        }
    }
</style>