<template>
  <div class="space-y-4 text-sm text-white">
    <p>
      <strong>TERMS &amp; CONDITIONS</strong>
    </p>
    <p>
      <strong>1. INTRODUCTION</strong>
    </p>
    <p>
      As a condition to registering on Punt (e.g. through any electronic device, such as
      the web, mobile, tablet or any other device), you accept these Terms and Conditions
      and enter into an agreement with Punt Limited.
    </p>
    <p>
      <strong>
        THESE TERMS AND CONDITIONS FORM A BINDING LEGAL AGREEMENT BETWEEN YOU AND PUNT AND
        APPLY TO YOUR USE OF OUR PLATFORM AND OUR GAMES.THESE TERMS AND CONDITIONS
        INCORPORATE MATERIAL REPRESENTATIONS BY YOU AND AFFECT YOUR LEGAL RIGHTS.PLEASE
        TAKE THE TIME TO READ THESE TERMS AND CONDITIONS CAREFULLY.BY ACCEPTING THESE
        TERMS AND CONDITIONS, YOU REPRESENT – AND WE ARE RELYING ON YOUR REPRESENTATION –
        THAT YOU HAVE DONE SO.
      </strong>
    </p>
    <p>
      <strong>
        ALSO, IF YOU LIVE IN ANY OF THE EXCLUDED TERRITORIES DEFINED BELOW, YOU ARE NOT
        ELIGIBLE TO ACCESS OR USE THE PLATFORM, CREATE A CUSTOMER ACCOUNT, OR PLAY THE
        GAMES.
      </strong>
    </p>
    <p>
      <strong>
        You must read these Terms and Conditions carefully in their entirety before
        checking the box for acceptance. By checking the box for acceptance during the
        registration process, accessing or using our Platform, creating a Customer
        Account, and/or accessing the Games, you confirm that you have read and agree to
        be bound by these Terms and Conditions, which include our Privacy Policy,
        Responsible Social Gameplay Policy and other game-specific or promotion-specific
        terms relevant to your Participation
      </strong>
      .
    </p>
    <p>
      If you do not agree with any provision of these Terms and Conditions or any other
      linked policy, rules or terms, you may not access or use the Platform, create a
      Customer Account, or play any Game.
    </p>
    <p>
      We may update these Terms and Conditions periodically. By continuing to access our
      Platform, your Customer Account, and/or our Games, you are deemed to have read and
      to be bound by any such updates. If you do not wish to be bound by these Terms and
      Conditions or any updated Terms and Conditions or any other linked policy, rules or
      terms, you may not continue to access the Platform or any of the Games (including
      the website and any associated apps). We will note the most recent date of these
      Terms and Conditions at the top of this page.
    </p>
    <p>
      <strong>2. PUNT STATEMENT</strong>
    </p>
    <p>
      <strong>
        2.1. IMPORTANT - PLEASE READ: IF YOU LIVE IN ANY OF THE FOLLOWING TERRITORIES
        (COLLECTIVELY, THE “EXCLUDED TERRITORIES”), YOU ARE NOT ELIGIBLE TO ACCESS OR USE
        THE PLATFORM, CREATE A CUSTOMER ACCOUNT, OR PLAY THE GAMES:
      </strong>
    </p>
    <p>a) Any country that is not United States of America (US);</p>
    <p>b) Within the US the following States are excluded:</p>
    <p>c) WASHINGTON</p>
    <p>d) IDAHO</p>
    <p>f) Kentucky</p>
    <p>
      2.2. Any other states or jurisdictions which, under the laws applicable to you, are
      legally precluded from playing the Games offered on the Platform, and any other
      jurisdiction PUNT’s excludes, in its sole discretion, from time to time.
    </p>
    <p>
      <strong>
        2.3. IF YOU LIVE IN ANY OF THE EXCLUDED TERRITORIES, DO NOT PROCEED ANY FURTHER
      </strong>
      : DO NOT REGISTER FOR THE PLATFORM, DO NOT CREATE A CUSTOMER ACCOUNT, DO NOT PLAY
      THE GAMES, AND DO NOT INTERACT WITH PUNT.COM IN ANY OTHER WAY.
      <strong>
        BY ACCEPTING THESE TERMS OF SERVICE, ACCESSING OR USING THE PLATFORM, CREATING A
        CUSTOMER ACCOUNT, AND/OR PLAYING THE GAMES, YOU SPECIFICALLY REPRESENT TO US THAT
        YOU DO NOT LIVE IN ANY OF THE EXCLUDED TERRITORIES .
      </strong>
      WE ARE SPECIFICALLY RELYING ON SUCH REPRESENTATIONS IN PROVIDING YOU ACCESS TO THE
      PLATFORM, CUSTOMER ACCOUNT, AND GAMES. IF YOU RESIDE IN ANY OF THE EXCLUDED
      TERRITORIES AND NONETHELESS CHECK THE BOX FOR ACCEPTANCE OF THESE TERMS AND
      CONDITIONS, ACCESS OR USE THE PLATFORM, CREATE A CUSTOMER ACCOUNT, AND/OR PLAY THE
      GAMES DESPITE OUR EFFORTS TO PREVENT YOU FROM DOING SO, WE CONSIDER YOUR ACTIONS TO
      BE A MATERIAL MISREPRESENTATION TO US, A FRAUDULENT INDUCEMENT OF OUR SERVICES, AND
      A VIOLATION OF THESE TERMS AND CONDITIONS, AND WE RESERVE ALL RIGHTS TO TAKE
      APPROPRIATE ACTION AGAINST YOU.
    </p>
    <p>
      <strong>
        2.4. NO PURCHASE OR PAYMENT IS NECESSARY TO PARTICIPATE OR PLAY THE GAMES. A
        PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.
      </strong>
    </p>
    <p>
      <strong>
        2.5. THE PLATFORM AND GAMES DO NOT OFFER REAL MONEY GAMBLING, AND NO ACTUAL MONEY
        IS REQUIRED TO PLAY.
      </strong>
    </p>
    <p>
      2.6. ONLY CUSTOMERS IN THE CONTINENTAL UNITED STATES, HAWAII ARE ELIGIBLE TO ACCESS
      AND USE THE PLATFORM, CREATE A CUSTOMER ACCOUNT, AND PLAY THE GAMES.
    </p>
    <p>
      <strong>
        2.7. PLEASE BE AWARE THAT THESE TERMS &amp; CONDITIONS INCLUDE DISPUTE RESOLUTION
        PROVISIONS, INCLUDING A PROVISION WAIVING YOUR RIGHT TO PURSUE ANY CLASS, GROUP OR
        REPRESENTATIVE CLAIM AND REQUIRING YOU TO PURSUE PAST, PENDING, AND FUTURE
        DISPUTES BETWEEN YOU AND US THROUGH INDIVIDUAL ARBITRATION.
      </strong>
    </p>
    <p>
      <strong>3. DEFINITIONS</strong>
    </p>
    <p>
      <strong>a) “Collective Action” </strong>
      – means any claim, action, or proceeding asserted or pursued as a class action,
      group action, collective action, joint action, coordinated action, consolidated
      action, mass action, or in any other representative or private attorney general
      capacity, whether in arbitration, court, or any other venue.
    </p>
    <p>
      <strong>b) “Content” </strong>
      – means text, graphics, user interfaces, visual interfaces, photographs, trademarks,
      logos, sounds, music, artwork, computer code and other material used, displayed or
      available as part of the Games and Platform.
    </p>
    <p>
      <strong>c) “Customer Account” </strong>
      – means an account held by a Registered Customer.
    </p>
    <p>
      <strong>d) “Fraudulent Conduct” </strong>
      – means any of the conduct described in clause 16.
    </p>
    <p>
      <strong>e) “Game” </strong>
      – means any one or more Game(s) available on the Platform in either Standard Play or
      Promotional Play. We reserve the right to add and remove Games from the Platform at
      our sole discretion.
    </p>
    <p>
      <strong>f) Gold Coin” </strong>
      – means the virtual social gameplay currency which enables you to play the Standard
      Play Games. Gold Coins have no monetary value and cannot under any circumstance be
      redeemed for prizes.
    </p>
    <p>
      <strong>g) “Merchandise” </strong>
      – means any physical goods provided to you by PUNT.
    </p>
    <p>
      <strong>h) “Participate” </strong>
      – means playing any Games or using our Platform in any manner whatsoever, including
      any of the conduct described in clause 6, 9, 10, 11 and 12.
    </p>
    <p>
      <strong>i) “Payment Administration Agent” </strong>
      – means the service provided through any related body corporate, affiliate, or third
      party we appoint to act as our agent.
    </p>
    <p>
      <strong>j) "Payment Medium” </strong>
      – means any card, online wallet, financial/bank account or other payment medium used
      to purchase Gold Coins.
    </p>
    <p>
      <strong>k) "Platform” </strong>
      – means the services provided through any URL or mobile application belonging to, or
      licensed to, PUNT, and branded as part of the PUNT games, including the website
      located at PUNT.COM, and all subdomains, subpages and successor sites thereof, as
      well as all Games, features, tools and services available thereon.
    </p>
    <p>
      <strong>i) “Customer” </strong>
      or <strong>“you” </strong>– means any person who Participates, whether or not a
      Registered Customer.
    </p>
    <p>
      <strong>j) “Prizes” </strong>
      – means prizes won when playing Promotional Play Games which are redeemable for
      valuable prizes in accordance with these Terms and Conditions.
    </p>
    <p>
      <strong>k) “Promotional Play / Sweepstakes” </strong>
      – means Participation in our sweepstakes promotions by playing the Platform’s game
      with Sweep Cash.
    </p>
    <p>
      <strong>l) “Registered Customer” </strong>
      – means a Customer who has successfully registered a Customer Account, whether that
      account is considered active or not.
    </p>
    <p>
      <strong>m) “Standard Play” </strong>
      – means Participating in any game played with Gold Coins.
    </p>
    <p>
      <strong>n) "Sweep Cash” </strong>
      – means sweepstakess entries.
    </p>
    <p>
      <strong>o) "Third Party Website” </strong>
      – means a third party website not controlled by us.
    </p>
    <p>
      <strong>4. REGISTRATION &amp; CUSTOMER WARRANTS</strong>
    </p>
    <p>
      <strong>4.1. Registration</strong>
    </p>
    <p>
      a) When you try to register a Customer Account you will be requested to provide the
      following information:
    </p>
    <p>i. Full legal name;</p>
    <p>ii. Date of birth;</p>
    <p>iii. Permanent Address;</p>
    <p>iv. E-mail.</p>
    <p>
      b) PUNT will also request you to provide a copy of your identification document and
      proof of address upon registration. You will also be requested to set a username and
      password.
    </p>
    <p>
      <strong>4.2. Warrants</strong>
    </p>
    <p>a) You declare and warrant that:</p>
    <p>
      i. You are over 21 years of age or such higher minimum legal age of majority as
      stipulated in the jurisdiction of your residence and are, under the laws applicable
      to you, legally allowed to participate in the Games offered on the Platform;
    </p>
    <p>
      ii. WHEN PARTICIPATING IN STANDARD OR PROMOTIONAL PLAY, YOU DO NOT RESIDE IN, OR
      ACCESS THE PLATFORM FROM, THE EXCLUDED TERRITORIES
    </p>
    <p>
      iii. You participate in the Games strictly in your personal capacity for
      recreational and entertainment purposes only;
    </p>
    <p>
      iv. You participate in the Games on your own behalf and not on behalf of any other
      person;
    </p>
    <p>
      v. All information that you provide to us during the term of validity of these Terms
      and Conditions is true, complete and correct, and you will immediately notify us of
      any change to such information;
    </p>
    <p>
      vi. Crypto that you use to purchase Gold Coins is not tainted with any illegality
      and, in particular, does not originate from any illegal activity or source, or from
      ill-gotten means;
    </p>
    <p>
      vii. You will not purchase Gold Coins from a business or corporate account, but only
      a wallet held in your name;
    </p>
    <p>
      viii. You will not be involved in any fraudulent, collusive, fixing or other
      unlawful activity in relation to your or third parties’ participation in any of the
      Games and you will not use any software-assisted methods or techniques (including
      but not limited to bots designed to play automatically) or hardware devices for your
      participation in any of the Games. We reserve the right to invalidate any
      participation in the event of such behaviour;
    </p>
    <p>
      ix. When purchasing Gold Coins, you must only use a valid Payment Medium which
      lawfully belongs to you;
    </p>
    <p>
      <strong>
        x. You will not sell or trade for value, or seek to sell or trade for value, or
        accept as a sale or trade for value, any Merchandise provided to you by PUNT or
        any Gold Coins or Sweep Cash;
      </strong>
    </p>
    <p>
      xi. It is a Customer’s responsibility to ensure that their Participation is lawful
      in their jurisdiction.
    </p>
    <p>
      xii. Any person who is knowingly in breach of Clauses 2, 4 and 23, including any
      attempt to circumvent this restriction, for example, by using a service that masks
      or manipulates the identification of your real location, or by otherwise providing
      false or misleading information regarding your location or place of residence, or by
      Participating from an Excluded State or through a third party or on behalf of a
      third party located in an Excluded State, is in breach of these Terms and
      Conditions. You may be committing fraud and may be subject to criminal prosecution.
    </p>
    <p>
      xiii. You shall not act in a manner that is defamatory, trade libelous, threatening,
      or harassing.
    </p>
    <p>
      xiv. You shall not engage in potentially fraudulent or suspicious activity and/or
      transactions.
    </p>
    <p>
      xv. You must cooperate in any investigation or provide confirmation of your identity
      or the accuracy of any information you provide to us.
    </p>
    <p>
      xvi. You shall not provide false, inaccurate or misleading information in connection
      with your use of the Platform, in communications with PUNT, or otherwise connected
      with these Terms and Conditions.
    </p>
    <p>
      xvii. You shall not violate, or attempt to violate, (1) any law, statute, or
      ordinance; (2) PUNT’s or any third-party’s copyright, patent, trademark, trade
      secret, or other intellectual property rights, or rights of publicity or privacy.
    </p>
    <p>
      xviii. You declare that your access and use of the Platform and/or the Games, and
      your execution and delivery of, and the performance of your obligations under these
      Terms and Conditions, will not result in a breach of any applicable laws, rules or
      regulations or of any order, decree or judgment of any court, any award of any
      arbitrator or those of any governmental or regulatory authority in any jurisdiction.
    </p>
    <p>
      <strong>c) Eligible Customers</strong>
    </p>
    <p>
      Employees and contractors of PUNT, any of its respective affiliates, subsidiaries,
      holding companies, advertising agencies, or any other company or individual involved
      with the design, production, execution or distribution of the Games and their
      immediate family (spouse, parents, siblings and children, whether the relationship
      is by birth, marriage or adoption) and household members (people who share the same
      residence at least 3 months of the year) are not eligible to Participate.
    </p>
    <p>
      <strong>d) Acceptance</strong>
    </p>
    <p>
      By accepting these Terms and Conditions you agree that your Participation is at your
      sole option, discretion and risk. You will have no claims whatsoever against us or
      any of our partners, or respective directors, officers, employees, or contractors in
      relation to your use of the Platform or the Games.
    </p>
    <p>
      <strong>5. LICENCE</strong>
    </p>
    <p>
      5.1. Subject to your agreement and continuing compliance with these Terms and
      Conditions, PUNT grants you a personal, non-exclusive, non-transferable,
      non-sublicensable, revocable, limited licence to access and use the Platform,
      including Gold Coins and Sweep Cash, through a supported Web browser or mobile
      device, solely for your personal, private entertainment and no other reason.
    </p>
    <p>
      5.2. These Terms and Conditions do not grant you any right, title or interest in the
      Platform or Content.
    </p>
    <p>
      5.3. You acknowledge and agree that your licence to use the Platform is limited by
      these Terms and Conditions and if you do not agree to, or act in contravention of,
      these Terms and Conditions, your licence to use the Platform (including the Games
      and Content) shall be immediately and automatically terminated.
    </p>
    <p>
      5.4. In the event that the Platform or any Game is deemed to be illegal under the
      laws of the jurisdiction in which you reside or are situated, you are not granted
      any licence to, and must refrain from accessing, the Platform or relevant Game.
    </p>
    <p>
      <strong>6. YOUR CUSTOMER ACCOUNT</strong>
    </p>
    <p>
      <strong>6.1. Single Account</strong>
    </p>
    <p>
      a) You are allowed to have <strong>only one Customer Account </strong>, including
      any Inactive Account, on the Platform. If you attempt to open more than one Customer
      Account, all accounts you have opened or try to open may be cancelled or suspended
      and the consequences described in clause 23 may be enforced.
    </p>
    <p>
      b) You must notify us immediately if you notice that you have more than one
      registered Customer Account, whether active or not, on the Platform.
    </p>
    <p>
      <strong>
        c) DO NOT CREATE A NEW CUSTOMER ACCOUNT IF YOU WISH TO CHANGE YOUR EMAIL, ADDRESS
        OR OTHER PERSONAL INFORMATION
      </strong>
      .
    </p>
    <p>
      <strong>6.2. Accuracy</strong>
    </p>
    <p>a) You are required to keep your registration details up to date at all times.</p>
    <p>
      b) All the personal information provided by you or further updates, must be
      identical to that listed on your government issued identification.
    </p>
    <p>
      <strong>6.3. Security and Responsibility of Your Customer Account</strong>
    </p>
    <p>
      a) As part of the registration process, you will have to choose a password to login
      into the Platform.
    </p>
    <p>
      b) It is your sole and exclusive responsibility to ensure that your Customer Account
      login details and any Payment Methods are kept secure and are only accessible by
      you. You accept full responsibility for any unauthorised use of your Customer
      Account and any activity linked to your Customer Account, including by a minor
      (which in all events is prohibited).
    </p>
    <p>
      <strong>
        c) We strongly recommend that you enable multi-factor authentication for your
        account.
      </strong>
    </p>
    <p>
      d) You must not share your Customer Account or password with another person, let
      anyone else access or use your Customer Account or do any other thing that may
      jeopardise the security of your Customer Account.
    </p>
    <p>
      e) If you become aware of, or reasonably suspect that security in your Customer
      Account has been compromised, including loss, theft or unauthorised disclosure of
      your password and Customer Account details, you must notify us immediately.
    </p>
    <p>
      f) You are solely responsible for maintaining the confidentiality of your password
      and you will be held responsible for all uses of your Customer Account, including
      any purchases made under the Customer Account, whether those purchases were
      authorised by you or not.
    </p>
    <p>
      g) You are solely responsible for anything that happens through your Customer
      Account, whether or not you undertook those actions.
    </p>
    <p>
      h) You acknowledge that your Customer Account may be terminated if someone else uses
      it and/or engages in any activity that breaches these Terms and Conditions or is
      otherwise illegal.
    </p>
    <p>
      i) PUNT is not responsible for any abuse or misuse of your Customer Account by third
      parties due to your disclosure of your login details to any third party, whether
      such disclosure is intentional or accidental, active or passive.
    </p>
    <p>
      <strong>6.4. Transfer of Accounts, Gold Coins and Sweep Cash.</strong>
    </p>
    <p>
      a) You are not allowed to transfer Gold Coins or Sweep Cash between Customer
      Accounts, or from your Customer Account to other Customers, or to receive Gold Coins
      or Sweep Cash from other Customer Accounts into your Customer Account, or to
      transfer, sell and/or acquire Customer Accounts. You may not attempt to sell, trade,
      or transfer Gold Coins or Sweep Cash, whether on the Platform or off the Platform.
    </p>
    <p>b) You are not allowed to convert Gold Coins to Sweep Cash or vice versa.</p>
    <p>
      c) You are prohibited from selling, transferring or acquiring Customer Accounts to
      or from other Customers. If you attempt to sell, transfer or acquire a Customer
      Account, all accounts you have opened or tried to sell, transfer or acquire will be
      cancelled and the consequences described in clause 23 may be enforced.
    </p>
    <p>
      <strong>6.5. Closing of Customer Accounts</strong>
    </p>
    <p>
      a) If you wish to close your Customer Account you may do so at any time by sending
      an e-mail to <strong>support@punt.com</strong>. Closing your Customer Account will
      forfeit all continued access to and right to use, enjoy or benefit from any Gold
      Coins, Sweep Cash and unredeemed Prizes associated with your Customer Account.
    </p>
    <p>
      b) If you have concerns about possible responsible social gameplay issues, please
      consult our <strong>Gameplay Self-Exclusion Policy </strong>, which is accessible
      here.
    </p>
    <p>
      c) You will be able to open your Customer Account again (unless you have implemented
      a self-exclusion) by sending an e-mail to
      <a href="mailto:support@punt.com">
        <strong>support@punt.com</strong>
      </a>
      . All requests for the re-opening of an account will be evaluated by our Customer
      Support and Compliance teams, who abide by strict customer protection guidelines.
    </p>
    <p>
      <strong>6.6. Discretion to Refuse or Close Accounts</strong>
    </p>
    <p>
      PUNT reserves the right to place limits on, suspend, close or refuse to open a
      Customer Account in its sole discretion.
    </p>
    <p>
      <strong>7. GOLD COINS</strong>
    </p>
    <p>
      <strong>7.1. Gold Coins</strong>
    </p>
    <p>
      a) Gold coins do not have a monetary value and can only be used to play Standard
      Games.
      <strong>
        GOLD COINS CAN BE USED FOR ENTERTAINMENT ONLY AND CANNOT BE REDEEMED FOR ANY VALUE
        WHATSOEVER.
      </strong>
    </p>
    <p>b) PUNT will give Gold Coins <strong>FREE OF CHARGE </strong>on:</p>
    <p>
      <strong>i. Daily Reload </strong>
      – You can claim Gold Coins once per day, through logging on your Customer Account
      and claim your daily reload.
    </p>
    <p>
      <strong>ii. Promotional Giveaways </strong>
      – Promotional giveaways organized by PUNT on its social media accounts (Facebook,
      Twitter, Instagram, etc.).
    </p>
    <p>
      c) You may also win more Gold Coins when you play in Standard Play and you may
      purchase more Gold Coins on the Platform.
    </p>
    <p>d) You cannot win prizes when you Participate in Standard Play.</p>
    <p>
      <strong>7.2. Gold Coin Purchases</strong>
    </p>
    <p>
      a) The purchase of Gold Coins is the purchase of a licence that allows you to
      Participate in Standard Play Games and it is not a deposit of funds which can be
      withdrawn.
      <strong>
        Funds used to purchase Gold Coins will not, and cannot, be refunded to you. Gold
        Coins do not have any real money value.
      </strong>
    </p>
    <p>
      b) You can purchase Gold Coins on the Platform through one of the Payment Methods
      available on the website.
    </p>
    <p>
      c) The Payment Methods you use to purchase Gold Coins must be legally and
      beneficially owned by you and in your name. If it comes to our attention that the
      name you registered on your Customer Account and the name linked to your Payment
      Method differs, your Customer Account will be immediately suspended. Should your
      Customer Account be suspended, we recommend that you contact Customer Support
      through the support@punt.com.
    </p>
    <p>
      d) PUNT reserves the right to request documents and information to verify the legal
      and beneficial ownership of the Payment Methods you use to make Gold Coin purchases.
    </p>
    <p>
      e) You agree that we and/or our Payment Administration Agents and payments
      facilitators may store your payment information to process your future purchases. By
      accepting these Terms and Conditions, you authorize PUNT and/or our Payment
      Administration Agents and payments facilitators to store your payment credentials in
      compliance with applicable payment processing regulations.
    </p>
    <p>
      f) Once a Gold Coin purchase has been made, the funds will be drawn from your
      Payment Methods as soon as practicable.
    </p>
    <p>
      g) You can only buy a maximum of $ 2,000 (two thousand US dollars) worth of Gold
      Coins per day. Each purchase has a maximum value of $300 (three hundred US Dollars)
      per transaction.
    </p>
    <p>
      h) If you are found to have one or more of your purchases returned and/or reversed
      or charged back, your account will be suspended. If this occurs, the amount of such
      purchases will constitute a debt owed by you to us and you must immediately remit
      payment for such purchases through an alternative payment method. Until payment is
      received by us or our Payment Administration Agent, your account will be suspended
      and any purchases will be deemed void and requests to redeem any Sweep Cash will not
      be allowed.
    </p>
    <p>
      <strong>7.3. Currency</strong>
    </p>
    <p>
      a) All Gold Coin purchases must be made using the FIAT or crypto currency supported
      by the Platform.
    </p>
    <p>
      b) Any exchange or transaction fees, charges or related costs that may be incurred
      as a result of, or in relation to, your purchase of Gold Coins are to be borne
      solely by you, including but not limited to any losses or additional costs arising
      from foreign exchange fluctuations.
    </p>
    <p>
      <strong>8. SWEEP CASH</strong>
    </p>
    <p>
      <strong>8.1. SWEEP CASH</strong>
    </p>
    <p>
      a) Participants who receive promotional Sweep Cash can use that Sweep Cash to play
      Promotional Play Games within PUNT.
      <strong>Sweep Cash can only be used to play Promotional Play Games</strong>
      .
    </p>
    <p>
      <strong>b) YOU CANNOT PURCHASE SWEEP CASH </strong>
      .
      <strong> SWEEP CASH CAN BE OBTAINED ONLY THROUGH FREE, PROMOTIONAL OFFERS. </strong>
    </p>
    <p>
      <strong>8.2. How to receive Sweep Cash</strong>
    </p>
    <p>a) You can obtain <strong>FREE SWEEP CASH </strong>through the following means:</p>
    <p>
      <strong>i. Daily Reload – </strong>
      You can claim Sweep Cash once per day, through logging on your Customer Account and
      claim your daily reload.
    </p>
    <p>
      <strong>ii. Promotional Giveaways – </strong>
      Promotional giveaways organized by PUNT on its social media (Facebook, Twitter,
      Instagram, etc.). The amount of Sweep Cash given away will be stated on the
      applicable Promotion Giveaway contest.
    </p>
    <p>
      <strong>iv) Post Card </strong>
      – Participants can receive 5 free Sweep Cash by sending a standard postcard on the
      terms set on the clause 8.3.
    </p>
    <p>
      b) From time to time, you may also be offered promotional Sweep Cash as a bonus when
      purchasing Gold Coins. Participants may receive Sweep Cash as a bonus upon the
      purchase of specifically marked packs of Gold Coins. The number of Sweep Cash a
      Participant will receive as a bonus for each relevant Gold Coin purchase will be
      stated in the website at the moment of purchase. All purchases of Gold Coins are
      final and no refunds will be given.
    </p>
    <p>
      c) Use of any automated or other system(s) to Participate, to acquire Sweep Cash or
      play the Games is prohibited and will result in disqualification and loss of
      eligibility to Participate in the Games.
    </p>
    <p>
      <strong>8.3. Sweep Cash through Post Card</strong>
    </p>
    <p>
      a) Participants can receive 5 free Sweep Cash by sending a standard postcard or
      piece of white paper (Request Card) which must be at least 4” x 6”, unfolded, and
      placed inside a stamped envelope addressed to the following address and satisfying
      the requirements set out below:
    </p>
    <p>PUNT Limited</p>
    <p>
      i. handwrite their return address on the front of the envelope and the words:
      <strong>“Sweep Cash Credits” </strong>; and
    </p>
    <p>
      ii. handwrite all of the following on only one side of the Request Card inserted
      inside the envelope:
    </p>
    <p>a. their PUNT.com username;</p>
    <p>b. their full name as shown on their government issued identification;</p>
    <p>c. the email address registered to their PUNT.com account;</p>
    <p>d. the return/residential address registered to their PUNT.COM account; and</p>
    <p>e. the following statement:</p>
    <p>
      “I wish to receive Sweep Cash to participate in the sweepstakes promotions offered
      by PUNT SweepStakes. By submitting this request, I hereby declare that I have read,
      understood and agree to be bound by PUNT’s Terms and Conditions.”
    </p>
    <p>c) There is a limit of one Request Card per outer envelope.</p>
    <p>
      d) A Participant must ensure that their handwriting is legible. If the Participant’s
      handwriting is not legible, the entry will be void and the Sweep Cash will not be
      credited to the Participant’s Customer Account. The legibility of a Participant’s
      handwriting will be determined PUNT in its sole discretion.
    </p>
    <p>
      e) THE REQUEST MUST ONLY BE MADE BY THE PARTICIPANT AND MUST BE POSTED FROM THE SAME
      STATE OR PROVINCE AS THE PARTICIPANT’S VERIFIED RESIDENTIAL ADDRESS. Requests made
      by any other individual or any entity (including but not limited to commercial
      sweepstakes subscription notification and/or entering services) or posted from a
      State or Province different to the Participant’s verified residential address will
      be declared invalid and Sweep Cash will not be credited to the Participant’s
      Customer Account.
    </p>
    <p>f) PUNT RESERVES THE RIGHT TO REJECT REQUEST CARDS FROM EXCLUDED TERRITORIES.</p>
    <p>
      g) Requests made by any other individual or any entity (including but not limited to
      commercial sweepstakes subscription notification and/or entering services) or posted
      from an Excluded State different to the Participant’s verified residential address
      will be declared invalid.
    </p>
    <p>
      h) Tampering with the entry process or the operation of the Sweepstakes, including
      but not limited to the use of any device to automate the Sweep Cash request/entry
      process, or to otherwise abuse the process, is prohibited and any requests/entries
      deemed by PUNT, in its sole discretion, to have been submitted in this manner will
      be void. In the event a dispute regarding the identity of the individual who
      actually submitted a request or whether a request satisfies the requirements set out
      above cannot be resolved to PUNT’s satisfaction, the affected request/entry will be
      deemed ineligible.
    </p>
    <p>
      i) For each Request Card a Participant submits in accordance with the above
      requirements, the Participant will receive 5 Sweep Cash.
    </p>
    <p>
      <strong>8.4. Sweep Cash Balance</strong>
    </p>
    <p>
      a) The amount of Sweep Cash a Participant has will be displayed in their Customer
      Account on the website.
    </p>
    <p>
      b) The amount of Sweep Cash to be allocated to Participants can be changed at any
      time by PUNT in its sole discretion.
    </p>
    <p>
      c) PUNT is not responsible for lost, late, incomplete, invalid, unintelligible or
      misdirected Sweep Cash requests or allocations.
    </p>
    <p>
      <strong>9. GAMES</strong>
    </p>
    <p>
      <strong>9.1. Rules</strong>
    </p>
    <p>
      a) To participate in any Standard or Promotional Play, you must have an active
      Customer Account and agree to be bound by these Terms and Conditions.
    </p>
    <p>
      b) You may participate in any Game only if you have sufficient Gold Coins or Sweep
      Cash (as applicable) in your Customer Account for such Participation.
    </p>
    <p>
      c) Games offered on the Platform may have their own rules which are available on the
      Platform. It is your responsibility to read the rules of a Game before playing. You
      must familiarize yourself with the applicable terms of play and read the relevant
      rules before playing any Game.
    </p>
    <p>
      d) Gold Coins or Sweep Cash that have been submitted for play and accepted cannot be
      changed or cancelled and the Gold Coins or Sweep Cash (whichever applicable) will be
      drawn from your Gold Coin or Sweep Cash balance instantly.
    </p>
    <p>
      <strong>9.2. Void Games</strong>
    </p>
    <p>
      PUNT reserves the right to declare Participation in a Game void, partially or in
      full, if, in our sole discretion, we deem it obvious that there was an error,
      mistake, misprint or technical error on the pay-table, win-table, minimum or maximum
      PUNT’s, odds or software.
    </p>
    <p>
      <strong>9.3. Final Decision</strong>
    </p>
    <p>
      In the event of a discrepancy between the result showing on a user’s device and PUNT
      server software, the result showing on the PUNT server software will be the official
      and governing result.
    </p>
    <p>
      <strong>10. STANDARD PLAY</strong>
    </p>
    <p>10.1.Standard Play can only be played with Gold Coins.</p>
    <p>10.2. On Standard Play you can only win Gold Coins.</p>
    <p>10.3. You cannot win money or prizes of any kind when playing on Standard Play.</p>
    <p>
      <strong>11. PROMOTIONAL PLAY / SWEEPSTAKESS</strong>
    </p>
    <p>11.1. Promotional Play / Sweepstakes can only be played with Sweep Cash.</p>
    <p>
      11.2. It is the sole responsibility of a Participant to determine whether the
      Sweepstakes is legal and compliant with all regulations in the jurisdiction in which
      the Participant resides.
    </p>
    <p>
      11.3. Within PUNT there are different games. The amount of Sweep Cash required to
      play each game will be detailed next to the game.
    </p>
    <p>
      11.3. Within PUNT there are different games. The amount of Sweep Cash required to
      play each game will be detailed next to the game.
    </p>
    <p>
      11.4. Only games played with Sweep Cash provide the opportunity to redeem winnings
      for crypto prizes. The prize that can be won while playing a Game will be shown in
      the Platform by clicking the “Redeem” button.
    </p>
    <p>
      11.5. PUNT’s decisions as to the administration and operation of the Sweepstakes,
      the Game and the amount of winnings are final and binding.
    </p>
    <p>
      <strong>12. PROMOTIONS</strong>
    </p>
    <p>
      12.1. All promotions, including Games played in Promotional Play, contests and
      promotions are subject to these Terms and Conditions, and to additional terms that
      may be published at the time of the promotion.
    </p>
    <p>
      12.2. In the event and to the extent of any conflict between these Terms and
      Conditions and any promotion-specific terms and conditions, the promotion-specific
      terms and conditions will prevail.
    </p>
    <p>
      12.3. PUNT reserves the right to withdraw or modify such promotions without prior
      notice to you.
    </p>
    <p>
      12.4. If, in the reasonable opinion of PUNT, a Registered Customer is abusing any
      promotion, to derive any advantage or gain for themselves or other Registered
      Customers, including by way of Fraudulent Conduct, we may, at our sole discretion,
      withhold, deny or cancel any advantage, bonus or Prize as we see fit.
    </p>
    <p>
      12.5. Without limiting clause 17, you confirm that you grant PUNT an irrevocable,
      perpetual, worldwide, non-exclusive, royalty-free licence to use in whatever way we
      see fit, and without further acknowledgement of you as the author, any Content you
      post or publish as part of a promotion, contest or competition.
    </p>
    <p>
      <strong>13. REDEMPTION OF PRIZES</strong>
    </p>
    <p>
      <strong>13.1. Prizes</strong>
    </p>
    <p>
      a) Only Promotion Play / Sweepstakes give access to Prizes. No Prizes can be won
      through playing Standard Games.
    </p>
    <p>b) Only Sweep Cash can be redeemed for Prizes.</p>
    <p>
      c) All Participants are required to play their Sweep Cash three (3) times before
      they are eligible to be redeemed for Prizes.
    </p>
    <p>
      d) PUNT reserves the right to change the Prize win rates and odds of any of the
      Sweepstakes at any time. It is a Participant’s responsibility to check the Prize win
      rate on each occasion before they participate.
    </p>
    <p>e) No Prize can be redeemed without completing the identification process.</p>
    <p>
      <strong>13.2. Prize Redemption Methods</strong>
    </p>
    <p>
      a) The Prizes for which you can redeem your Sweep Cash will be identified on the
      website and can change from time to time at PUNT’s discretion.
    </p>
    <p>b) Prizes may include, but are not necessarily limited to:</p>
    <p>i. Gift cards;</p>
    <p>ii. Crypto Currency;</p>
    <p>iii. Merchandise.</p>
    <p>
      <strong>13.3. Limits and Fees</strong>
    </p>
    <p>
      PUNT reserves the right to charge fees for processing the redemption of Prizes to
      you and to set a minimum redemption threshold for Prize redemptions.
    </p>
    <p>
      <strong>13.4. Prizes redeemed for Gift Cards</strong>
    </p>
    <p>
      a) When you choose to redeem Prizes for gift cards, the gift cards will be sent to
      the email address that you have registered with your Customer Account.
    </p>
    <p>
      b) PUNT is not responsible for non-working email addresses or if you fail to access
      or use the gift card, for instance, due to the failure to check your email or due to
      a spam filter blocking it.
    </p>
    <p>
      c) If no valid email address is provided to us within 60 days of a request from us
      to do so, PUNT is not obliged to allocate the gift cards to you and may in its
      discretion deem the Prizes to be void.
    </p>
    <p>
      <strong>13.5. Prizes redeemed for Merchandise</strong>
    </p>
    <p>
      a) When you choose to redeem Prizes for merchandise, the merchandise will be sent to
      the address that you have provided when registering your Customer Account.
    </p>
    <p>b) PUNT is not responsible for any error or invalid addresses provided by you.</p>
    <p>
      c) In case you do not receive the merchandise due to an error on the address
      provided by you, PUNT will not be liable for such error neither will be liable for
      such non-delivery.
    </p>
    <p>
      <strong>13.6. Prizes redeemed for Crypto Currency</strong>
    </p>
    <p>
      a) When you choose to redeem Prizes for crypto currency, you can go to your “Wallet”
      account, press “Redeem,” and choose the crypto currency to which you want to redeem
      your Prize.
    </p>
    <p>
      b) When you choose to redeem Prizes for crypto currency, it is your sole
      responsibility to ensure that the crypto wallet to which you are transferring the
      funds can receive those funds. PUNT has no obligation to check whether your wallet
      will accept the crypto currency of your choice.
    </p>
    <p>
      c) Sweep Cash will be redeemable at an implied rate of 1 Sweep Cash per 1 USD.As
      such, the amount of crypto currency that can be redeemed per 1 Sweep Cash will be
      determined by the market price of that crypto currency in USD at the time of such
      redemption (as determined in our discretion).
    </p>
    <p>
      d) In Florida, the maximum redemption value of Sweep Cash prize won on any one game
      or play, via a Participant’s participation in the Sweepstakes is US$5,000. Any
      redemption of a prize valued in excess of US$5,000 will not be allocated or paid.
    </p>
    <p>
      <strong>13.7. Timing and Frequency for Prize Redemptions</strong>
    </p>
    <p>
      a) We process requests to redeem Prizes in the order in which they are received. Our
      goal is to process your request as soon as practicable.
    </p>
    <p>
      b) We will only process one Prize redemption request per Customer Account in any 24
      hour period.
    </p>
    <p>
      c) There may be delays in payments due to our identity verification process. Certain
      Payment Mediums will require additional verification at the time of redemption.
    </p>
    <p>
      d) Without limiting clause 13, Customers can request to redeem Prizes of any
      value.However, we reserve the right to allocate or pay Prizes in smaller increments
      over a number of days until all of the Prize has been allocated or paid.
    </p>
    <p>
      <strong>13.8. Refused Prizes &amp; Mistaken Credits</strong>
    </p>
    <p>
      If we mistakenly credit your Customer Account from time to time with Prizes that do
      not belong to you, whether due to a technical error, human error or otherwise, the
      amount credited will remain PUNT property and will be deducted from your Customer
      Account. If you have been transferred crypto currency or gift cards that do not
      belong to you prior to us becoming aware of the error, the mistakenly paid amount
      will (without prejudice to other remedies and actions that may be available at law)
      constitute a debt owed by you to us. In the event of an incorrect crediting, you are
      obliged to notify Customer Support through
      <a href="mailto:support@punt.com">support@punt.com</a>
    </p>
    <p>
      <strong>14. VERIFICATION</strong>
    </p>
    <p>
      <strong>14.1. Verification Checks</strong>
    </p>
    <p>
      a) You agree that we are entitled to conduct any identification, credit and other
      verification checks that we may reasonably require and/or that are required of us
      under applicable laws and regulations or by relevant regulatory authorities or to
      otherwise prevent financial crime.
    </p>
    <p>b) Until all required verification checks are completed to our satisfaction:</p>
    <p>i. any request you have made for redemption of Prizes will remain pending; and</p>
    <p>
      ii. we are entitled to restrict your Customer Account in any manner that we may
      reasonably deem appropriate, including by suspending or deactivating your Customer
      Account.
    </p>
    <p>
      iii. Where any identification, credit or other verification check we require cannot
      be completed to our satisfaction because you have not provided any document we
      request from you in the form that we require within 30 days’ of the date the
      document was first requested, then we are under no obligation to continue with the
      verification check and we may, in our sole discretion, deactivate or otherwise
      restrict your Customer Account in any manner that we may reasonably deem
      appropriate.
    </p>
    <p>
      ii. we are entitled to restrict your Customer Account in any manner that we may
      reasonably deem appropriate, including by suspending or deactivating your Customer
      Account.
    </p>
    <p>
      iii. Where any identification, credit or other verification check we require cannot
      be completed to our satisfaction because you have not provided any document we
      request from you in the form that we require within 30 days’ of the date the
      document was first requested, then we are under no obligation to continue with the
      verification check and we may, in our sole discretion, deactivate or otherwise
      restrict your Customer Account in any manner that we may reasonably deem
      appropriate.
    </p>
    <p>
      iv. Customers who request the redemption of Prizes held in a deactivated or
      suspended account should contact
      <a href="mailto:support@punt.com">support@punt.com</a>
 
    </p>
    <p>
      You agree that PUNT may use third party service providers to run external
      identification and other verification checks on all Customers on the basis of the
      information provided by you from time to time.
    </p>
    <p>
      <strong>15. RESPONSIBLE SOCIAL GAMEPLAY</strong>
    </p>
    <p>
      <strong>15.1. Policy</strong>
    </p>
    <p>
      a) PUNT actively supports responsible social gameplay and encourages its Customers
      to make use of a variety of responsible social gameplay features so as to better
      manage their Customer Account.
    </p>
    <p>
      b) PUNT is committed to providing excellent customer service. As part of that
      pledge, PUNT is committed to supporting responsible social gameplay. Although PUNT
      will use all reasonable endeavours to enforce its responsible social gameplay
      policies, PUNT does not accept any responsibility or liability if you nevertheless
      continue gameplay and/or seek to use the Platform with the intention of deliberately
      avoiding the relevant measures in place and/or PUNT is unable to enforce its
      measures/policies for reasons outside of PUNT’s reasonable control.
    </p>
    <p>c) We refer to you our Responsible Social Gameplay Policy for full details.</p>
    <p>
      <strong>15.2. Self-Exclusion</strong>
    </p>
    <p>
      You may, at any time, request a time-out or self-exclusion from our Games. Please
      check our self-exclusion procedure here.
    </p>
    <p>
      <strong>15.3. Customer Protection Policy</strong>
    </p>
    <p>
      We want to ensure that you enjoy your experience playing our Games in a safe and
      responsible manner. On the Responsible Social Gameplay page of our website, we have
      a detailed Customer Protection Policy, which includes a list of mechanisms which you
      can enlist in order to ensure a safer gameplay experience.
    </p>
    <p>
      <strong>16. FRAUDULENT CONDUCT</strong>
    </p>
    <p>
      16.1. As a condition to access the Games or Platform, you may not, directly or
      indirectly:
    </p>
    <p>
      a) hack into any part of the Games or Platform through password mining, phishing, or
      any other means;
    </p>
    <p>
      b) attempt to modify, reverse engineer, or reverse-assemble any part of the Games or
      Platform;
    </p>
    <p>
      c) knowingly introduce viruses, Trojans, worms, logic bombs, spyware, malware, or
      other similar material;
    </p>
    <p>
      d) circumvent the structure, presentation or navigational function of any Game so as
      to obtain information that PUNT has chosen not to make publicly available on the
      Platform;
    </p>
    <p>e) engage in any form of cheating or collusion;</p>
    <p>
      f) use the Platform and the systems of PUNT to facilitate any type of illegal money
      transfer (including money laundering proceeds of crime); or
    </p>
    <p>
      g) participate in or take advantage of, or encourage others to participate in or
      take advantage of schemes, organisations, agreements, or groups designed to share:
    </p>
    <p>
      i. special offers or packages emailed to a specific set of Customers and redeemable
      by URL; or
    </p>
    <p>
      ii. identification documents (including, but not limited to, photographs, bills and
      lease documents) for the purpose of misleading PUNT as to a Customer’s identity.
    </p>
    <p>
      16.2. You must not use the Platform for any unlawful or fraudulent activity or
      prohibited transaction (including Fraudulent Conduct) under the laws of any
      jurisdiction that applies to you. We monitor all transactions in order to prevent
      money laundering.
    </p>
    <p>
      16.3. If PUNT suspects that you may be engaging in, or have engaged in fraudulent,
      unlawful or improper activity, including money laundering activities or any conduct
      which violates these Terms and Conditions, your access to the Platform will be
      deactivated immediately and your Customer Account may be deactivated or suspended.
      If your Customer Account is deactivated or suspended under such circumstances, PUNT
      is under no obligation to reverse any Gold Coin purchases you have made or to redeem
      any Sweep Cash or Prizes that may be in your Customer Account. In addition, PUNT may
      pass any necessary information on to the relevant authorities, other online service
      providers, banks, credit card companies, electronic payment providers or other
      financial institutions. You will cooperate fully with any PUNT investigation into
      such activity.
    </p>
    <p>
      16.4. If you suspect any unlawful or fraudulent activity or prohibited transaction
      by another Customer, please notify us immediately via the means of communication
      listed in the Customer Complaints procedure.
    </p>
    <p>
      <strong>17. INTELLECTUAL PROPERTY</strong>
    </p>
    <p>
      17.1. The computer software, the computer graphics, the Platform and the user
      interface that we make available to you is owned by, or licensed to, PUNT or its
      associates and protected by copyright laws. You may only use the software for your
      own personal, recreational uses in accordance with all rules, terms and conditions
      we have established (including these Terms and Conditions and the Sweep Cash Rules)
      and in accordance with all applicable laws, rules and regulations.
    </p>
    <p>
      17.2. You acknowledge that PUNT is the proprietor or authorized licensee of all
      intellectual property in relation to any Content.
    </p>
    <p>
      17.3. Your use of the Games and Platform does not provide you with any intellectual
      property rights in the Content, Games or Platform.
    </p>
    <p>
      17.4. You grant us, and represent and warrant that you have the right to grant us,
      an irrevocable, perpetual, worldwide, non-exclusive, royalty-free licence to use in
      whatever way we see fit, any information, images, videos, comments, messages, music
      or profiles you publish or upload to any website or social media page controlled and
      operated by PUNT.
    </p>
    <p>
      17.5. You must not reproduce or modify the Content in any way, including by removing
      any copyright or trade mark notice.
    </p>
    <p>
      17.6. All trademarks and logos displayed in the Games and Platform are the property
      of their respective owners and are protected by applicable trade mark and copyright
      laws.
    </p>
    <p>
      <strong>18. THIRD PARTY WEBSITES, LINKS OR GAMES</strong>
    </p>
    <p>
      <strong>18.1. Third Party Websites.</strong>
    </p>
    <p>
      a) You acknowledge and agree that PUNT is not responsible for any Third Party
      Websites and makes no guarantee as to the content, functionality, or accuracy of any
      Third Party Website.
    </p>
    <p>
      <strong>
        b) You further acknowledge that any Third Party Websites purporting to offer Gold
        Coins or Sweep Cash are not authorized to do so
      </strong>
      .Any purported Third Party Websites purporting to do so may be an effort to induce
      you to reveal personal information (including passwords, account information and
      credit card details). You agree that PUNT is not responsible for any actions you
      take at the request or direction of these or any other Third Party Websites.
    </p>
    <p>
      <strong>18.2. Links</strong>
    </p>
    <p>a) Any links to Third Party Websites do not:</p>
    <p>i. indicate a relationship between PUNT and the third party; or</p>
    <p>
      i. indicate any endorsement or sponsorship by PUNT of the Third Party Website, or
      the goods or services it provides, unless specifically indicated by PUNT.
    </p>
    <p>
      b) Where a website controlled and operated by PUNT contains links to certain social
      networking sites, such as Facebook® and Twitter®, you acknowledge and agree that:
    </p>
    <p>
      i. any comments or content that you post on such social networking sites are subject
      to the terms and conditions of that particular social networking site;
    </p>
    <p>
      ii. you will not post any comments that are false, misleading or deceptive or
      defamatory to us, our employees, agents, officers or other Customers; and
    </p>
    <p>
      iii. we are not responsible or liable for any comments or content that you or others
      post on social networking sites.
    </p>
    <p>
      <strong>19. DISRUPTIONS AND CHANGE</strong>
    </p>
    <p>
      <strong>19.1. No warranties</strong>
    </p>
    <p>
      The Platform is provided on an “as is” basis and to the fullest extent permitted by
      law, we make no warranty or representation, whether express or implied, in relation
      to the satisfactory quality, fitness for purpose, completeness or accuracy of the
      Platform (including the Games and Content).
    </p>
    <p>
      <strong>19.2. Malfunctions</strong>
    </p>
    <p>
      a) WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITES, THE
      PLATFORM, ANY OF YOUR ACCOUNT(S), OR THE GAMES WILL BE CONTINUOUS, UNINTERRUPTED,
      TIMELY, OR ERROR-FREE.
    </p>
    <p>
      b) PUNT is not liable for any downtime, server disruptions, lagging, or any
      technical or political disturbance to Game play, nor attempts by you to Participate
      by methods, means or ways not intended by us.
    </p>
    <p>
      c) PUNT accepts no liability for any damages or losses which are deemed or alleged
      to have arisen out of or in connection with any Platform or its Content including,
      without limitation, delays or interruptions in operation or transmission, loss or
      corruption of data, communication or lines failure, any person’s misuse of a
      Platform or its Content or any errors or omissions in Content.
    </p>
    <p>
      d) In the event of a Platform system malfunction all Game play on that Platform is
      void.
    </p>
    <p>
      e) In the event a Game is started but fails to conclude because of a failure of the
      system, PUNT will use commercially reasonable efforts to reinstate the amount of
      Gold Coins or Sweep Cash played (whichever applicable) in the Game to you by
      crediting it to your Customer Account.
    </p>
    <p>
      f) PUNT reserves the right to alter Customer balances and account details to correct
      such mistakes
    </p>
    <p>
      g) PUNT reserves the right to remove any part of the Games from the Platform at any
      time. Any part of the Games that indicate incorrect behaviour affecting Prize
      redemption, game data, Gold Coin balances, Sweep Cash balances or other balances,
      that may be due to misconfiguration or a bug, will be cancelled and removed from the
      Platform. Customer balances and account details may be altered by PUNT in such cases
      in order to correct any mistakes.
    </p>
    <p>
      <strong>19.3. Change</strong>
    </p>
    <p>
      PUNT reserves the right to suspend, modify, remove or add Content to the Platform at
      its sole discretion with immediate effect and without notice to you. We will not be
      liable to you for any loss suffered as a result of any changes made or for any
      modification or suspension of or discontinuance of the Platform (including any Game
      thereon) and you will have no claims against PUNT in such regard.
    </p>
    <p>
      <strong>19.4. Service Suspension</strong>
    </p>
    <p>
      We may temporarily suspend the whole or any part of the Platform for any reason at
      our sole discretion. We may, but will not be obliged to, give you as much notice as
      is reasonably practicable of such suspension. We will restore the Platform, as soon
      as is reasonably practicable, after such temporary suspension.
    </p>
    <p>
      <strong>19.5 VIRUSES</strong>
    </p>
    <p>
      Although we take reasonable measures to ensure that the Platform is free from
      viruses we cannot and do not guarantee that the Platform is free of such problems.
      It is your responsibility to protect your systems and have in place the ability to
      reinstall any data or programs lost due to a virus.
    </p>
    <p>
      <strong>20. PRIVACY POLICY</strong>
    </p>
    <p>
      20.1. PUNT is committed to protecting and respecting your privacy and complying with
      all applicable data protection and privacy laws.
    </p>
    <p>
      20.2. Our Privacy Policy is incorporated into these Terms and Conditions and its
      acceptance is a prerequisite to account registration.
    </p>
    <p>
      20.3. You consent to receive marketing communications from PUNT in respect of its
      offerings by way of email, notifications, any of which you may unsubscribe from at
      any time by contacting Customer Support via support@punt.com
    </p>
    <p>
      <strong>21. USE OF LIVE CHAT SERVICES</strong>
    </p>
    <p>
      21.1. We may provide you with a Live Chat service to talk to our Customer Support
      representatives or to talk to other Customers. It is your responsibility to use
      these services only for their intended purposes. You are not permitted to use our
      Live Chat services for illegal purposes.
    </p>
    <p>
      21.2. Be careful what you post on any Live Chat service. We review and moderate
      chats and keep a log and record of statements. Your use of the Live Chat service
      should be for recreational and social purposes only.
    </p>
    <p>
      21.3. Spamming on Live Chat is prohibited. You are prohibited from intimidating,
      harassing or abusing other Customers or PUNT employees and representatives.
    </p>
    <p>
      21.4. You will not use any Live Chat service to engage in any form of harassment or
      offensive behaviour, including but not limited to, threatening, derogatory, abusive
      or defamatory statements, or racist, sexually explicit, pornographic, obscene, or
      offensive language.
    </p>
    <p>
      21.5. You will not use any Live Chat service to infringe the privacy rights,
      property rights, or any other rights of any person.
    </p>
    <p>
      21.6. You will not submit any kind of material or information on any Live Chat
      service that is fraudulent or otherwise unlawful or violates any law.
    </p>
    <p>
      21.7. You will not use any Live Chat service to distribute, promote or otherwise
      publish any material containing any solicitation for funds, advertising or
      solicitation for goods or services of other forums.
    </p>
    <p>
      21.8. You will not use any Live Chat service to distribute, promote or otherwise
      publish any kind of malicious code or do anything else that might cause harm to the
      Platform or to other Customer’s systems in any way.
    </p>
    <p>
      21.9. We reserve the right to monitor anything and everything submitted by you to
      any Live Chat service to ensure that it conforms to content guidelines that are
      monitored by us and subject to change from time to time.
    </p>
    <p>
      21.10. If you breach any of the provisions relating to a Live Chat service, we may
      ban you from using that Live Chat service or all Live Chat services and/or suspend
      or deactivate your Customer Account. If we deactivate your Customer Account, we
      reserve the right to cancel or refuse to redeem any Prizes.
    </p>
    <p>
      21.11. We reserve the right to remove any Live Chat service from the Platform if
      abused.
    </p>
    <p>21.11. We will not be liable if damage arises out of the Live Chat service.</p>
    <p>
      21.12. You agree to indemnify us against any damage arising out of your illegal,
      unlawful or inappropriate conduct or arising out of violation of the provisions in
      clause 21 or any other rules on the Platform applying to the Live Chat service.
    </p>
    <p>
      21.13. You will not collude in any way through the Live Chat service. Customers are
      encouraged to report any suspicious behaviour to Customer Support via this form.
    </p>
    <p>
      <strong>22. COMPLAINTS AND CUSTOMER SUPPORT</strong>
    </p>
    <p>
      22.1. You may send correspondence, including inquiries or complaints, to
      complaints@punt.com
    </p>
    <p>
      22.2. TO PROTECT YOUR PRIVACY, ALL EMAIL COMMUNICATIONS BETWEEN YOU AND PUNT SHOULD
      BE CARRIED OUT USING THE EMAIL ADDRESS THAT YOU HAVE REGISTERED ON YOUR CUSTOMER
      ACCOUNT. FAILURE TO DO SO MAY RESULT IN OUR RESPONSE BEING DELAYED.
    </p>
    <p>
      22.3. The following information must be included in any written communication with
      PUNT (including a complaint):
    </p>
    <p>a) your username;</p>
    <p>b) your first and last name, as registered on your Customer Account;</p>
    <p>c) a detailed explanation of the complaint/claim; and</p>
    <p>
      d) any specific dates and times associated with the complaint/claim (if applicable).
    </p>
    <p>
      22.4. Failure to submit a written communication with the information outlined above
      may result in a delay in our ability to identify and respond to your correspondence
      in a timely manner.
    </p>
    <p>
      22.5. PUNT will endeavour to respond to correspondence within 10 days from the
      receipt of the same.
    </p>
    <p>
      <strong>23. DEACTIVATION/SUSPENSION OF ACCOUNT</strong>
    </p>
    <p>
      23.1. Without limiting clause 4, we reserve the right, at our sole discretion, to
      deactivate or suspend your Customer Account (notwithstanding any other provision
      contained in these Terms and Conditions) where we have reason to believe that you
      have engaged or are likely to engage in any of the following activities:
    </p>
    <p>
      a) you breached, or assisted another party to breach, any provision of these Terms
      and Conditions or the Sweep Cash Rules, or we have a reasonable ground to suspect
      such breach;
    </p>
    <p>
      b) you have more than one Customer Account, including any Inactive Account, on any
      Platform;
    </p>
    <p>
      c) the name registered on your Customer Account does not match the name on (i) your
      Payment Medium used to make purchases of Gold Coins or (ii) the account into which
      you elect to redeem Prizes or you do not legally and beneficially own such Payment
      Medium or redemption account;
    </p>
    <p>
      d) your communication with us or other Customers consists of harassment or offensive
      behaviour, including (but not limited to) threatening, derogatory, abusive or
      defamatory statements, or racist, sexually explicit, pornographic, obscene or
      offensive language;
    </p>
    <p>e) your Customer Account is deemed to be an Inactive Account;</p>
    <p>f) you become bankrupt;</p>
    <p>g) you provide incorrect or misleading information;</p>
    <p>
      h) your identity or source of wealth or source of funds (if requested) cannot be
      verified;
    </p>
    <p>
      i) you attempt to use your Customer Account through a VPN, proxy or similar service
      that masks or manipulates the identification of your real location, or by otherwise
      providing false or misleading information regarding your citizenship, location or
      place of residence, or by playing Games using the Platform through a third party or
      on behalf of a third party;
    </p>
    <p>
      j) you are not over 21 years of age or such higher minimum legal age of majority as
      stipulated in the jurisdiction of your residence;
    </p>
    <p>k) you are located in a jurisdiction where participation is illegal;</p>
    <p>
      l) you have allowed or permitted (whether intentionally or unintentionally) someone
      else to Participate using your Customer Account;
    </p>
    <p>
      m) you have played in tandem with other Customer(s) as part of a club, group, etc.,
      or played the Games in a coordinated manner with other Customer(s) involving the
      same (or materially the same) selections;
    </p>
    <p>
      n) without limiting clause 7, where PUNT has received a “charge back” , claim or
      dispute and/or a “return” notification via your Payment Medium;
    </p>
    <p>
      o) you have failed our due diligence procedures, or are found to be colluding,
      cheating, money laundering or undertaking any kind of fraudulent activity; or
    </p>
    <p>
      p) it is determined by PUNT that you have employed or made use of a system
      (including machines, computers, software or other automated systems such as bots)
      which give you an unfair advantage;
    </p>
    <p>
      23.2.PUNT reserves the right to suspend your account at its own discretion whenever
      such suspension is needed in order to protect the platform, the public in general or
      other Customers.
    </p>
    <p>
      23.3. If PUNT deactivates or suspends your Customer Account for any of the reasons
      referred to in clause 23.1 above, you will be liable for any and all claims, losses,
      liabilities, damages, costs and expenses incurred or suffered by PUNT (together
      “Claims”) arising therefrom and you will indemnify and hold PUNT harmless on demand
      for such Claims.
    </p>
    <p>
      23.4. If we have reasonable grounds to believe that you have participated in any of
      the activities set out in clause 23.1 above then we reserve the right to withhold
      all or part of the balance and/or recover from your Customer Account any Prizes,
      Gold Coins or Sweep Cash that are attributable to any of the activities contemplated
      in clause 23.1. In such circumstances, your details may be passed on to any
      applicable regulatory authority, regulatory body or any other relevant external
      third parties.
    </p>
    <p>
      23.5. If your Customer Account is deactivated as a result of closure of the Platform
      or similar event, we will make commercially reasonable efforts to enable redemption
      of any existing Prizes associated with your Customer Account, but all of your rights
      to use, enjoy or benefit from the Gold Coins and Sweep Cash will be terminated.
    </p>
    <p>
      23.6. The rights set out in clause 23 are without prejudice to any other rights that
      we may have against you under these Terms and Conditions or otherwise.
    </p>
    <p>
      <strong>24. INDEMNITY AND LIMITATION OF LIABILITY</strong>
    </p>
    <p>
      <strong>24.1. Indemnity</strong>
    </p>
    <p>
      a) YOU AGREE TO INDEMNIFY AND HOLD HARMLESS PUNT AND ITS AFFILIATES, SUBSIDIARIES,
      ULTIMATE PARENT AND PARENT COMPANIES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES,
      CONTRACTORS SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS OR SUPPLIERS, AND EACH
      OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS, LICENSORS,
      AND CONTRACTORS (“INDEMNIFIED PERSONS”) FROM AND AGAINST ANY CLAIMS, SUITS, ACTIONS,
      DEMANDS, DISPUTES, ALLEGATIONS, OR INVESTIGATIONS BROUGHT BY ANY THIRD-PARTY,
      GOVERNMENTAL AUTHORITY, OR INDUSTRY BODY, AND ALL LIABILITIES, DAMAGES (ACTUAL AND
      CONSEQUENTIAL), LOSSES, COSTS, AND EXPENSES, INCLUDING WITHOUT LIMITATION REASONABLE
      ATTORNEYS’ FEES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH:
    </p>
    <p>
      b) YOUR ACCESS TO OR USE OF THE PLATFORM, AND/OR YOUR INABILITY TO ACCESS OR USE THE
      PLATFORM;
    </p>
    <p>
      c) YOUR BREACH OR ALLEGED BREACH OF THESE TERMS AND CONDITIONS OR YOUR VIOLATION OF
      ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, INCLUDING ANY TERMS AND
      CONDITIONS INCORPORATED BY REFERENCE HEREIN;
    </p>
    <p>d) YOUR VIOLATION OF ANY LAW, RULE, OR REGULATION;</p>
    <p>e) YOUR VIOLATION OF THE RIGHTS OF ANY THIRD-PARTY.</p>
    <p>
      f) RE-USE OF ANY CONTENT AT, OR OBTAINED FROM, THE PLATFORM OR ANY OTHER SOURCE
      WHATSOEVER;
    </p>
    <p>g) FACILITATING OR MAKING A PAYMENT INTO YOUR CUSTOMER ACCOUNT;</p>
    <p>h) PLAYING THE GAMES THROUGH ANY DELIVERY MECHANISM OFFERED; AND</p>
    <p>i) ACCEPTANCE AND USE OF ANY PRIZE OR THE INABILITY TO ACCEPT OR USE ANY PRIZE.</p>
    <p>
      <strong>24.2. Limitation of Liability</strong>
    </p>
    <p>
      a) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES
      WHATEVER WILL WE OR OUR AFFILIATES, SUBSIDIARIES, ULTIMATE PARENT AND PARENT
      COMPANIES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, SHAREHOLDERS,
      AGENTS, LICENSORS, SUBCONTRACTORS AND SUPPLIERS, BE RESPONSIBLE OR LIABLE TO YOU OR
      TO ANY OTHER ENTITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, UNDER ANY
      LEGAL THEORY, WHETHER CONTRACT, TORT OR OTHERWISE, FOR ANY INDIRECT, INCIDENTAL,
      CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING ANY LOST PROFITS
      AND LOST BUSINESS OPPORTUNITIES, BUSINESS INTERRUPTION, LOST REVENUE, INCOME,
      GOODWILL, USE OF DATA OR OTHER INTANGIBLE LOSSES, WHETHER ARISING OUT OF OR IN
      CONNECTION WITH OUR SITES, THE PLATFORM, YOUR ACCOUNT(S), YOUR USE OF THE GAMES,
      THESE TERMS AND CONDITIONS, AND/ORANY OTHER ACT OR OMISSION BY US.
    </p>
    <p>
      b) TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL
      WE, OUR AFFILIATES, SUBSIDIARIES, ULTIMATE PARENT AND PARENT COMPANIES, PARTNERS,
      OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS SHAREHOLDERS, AGENTS, LICENSORS,
      SUBCONTRACTORS OR SUPPLIERS, BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID
      US IN THE THIRTY (30) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT
      ANY SUCH CLAIM PURSUANT TO CLAUSE 26 BELOW. YOU ACKNOWLEDGE AND AGREE THAT IF YOU
      HAVE NOT PAID US ANY AMOUNTS IN THE THIRTY (30) DAYS IMMEDIATELY PRECEDING THE DATE
      ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY
      DISPUTE WITH US IS TO STOP USING THE PLATFORM AND TO CLOSE YOUR CUSTOMER ACCOUNT.
    </p>
    <p>
      c) YOU RECOGNIZE AND AGREE THAT THE WARRANTY DISCLAIMERS IN CLAUSES 19 AND 23, AND
      THE INDEMNITIES AND LIMITATIONS OF LIABILITY IN CLAUSE 24, ARE MATERIAL AND
      BARGAINED-FOR BASES OF THESE TERMS AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT AND
      REFLECTED IN THE DECISION BY YOU TO ENTER INTO THESE TERMS AND CONDITIONS. d) We are
      not liable for any breach of these Terms and Conditions where the breach is due to
      abnormal and unforeseeable circumstances beyond our control, the consequences of
      which would have been unavoidable despite all effects to the contrary, nor are we
      liable where the breach is due to any action or inaction which is necessary or
      desirable in order to comply with any laws, rules, or regulations.
    </p>
    <p>
      e) Depending on where you reside and use the Platform, some of the limitations
      contained in clause 24 may not be permissible. In such case, they will not apply to
      you, solely to the extent so prohibited.
    </p>
    <p>
      <strong>24.3. Negligence and Wilful Misconduct</strong>
    </p>
    <p>
      NOTHING IN THESE TERMS AND CONDITIONS WILL OPERATE SO AS TO EXCLUDE ANY LIABILITY OF
      PUNT FOR DEATH OR PERSONAL PHYSICAL INJURY THAT IS DIRECTLY AND PROXIMATELY CAUSED
      BY PUNT’S GROSS NEGLIGENCE OR WILFUL MISCONDUCT.
    </p>
    <p>
      <strong>24.5. Survival of Obligations</strong>
    </p>
    <p>
      THIS CLAUSE 24 (INDEMNITY AND LIMITATION OF LIABILITY) SURVIVES THE TERMINATION OF
      THESE TERMS AND CONDITIONS FOR ANY REASON.
    </p>
    <p>
      <strong>25. PUNT NOT A FINANCIAL INSTITUTION</strong>
    </p>
    <p>
      25.1. You will not receive any interest on outstanding Prizes and you will not treat
      PUNT as a financial institution.
    </p>
    <p>
      25.2. PUNT does not provide advice regarding tax and/or legal matters. Customers who
      wish to obtain advice regarding tax and legal matters are advised to contact
      appropriate advisors.
    </p>
    <p>
      <strong>26 DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE</strong>
    </p>
    <p>
      26.1. PLEASE READ THIS CLAUSE 26 CAREFULLY AS IT REQUIRES YOU TO ARBITRATE DISPUTES
      AGAINST PUNT ON AN INDIVIDUAL BASIS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
      RELIEF FROM PUNT.
    </p>
    <p>
      26.2. This clause 26 (Dispute Resolution and Agreement to Arbitrate) shall be
      construed under and be subject to the Federal Arbitration Act.
    </p>
    <p>
      26.3. By agreeing to these Terms and Conditions, you agree that any and all past,
      present and future disputes, claims or causes of action between you and PUNT or any
      of its affiliates, subsidiaries, ultimate parent and parent companies, partners,
      officers, directors, employees, contractors, shareholders, agents, licensors,
      subcontractors or suppliers, which arise out of or are related in any way to these
      Terms and Conditions, the formation of these Terms and Conditions, the validity or
      scope of this clause 26 (Dispute Resolution and Agreement to Arbitrate), your
      Participation in or other access to or use of the Games or the Platform, or any
      other dispute between you and PUNT or any of its affiliates, subsidiaries, ultimate
      parent and parent companies, partners, officers, directors, employees, contractors,
      shareholders, agents, licensors, subcontractors or suppliers, including as to the
      arbitrability of any of the foregoing, and whether arising prior to or after your
      agreement to this clause 26 (Dispute Resolution and Agreement to Arbitrate) (all of
      the foregoing, collectively “Disputes”), will be governed by the procedure set out
      below.
    </p>
    <p>
      26.4. Whether to agree to arbitration is an important decision. It is your decision
      to make and you ARE NOT REQUIRED TO rely solely on the information provided in these
      Terms and Conditions. You should take reasonable steps to conduct further research
      and, if you wish, to consult with counsel of your choice.
    </p>
    <p>
      <strong>26.5. Informal Complaint Resolution</strong>
    </p>
    <p>
      We want to address any concerns or Disputes you may have without the need for formal
      dispute resolution. Therefore, before filing any arbitration demand or other claim
      of any kind against PUNT or any of its affiliates, subsidiaries, ultimate parent and
      parent companies, partners, officers, directors, employees, contractors,
      shareholders, agents, licensors, subcontractors or suppliers, you agree to notify us
      in writing of the nature of your concern and/or Dispute and to try in good faith
      using best efforts to resolve it in accordance with clause 22. If your concern or
      Dispute is not resolved after exhausting the internal complaints process outlined in
      clause 22, only then may you initiate an arbitration as set out in this clause 26.
      During the arbitration, the amount of any settlement offer made by you or PUNT shall
      not be disclosed to the arbitrator until after the arbitrator makes a final decision
      and award, if any.
    </p>
    <p>
      <strong>26.7 Arbitration</strong>
    </p>
    <p>
      <strong>a) We Both Agree to Arbitrate </strong>
      .
      <strong>
        By agreeing to these Terms and Conditions, both you and PUNT agree that any and
        all Disputes, including without limitation any question regarding the existence,
        validity, enforceability, or termination of these Terms and Conditions and/or this
        clause 26 (Dispute Resolution and Agreement to Arbitrate), shall be referred to
        and finally resolved by arbitration administered by the American Arbitration
        Association (AAA), the Rules of which are deemed to be incorporated by reference
        into this claus
      </strong>
      e. In agreeing to this binding commitment to arbitrate your Disputes, you agree that
      you waive any right to proceed in a court of law or to have your claims heard by a
      jury. The arbitration shall: (1) be conducted by a single, neutral arbitrator in the
      English language; (2) be held virtually and not in person for all proceedings
      related to the arbitration, except by mutual agreement of all parties; and (3) be
      limited to one deposition per party, except by mutual agreement of all parties.
      Furthermore, in cases where neither party’s claim(s) or counterclaim(s) exceed
      $25,000, both parties agree to waive an arbitration hearing and resolve the dispute
      solely through submissions of documents to the arbitrator. No award or procedural
      order made in the arbitration shall be published. The AAA rules, as well as
      instructions on how to file an arbitration proceeding with the AAA, appear at
      adr.org, or you may call the AAA at 1-800-778-7879.
    </p>
    <p>
      b) Except as may be required by law, neither a party nor an arbitrator may disclose
      the existence, content, or results of any arbitration hereunder without the prior
      written consent of both parties. All documents and information disclosed in the
      course of the arbitration shall be kept strictly confidential by the recipient and
      shall not be used by the recipient for any purpose other than for purposes of the
      arbitration or the enforcement of the arbitrator’s decision and award and shall not
      be disclosed except in confidence to persons who have a need to know for such
      purposes or as required by applicable law.
    </p>
    <p>
      c) Both you and PUNT agree not to make any argument that the AAA is an inconvenient
      forum or otherwise incompetent or without authority or jurisdiction to hear any
      Dispute, and expressly waive any and all such arguments.
    </p>
    <p>
      <strong>26.8. Arbitration to Proceed Individually</strong>
    </p>
    <p>
      a) You and PUNT agree that an arbitration or any other proceeding to resolve a
      Dispute shall proceed in an individual capacity only, and neither you nor PUNT may
      bring a Dispute as a Collective Action. UNLESS BOTH YOU AND PUNT AGREE, NO
      ARBITRATOR OR JUDGE MAY CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY
      COLLECTIVE ACTION.
    </p>
    <p>
      b) Without limiting the generality of clause 26.7(a) or the term Collective Action,
      and as an example only, a claim to resolve a Dispute against PUNT will be deemed a
      Collective Action if:
    </p>
    <p>
      i. claims are filed or pursued concurrently by or on behalf of two or more persons;
      and
    </p>
    <p>
      ii. counsel for the two or more persons is the same, or share fees, or coordinate in
      any way.
    </p>
    <p>
      c) For the purposes of this clause, the term ‘concurrently’ means that the claims
      are pending (filed but not resolved) at the same time.
    </p>
    <p>
      <strong>26.8.Waiver of Collective Action</strong>
    </p>
    <p>
      a) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER YOU NOR PUNT WILL BE
      ENTITLED TO PARTICIPATE IN ANY COLLECTIVE ACTION (AS DEFINED ABOVE), OR OTHERWISE
      CONSOLIDATE, JOIN, PARTICIPATE, REPRESENT OR COORDINATE DISPUTES (AS DEFINED ABOVE)
      WITH OR INVOLVING OTHER INDIVIDUALS OR ENTITIES, INCLUDING AS A REPRESENTATIVE OR
      MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.IN CONNECTION WITH ANY
      DISPUTE (AS DEFINED ABOVE), ALL SUCH RIGHTS ARE EXPRESSLY AND UNCONDITIONALLY
      WAIVED.
      <a href="https://www.lawinsider.com/clause/class-action-waiver">
        <strong>THE PARTIES</strong>
      </a>
      FURTHER AGREE THAT THE AAA RULES REFERENCED ABOVE SHALL EXCLUDE ANY RULES OR
      PROCEDURES GOVERNING OR PERMITTING COLLECTIVE ACTIONS (AS DEFINED ABOVE) OF ANY
      KIND.
    </p>
    <p>
      b) BY AGREEING TO THESE TERMS, YOU ACKNOWLEDGE THAT YOU AND PUNT EACH WAIVE THE
      RIGHT TO: (1) A JURY TRIAL; AND (2) PARTICIPATE IN A CLASS ACTION. IF A COURT
      DECIDES THAT APPLICABLE LAW PRECLUDES ENFORCEMENT OF ANY OF THIS PARAGRAPH’S
      LIMITATIONS AS TO A PARTICULAR CLAIM FOR RELIEF, THEN THAT CLAIM (AND ONLY THAT
      CLAIM) MUST BE SEVERED FROM THE ARBITRATION AND MAY BE BROUGHT IN COURT.
    </p>
    <p>
      c) NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS AND CONDITIONS, IN THE
      EVENT ALL OR ANY PORTION OF THIS CLAUSE 26 (DISPUTE RESOLUTION AND AGREEMENT TO
      ARBITRATE) IS FOUND TO BE INVALID OR LESS THAN FULLY ENFORCEABLE, THEN THE ENTIRETY
      OF CLAUSE 26 (EXCEPT THIS SENTENCE) MAY, UPON PUNT’S SOLE AND EXCLUSIVE ELECTION, BE
      DEEMED VOID AND AS HAVING NO EFFECT, SUBJECT TO PUNT’S RIGHT TO APPEAL THE
      LIMITATION OR INVALIDATION OF SUCH CLAUSE.
    </p>
    <p>
      <strong>26.9. Survival of Obligations</strong>
    </p>
    <p>
      THIS CLAUSE 26 (DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE) SURVIVES THE
      TERMINATION OF THESE TERMS AND CONDITIONS FOR ANY REASON.
    </p>
    <p>
      <strong>27. OTHER</strong>
    </p>
    <p>
      <strong>27.1. Entire Agreement</strong>
    </p>
    <p>
      These Terms and Conditions constitute the entire agreement between you and us with
      respect to your Participation and supersede all prior or contemporaneous
      communications and proposals, whether electronic, oral or written, between you and
      us with respect to your Participation.
    </p>
    <p>
      <strong>27.2. Amendments</strong>
    </p>
    <p>
      PUNT reserves the right to amend these Terms and Conditions, or to implement or
      amend any procedures, at any time. Any amendments will be published on the Platform
      and such changes will be binding and effective immediately. If you do not agree to
      the amended Terms and Conditions, you must stop using the Plaform.
    </p>
    <p>
      <strong>27.3. Tax</strong>
    </p>
    <p>
      You are solely responsible for any taxes which apply to any Prizes that you collect
      from your Participation.
    </p>
    <p>
      <strong>27.4. Force Majeure</strong>
    </p>
    <p>
      PUNT will not be liable or responsible for any failure to perform, or delay in
      performance of, any of our obligations under these Terms and Conditions that is
      caused by events outside of our reasonable control.
    </p>
    <p>
      <strong>27.5. No agency</strong>
    </p>
    <p>
      Nothing in these Terms and Conditions will be construed as creating any agency,
      partnership, trust arrangement, fiduciary relationship or any other form of joint
      enterprise between you and us.
    </p>
    <p>
      <strong>27.6. Severability</strong>
    </p>
    <p>
      If any of the Terms and Conditions are determined by any competent authority to be
      invalid, unlawful or unenforceable to any extent, such term, condition or provision
      will, to that extent, be severed from these Terms and Conditions. All remaining
      terms, conditions and provisions will continue to be valid to the fullest extent
      permitted by law. In such cases, the part deemed invalid or unenforceable will be
      amended in a manner consistent with the applicable law to reflect, as closely as
      possible, PUNT’s original intent.
    </p>
    <p>
      <strong>26.7. Explanation of Terms and Conditions</strong>
    </p>
    <p>
      We consider these Terms and Conditions to be open and fair. If you need any
      explanation regarding these Terms and Conditions or any other part of our Platform
      contact Customer Support via this form.
    </p>
    <p>The Terms and Conditions prevail over any communication via email or chat.</p>
    <p>All correspondence between you and us may be recorded.</p>
    <p>
      <strong>26.8. Assignment</strong>
    </p>
    <p>
      These Terms and Conditions are personal to you, and are not assignable, transferable
      or sub-licensable by you except with our prior written consent. We reserve the right
      to assign, transfer or delegate any of our rights and obligations hereunder to any
      third party without notice to you.
    </p>
    <p>
      <strong>26.9. Business Transfers</strong>
    </p>
    <p>
      In the event of a change of control, merger, acquisition, or sale of assets of PUNT,
      your Customer Account and associated data may be part of the assets transferred to
      the purchaser or acquiring party. In such an event, we will provide you with notice
      via email or via our Platform explaining your options with regard to the transfer of
      your Customer Account.
    </p>
    <p>
      <strong>26.10. Language</strong>
    </p>
    <p>
      These Terms and Conditions may be published in several languages for information
      purposes and ease of access by Customers but will all reflect the same principles.
      It is only the English version that is the legal basis of the relationship between
      you and us and in case of any discrepancy between a non-English version and the
      English version of these Terms and Conditions, the English version will prevail.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
};
</script>
