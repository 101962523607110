<template>
    <div class="testimonial-slide">
        <h3 class="slide-title">
            “{{title}}”
        </h3>
        <div class="slider-bottom">
            <div class="left-part" v-if=image >
                <img :src=image alt="slider-image" />
            </div>
            <div class="right-part">
                <h4 class="name">{{name}}</h4>
                <h4 class="date">{{date}}</h4>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Testimonials Item',
    props: {
        title: '',
        image: '',
        name: '',
        date: '',
    },
};
</script>