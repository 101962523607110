import { generateHexString } from '../../lib/random';
import { getFetch, postFetch } from './http';

const KenoTypes = {
  SET_NONCE: 'keno/SET_NONCE',
  UPDATE_CLIENT_SEED: 'keno/UPDATE_CLIENT_SEED',
  SET_SERVER_SEED_HASH: 'keno/SET_SERVER_SEED_HASH',
  HIDE_PF: 'keno/HIDE',
  SHOW_PF: 'keno/SHOW',
};

export default {
  namespaced: true,
  state: {
    popup: {
      visible: false,
    },
    seeds: {
      client: '',
      serverHash: '',
      nonce: 1,
    },
  },
  actions: {
    autoClientSeed({ dispatch, state }) {
      return;
      let p = Promise.resolve();
      if (!state.seeds.serverHash) {
        p = dispatch('refreshSeeds');
      }

      return p.then(() => {
        if (!state.seeds.client) {
          return dispatch('setNewClientSeed', {
            seed: generateHexString(30),
          });
        }
      });
    },
    refreshSeedInfo({ commit }) {
      return getFetch('keno/seeds', 'refreshing seed information').then(({ data }) => {
        commit(KenoTypes.SET_NONCE, data.nonce);
        commit(KenoTypes.SET_SERVER_SEED_HASH, data.server_seed_hash);
        commit(KenoTypes.UPDATE_CLIENT_SEED, data.client_seed);
      });
    },
    updateServerSeedHash(ctx, seed) {
      ctx.commit(KenoTypes.SET_SERVER_SEED_HASH, seed);
    },
    updateClientSeed(ctx, seed) {
      if (ctx.state.seeds.client === '') {
        ctx.commit(KenoTypes.UPDATE_CLIENT_SEED, seed);
      } else {
        ctx.actions
          .refreshSeeds(ctx)
          .then(() => ctx.commit(KenoTypes.UPDATE_CLIENT_SEED, seed));
      }
    },
    promptClientSeed({ commit, dispatch }) {
      window
        .swal({
          title: 'Create a new seed pair',
          text: 'Enter a phrase below to use as your client seed.',
          input: 'text',
          showCancelButton: true,
          // closeOnConfirm: false,
          animation: 'slide-from-top',
          inputPlaceholder: 'Client seed',
          inputValue: generateHexString(30),
        })
        .then((inputValue) => {
          if (inputValue === false) {
            return false;
          }

          if (inputValue === '') {
            window.swal('Whoops!', 'You need to write something!', 'error');
            return false;
          }

          dispatch('setNewClientSeed', { seed: inputValue }).then(({ clientSeed }) => {
            dispatch('hideProvablyFair');
            setTimeout(() => {
              window.swal.closeModal();
            }, 2000);
            window.swal({
              text: 'Your client seed is now set to ' + clientSeed,
              type: 'success',
            });
          });
        })
        .catch((err) => {
          if (err !== 'cancel' && err !== 'overlay') {
            window.swal({
              title: 'Unable to change the seed!',
              text: err.message || err,
              type: 'error',
            });
          }
        });
    },
    setNewClientSeed({ commit }, { seed }) {
      return postFetch(
        'keno/seeds',
        { action: 'update_client', client_seed: seed },
        'changing seed',
      ).then(({ data }) => {
        commit(KenoTypes.SET_NONCE, data.nonce);
        commit(KenoTypes.SET_SERVER_SEED_HASH, data.server_seed_hash);
        commit(KenoTypes.UPDATE_CLIENT_SEED, data.client_seed);
        return data;
      });
    },
    refreshSeeds({ commit, dispatch }) {
      return postFetch(
        'keno/seeds',
        { action: 'refresh' },
        'refreshing the server seed',
      ).then(() => {
        dispatch('refreshSeedInfo');
      });
    },
    hideProvablyFair({ commit }) {
      commit(KenoTypes.HIDE_PF);
    },
    showProvablyFair({ commit }) {
      commit(KenoTypes.SHOW_PF);
    },
    updateNonce({ commit, getters }) {
      commit(KenoTypes.SET_NONCE, getters.seedPairNonce + 1);
    },
  },
  getters: {
    clientSeed: (state) => state.seeds.client,
    serverSeedHash: (state) => state.seeds.serverHash,
    seedPairNonce: (state) => state.seeds.nonce,
    shouldDisplayPF: (state) => state.popup.visible,
  },
  mutations: {
    [KenoTypes.SET_NONCE](state, nonce) {
      state.seeds.nonce = nonce;
    },
    [KenoTypes.SET_SERVER_SEED_HASH](state, seedHash) {
      state.seeds.serverHash = seedHash;
    },
    [KenoTypes.UPDATE_CLIENT_SEED](state, seed) {
      state.seeds.client = seed;
    },
    [KenoTypes.HIDE_PF](state) {
      state.popup.visible = false;
    },
    [KenoTypes.SHOW_PF](state) {
      state.popup.visible = true;
    },
  },
};
