import { defineStore } from 'pinia';

export const historyStore = defineStore('history', {
  state: () => {
    return {
      page: 1,
      pageLength: 11,
      tab: 'all',
      selectedTab: 0,
      tables: {
        mine: [],
        all: [],
        big: [],
      },
      next: {
        mine: 0,
        all: 0,
        big: 0,
      },
      loaded: {
        mine: false,
        all: false,
        big: false,
      },
    };
  },
  getters: {},
  actions: {},
});
