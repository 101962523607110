<template>
  <punt-modal size="login-register-wrapper rounded-md" spacing="0" :onClose="close">
    <template #body>
      <div class="flex flex-col md:flex-row">
        <div class="w-full pb-4 rounded-md">
          <div
            class="hidden text-sm font-medium text-center flex justify-between border-b border-line-color">
            <ul class="flex flex-wrap -mb-px">
              <li class="mr-2">
                <button
                  v-on:click="
                    $router.replace({ path: $route.path, query: { tab: 'login' } })
                  "
                  :class="{
                    '!text-white rounded-t-lg border-b-2 border-[#ff3860]':
                      activeTab === 'login',
                  }"
                  class="inline-block p-4 font-semibold text-text-dim-color">
                  Login
                </button>
              </li>
              <li class="mr-2">
                <button
                  v-on:click="
                    $router.replace({ path: $route.path, query: { tab: 'register' } })
                  "
                  :class="{
                    '!text-white rounded-t-lg border-b-2 border-[#ff3860]':
                      activeTab === 'register',
                  }"
                  class="inline-block p-4 font-semibold text-text-dim-color">
                  Register
                </button>
              </li>
            </ul>
            <button v-on:click="close" class="p-2 hover:opacity-70 text-slate-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <section>
            <button
              v-on:click="close"
              class="absolute right-[10px] p-2 hover:opacity-70 text-slate-300 top-[8px] cursor-pointer z-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
            <LoginForm v-if="activeTab === 'login'" />
            <RegisterForm v-else />
            <p class="mt-4 text-xs text-center text-text-dim-color">
              This site is protected by reCAPTCHA and the Google
              <a
                href="https://policies.google.com/privacy"
                class="opacity-70"
                target="_blank"
                >Privacy Policy</a
              >
              and
              <a
                href="https://policies.google.com/terms"
                class="opacity-70"
                target="_blank"
                >Terms of Service</a
              >
              apply.
            </p>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import LoginForm from './LoginForm.vue';
import RegisterForm from './RegisterForm.vue';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginRegister',
  components: {
    PuntModal,
    LoginForm,
    RegisterForm,
  },
  setup() {
    return {};
  },
  data() {
    return {
      activeTab: 'login',
    };
  },
  created() {
    if (this.$route.meta.tab === 'register') {
      this.activeTab = 'register';
    }
    if (this.isLoggedIn) {
      this.close();
    }
  },
  methods: {
    close() {
      const { tab, ...query } = this.$route.query;
      this.$router.push({ query });
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  watch: {
    '$route.query.tab': {
      handler: function (newState) {
        if (newState === 'login' || newState === 'register') {
          this.activeTab = newState;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
