<template>
  <Loader v-if="loading" />
  <div v-else class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 sm:px-6 sm:px-8 max-[767px]:py-0">
      <div>
        <table class="w-full table-auto md:table-fixed">
          <thead class="">
            <tr>
              <th class="text-sm text-left text-gray-400 px-2" scope="col">Registered</th>

              <th class="px-6 py-3 text-sm text-left text-gray-400" scope="col">
                Username
              </th>
              <th
                class="text-sm text-left text-gray-400 px-2 whitespace-nowrap"
                scope="col">
                Total Purchases
              </th>
              <th
                class="text-sm text-left text-gray-400 px-2 whitespace-nowrap"
                scope="col">
                Last Purchase
              </th>
              <th
                class="text-sm text-left text-gray-400 px-2 whitespace-nowrap min-w-[125px]"
                scope="col">
                Total Played
              </th>
              <th class="text-sm text-left text-gray-400 px-2 min-w-[125px]" scope="col">
                Commission
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, i) in registeredUsers"
              v-bind:key="i"
              class="rounded-lg odd:bg-secondary-light odd:opacity-90">
              <td class="px-6 py-4 text-sm font-medium text-slate-300">
                <label
                  class="flex flex-row items-center justify-center w-full text-center">
                  {{ this.formattedTime(user.registered_on) }}
                </label>
              </td>
              <td class="px-6 py-4 text-sm font-medium text-slate-300">
                <label class="flex flex-row w-full text-left">
                  {{ transformUsernameToStar(user.username) }}
                </label>
              </td>
              <td class="text-gray-400">
                <span class="flex w-full mt-1 text-sm gap-1 items-center">
                  <label class="flex flex-row w-full text-left">
                    {{ user.total_purchase }}
                  </label>
                </span>
              </td>
              <td class="text-gray-400">
                <span class="flex w-full mt-1 text-sm gap-1 items-center">
                  <label class="flex flex-row w-full text-left">
                    {{
                      user.last_purchase !== null
                        ? this.formattedTime(user.last_purchase)
                        : '--'
                    }}
                  </label>
                </span>
              </td>
              <td class="text-gray-400">
                <span class="flex w-full text-sm gap-1 items-center">
                  <label class="text-left">
                    {{ user.total_played.gc }}
                  </label>
                  <img :src="getIcon('GC')" alt="Currency Icon" class="inline w-4 h-4" />
                </span>

                <span class="flex w-full mt-1 text-sm gap-1 items-center">
                  <label class="text-left">
                    {{ user.total_played.sc }}
                  </label>
                  <img :src="getIcon('SC')" alt="Currency Icon" class="inline w-4 h-4" />
                </span>
              </td>
              <td class="text-gray-400">
                <span class="flex w-full text-sm gap-1 items-center">
                  {{ balS.getCurrencyContext(parseFloat(user.commissions.GC)) }}
                  <img :src="getIcon('GC')" alt="Currency Icon" class="inline w-4 h-4" />
                </span>
                <span class="flex w-full mt-1 text-sm gap-1 items-center">
                  {{ balS.getCurrencyContext(parseFloat(user.commissions.SC)) }}
                  <img :src="getIcon('SC')" alt="Currency Icon" class="inline w-4 h-4" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
    class="flex items-center justify-center max-[575px]:mt-4 tailwind-custom-pagination">
    <TailwindPagination
      :data="pagination"
      @pagination-change-page="getReferedUsers"
      :active-classes="activeCls" />
  </div>
  <div v-if="!loading" class="flex items-center justify-center">
    <p>{{ `Showing ${registeredUsers.length} of ${pagination.total} records` }}</p>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import { balanceStore } from '@/store/pinia/balanceStore';
import { mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import { affiliatesStore } from '@/store/pinia/affiliatesStore';
import Loader from '@/components/Shared/Loader.vue';

export default {
  name: 'ReferedUsers',
  components: { Loader },
  setup() {
    const balS = balanceStore();
    const affStore = affiliatesStore();
    return { balS, affStore };
  },
  data() {
    return {
      registeredUsers: [],
      activeCls: ['active-page'],
      pagination: {
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
      },
      loading: true,
    };
  },
  mounted() {
    if (this.me.id) {
      this.getReferedUsers();
    }
  },
  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    formattedTime(timestamp) {
      return dateFormat(new Date(timestamp), 'yyyy:mm:dd');
    },
    getReferedUsers(page = 1) {
      this.loading = true;
      const perPage = 20;
      try {
        this.affStore.fetchReferedUsers(this.me.id, page, perPage).then((res) => {
          this.registeredUsers = res.data.data;
          this.pagination = res.data;
          this.loading = false;
        });
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    transformUsernameToStar(str) {
      const length = str.length;
      if (length <= 3) return str;
      const mask = '*'.repeat(length - 3);
      return mask + str.slice(-3);
    },
  },

  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'userBalance']),
  },
};
</script>
