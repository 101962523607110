<template>
  <span
    v-on:click.prevent="debouncedOnClick"
    class="button bac-chip-item bg-chip-bg transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110"
    :class="isSelected ? `bac-chip-selected` : ''"
    :style="`appearance: none; margin: 1px; padding: 1px; height: ${size.height}px; width: ${size.width}px; background-color: ${color};`">
    <span
      class="bac-chip-item-content text-white rounded-full brightness-[80%] bg-inherit h-[25px] w-[25px]"
      >{{ display }}</span
    >
  </span>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'Chip',
  props: {
    selected: {},
    onclick: {},
    color: {},
    value: {},
    display: {},
    size: {
      type: Object,
      default: () => ({
        width: 35,
        height: 35,
      }),
    },
  },
  created() {
    this.debouncedOnClick = debounce(
      () => {
        this.onclick(this.value);
      },
      400,
      {
        leading: true,
        trailing: false,
      },
    );
  },
  computed: {
    isSelected() {
      return this.selected === this.value;
    },
  },
  data: () => ({
    debouncedOnClick: () => {},
  }),
};
</script>

<style>
.bac-chip-item {
  color: #591700;
  display: inline-block;
  position: relative;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media (max-width: 200px) {
  .bac-chip-item {
    display: inline-block;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }
}

.bac-chip-item-content {
  position: absolute;
  font-size: 0.6em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
  pointer-events: none;
}

.bac-chip-selected {
  border: 1px whitesmoke solid;
}
</style>
