<template>
  <header class="site-header "  > 
        <div class="container">
      <div class="row flex justify-between items-center">
        <div class="col-lg-4">
          <router-link to="/" class="logo">
            <img :src="getImage('logo.svg')" alt="" />
          </router-link>
        </div>
<!--        <div class="col-lg-4">
          <div class="menu-links">
            <ul class="flex justify-between">
              <li class="active" ><button @click="scrollToElement('main')">Home</button></li>
              <li><button  @click="scrollToElement('games')">Games</button></li>
              <li><button  @click="scrollToElement('latestWinner')">Winners</button></li>
              &lt;!&ndash; <li><a href="" @click="scrollToElement('main')">Reactions</a></li>&ndash;&gt;
              <li><button  @click="scrollToElement('testimonials')">Testimonials</button></li>
            </ul>
          </div>
        </div>-->
        <div class="col-lg-4">
          <div class="header-buttons">
            <ul class="flex justify-between">
              <li>
                <button
                  class="header-btn log-btn"
                  href=""
                  v-on:click="
                    $router.push({ path: $route.path, query: { tab: 'login' } })
                  ">
                  Log in
                </button>
              </li>
              <li>
                <button
                  class="header-btn sign-btn"
                  href=""
                  v-on:click="
                    $router.push({ path: $route.path, query: { tab: 'register' } })
                  ">
                  Sign up
                </button>
              </li>
            </ul>
          </div>
          <button
            class="toggle-button"
            @click="openMenu"
            :class="menuopen ? 'active' : ''">
            <div class="bar bar-1"></div>
            <div class="bar bar-2"></div>
            <div class="bar bar-3"></div>
          </button>
        </div>
      </div>
    </div>
  </header>
  <div
    class="mobile-toggle-menu flex flex-col justify-between"
    :class="menuopen ? 'active' : ''">
<!--    <div class="menu-links">-->
<!--      <ul>-->
<!--        <li class="active"><a href="">Home</a></li>-->
<!--        <li><a href="">Games</a></li>-->
<!--        <li><a href="">Winners</a></li>-->
<!--        <li><a href="">Reactions</a></li>-->
<!--        <li><a href="">Testimonials</a></li>-->
<!--      </ul>-->
<!--    </div>-->
    <div class="header-buttons">
      <ul>
        <li>
          <button
            class="header-btn sign-btn"
            href=""
            v-on:click="
              this.menuopen = false;
              $router.push({ path: $route.path, query: { tab: 'register' } });
            ">
            Sign up
          </button>
        </li>
        <li>
          <button
            class="header-btn log-btn"
            href=""
            v-on:click="
              this.menuopen = false;
              $router.push({ path: $route.path, query: { tab: 'login' } });
            ">
            Log in
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';

export default {
  name: 'PHeader',
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
   
  },
  mounted: function () {
    setTimeout(function () {
      $('.site-header').addClass('activeanimation');
    }, 1000);
    if($(window).outerWidth()<768)
    {
      $('body').scroll( this.onScroll)
    }
    else 
    {
      $('#mainscrollcontainer').scroll( this.onScroll)
    }
  },
  data() {
    return {
      menuopen: false,
      scrolled:false
    };
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
    openMenu() {
      this.menuopen = !this.menuopen;
    },
    onScroll(e) {
     var scrollcap = 200;
      if($(window).outerWidth()<768)
      {
        scrollcap=10;
      }
      if( !$('.site-header').hasClass('active') && e.currentTarget.scrollTop >scrollcap  )
       $('.site-header').addClass('active');
      else if ($('.site-header').hasClass('active') && e.currentTarget.scrollTop < scrollcap)
      {
        $('.site-header').removeClass('active')
      }
    },
    scrollToElement(id) {
      const container = document.getElementById(id);
      container.scrollIntoView({ behavior: 'smooth' });
    },
    
  }
  
};
</script>
