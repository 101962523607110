<template>
  <div class="sm:-mx-6 lg:-mx-8">
    <div class="inline-block w-full py-2 sm:px-6">
      <div class="rounded-lg border-slate-300 px-4 text-sm text-slate-300">
        <div class="box">
          <h2 class="subtitle font-bold text-lg" style="margin-top: 0">
            Bytes to Floats
          </h2>
          <p>
            The output of the Random Number Generator (byteGenerator) function is a
            hexadecimal 32-byte hash. As explained under the cursor implementation, we use
            4 bytes of data to generate a single game result. Each set of 4 bytes are used
            to generate floats between 0 and 1 (4 bytes are used instead of one to ensure
            a higher level of precision when generating the float.) It is with these
            generated floats that we derive the formal output of the provable fair
            algorithm before it is translated into game events.
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow-x: auto;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
            ">
            <code>
              <span  style="color: rgb(136, 136, 136);">// Convert the hash output from the rng byteGenerator to floats</span>
              <span class="hljs-function">
                <span style="font-weight: bold;">function</span>
                <span style="color: rgb(136, 0, 0); font-weight: bold;">generateFloats</span> (<span class="hljs-params">{ serverSeed, clientSeed, nonce, cursor, count }</span>) </span>{
  <span style="color: rgb(136, 136, 136);">// Random number generator function</span>
  <span style="font-weight: bold;">const</span> rng = byteGenerator({ serverSeed, clientSeed, nonce, cursor });
  <span
      style="color: rgb(136, 136, 136);"
  >// Declare bytes as empty array</span>
  <span style="font-weight: bold;">const</span> bytes = [];

  <span
      style="color: rgb(136, 136, 136);"
  >// Populate bytes array with sets of 4 from RNG output</span>
  <span style="font-weight: bold;">while</span> (bytes.length &lt; count * <span style="color: rgb(136, 0, 0);">4</span>) {
    bytes.push(rng.next().value);
  }

  <span
      style="color: rgb(136, 136, 136);"
  >// Return bytes as floats using lodash reduce function</span>
  <span style="font-weight: bold;">return</span> _.chunk(bytes, <span style="color: rgb(136, 0, 0);">4</span>).map(<span
                class="hljs-function"><span class="hljs-params">bytesChunk</span> =&gt;</span>
    bytesChunk.reduce(<span class="hljs-function">(<span class="hljs-params">result, value, i</span>) =&gt;</span> {
      <span style="font-weight: bold;">const</span> divider = <span
                style="color: rgb(136, 0, 0);">256</span> ** (i + <span style="color: rgb(136, 0, 0);">1</span>);
      <span style="font-weight: bold;">const</span> partialResult = value / divider;
      <span style="font-weight: bold;">return</span> result + partialResult;
    }, <span style="color: rgb(136, 0, 0);">0</span>)
  );
};</code></pre>

          <h2 class="subtitle font-bold text-lg my-2">Floats to Game Events</h2>
          <p>
            Where the process of generating random outputs is universal for all our games,
            it's at this point in the game outcome generation where a unique procedure is
            implemented to determine the translation from floats to game events.
          </p>
          <p>
            The randomly float generated is multiplied by the possible remaining outcomes
            of the particular game being played. For example: In a game that uses a 52
            card deck, this would simply be done by multiplying the float by 52. The
            result of this equation is then translated into a corresponding game event.
            For games where multiple game events are required, this process continues
            through each corresponding 4 bytes in the result chain that was generated
            using the described byteGenerator function.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Conversions',
};
</script>
