<template>
  <div class="sm:-mx-6 lg:-mx-8">
    <div class="inline-block w-full py-2 sm:px-6">
      <div class="rounded-lg border-slate-300 px-4 text-sm text-slate-300">
        <div class="box">
          <p style="margin-top: 0">
            Game events are translation of the randomly generated floats into a relatable
            outcome that is game specific. This includes anything from the outcome of a
            dice roll to the order of the cards in a deck, or even the location of every
            bomb in a game of mines.
          </p>
          <p>
            Below is a detailed explanation as to how we translate floats into events for
            each particular different house game on our platform.
          </p>
          <h2 class="font-bold text-lg my-2">Blackjack & War</h2>
          <p>
            In a standard deck of cards, there are 52 unique possible outcomes. When it
            comes to playing Blackjack and War on our platform, we utilise an unlimited
            amount of decks when generating the game event, and therefore each turn of a
            card always has the same probability. To calculate this, we multiply each
            randomly generated float by 52, and then translate that result into a
            particular card, based on the following index:
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow: scroll;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code><span style="color: rgb(136, 136, 136);">// Index of 0 to 51 : ♦2 to ♣A</span>
<span style="font-weight: bold;">const</span> CARDS = [
  ♦<span style="color: rgb(136, 0, 0);">2</span>, ♥<span style="color: rgb(136, 0, 0);">2</span>, ♠<span style="color: rgb(136, 0, 0);">2</span>, ♣<span style="color: rgb(136, 0, 0);">2</span>, ♦<span style="color: rgb(136, 0, 0);">3</span>, ♥<span style="color: rgb(136, 0, 0);">3</span>, ♠<span style="color: rgb(136, 0, 0);">3</span>, ♣<span style="color: rgb(136, 0, 0);">3</span>, ♦<span style="color: rgb(136, 0, 0);">4</span>, ♥<span style="color: rgb(136, 0, 0);">4</span>,
  ♠<span style="color: rgb(136, 0, 0);">4</span>, ♣<span style="color: rgb(136, 0, 0);">4</span>, ♦<span style="color: rgb(136, 0, 0);">5</span>, ♥<span style="color: rgb(136, 0, 0);">5</span>, ♠<span style="color: rgb(136, 0, 0);">5</span>, ♣<span style="color: rgb(136, 0, 0);">5</span>, ♦<span style="color: rgb(136, 0, 0);">6</span>, ♥<span style="color: rgb(136, 0, 0);">6</span>, ♠<span style="color: rgb(136, 0, 0);">6</span>, ♣<span style="color: rgb(136, 0, 0);">6</span>,
  ♦<span style="color: rgb(136, 0, 0);">7</span>, ♥<span style="color: rgb(136, 0, 0);">7</span>, ♠<span style="color: rgb(136, 0, 0);">7</span>, ♣<span style="color: rgb(136, 0, 0);">7</span>, ♦<span style="color: rgb(136, 0, 0);">8</span>, ♥<span style="color: rgb(136, 0, 0);">8</span>, ♠<span style="color: rgb(136, 0, 0);">8</span>, ♣<span style="color: rgb(136, 0, 0);">8</span>, ♦<span style="color: rgb(136, 0, 0);">9</span>, ♥<span style="color: rgb(136, 0, 0);">9</span>,
  ♠<span style="color: rgb(136, 0, 0);">9</span>, ♣<span style="color: rgb(136, 0, 0);">9</span>, ♦<span style="color: rgb(136, 0, 0);">10</span>, ♥<span style="color: rgb(136, 0, 0);">10</span>, ♠<span style="color: rgb(136, 0, 0);">10</span>, ♣<span style="color: rgb(136, 0, 0);">10</span>, ♦J, ♥J, ♠J,
  ♣J, ♦Q, ♥Q, ♠Q, ♣Q, ♦K, ♥K, ♠K, ♣K, ♦A,
  ♥A, ♠A, ♣A
];

<span style="color: rgb(136, 136, 136);">// Game event translation</span>
<span style="font-weight: bold;">const</span> card = CARDS[<span style="color: rgb(57, 115, 0);">Math</span>.floor(float * <span style="color: rgb(136, 0, 0);">52</span>)];</code></pre>

          <h2 class="font-bold text-lg my-2">Dice</h2>
          <p>
            In our version of dice, we cover a possible roll spread of 00.00 to 100.00,
            which has a range of 10,001 possible outcomes. The game event translation is
            done by multiplying the float by number of possible outcomes and then dividing
            by 100 so that the resulting number fits the constraints of our stated dice
            range.
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow: scroll;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code><span style="color: rgb(136, 136, 136);">// Game event translation</span>
<span style="font-weight: bold;">const</span> roll = (float * <span style="color: rgb(136, 0, 0);">10001</span>) / <span style="color: rgb(136, 0, 0);">100</span>;</code></pre>
          <h2 class="font-bold text-lg my-2">Limbo</h2>
          <p>
            When it comes to Limbo, we use a two-step process. Firstly, we take the float
            and multiply it by both the maximum possible multiplier and the house edge.
            Then, in order to generate a game event that has
            <a
              href="https://en.wikipedia.org/wiki/Probability_distribution"
              target="_blank"
              >probability distribution</a
            >, we divide the maximum possible multiplier by the result of the first step
            to create the game event in the form of a crash point.
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow: scroll;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code><span style="color: rgb(136, 136, 136);">// Game event translation with houseEdge</span>
<span style="font-weight: bold;">const</span> floatPoint = <span style="color: rgb(57, 115, 0);">Math</span>.pow(2, 32) / (float * <span style="color: rgb(57, 115, 0);">Math</span>.pow(2, 32)) * houseEdge;

<span style="color: rgb(136, 136, 136);">// Crash point rounded down to required denominator</span>
<span style="font-weight: bold;">const</span> crashPoint = <span style="color: rgb(57, 115, 0);">Math</span>.floor(floatPoint * <span style="color: rgb(136, 0, 0);">100</span>) / <span style="color: rgb(136, 0, 0);">100</span>;
</code></pre>

          <h2 class="font-bold text-lg my-2">Plinko</h2>
          <p>
            For any game of Plinko, the generated outcome is based on the path of the
            falling ball. The game event determines the direction of the falling ball for
            each level in the falling process. Players can choose between 8 and 16 pins of
            play, which determines the number of game events required to generate a
            complete path from top to bottom. Since there are only two possible directions
            (left or right) the translation is done by multiplying each float by 2, which
            maps to the following index:
          </p>
          <pre
            style="
              display: block;
              overflow: scroll;
              margin-top: 20px;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code>
<span style="color: rgb(136, 136, 136);">// Index of 0 to 1 : left to right</span>
<span style="font-weight: bold;">const</span> SQUARES = [
  <span style="color: rgb(136, 0, 0);">left</span>, <span style="color: rgb(136, 0, 0);">right</span>
  ];

<span style="color: rgb(136, 136, 136);">// Game event translation</span>
<span style="font-weight: bold;">const</span> direction  = CARDS[<span style="color: rgb(57, 115, 0);">Math</span>.floor(float * <span style="color: rgb(136, 0, 0);">2</span>)];
</code></pre>
          <br />

          <h2 class="font-bold text-lg my-2">Mines</h2>
          <p>
            A mine game is generated with 24 separate game events, in the form of mines on
            the board. Each float is multiplied by the number of possible unique tiles
            still remaining on the board. This is done by subtracting the number of tiles
            remaining by 1 for each iteration of game event result generated using the
            corresponding float provided. The location of the mine is plotted using a grid
            position from left to right, top to bottom. The fisher-yates shuffle
            implementation is utilised to prevent duplicate possible hits being generated.
            Between 1 and 24 game event results are used, based on the settings chosen.
          </p>
          <br />

          <h2 class="font-bold text-lg my-2">Keno</h2>
          <p>
            Traditional Keno games require the selection of 10 possible game events in the
            form of hits on a board. To achieve this, we multiply each float by the number
            of possible unique squares that exist. Once a hit has been placed, it cannot
            be chosen again, which changes the pool size of the possible outcomes. This is
            done by subtracting the size of possible maximum outcomes by 1 for each
            iteration of game event result generated using the corresponding float
            provided, using the following index:
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow: scroll;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code>
<span style="color: rgb(136, 136, 136);">// Index of 0 to 39 : 1 to 40</span>
<span style="font-weight: bold;">const</span> SQUARES = [
  <span style="color: rgb(136, 0, 0);">1</span>, <span style="color: rgb(136, 0, 0);">2</span>, <span style="color: rgb(136, 0, 0);">3</span>, <span style="color: rgb(136, 0, 0);">4</span>, <span style="color: rgb(136, 0, 0);">5</span>, <span style="color: rgb(136, 0, 0);">6</span>, <span style="color: rgb(136, 0, 0);">7</span>, <span style="color: rgb(136, 0, 0);">8</span>, <span style="color: rgb(136, 0, 0);">9</span>, <span style="color: rgb(136, 0, 0);">10</span>,
  <span style="color: rgb(136, 0, 0);">11</span>, <span style="color: rgb(136, 0, 0);">12</span>, <span style="color: rgb(136, 0, 0);">13</span>, <span style="color: rgb(136, 0, 0);">14</span>, <span style="color: rgb(136, 0, 0);">15</span>, <span style="color: rgb(136, 0, 0);">16</span>, <span style="color: rgb(136, 0, 0);">17</span>, <span style="color: rgb(136, 0, 0);">18</span>, <span style="color: rgb(136, 0, 0);">19</span>, <span style="color: rgb(136, 0, 0);">20</span>,
  <span style="color: rgb(136, 0, 0);">21</span>, <span style="color: rgb(136, 0, 0);">22</span>, <span style="color: rgb(136, 0, 0);">23</span>, <span style="color: rgb(136, 0, 0);">24</span>, <span style="color: rgb(136, 0, 0);">25</span>, <span style="color: rgb(136, 0, 0);">26</span>, <span style="color: rgb(136, 0, 0);">27</span>, <span style="color: rgb(136, 0, 0);">28</span>, <span style="color: rgb(136, 0, 0);">29</span>, <span style="color: rgb(136, 0, 0);">30</span>,
  <span style="color: rgb(136, 0, 0);">31</span>, <span style="color: rgb(136, 0, 0);">32</span>, <span style="color: rgb(136, 0, 0);">33</span>, <span style="color: rgb(136, 0, 0);">34</span>, <span style="color: rgb(136, 0, 0);">35</span>, <span style="color: rgb(136, 0, 0);">36</span>, <span style="color: rgb(136, 0, 0);">37</span>, <span style="color: rgb(136, 0, 0);">38</span>, <span style="color: rgb(136, 0, 0);">39</span>, <span style="color: rgb(136, 0, 0);">40</span> ];

<span style="color: rgb(136, 136, 136);">// Game event translation</span>
<span style="font-weight: bold;">const</span> hit = SQUARES[<span style="color: rgb(57, 115, 0);">Math</span>.floor(float * <span style="color: rgb(136, 0, 0);">40</span>)];
</code></pre>
          <br />

          <p>
            The original
            <a
              href="https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle"
              target="_blank"
              >Fisher-Yates shuffle</a
            >
            implementation is utilised to prevent duplicate possible hits being generated.
          </p>

          <h2 class="font-bold text-lg my-2">Roulette</h2>
          <p>
            Our Roulette is derived from the American version of the game where the wheel
            consists of 38 possible different pockets, ranging from 0 to 36 with
            additional pocket 00. The game event is calculated by multiplying the float by
            38 and then translated into a corresponding pocket using the following index:
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow: scroll;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
              max-height: 300px;
            "><code><span style="color: rgb(136, 136, 136);">// Index of 0 to 37, 37 represents 00</span>
<span style="font-weight: bold;">const</span> POCKETS = [
  <span style="color: rgb(136, 0, 0);">0</span>, <span style="color: rgb(136, 0, 0);">1</span>, <span style="color: rgb(136, 0, 0);">2</span>, <span style="color: rgb(136, 0, 0);">3</span>, <span style="color: rgb(136, 0, 0);">4</span>, <span style="color: rgb(136, 0, 0);">5</span>, <span style="color: rgb(136, 0, 0);">6</span>, <span style="color: rgb(136, 0, 0);">7</span>, <span style="color: rgb(136, 0, 0);">8</span>, <span style="color: rgb(136, 0, 0);">9</span>,
  <span style="color: rgb(136, 0, 0);">10</span>, <span style="color: rgb(136, 0, 0);">11</span>, <span style="color: rgb(136, 0, 0);">12</span>, <span style="color: rgb(136, 0, 0);">13</span>, <span style="color: rgb(136, 0, 0);">14</span>, <span style="color: rgb(136, 0, 0);">15</span>, <span style="color: rgb(136, 0, 0);">16</span>, <span style="color: rgb(136, 0, 0);">17</span>, <span style="color: rgb(136, 0, 0);">18</span>, <span style="color: rgb(136, 0, 0);">19</span>,
  <span style="color: rgb(136, 0, 0);">20</span>, <span style="color: rgb(136, 0, 0);">21</span>, <span style="color: rgb(136, 0, 0);">22</span>, <span style="color: rgb(136, 0, 0);">23</span>, <span style="color: rgb(136, 0, 0);">24</span>, <span style="color: rgb(136, 0, 0);">25</span>, <span style="color: rgb(136, 0, 0);">26</span>, <span style="color: rgb(136, 0, 0);">27</span>, <span style="color: rgb(136, 0, 0);">28</span>, <span style="color: rgb(136, 0, 0);">29</span>,
  <span style="color: rgb(136, 0, 0);">30</span>, <span style="color: rgb(136, 0, 0);">31</span>, <span style="color: rgb(136, 0, 0);">32</span>, <span style="color: rgb(136, 0, 0);">33</span>, <span style="color: rgb(136, 0, 0);">34</span>, <span style="color: rgb(136, 0, 0);">35</span>, <span style="color: rgb(136, 0, 0);">36</span>, <span style="color: rgb(136, 0, 0);">37</span> ];

<span style="color: rgb(136, 136, 136);">// Game event translation</span>
<span style="font-weight: bold;">const</span> pocket = POCKETS[<span style="color: rgb(57, 115, 0);">Math</span>.floor(float * <span style="color: rgb(136, 0, 0);">38</span>)];</code></pre>
          <h2 class="font-bold text-lg my-2">Slots</h2>
          <p>
            The game event number is calculated by multiplying the float by the possible
            outcomes in the reel. The first 4 reels have a length of 30 possible outcomes,
            whilst the last reel has 41. The game event determines the central stop
            position for each reel. This game consists of 5 game event numbers, until the
            case of a bonus round, where more are generated.
          </p>
          <h2 class="font-bold text-lg my-2 hidden">Crash</h2>
          <p class="hidden" color="#c9c9c9">
            See the
            <a target="blank" href="#">BitcoinTalk seeding thread</a>
            to learn about how we utilise the salt hash based provable fairness modal for
            this particular game.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GameEvents',
};
</script>
