import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/win_slot.mp3';
import spin from '../../assets/media/spin_slot.mp3';

const betSound = new Audio(bet);
const winSound = new Audio(win);
const spinSound = new Audio(spin);

betSound.load();
winSound.load();
spinSound.load();

export { betSound, winSound, spinSound };
