<template>
  <div class="tabSlider px-1 md:px-4 py-2">
    <div
        class="text-sm font-medium text-center text-gray-500 w-full border-b border-line-color mt-2 sm:mt-0 flex flex-row justify-between">
        <ul class="flex flex-wrap -mb-px space-x-2 ">
          <li v-for="page in pages">
            <button
              :class="{
                '!text-white rounded-t-lg border-b-2 border-onprimary': isActive(
                  page.page,
                ),
                'inline-flex items-center gap-2':page.page == 'live'
              }"
              class="inline-block px-1 py-2 sm:p-4 font-semibold text-text-dim-color"
              v-on:click="activeTab = page.page"
              >
              <span class="relative flex h-2 w-2" v-if="page.page == 'live'">
                <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-500 opacity-75">
                </span><span class="relative inline-flex h-2 w-2 rounded-full bg-green-500"></span>
              </span>
              {{ page.label }}
            </button>
          </li>
        </ul>
        <div class="flex flex-row items-center" v-show="activeTab != 'live'">
          <button type="button" class="px-2 py-1 md:px-4 md:py-2 text-gray-300 border cursor-pointer rounded-l-xl border-slate-600 hover:opacity-60" v-on:click="scrollToPrev">
            <img src="/src/assets/3d/right-arrow.png" class="h-4 -rotate-180">
          </button>
          <button type="button" class="px-2 py-1 md:px-4 md:py-2 text-gray-300 border cursor-pointer rounded-r-xl border-slate-600 hover:opacity-60" v-on:click="scrollToNext">
            <img src="/src/assets/3d/right-arrow.png" class="h-4">
          </button>
        </div>
      </div>
      <div class="flex flex-col p-4 max-[480px]:p-0">
        <section class="flex flex-col text-slate-300">
          <div class="flex flex-row gap-2 mt-2 no-scrollbar snap-x snap-mandatory  card-content overflow-hidden overflow-x-auto" ref="sectionRef">
            <WinTab :type="'live'" v-if="activeTab === 'live'" />  
            <WinTab :type="'week'" v-if="activeTab === 'week'" />  
            <WinTab :type="'24h'" v-if="activeTab === '24h'" />
            
          </div>
        </section>
      </div>

  </div>
</template>
<script>
import WinTab from './WinTab.vue';
export default {
  name:'BetWins',
  data() {
    return {
      activeTab: 'live',
      pos : 0,
      nextDisable:false,
      prevDisable:false,
    };
  },
  components: {
    WinTab,
  },
  computed: {
    pages()
    {
      let pages =[
        { page: 'live', label: 'Live' },
        /*{page: 'buy', label: 'Buy Gold Coins'},*/
        { page: 'week', label: 'Week' },
        { page: '24h', label: '24h' },
       
      ];
      return pages;
    }
    
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
      });
    },
    isActive(tab) {
      return this.activeTab.toLowerCase() === tab.toLowerCase();
    },
    scrollToNext() {
      const section = this.$refs.sectionRef;
      if (section) {
        if(section.scrollWidth > section.clientWidth && section.scrollLeft !== section.clientWidth){
          this.pos = section.scrollLeft + 500
        }
        section.scrollTo({left: this.pos, behavior: 'smooth' });
      }
      this.prevDisable = false
      if(section.scrollLeft === section.clientWidth){
          this.nextDisable = true
        }
    },
    scrollToPrev() {
      const section = this.$refs.sectionRef;
      if (section) {
        if(section.scrollWidth > section.clientWidth && section.scrollLeft !== section.clientWidth){
          this.pos = section.scrollLeft - 500
        }
        section.scrollTo({left: this.pos, behavior: 'smooth' });
        this.nextDisable = false
        if(section.scrollLeft === 0){
          this.prevDisable = true
        }
      }
    }
  },

};
</script>
<style>
    .tabSlider .card-content{
      --flexx-items: 7;
      --flexx-gap: 0.5rem;
    }

    @media screen and (max-width:1000px){
      .tabSlider .card-content{
        --flexx-items: 3;
      }
    }
    @media screen and (max-width:500px){
      .tabSlider .card-content{
        --flexx-items: 2;
      }
    }
</style>