import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/keno_profit.mp3';
import hit from '../../assets/media/keno_hit.mp3';
import miss from '../../assets/media/keno_miss.mp3';
import auto from '../../assets/audio/kenoAutoPick.mp3';
import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const winSound = new Howl({
  src: [win],
});

const hitSound = new Howl({
  src: [hit],
});

const missSound = new Howl({
  src: [miss],
});

const autoPickSound = new Howl({
  src: [auto],
});

export { betSound, winSound, hitSound, missSound, autoPickSound };
