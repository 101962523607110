<template>
  <section class="section section-hero-banner" id="main">
    <div class="bg-overlay">
      <img :src="getImage('intro-bg.png')" alt="" />
    </div>
    <div class="container flex flex-row banner-text-container">
      <div class="leftSide">
        <div class="title-wrap flex">
          <h1
            class="text-[60px] leading-[62px] not-italic font-bold tracking-[-1.6px] text-white">
            <span class="text-[color:var(--aqua)]">#1</span> FREE-TO-PLAY <span class="text-[color:var(--aqua)]">SOCIAL CASINO!</span>
          </h1>
        </div>
        <div class="desc-wrap">
          <p class="section-desc lg: mt-[15px] mb-[60px]">
            <b>FREE FOREVER! NO PURCHASE NECESSARY!</b>
          </p>
        </div>
        <div class="button-wrap p-[10px]">
          <button
            href="#"
            class="btn-primary"
            v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
            <span class="top play-for-free-btn"><strong>PLAY FOR FREE</strong></span>
            <span class="arr play-for-free-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M17.6568 6.34323H9.17154M17.6568 6.34323V14.8285M17.6568 6.34323L6.5 17.5001"
                  stroke="#031618"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="black-bg"></div>
      <div class="image-wrapper">
        <img class="image-1" :src="getImage('i-1.webp')" alt="" />
        <img class="image-2" :src="getImage('i-2.png')" alt="" />
        <img class="image-3" :src="getImage('i-3.png')" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import lady_background from '@/assets/home/lady.webp';
import $ from 'jquery';

export default {
  name: 'MainSection',
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  mounted: function () {
    // $(this.$refs.videoslider).carousel() ;
    setTimeout(function () {
      $('.section-hero-banner').addClass('activeanimation');
    }, 1000);

    // $('.section-hero-banner .image-wrapper .image-1').delay(1000).animate({scale:1},1200,'easeOutBack');
    // $('.section-hero-banner .image-wrapper .image-2').delay(1000).animate({scale:1},1200,'easeOutBack');
    // $('.section-hero-banner .image-wrapper .image-3').delay(1000).animate({scale:1},1200,'easeOutBack');
    // $('.section-hero-banner .leftSide h1').delay(1000).animate({top:0},1200,'easeOutBack');
    // $('.section-hero-banner .leftSide .section-desc').delay(1000).animate({top:0},1200,'easeOutBack');
    // $('.section-hero-banner .leftSide .btn-primary').delay(1000).animate({top:0},1200,'easeOutBack');
  },
  data() {
    return {
      lady_background,
    };
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
  },
};
</script>
