<template>
  <div class="p-4 md:p-6 hidden w-full rounded-lg bg-side-color">
    <div class="flex flex-row items-center">
      <div class="flex flex-row items-center grow gap-2 md:gap-4">
        <span v-if="game" class="font-bold text-sm md:text-md">
          {{ game }}
        </span>
        <span v-if="provider" class="opacity-75 font-semibold">
          {{ provider }}
        </span>
      </div>
      <div v-if="edge && !isNaN(edge)" class="flex flex-row text-xs text-slate-300">
        <span class="bg-line-color-bright px-2 py-1 rounded-full">
          Edge <span>{{ parseFloat(edge).toFixed(2) }}%</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FiatDropdown from '@/components/Bank/FiatDropdown.vue';
// import { softswissStore } from "@/store/pinia/softswissStore";
import { relaxStore } from '@/store/pinia/relaxStore';
import { openFullscreen } from '@/lib/utils';
import { generalStore } from '@/store/pinia/generalStore';

export default {
  components: {
    FiatDropdown,
  },
  props: {
    provider: {
      default: null,
    },
    edge: {
      default: null,
    },
    game: {
      default: null,
    },
  },
  setup() {
    // const softS = softswissStore();
    const generalS = generalStore();
    const relaxS = relaxStore();
    return { relaxS, generalS };
  },
  computed: {
    ...mapGetters('Sound', ['isSoundMuted']),
  },
  data() {
    return {};
  },
};
</script>
