<template></template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Custom',
  props: {
    aid: {},
  },
  created() {
    if (!this.isLoggedIn) {
      window.localStorage['aid'] = this.aid;
    }
    this.$router.push('/');
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
};
</script>
