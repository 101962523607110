<template>
  <div
    class="max-w-[30px] flex items-center md:max-w-[50px] max-h-[35px] md:max-h-[70px]">
    <img src="../../../assets/new/icons/slots/lightning.png" />
  </div>
</template>

<script>
export default {
  name: 'SlotsYellowTriangle',
};
</script>
