import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const aptPay = defineStore('aptpay', {
  state: () => {
    return {
      currentBal: null,
      isLoading: false,
      isAptPayVerified: false,
      isIndividual: true,
      hasAptPayVerifyDetails: false,
      identityForm: [],
      paymentACHForm: [],
      redemptionForm: [],
      verifiedDetails: [],
      scriptLoad: false,
      isFlinksVerified: false,
    };
  },
  getters: {},
  actions: {
    async payment(data) {
      this.isLoading = true;
      try {
        let response = await axios.post('apt-pay/ach-debit/create', data);
        this.isLoading = false;
        this.paymentACHForm = data;
        return response;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
    async ssnverify(data) {
      this.isLoading = true;
      try {
        let response = await axios.post('validatessn', data);
        this.isLoading = false;

        return response;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
    async redeem(data) {
      this.isLoading = true;
      try {
        let response = await axios.post('apt-pay/disbursements/add', data);
        this.isLoading = false;
        this.redemptionForm = data;
        return response;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
    async redeembyid(data) {
      this.isLoading = true;
      try {
        let response = await axios.post('apt-pay/disbursements/addbyinstrument', data);
        this.isLoading = false;
        this.redemptionForm = data;
        return response;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
    //create identityId for user
    async identityCreation(data) {
      this.isLoading = true;

      try {
        let response = await axios.post('apt-pay/add/identity', data);
        if (typeof response.data.errors != 'undefined') {
          return response;
        }
        this.isLoading = false;

        let obj = {};
        ({
          first_name: obj.firstName,
          last_name: obj.lastName,
          email: obj.email,
          phone: obj.phone,
        } = data);

        return this.identityVerification(obj);
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },

    //check for identity verified details
    async identityCheck() {
      this.isLoading = true;
      try {
        let response = await axios.get('apt-pay/verify');

        if (response.data.verification !== false) {
          // this.verifiedDetails = response.data;
          this.isAptPayVerified = true;
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },

    async identityVerification(data) {
      this.isLoading = true;
      try {
        let response = await axios.post('apt-pay/verify', data);
        if (typeof response.data.errors != 'undefined') {
          return response;
        }
        this.isLoading = false;
        this.identityForm = response.data;

        return this.getlatestAptPayVerify();
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },

    async getlatestAptPayVerify() {
      this.isLoading = true;
      this.hasAptPayVerifyDetails = false;
      let response;
      try {
        response = await axios.get('apt-pay/verification');
        if (typeof response.data.errors != 'undefined') {
          return response;
        }
        this.identityForm.id = response.data.verification_id;
        this.identityForm.url = response.data.url;
        this.identityForm.qrcode = response.data.qrcode;
        this.identityForm.session = response.data.session;
        if (response.data.data != 0) {
          this.hasAptPayVerifyDetails = true;
        }
        this.isLoading = false;

        return response;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
    async verifyAccountDetails() {
      return await axios.post('apt-pay/verify-account');
    },
    verifyCardDetails(param) {
      return axios.post('apt-pay/verify-card', param);
    },
    async verifyMicroDeposite(param) {
      return await axios.post('apt-pay/verify-micro', param);
    },
    async getUserAccounts() {
      return await axios.get('apt-pay/getaccounts');
    },
    async getUserCards() {
      return await axios.get('apt-pay/getaccounts?cards=1');
    },
    async removesaved(id) {
      return await axios.get('apt-pay/removesaved/' + id);
    },
    async flinksVerification(amount, result, refid) {
      this.isLoading = true;
      let obj = {
        amount: amount,
        result: result,
        referenceId: refid,
      };
      let response;
      try {
        response = await axios.post('apt-pay/ach-debit/flinks', obj);

        this.isLoading = false;
        return response;
      } catch (error) {
        this.isLoading = false;
        return error;
      }
    },
  },
});
