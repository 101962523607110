<template>
    <div class="ranking-cards-wrapper">
        <div class="ranking-group-title-wrapper" v-if="title">
            <h3 class="ranking-group-title bg-prim-color" >{{ title }}</h3>

        </div>
        <swiper class="ranking-cards" :slides-per-view="2.5" :space-between="10" :breakpoints="{ 300:{ slidesPerView:1.5 },650:{slidesPerView:2.5,},992:{slidesPerView:4.5,} }" >
            <swiper-slide v-for="item in cardData"><Card  :item="item" /></swiper-slide>
        </swiper>
        <!-- <div class="ranking-cards" v-if="width > 992" >
            <Card v-for="item in cardData" :item="item" />
        </div> -->
    </div>
</template>

<script>
import Card from './Card.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: 'VipRankingCards',
  props : ['title','jsondata'],
  data() {
    return {
        cardData: this.jsondata,
        width : 0
    }
  },
  components: {
    Card,
    Swiper,
    SwiperSlide,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
        this.width = window.innerWidth;
    }
  } 
}
</script>

<style lang="scss" scoped>
    .ranking-cards-wrapper{
        .ranking-group-title-wrapper{
            text-align: center;
            position: relative;
            margin-bottom: 38px;
            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                transform: translateY(-50%);
                @apply bg-plum;
                // z-index: -1;
                background-color: rgba(255, 255, 255, 0.12);
            }
            .ranking-group-title{
                font-size: 24px;
                line-height: 20px;
                font-weight: 700;
                padding: 0 30px;
                display: inline-block;
                position: relative;
            }
        }
        .ranking-card{
                background-color: #112021;
            }
        .ranking-cards{
            display: grid;
            grid-template-columns: repeat(4,1fr);
            grid-gap: 21px;
            margin-bottom: 38px;
        }
    }
    @media screen and (max-width:1200px){
        .ranking-cards-wrapper .ranking-cards{
            grid-template-columns : repeat(3, 1fr);
            
        }
    }
    @media screen and (max-width:992px){
        
        .ranking-cards-wrapper{
            
            .ranking-group-title-wrapper{
                text-align: left;
                margin-bottom: 25px;
                &::before{
                    content:none;
                }
                .ranking-group-title{
                    font-size: 20px;
                    padding: 0;
                }
            }
            .ranking-cards{
                display: block;
                grid-template-columns: unset;
                grid-gap: unset;    
                margin-bottom:30px            
            }
        }
        
    }
</style>
