import { sampleSize } from 'lodash';

function canUseWebCrypto() {
  if (!('Uint8Array' in window) || !('crypto' in window)) {
    return false;
  }
  return 'getRandomValues' in window.crypto;
}

const HEX_ALPHA = '0123456789abcdef'.repeat(16);

export function generateHexString(size) {
  if (canUseWebCrypto()) {
    try {
      const data = new Uint8Array(size);
      window.crypto.getRandomValues(data);
      return data.map((x) => HEX_ALPHA[x]).join('');
    } catch (e) {
      window.Raven && window.Raven.captureException(e);
    }
  }
  return sampleSize(HEX_ALPHA, size).join('');
}

export const createRandomString = function (length = 36) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
