import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const softswissStore = defineStore('softswiss', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async createGameSession(isMobile, gameId, isDemo, fiat) {
      const client_type = isMobile ? 'mobile' : 'desktop';
      const url = '/games/softswiss/' + (isDemo ? 'demo' : 'session');
      const custom_ip =
        document.getElementById('custom_ip') != null
          ? document.getElementById('custom_ip').value
          : '0.0.0.0';

      return await axios.post(url, { client_type, gameId, fiat, custom_ip });
    },
    async createSession(currency, amount, address, tag) {
      return await axios.post('/games/softswiss/session', {
        currency,
        amount,
        address,
        tag,
      });
    },
    async fetchFeatured() {
      if (!this.featured) {
        const { data } = await axios.get('softswiss/featured');
        this.featured = data;
      }
      return this.featured;
    },
    async fetchAll(params) {
      return await axios.get('softswiss/games', { params });
    },
    async fetchGame(identifier) {
      return await axios.get('softswiss/games/' + identifier);
    },
    async toggleFavourite(data) {
      return await axios.post('favourite', data);
    },
    async checkFavourite(data) {
      return await axios.post('favourite/check', data);
    },
  },
});
