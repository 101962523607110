<template>
  <!-- section-testimonial -->
  <section class="section section-testimonial" id="testimonials">
    <div class="flex testimonial-container">
      <!-- <div class="container flex"> -->
      <div class="left-part md:w-1/3">
        <div class="inner-top">
          <h2 class="section-title mb-[52px]">Check out our testimonials</h2>
          <p class="section-desc">
            Magnify our satisfied customers' triumphs and big wins
          </p>
        </div>
        <div class="inner-bottom desktop">
          <button
            class="social-slider-prev navigation-arr mr-[16px]"
            @click="testimonialprev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M4 12L10 6M4 12L10 18M4 12H20"
                stroke="#031618"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
          <button class="social-slider-next navigation-arr" @click="testimonialenext">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M20 12L14 6M20 12L14 18M20 12H4"
                stroke="#031618"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div class="right-part md:w-2/3">
        <div class="testimonial-slider" ref="testimonialslider">
          <TestimonialItem title="I absolutely LOVE playing games on Punt. They have my favorite providers and the slots are super loose." name="Dally" />
          <TestimonialItem title="Punt is my favorite social casino. I love that it is free-to-play and their 24/7 support is so kind and helpful." name="Jeremy" />
          <TestimonialItem title="This is by far the best social casino out there. The bonuses are something out of this world. I have already invited all my friends here and they are truly enjoying themselves." name="Susan" />
          <TestimonialItem title="Two thumbs up for Punt.com! I recommend it over ALL social casinos." name="Katie" />
          <TestimonialItem title="5 stars. The checkouts are lightning quick and the site is buttery smooth. Incredible domain as well. Cheers to the Punt.com team!" name="Chris" />
          
        </div>
        <div class="inner-bottom mobile">
          <button
            class="social-slider-prev navigation-arr mr-[16px]"
            @click="testimonialprev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M4 12L10 6M4 12L10 18M4 12H20"
                stroke="#031618"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
          <button class="social-slider-next navigation-arr" @click="testimonialenext">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M20 12L14 6M20 12L14 18M20 12H4"
                stroke="#031618"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
  <!-- section-testimonial -->
</template>

<script>
import { mapGetters } from 'vuex';
import lady_background from '@/assets/home/lady.webp';
import $ from 'jquery';
import TestimonialItem from './TestimonialItem.vue';
export default {
  name: 'Testimonials',
  components: {
    TestimonialItem,
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  mounted: function () {
    $(this.$refs.testimonialslider).slick({
      infinite: false,
      slidesToShow: 2.5,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 1225,
          settings: {
            slidesToShow: 1.8,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
  },
  data() {
    return {
      lady_background,
    };
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
    testimonialprev() {
      $(this.$refs.testimonialslider).slick('slickPrev');
    },
    testimonialenext() {
      $(this.$refs.testimonialslider).slick('slickNext');
    },
  },
};
</script>
