<template>
  <fairness
    game="Dice"
    :client-seed="clientSeed"
    :server-seed="serverSeedHash"
    :nonce="seedPairNonce"
    :fetch-seed="refreshSeedInfo"
    :update-seed="changeSeed" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Fairness from '@/components/Shared/Fairness.vue';

export default {
  components: { Fairness },
  computed: {
    ...mapGetters({
      visible: 'shouldDisplayPF',
    }),
    ...mapGetters(['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  methods: {
    ...mapActions(['refreshSeedInfo', 'setNewClientSeed']),
    changeSeed(seed) {
      return this.setNewClientSeed({ seed });
    },
  },
};
</script>
