<template>
  <!-- <router-view></router-view> -->
  <punt-modal size="max-w-xl">
    <template #body>
      <div class="flex flex-col">
        <div
          class="text-sm font-medium text-center text-gray-500 border-b border-line-color">
          <ul class="flex flex-wrap -mb-px space-x-2">
            <li class="text-white">
              <span style="font-size: 24px">Freebies Contributors </span>
            </li>
          </ul>
          <br />
        </div>
        <section class="min-h-[480px] flex flex-col">
          <div class="flex flex-col justify-between items-center">
            <div class="mt-4 z-20"></div>
          </div>
        </section>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { defaultConvertor, convertGameMode } from '../../lib/rsgp';
const pages = ['details'];
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  PopoverOverlay,
} from '@headlessui/vue';

import { ordersStore } from '@/store/pinia/ordersStore';

import { mapGetters } from 'vuex';

export default {
  name: 'Details',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    PuntModal,
  },
  setup() {
    const orderS = ordersStore();

    return { orderS };
  },
  data() {
    return {
      activeTab: 'details',
      amounts: 0,
      contributions: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
    gameMode() {
      return this.isLoggedIn !== undefined && this.isLoggedIn && this.me !== undefined
        ? this.me.game_mode
        : 'osrs';
    },
    getAmount() {
      return this.contributions.length > 0
        ? `- Total ${this.rsgp(this.amounts[this.gameMode])} ${convertGameMode(
            this.gameMode,
          )}`
        : '';
    },
  },
  methods: {},
  watch: {
    '$route.query.action': {
      handler: function (newState) {},
      deep: true,
      immediate: true,
    },
  },
};
</script>
