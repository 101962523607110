<template>
  <div v-if="filteredGames.length > 0" class="flex flex-col px-2 py-2 md:px-6">
    <div class="flex flex-row items-center justify-between px-2 md:py-4 max-[575px]:px-0">
      <router-link
        :to="'/casino/group/' + slug"
        class="flex items-center justify-start flex-grow gap-2 py-0 md:py-4">
        <img class="h-[26px] w-[30px]" :src="icon" />
        <h2 class="font-semibold break-all text-md md:text-xl">{{ title }}</h2>
      </router-link>
      <div class="flex flex-row">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 cursor-pointer rounded-xl bg-secondary-light mr-2 hover:opacity-60">
          <img
            src="../../assets/3d/right-arrow.png"
            class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 bg-secondary-light cursor-pointer rounded-xl hover:opacity-60">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div>
    <!--    <vue-scroll :ref="slug" @handle-scroll-complete="handleComplete" class="srcoll-fix">-->
    <div :ref="this.slug" class="py-2 overflow-x-scroll no-scrollbar home-casino-slider">
      <div
        v-for="(game, i) in filteredGames"
        v-bind:key="i"
        class="slider-tile"
        :data-key="i">
        <router-link class="flex flex-col" :key="i" :to="getLink(game)">
          <img
            :src="getImageUrl(game)"
            @error="loadFallbackImage"
            :data-fallback="getOriginalImage(game)"
            alt="Game Tile"
            v-tooltip="game.title"
            class="flex flex-col items-center transition duration-150 ease-in-out rounded-md shadow-md delay-0 hover:rotate-1 hover:scale-105" />
        </router-link>
      </div>

      <div class="slider-tile">
        <router-link class="flex flex-col" :to="'/casino/group/' + slug">
          <img
            alt="View all Slots"
            :src="getcategoryimagethumb()"
            class="flex flex-col items-center transition duration-150 ease-in-out rounded-md shadow-md delay-0 hover:rotate-1 hover:scale-105" />
        </router-link>
      </div>
    </div>
    <!--    </vue-scroll>-->
  </div>
</template>

<script>
import { clamp } from 'lodash';
import V2 from '@/assets/svg/tiles/png/v2test.png';
import Test from '@/assets/svg/tiles/png/test.png';
import Secondary from '@/assets/svg/tiles/png/secondaryTest.png';
import originals from './originals';
const acceptanceTiles = {
  v2test: V2,
  secondary_test: Secondary,
  test: Test,
};

export default {
  name: 'RelaxHomeTiles',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { scrollAmount: 0, currentIndex: 0 };
  },
  computed: {
    filteredGames() {
      return (
        this.games
          .filter((game) => this.filterGames(game))
          //.filter((game) => this.testsFilter(game))
          .sort((a, b) => parseFloat(b.rank) - parseFloat(a.rank))
      );
    },
  },
  methods: {
    getImageUrl(game) {
      if (game.provider === 'runebet') {
        const original = originals.find((o) => o.title === game.title);
        return original.img;
      }

      if (game.software == 'relax') {
        return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
      }

      if (game.software == 'softswiss') {
        const identifier = this.getIdentifierName(game.identifier);

        return `${location.protocol}//${location.host}/images/softswiss/${identifier}.webp`;
      }
      if (game.software == 'pragmatic') {
        return `${location.protocol}//${location.host}/images/pragmatic/${game.identifier}.webp`;
      }

      return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
    },
    testsFilter(game) {
      const isProdMode = import.meta.env.MODE === 'punt_prod';
      return isProdMode ? game.provider !== 'acceptance' : true;
    },
    filterGames(game) {
      return true;
      const liveFilter = ['live', 'table-games'].includes(this.slug)
        ? game.has_live === true
        : game.has_live === false;
      return (
        (this.categories.includes(game.category) || this.categories.length === 0) &&
        liveFilter
      );
    },
    loadFallbackImage(event) {
      if (event.target.src != event.target.getAttribute('data-fallback'))
        event.target.src = event.target.getAttribute('data-fallback');
      else return false;
    },
    getcategoryimagethumb() {
      return `${location.protocol}//${location.host}/images/viewall-slots.webp`;
    },
    getOriginalImage(game) {
      if (game.software == 'softswiss') {
        const identifier = this.getIdentifierName(game.identifier);

        if (identifier == 'BlackjackA')
          return `${location.protocol}//${location.host}/images/default.webp`;

        if (game.provider === 'runebet') {
          const original = originals.find((o) => o.title === game.title);
          return original.img;
        }

        return `https://cdn.softswiss.net/i/s4/${game.provider}/${identifier}.png`;
      }

      return `${location.protocol}//${location.host}/images/default.webp`;
    },
    getLink(game) {
      if (game.provider === 'runebet') {
        const original = originals.find((o) => o.title === game.title);
        return original.link;
      }
      if (game.software == 'relax') {
        // const gameName = this.getIdentifierName(game.identifier);
        return `/casino/games/${game.provider}-${game.identifier}`;
      }

      if (game.software == 'softswiss') {
        const gameName = this.getIdentifierName(game.identifier);
        return `/softswiss/casino/games/${game.provider}-${gameName}`;
      }

      if (game.software == 'pragmatic') {
        return `/pragmatic/casino/games/${game.identifier}`;
      }
    },
    getIdentifierName(gameName) {
      return gameName.split(':')[1];
    },
    setFade(isRight = true) {
      const menu = this.$refs[this.slug];
      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('slider-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
          ? this.currentIndex + visible_item
          : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
          '.slider-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
          menu.getElementsByClassName('slider-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs[this.slug];

      const slide_width = menu.getElementsByClassName('slider-tile')[0].clientWidth;

      /*sroll_amount_increase = menu.clientWidth  -  (sroll_amount_increase   + 1  )  ; 
      this.scrollAmount = clamp(
        isRight ? (this.scrollAmount += sroll_amount_increase) : (this.scrollAmount -= sroll_amount_increase),
        0,
        width
      );*/
      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
  },
};
</script>
