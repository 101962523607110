import { defineStore } from 'pinia';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const rouletteStore = defineStore('roulette', {
  state: () => {
    return {
      selectedChip: 1e6,
    };
  },
  getters: {},
  actions: {},
});
