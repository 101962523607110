<template>
  <div class="w-full technical-warnning-outer bg-tertiary px-4 py-12 md:px-8 md:py-12">
    <img src="../assets/technical-warrning.png" alt="Technical Warnning" class="w-full" />
    <p class="big-text text-white">
      {{ wtitle }}
    </p>
    <p class="small-text text-secondary-light">
      {{ wdesc }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'technical-warning',
  props: {
    wtitle: {
      type: String,
      required: false,
    },
    wdesc: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.technical-warnning-outer {
  text-align: center;
}
.technical-warnning-outer .big-text {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
  letter-spacing: 0.864px;
  margin-bottom: 30px;
  color: #fff;
}
.technical-warnning-outer .small-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
</style>
