/**
 * @name VueJS vChatScroll (vue-chat-scroll)
 * @description Monitors an element and scrolls to the bottom if a new child is added
 * @author Theodore Messinezis <theo@theomessin.com>
 * @file vue-chat-scroll plugin definition
 */

import vChatScroll from './v-chat-scroll';

const VueChatScroll = {
  install: (Vue, options) => {
    Vue.directive('chat-scroll', vChatScroll);
  },
};

export default VueChatScroll;
