<template>
    <div class="faq">
        <SectionTitle title="Frequently Asked Questions" />
        <h4 class="desc">Reach out to our award winning support team</h4>
        <div class="faq-tabs">
            <TabGroup vertical="true">
                <TabList class="tab-nav-wrap">
                    <Tab class="tab-nav" v-for="item in faq">{{ item.title }}</Tab>
                </TabList>
                <TabPanels class="tab-content-wrap">
                    <TabPanel v-for="items in faq" >
                        <Accordian v-for="item in items.data" :key="item.id" :item="item" @toggle-data="toggleData"/>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    </div>
</template>

<script setup>
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
  import { faq } from './faq'
  import Accordian from './Accordian.vue'
</script>

<script>
import SectionTitle from './SectionTitle.vue';
export default {
  name: 'VipClubBanefit',
  components: {
    SectionTitle,
    Accordian
  },
  data(){
    return{
        faq
    }
  }
}
</script>

<style lang="scss" scoped>
    .faq-tabs{
        display: grid;
        grid-template-columns: 124px 1fr;
        grid-gap:10px;
        align-items: start;
        .tab-nav-wrap{
            padding: 12px 0;
            @apply bg-tristesse;
            border-radius: 14px;
            background-color: rgba(17, 32, 33, 0.8);
            .tab-nav{
                display: block;
                padding: 7px 7px 7px 20px;
                border-left: 2px solid transparent;
                margin-bottom: 10px;
            }
            .tab-nav[aria-selected = true]{
                @apply border-onprimary
            }
            .tab-nav:focus{
                outline: none;
            }
        }
        .tab-content-wrap{
            @apply bg-tristesse;
            border-radius: 18px;
            padding: 10px 10px 2px;
            background-color:rgba(17, 32, 33, 0.8);
        }
        
    }
    .faq{
        padding-bottom: 100px;
        .section-title{
            margin-bottom: 16px;
        }
        .desc{
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 42px;
            text-align: center;
            color:  #BEBEBE !important;
            @apply text-pango-black;
        }
    }
    @media screen and (max-width:992px){
        .faq{
            .desc{
                max-width: 225px;
                margin: auto;
                margin-bottom: 30px;
            }
            .faq-tabs{
                grid-template-columns:1fr;
                grid-gap:22px;
                .tab-nav-wrap {
                    @apply bg-obsidian-shard;
                    border-radius: 14px;
                    padding: 0 18px;
                    display: flex;
                    justify-content: space-between;
                    .tab-nav{
                        display: inline-block;
                        padding: 17px 6px;
                        border-left:none;
                        border-bottom: 2px solid transparent;
                        margin:0;
                        flex-grow: 1;

                    }
                    .tab-nav[aria-selected=true]{
                        @apply border-onprimary;
                    }
                }
                .tab-content-wrap{
                    @apply bg-obsidian-shard;
                    border-radius:14px;
                    padding:10px 10px 35px;
                }
            }
        }
    }
</style>
