<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row p-8 gap-4 w-full max-w-[1200px]">
      <div class="w-[200px] flex flex-col">
        <div class="space-y-2 py-2 bg-secondary h-auto rounded-lg">
          <span class="font-medium w-full mx-2" v-for="item in policies">
            <router-link
              :to="'/policies/' + item.url"
              :active-class="'text-slate-400 border-b-4 md:border-b-0 md:border-l-4 border-[#1EE563]'"
              class="inline-block p-4 font-semibold text-white">
              {{ item.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="bg-secondary p-4 flex-1 rounded-lg">
        <terms-of-service v-if="activeTab === 'terms-and-conditions'" />
        <privacy v-if="activeTab === 'privacy'" />
      </div>
    </div>
  </div>
</template>

<script>
import TermsOfService from '@/components/Policies/TermsOfService.vue';
import Privacy from '@/components/Policies/Privacy.vue';
export default {
  name: 'Policies',
  components: { Privacy, TermsOfService },
  setup() {
    return {
      policies: [
        {
          name: 'Terms & Conditions',
          url: 'terms-and-conditions',
        },
        {
          name: 'Privacy',
          url: 'privacy',
        },
      ],
    };
  },
  data() {
    return {
      activeTab: 'terms-and-conditions',
    };
  },
  watch: {
    '$route.params.name': {
      handler: function (newState) {
        if (this.policies.some((item) => item.url === newState)) {
          this.activeTab = newState;
        } else {
          this.activeTab = 'terms-and-conditions';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
