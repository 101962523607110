<template>
  <div
    class="chat-connection is-unselectable"
    v-on:click.shift.stop="() => setBzFlag(true)"
    v-on:click="showRoster()">
    <div class="level is-mobile">
      <div class="flex flex-row justify-start items-center gap-2">
        <div class="level-item">
          <span
            class="hidden md:block rounded-full h-2 w-2"
            :class="[isOnline ? 'bg-[#01DC07]' : 'bg-red-500']"></span>
        </div>
        <div class="level-item" v-if="!isOnline || usersOnline < 50">
          <span class="chat-connection__status" style="color: #ffffff">{{ status }}</span>
        </div>
        <template v-else>
          <div class="level-item" style="color: #ffffff">
            <span class="icon is-small"><i class="fa fa-users"></i></span>
            <!--            <strong>{{ usersOnline }}</strong>-->{{ status }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ChatConnectionStatus',
  props: ['showRoster'],
  computed: {
    ...mapGetters({
      isOnline: 'isConnected',
      userCount: 'chatRoomUsers',
    }),
    usersOnline() {
      let offset = 0;
      const storage = window.sessionStorage || window.localStorage;
      if (storage && 'user-count' in storage) {
        offset = +storage['user-count'];
      }
      return this.userCount + offset;
    },
    status() {
      return this.isOnline ? 'Online' : 'Offline';
    },
  },
  methods: {
    ...mapActions(['setBzFlag']),
  },
};
</script>
