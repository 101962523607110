<template>
  <div class="mx-2" :class="`racebot-wrap racebot-wrap-${theme.style}`">
    <div class="header py-2 px-4">
      <div class="lobby-parent">
        <div class="lobby-child">
          <img :src="theme.icon" class="h-4 w-4 mr-2" />
          <p class="text-md font-bold" :style="`color: ${theme.color};`">
            {{ messageTitle }}
          </p>
        </div>
        <div class="lobby-child">
          <button
            v-if="canSeeActionButton"
            :class="`button is-${theme.style} is-outlined is-small`"
            v-on:click="action()">
            <span class="uppercase text-xs text-slate-300 hover:text-slate-300/50">{{
              game.action
            }}</span>
            <span class="icon is-small">
              <i class="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="p-2">
      <div class="lobby-parent">
        <div class="lobby-child">
          <div class="text-sm inline" id="alertD" style="color: #ffffff">
            <span
              class="inline"
              v-tooltip="`User has enabled private mode`"
              v-if="isPrivate"
              style="color: #aaaaaa !important"
              >Hidden</span
            >
            <span class="inline" v-html="hiddenMessage"></span>
          </div>
          <p class="hidden body text-sm" style="color: #ffffff">
            <b
              v-tooltip="`User has enabled private mode`"
              v-if="isPrivate"
              style="color: #aaaaaa !important"
              >Hidden </b
            >{{ hiddenMessage }}
            <a v-if="game.name === 'URL'" :href="game.url" target="_blank">{{
              game.url
            }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WarIcon from '../../assets/svg/swords.svg';
import MegaIcon from '../../assets/svg/megaphone.svg';
import PokerIcon from '../../assets/svg/poker_chat.svg';
import { getBalanceIcon, getCurrencies } from '@/lib/icons';

export default {
  name: 'GameActionMessage',
  props: {
    game: {},
  },
  setup() {
    const currencies = getCurrencies();
    return {
      currencies,
    };
  },
  computed: {
    ...mapGetters(['me']),
    messageTitle() {
      switch (this.game.name) {
        case 'URL':
          return 'RuneBet';
        default:
          return this.game.name;
      }
    },
    canSeeActionButton() {
      switch (this.game.name) {
        case 'War':
          return (
            this.me.game_mode === this.game.game_mode &&
            this.startTimeInterval !== null &&
            this.startTimeCounter > 1
          );
        case 'URL':
          return true;
        default:
          return this.startTimeInterval !== null && this.startTimeCounter > 1;
      }
    },
    isPrivate() {
      return this.game.message.startsWith('#hidden');
    },
    hiddenMessage() {
      let message = this.game.message.replace('#hidden', '');
      for (const currency of this.currencies) {
        if (message.includes(currency)) {
          message = message.replace(
            currency,
            `<img src="${this.getIcon(currency)}" class="inline h-4 w-4"  alt=""/>`,
          );
        }
      }
      return message;
    },
  },
  data: () => ({
    startTimeInterval: null,
    startTimeCounter: 0,
    theme: 'success',
  }),
  created() {
    this.starTimer();
    switch (this.game.name) {
      case 'War':
        this.theme = {
          style: 'success',
          color: '#23D160',
          icon: WarIcon,
        };
        break;
      case 'Poker':
        this.theme = {
          style: 'warning',
          color: '#FFDD57',
          icon: PokerIcon,
        };
        break;
      case 'URL':
        this.theme = {
          style: 'danger',
          color: '#FF0000',
          icon: MegaIcon,
        };
        this.startTimeInterval = 'x';
        this.tartTimeCounter = 2;
        break;
    }
  },
  methods: {
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    action() {
      switch (this.game.name) {
        case 'War':
          this.$router.push({
            path: '/casino/war',
            query: { join: window.btoa(this.game.uuid) },
          });
          break;
        case 'Poker':
          this.$router.push({
            path: 'poker',
          });
          break;
        case 'URL':
          window.open('https://t.me/+CpkIVzI-Dn1kOTI5', '_blank');
          break;
      }
    },
    starTimer() {
      this.startTimeInterval = setInterval(() => {
        let currentTime = new Date().getTime();
        this.startTimeCounter = Math.floor(
          (this.game.timestamp * 1000 + this.game.starts_in * 1000 - currentTime) / 1000,
        );
        if (this.startTimeCounter <= 0) {
          clearInterval(this.startTimeInterval);
          this.startTimeInterval = null;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-bet:hover {
  background-color: #00b100 !important;
}

.lobby-parent {
  display: flex;
  min-width: 100%;
  align-items: center; /* Align Items Vertically */
  justify-content: space-between; /* Align Items Horizontally (with equal space in between each of them */
}

.lobby-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.racebot-wrap-warning {
  border: 1px solid rgb(255, 221, 87);
}

.racebot-wrap-success {
  border: 1px solid rgb(35, 209, 96);
}

.racebot-wrap-danger {
  border: 1px solid rgb(113, 37, 43);
}

.racebot-wrap {
  border-radius: 8px;
  background: rgba(35, 209, 96, 0.005);

  .header {
    position: relative;
    border-bottom: 1px outset #ddd;
    overflow: hidden;

    .title {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
}

.race {
  width: 8px;
  height: 12px;
  position: absolute;
}
</style>
