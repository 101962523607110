<template>
  <div class="relative max-w-[35px] md:max-w-[60px] max-h-[35px] md:max-h-[70px]">
    <img src="../../../assets/new/icons/slots/diamond.png" />
    <svg
      class="absolute -top-2 md:top-0 md:-left-2 left-0 right-0 z-50 w-[40px] md:w-[80px]"
      width="50"
      height="50"
      viewBox="0 0 162 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_89_9986)">
        <path
          d="M69.7133 35.3714L54.4556 68.6118L43.8507 70.866L42.174 55.4095L34.126 72.933L23.521 75.1872L20 45.9383L29.7666 43.8623L31.6529 60.5598L40.3716 41.6082L49.4675 39.6748L51.1442 56.674L59.9886 37.4384L69.7133 35.3714Z"
          fill="url(#paint0_linear_89_9986)" />
        <path
          d="M68.1963 42.8361L76.44 41.0838L71.5497 67.121L63.306 68.8733L68.1963 42.8361Z"
          fill="url(#paint1_linear_89_9986)" />
        <path
          d="M80.3521 35.7884L88.5958 34.0361L84.9979 53.2635L96.036 50.9173L94.7436 57.7271L75.4618 61.8256L80.3521 35.7884Z"
          fill="url(#paint2_linear_89_9986)" />
        <path
          d="M102.184 32.0407L113.222 29.6945C115.783 29.15 117.996 29.1083 119.859 29.5694C121.745 30.0255 123.189 30.9447 124.19 32.3269C125.191 33.7092 125.692 35.4836 125.692 37.65C125.692 40.5307 125.04 43.2048 123.736 45.6723C122.432 48.1159 120.616 50.1804 118.287 51.8658C115.981 53.5224 113.35 54.665 110.392 55.2936L97.2935 58.0779L102.184 32.0407ZM110.672 48.6634C112.791 48.213 114.433 47.1855 115.597 45.581C116.785 43.9477 117.379 42.0597 117.379 39.9171C117.379 38.4648 116.936 37.4399 116.051 36.8424C115.19 36.2399 113.932 36.1143 112.279 36.4658L109.135 37.134L106.83 49.4801L110.672 48.6634Z"
          fill="url(#paint3_linear_89_9986)" />
        <path
          d="M130.159 18.9522L141.712 16.4965L136.107 36.5862L128.404 38.2234L130.159 18.9522ZM130.587 49.9209C129.018 50.2544 127.734 50.0607 126.736 49.3396C125.737 48.6186 125.238 47.5873 125.238 46.2458C125.238 44.6126 125.794 43.1528 126.907 41.8664C128.048 40.574 129.474 39.7458 131.186 39.382C132.755 39.0485 134.039 39.2277 135.037 39.9196C136.035 40.6114 136.535 41.6281 136.535 42.9697C136.535 44.5737 135.964 46.0511 134.823 47.4019C133.682 48.7235 132.27 49.5632 130.587 49.9209Z"
          fill="url(#paint4_linear_89_9986)" />
      </g>
      <defs>
        <filter
          id="filter0_d_89_9986"
          x="0"
          y="0.496094"
          width="161.712"
          height="98.6914"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0431373 0 0 0 0 0.0470588 0 0 0 0 0.0823529 0 0 0 0.5 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_89_9986" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_89_9986"
            result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_89_9986"
          x1="80.8561"
          y1="29.4318"
          x2="88.1186"
          y2="63.5992"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_89_9986"
          x1="80.8561"
          y1="29.4318"
          x2="88.1186"
          y2="63.5992"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_89_9986"
          x1="80.8561"
          y1="29.4318"
          x2="88.1186"
          y2="63.5992"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_89_9986"
          x1="80.8561"
          y1="29.4318"
          x2="88.1186"
          y2="63.5992"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_89_9986"
          x1="80.8561"
          y1="29.4318"
          x2="88.1186"
          y2="63.5992"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.347607" stop-color="#E7E9F5" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SlotsWild',
};
</script>
