<template>
  <div class="flex flex-col items-center">
    <div class="flex w-full max-w-[1200px] flex-col gap-4 p-4 md:flex-row md:p-8">
      <div class="flex flex-col md:w-[200px]">
        <div class="h-auto space-y-2 rounded-lg bg-secondary py-2">
          <span class="mx-2 w-full font-medium" v-for="item in tabs">
            <router-link
              :to="'/provablyfair/' + item.url"
              :active-class="'text-slate-400 border-b-4 md:border-b-0 md:border-l-4 border-[#1EE563]'"
              class="inline-block p-4 font-semibold text-white">
              {{ item.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="flex-1 rounded-lg bg-secondary p-4">
        <Overview v-if="activeTab === 'overview'" />
        <Implementation v-if="activeTab === 'implementation'" />
        <Conversions v-if="activeTab === 'conversions'" />
        <GameEvents v-if="activeTab === 'gameevents'" />
      </div>
    </div>
  </div>
</template>

<script>
import Overview from './Overview.vue';
import Implementation from './Implementation.vue';
import GameEvents from './GameEvents.vue';
import Conversions from './Conversions.vue';

export default {
  name: 'ProvablyFair',
  components: { Overview, Implementation, GameEvents, Conversions },
  setup() {
    return {
      tabs: [
        {
          name: 'Overview',
          url: 'overview',
        },
        {
          name: 'Implementation',
          url: 'implementation',
        },
        {
          name: 'Conversions',
          url: 'conversions',
        },
        {
          name: 'Game Events',
          url: 'gameevents',
        },
      ],
    };
  },
  data() {
    return {
      activeTab: 'funds',
    };
  },
  watch: {
    '$route.params.name': {
      handler: function (newState) {
        if (this.tabs.some((item) => item.url === newState)) {
          this.activeTab = newState;
        } else {
          this.activeTab = 'overview';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
