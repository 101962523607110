<template>
  <div
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 items-center child:max-w-[1600p]" id="landingpageouter">
    <div class="grid grid-cols-1 w-full">
      <PHeader />
      <main-section />

      <social-games />

      <!--      <VideoSection />-->
<!--      <Testimonials />-->
      <PFooter />
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import { mapGetters } from 'vuex';
import MainSection from '@/components/Home/Landingpage/MainSection.vue';
import SocialGames from '@/components/Home/Landingpage/SocialGames.vue';
import Testimonials from '@/components/Home/Landingpage/Testimonials.vue';
import PHeader from '@/components/Home/Landingpage/PHeader.vue';
import PFooter from '@/components/Home/Landingpage/PFooter.vue';
import VideoSection from '@/components/Home/Landingpage/VideoSection.vue';
import BannerSlider from './Slider/BannerSlider.vue';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

export default {
  name: 'landing',
  components: {
    MainSection,
    SocialGames,
    Auth,
    Testimonials,
    PFooter,
    PHeader,
    VideoSection,
    BannerSlider,
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
  },
  mounted() {
    if (this.$route.query.aid) {
      this.$router.push(`/c/${this.$route.query.aid}`);
    }
    let section = this.$router.currentRoute.value.hash.replace('#', '');
    if (section)
      this.$nextTick(() => window.document.getElementById(section).scrollIntoView());
  },
  data() {
    return {
      loading: true,
      games: [],
    };
  },
  watch: {
    '$route.hash': {
      handler: function (hash) {
        hash = hash.length === 0 ? 'main' : hash.replace('#', '');
        const el = document.getElementById(hash);
        if (el) {
          el.scrollIntoView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
@import '@/components/Home/Landingpage/style.css';
</style>
