<template>
  <div class="playing-card">
    <div
      class="playing-card-inner"
      :class="{
        'is-revealed': revealed,
        'card-black': color == 0,
        'card-red': color == 1,
      }">
      <div class="card--face card--front">
        <span class="value">{{ valueDisplay }}</span>
        <span class="suit">{{ suitDisplay }}</span>
      </div>
      <div class="card--face card--back p-2">
        <img src="../../assets/logo.png" alt="RuneBet" />
      </div>
    </div>
  </div>
</template>

<script>
import { SUIT_COLORS, SUITS } from './PlayingCards';

export default {
  name: 'Card',
  props: {
    suit: Number,
    value: Number,
    revealed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      switch (this.suit) {
        case SUITS.CLUBS:
          return SUIT_COLORS.CLUBS;

        case SUITS.DIAMONDS:
          return SUIT_COLORS.DIAMONDS;

        case SUITS.HEARTS:
          return SUIT_COLORS.HEARTS;

        case SUITS.SPADES:
          return SUIT_COLORS.SPADES;
      }
    },
    valueDisplay() {
      if (this.value >= 2 && this.value <= 10) {
        return this.value;
      }

      switch (this.value) {
        case 1:
          return 'A';
        case 11:
          return 'J';
        case 12:
          return 'Q';
        case 13:
          return 'K';
      }
    },
    suitDisplay() {
      switch (this.suit) {
        case SUITS.CLUBS:
          return '♣';

        case SUITS.DIAMONDS:
          return '♦';

        case SUITS.HEARTS:
          return '♥';

        case SUITS.SPADES:
          return '♠';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.playing-card {
  min-width: 75px;
  min-height: 120px;
  max-width: 75px;
  max-height: 120px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;

  .playing-card-inner {
    width: 100%;
    height: 100%;
    position: relative;

    padding: 0 !important;

    transition: transform 400ms;
    transform-style: preserve-3d;
    transform-origin: center right;

    background: none;

    border-radius: 0.5rem;
    box-shadow:
      0 4px 3px rgba(10, 10, 10, 0.1),
      0 0 4px 3px rgba(10, 10, 10, 0.1);

    .card--face {
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;
      margin: 0;
      backface-visibility: hidden;
      -webkit-font-smoothing: subpixel-antialiased;

      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      border-radius: 0.5rem;
    }

    .card--front {
      transform: translateZ(0) rotateY(180deg);

      background: #ffffff;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      padding: 0.25rem;

      font-size: 2rem;
      font-family: 'Brandon Grotesque';
      font-weight: bold;

      > .value {
        display: inline-block;
        width: 1.25rem;
        text-align: center;
        line-height: 2.5rem;
      }

      .suit {
        font-size: 2.25rem;
        line-height: 6.75rem;
      }
    }

    .card--back {
      background-color: #3579f6;
      border: 0.25rem solid #ffffff;
    }

    &.is-revealed {
      transform: translateZ(0) translateX(-100%) rotateY(180deg);
    }

    &.card-black {
      color: black !important;
    }

    &.card-red {
      color: rgb(255, 33, 71) !important;
    }
  }
}

@media (min-width: 500px) and (max-width: 1369px) {
  .playing-card {
    min-width: 84.375px;
    min-height: 135px;
    max-width: 84.375px;
    max-height: 135px;
  }

  .card-size {
    min-width: 84.375px;
    min-height: 135px;
    max-width: 84.375px;
    max-height: 135px;
  }
}

@media (max-width: 500px) {
  .playing-card {
    min-width: 60px;
    min-height: 90px;
    max-width: 60px;
    max-height: 90px;

    .playing-card-inner {
      .card--front {
        font-size: 1.7rem;

        > .value {
          width: 1rem;
          line-height: 2.5rem;
        }

        .suit {
          font-size: 1.75rem;
          line-height: 2.75rem;
        }
      }
    }
  }

  .card-size {
    min-width: 60px;
    min-height: 90px;
    max-width: 60px;
    max-height: 90px;
  }
}
</style>
