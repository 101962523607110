<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-col md:flex-row p-4 md:p-8 gap-4 w-full max-w-[1200px]">
      <div class="md:w-[200px] flex flex-col">
        <div class="h-auto py-2 px-2 sm:px-0 md:space-y-2 rounded-lg bg-secondary">
          <span
            class="w-full new-border-color mx-2 font-medium max-[480px]:mx-0 max-[480px]:text-sm"
            v-for="item in tabs">
            <router-link
              :to="'/affiliates/' + item.url"
              :active-class="'text-slate-400 border-b-4 md:border-b-0 md:border-l-4 border-accent max-[575px]:border-b-2'"
              class="inline-block px-2 py-3 sm:p-4 max-[400px]:px-[0.45rem] font-semibold text-white">
              {{ item.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="flex-1 p-4 rounded-lg bg-secondary">
        <Overview v-if="activeTab === 'overview'" />
        <Funds v-if="activeTab === 'funds'" />
        <NewReferralBonus v-if="activeTab === 'new-referral-bonus'" />
        <ReferedUsers v-if="activeTab === 'referred-users'" />
        <Campaign v-if="activeTab === 'campaign'" />
      </div>
    </div>
  </div>
</template>

<script>
import Funds from '@/components/Affiliates/Funds.vue';
import ReferedUsers from '@/components/Affiliates/ReferedUsers.vue';
import NewReferralBonus from '@/components/Affiliates/NewReferralBonus.vue';
import Overview from '@/components/Affiliates/Overview.vue';
import Campaign from '@/components/Affiliates/Campaign.vue';

export default {
  name: 'MyAffiliates',
  components: { Funds, ReferedUsers, NewReferralBonus, Overview, Campaign },
  setup() {
    return {
      tabs: [
        {
          name: 'Overview',
          url: 'overview',
        },
        {
          name: 'Referred Users',
          url: 'referred-users',
        },
        {
          name: 'New Referral Bonus',
          url: 'new-referral-bonus',
        },
        {
          name: 'Funds',
          url: 'funds',
        },
        {
          name: 'Campaign',
          url: 'campaign',
        },
      ],
    };
  },
  data() {
    return {
      activeTab: 'funds',
    };
  },
  watch: {
    '$route.params.name': {
      handler: function (newState) {
        if (this.tabs.some((item) => item.url === newState)) {
          this.activeTab = newState;
        } else {
          this.activeTab = 'overview';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.new-border-color a{
    border-color: #00FFBA;
  }
</style>
