import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/win_dicing.mp3';
import loose from '../../assets/media/loose.mp3';
import dicing from '../../assets/media/dicing.mp3';

import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const winSound = new Howl({
  src: [win],
});

const looseSound = new Howl({
  src: [loose],
});

const dicingSound = new Howl({
  src: [dicing],
});

export { betSound, winSound, looseSound, dicingSound };
