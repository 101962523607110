<template>
  <div class="w-full sm:-mx-6 lg:-mx-8">
    <div class="inline-block py-2 w-full sm:px-6">
      <div class="text-sm text-slate-300 border-slate-300 px-4 rounded-lg">
        <div class="box max-w-2xl">
          <h2 class="font-bold text-lg my-2" style="margin-top: 0">
            Random Number Generation
          </h2>
          <p>
            For each verifiable play, a client seed, a server seed, a nonce and a cursor
            are used as the input parameters for the
            <a
              href="https://en.wikipedia.org/wiki/Random_number_generation"
              target="_blank"
              >random number generation</a
            >
            function. This function utilises the cryptographic hash functions
            <a href="https://en.wikipedia.org/wiki/HMAC" target="_blank"
              >HMAC_SHA256 or HMAC_SHA512</a
            >
            to generate bytes which are then used as the foundation for how we generate
            provably fair random outcomes on our platform.
          </p>
          <br />
          <pre
            style="
              display: block;
              overflow-x: auto;
              padding: 0.5em;
              background: rgb(240, 240, 240);
              color: rgb(68, 68, 68);
            ">
                      <code><span style="color: rgb(136, 136, 136);" >// Random number generation based on following inputs: serverSeed, clientSeed, nonce and cursor</span>
    <span class="hljs-function"><span style="font-weight: bold;">function</span> <span style="color: rgb(136, 0, 0); font-weight: bold;">byteGenerator</span>(<span class="hljs-params">{ serverSeed, clientSeed, nonce, cursor }</span>) </span>{

      <span style="color: rgb(136, 136, 136);">// Setup curser variables</span>
      <span style="font-weight: bold;">let</span> currentRound = <span style="color: rgb(57, 115, 0);">Math</span>.floor(cursor / <span style="color: rgb(136, 0, 0);">32</span>);
      <span style="font-weight: bold;">let</span> currentRoundCursor = cursor; currentRoundCursor -= currentRound * <span style="color: rgb(136, 0, 0);" >32</span>;

      <span style="color: rgb(136, 136, 136);" >// Generate outputs until cursor requirement fullfilled</span>
      <span style="font-weight: bold;">while</span> (<span style="color: rgb(120, 169, 96);">true</span>) {

        <span  style="color: rgb(136, 136, 136);" >// HMAC function used to output provided inputs into bytes</span>
        <span style="color: rgb(136, 136, 136);">// sha512 used for Roulette instead</span>
        <span style="font-weight: bold;">const</span> hmac = createHmac(<span style="color: rgb(136, 0, 0);">'sha256'</span>, serverSeed);
        hmac.update(<span style="color: rgb(136, 0, 0);">`<span style="color: rgb(68, 68, 68);">${clientSeed}</span>:<span style="color: rgb(68, 68, 68);">${nonce}</span>:<span style="color: rgb(68, 68, 68);">${currentRound}</span>`</span>);
        <span style="font-weight: bold;">const</span> buffer = hmac.digest();

        <span
  style="color: rgb(136, 136, 136);"
>// Update curser for next iteration of loop</span>
        <span style="font-weight: bold;">while</span> (currentRoundCursor &lt; <span style="color: rgb(136, 0, 0);">32</span>) {
          <span style="font-weight: bold;">yield</span> <span style="color: rgb(57, 115, 0);">Number</span>(buffer[currentRoundCursor]);
          currentRoundCursor += <span style="color: rgb(136, 0, 0);">1</span>;
        }
        currentRoundCursor = <span style="color: rgb(136, 0, 0);">0</span>;
        currentRound += <span style="color: rgb(136, 0, 0);">1</span>;
      }
    }</code>
                    </pre>
          <div class="flex flex-col gap-2">
            <h2 class="font-bold text-lg my-2">Server Seed</h2>
            <p>
              The server seed is generated by our system as a random 64-character hex
              string. You are then provided with an encrypted hash of that generated
              server seed before you place any plays. The reason we provide you with the
              encrypted form of the server seed is to ensure that the un-hashed server
              seed cannot be changed by the website operator, and that the player cannot
              calculate the results beforehand.
            </p>
            <p>
              To reveal the server seed from its hashed version, the seed must be rotated
              by the player, which triggers the replacement with a newly generated one.
            </p>
            <p>
              From this point you are able to verify that the hashed server seed matches
              that of the un-hashed server seed. This process can be verified via our
              un-hashed server seed function found in the menu above.
            </p>
            <h2 class="font-bold text-lg my-2">Client Seed</h2>
            <p>
              The client seed belongs to the player and is used to ensure the player also
              has influence on the randomness of the outcomes generated. Without this
              component of the algorithm, the server seed alone would have complete
              leverage over the outcome of each play.
            </p>
            <p>
              All players are free to edit and change their client seed regularly to
              create a new chain of random upcoming outcomes. This ensures the player has
              absolute control over the generation of the result.
            </p>
            <p>
              During registration, a client seed is created for you by your browser, to
              ensure your initial experience with the site goes uninterrupted. Whilst this
              randomly generated client seed is considered suitable, we highly recommend
              that you choose your own, so that your influence is included in the
              randomness.
            </p>
            <p>You can do this via the fairness modal.</p>
            <h2 class="font-bold text-lg my-2">Nonce</h2>
            <p>
              The nonce is simply a number that increments as every new play is made. Due
              to the nature of the SHA256 and SHA512 cryptographic functions, this creates
              a completely new result each time, without having to generate a new client
              seed and server seed.
            </p>
            <p>
              The implementation of nonce, ensures we remain committed to your client seed
              and server seed pair, whilst generating new results for each play placed.
            </p>
            <h2 class="font-bold text-lg my-2">Cursor (Incremental Number)</h2>
            <p>
              We use 4 bytes of data to generate a single game result, and because SHA256
              and SHA512 are limited to 32 bytes, we utilise this implementation of a
              cursor to give us the ability to create more game events without having to
              modify our provable fair algorithm.
            </p>
            <p>
              The cursor is only iterated over when the game being played requires the
              generation of more than 8 (32 bytes / 4 bytes) possible outcomes. For
              example: when we need to use more than 8 cards in a game of blackjack.
            </p>
            <p>
              The cursor starts as 0 and gets increased by 1 every time the 32 bytes are
              returned by the hash function. If we don’t require more than 8 random
              numbers to be generated for the game events, then the cursor does not
              increment as there is no need to generate any additional possible game
              outcomes.
            </p>
            <p class="font-semibold">Games with more than 1 incremental number:</p>
            <div>
              <ul class="list list-disc px-4">
                <li>Blackjack (Unlimited to cover required amount of cards)</li>
                <li>Slots (The incremental number is only utilised for bonus rounds)</li>
              </ul>
            </div>
            <p class="font-semibold">
              <strong
                >Games with only 1 incremental number (represented as default value
                0):</strong
              >
            </p>
            <div>
              <ul class="list list-disc px-4">
                <li>Dice</li>
                <li>Limbo</li>
                <li>Roulette</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Implementation',
};
</script>
