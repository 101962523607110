import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application

const warnings = {
  ETH: {
    deposit: [
      'RuneBet does not support ERC20 tokens or any other form of tokens. Please only send Ethereum to your Ethereum address.',
    ],
    withdrawal: [
      'RuneBet does not support ERC20 tokens or any other form of tokens. Please only send Ethereum to your Ethereum address.',
    ],
  },
  XRP: {
    deposit: [
      'Important: The tag must be provided as part of the transaction to ensure your funds are sent to the correct location.',
    ],
    withdrawal: [],
  },
  'BNB-BSC': {
    deposit: [
      'Please only send BNB to your BNB address on the BEP20 network. We do not accept any other networks.',
    ],
    withdrawal: [],
  },
  TRX: {
    deposit: ['RuneBet does not accept TRX smart contracts'],
    withdrawal: ['RuneBet does not accept TRX smart contracts'],
  },
  EOS: {
    deposit: [
      'Important: The tag must be provided as part of the transaction to ensure your funds are sent to the correct location.',
    ],
    withdrawal: ['RuneBet does not accept TRX smart contracts'],
  },
};

export const ordersStore = defineStore('orders', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      updated: '',
    };
  },
  getters: {},
  actions: {
    async deposit(network) {
      const body = { currency: this.selected.name };
      if (network) {
        body.currency = network.name;
      }
      return await axios.post('/coins', body);
    },
    async rolloverProgress() {
      return await axios.get('/rollover');
    },
    async withdraw(currency, amount, address, tag, network = null) {
      const body = { currency, amount, address, tag };
      if (network) {
        body.network = network.id;
      }
      return await axios.post('/coins/withdraw', body);
    },
    async sendtip(form) {
      let action = 'tip';
      let username = form.username + ' ' + form.amount;
      //concatenate username and amount
      let meta = {};
      return await axios.post('chat_actions', { action, username, meta });
    },
    getWarnings(currency, type) {
      if (currency in warnings) {
        return warnings[currency][type];
      }
      return [];
    },
    onOrdersUpdated() {
      this.updated = parseInt(Date.now() / 1000);
    },
  },
});
