<template>
  <div class="playing-card border-2 border-white">
    <div
      class="playing-card-inner card-size"
      :class="{
        'is-revealed': revealed,
        'card-black': color == 0,
        'card-red': color == 1,
      }"
      :style="`opacity:${loaded ? 1 : 0.5};`">
      <div
        class="card--face card--front card-size"
        :class="{ 'playing-card-result': revealed }"
        :style="`border-width:${borderWith}rem !important;border-radius:${
          allFlipped ? borderWith * 1.5 : 0.5
        }rem !important; border-color:${borderColor} !important`">
        <span class="value">{{ valueDisplay }}</span>
        <span class="suit z-20">{{ suitDisplay }}</span>
      </div>
      <div v-if="!revealed" class="card--face card--back card-size">
        <img class="p-4" src="../../assets/logo.png" alt="RuneBet" />
      </div>
    </div>
  </div>
</template>

<script>
import { SUIT_COLORS, SUITS } from './PlayingCards';
import { flipSound } from './sounds';

export default {
  name: 'Card',
  props: {
    player: {},
    allFlipped: { default: false },
    me: {},
  },
  methods: {
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.cloneNode().play();
      }
    },
  },
  computed: {
    color() {
      switch (this.suit) {
        case SUITS.CLUBS:
          return SUIT_COLORS.CLUBS;

        case SUITS.DIAMONDS:
          return SUIT_COLORS.DIAMONDS;

        case SUITS.HEARTS:
          return SUIT_COLORS.HEARTS;

        case SUITS.SPADES:
          return SUIT_COLORS.SPADES;
      }
    },
    valueDisplay() {
      if (this.value >= 2 && this.value <= 10) {
        return this.value;
      }

      switch (this.value) {
        case 1:
          return 'A';
        case 11:
          return 'J';
        case 12:
          return 'Q';
        case 13:
          return 'K';
      }
    },
    suitDisplay() {
      switch (this.suit) {
        case SUITS.CLUBS:
          return '♣';

        case SUITS.DIAMONDS:
          return '♦';

        case SUITS.HEARTS:
          return '♥';

        case SUITS.SPADES:
          return '♠';
      }
    },
    suit() {
      return this.player && this.player.result !== null ? this.player.result.suit : 0;
    },
    value() {
      return this.player.result !== null ? this.player.result.rank : 4;
    },
    revealed() {
      return this.player.user !== null && this.player.result !== null;
    },
    loaded() {
      return this.player.user !== null;
    },
    won() {
      return (
        this.player.result !== null &&
        this.player.profit !== null &&
        this.player.profit > 0
      );
    },
    draw() {
      return (
        this.player.result !== null &&
        this.player.profit !== null &&
        this.player.profit === 0
      );
    },
    isMe() {
      return (
        this.player && this.player.user != null && this.player.user.id === this.me.id
      );
    },
    borderWith() {
      return this.revealed && this.allFlipped
        ? this.isMe
          ? 0.4
          : this.won
            ? 0.3
            : 0.3
        : 0;
    },
    borderColor() {
      return this.revealed
        ? this.won
          ? 'lime'
          : this.draw
            ? '#FDDE00'
            : '#FF3860'
        : this.loaded
          ? '#FFF'
          : '#889197';
    },
  },
  watch: {
    revealed: {
      handler: function (newState) {
        if (newState) {
          this.playSound(flipSound);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.playing-card-result {
  border-style: solid !important;
}

.card-size {
  min-width: 90px;
  min-height: 144px;
  max-width: 90px;
  max-height: 144px;
}

.playing-card {
  min-width: 90px;
  min-height: 144px;
  max-width: 90px;
  max-height: 144px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 2px;
  box-shadow: none;

  .playing-card-inner {
    position: relative;
    padding: 0 !important;
    transition: transform 400ms;
    transform-style: preserve-3d;
    transform-origin: center right;
    background: none;
    border-radius: 0rem;
    box-shadow:
      0 4px 3px rgba(10, 10, 10, 0.1),
      0 0 4px 3px rgba(10, 10, 10, 0.1);

    .card--face {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      backface-visibility: hidden;
      -webkit-font-smoothing: subpixel-antialiased;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 0.5rem;
    }

    .card--front {
      transform: translateZ(0) rotateY(180deg);
      background: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.25rem;
      font-size: 2rem;
      font-family: 'Brandon Grotesque';
      font-weight: bold;
      > .value {
        display: inline-block;
        width: 2.25rem;
        text-align: center;
        line-height: 2.5rem;
      }
      .suit {
        font-size: 2.25rem;
        line-height: 2.75rem;
      }
    }

    .card--back {
      // background-color: #26444F;
      background-color: #3579f6;
      border: 0.25rem solid #ffffff !important;
    }

    &.is-revealed {
      transform: translateZ(0) translateX(-100%) rotateY(180deg);
    }

    &.card-black {
      color: black !important;
    }

    &.card-red {
      color: rgb(255, 33, 71) !important;
    }
  }
}
@media (max-width: 500px) {
  .playing-card {
    min-width: 56px;
    min-height: 90px;
    max-width: 56px;
    max-height: 90px;
  }
  .card-size {
    min-width: 56px;
    min-height: 90px;
    max-width: 56px;
    max-height: 90px;
  }
}
</style>
