<template>
  <div class="w-full relative flex flex-row justify-center">
    <img
      class="absolute h-28 md:h-56 md:-left-2 left-2 top-12 rotate-[-27deg]"
      src="../../assets/svg/bigDice.png"
      alt="a green diamong png" />
    <img class="absolute" src="../../assets/svg/stars.svg" alt="Stars background" />
    <img
      class="absolute h-40 w-40 md:right-20 right-0 top-6 md:top-5 rotate-[0deg]"
      src="../../assets/svg/diamond.png"
      alt="a green diamong png" />
    <div
      class="relative flex flex-col m-8 w-full p-8 rounded-2xl justify-center items-center"
      style="background: rgba(196, 216, 226, 0.03)">
      <h1 class="font-semibold text-center text-2xl italic text-slate-300">RuneBet</h1>
      <p class="mt-4 text-center w-3/4 text-slate-300 text-sm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
      </p>
      <div class="flex flex-col py-4 space-y-4 w-1/2 mt-4">
        <button class="bg-chat-top-color rounded-lg py-2">Register instantly</button>
        <div class="flex flex-row items-center w-full justify-center space-x-4">
          <span class="flex-1 h-[1px] opacity-50 bg-[#5289A6]" />
          <p class="text-text-dim-color text-xs">Or</p>
          <span class="flex-1 h-[1px] opacity-50 bg-[#5289A6]" />
        </div>
        <button
          class="bg-chat-top-color rounded-lg py-2"
          style="background: rgba(255, 255, 255, 0.05)">
          Sign in with google
        </button>
        <img
          class="absolute h-32 left-2 md:left-16 rotate-[300deg]"
          src="../../assets/svg/diamond.png"
          alt="a green diamong png" />
      </div>
    </div>
    <img
      class="absolute -right-4 bottom-12 h-48 h-48 rotate-[60deg]"
      src="../../assets/svg/bigDice.png"
      alt="a green diamong png" />
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>
