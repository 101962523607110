import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/win.mp3';
import place from '../../assets/media/place.mp3';
import spinning from '../../assets/media/spinning.mp3';
import bouncing from '../../assets/media/bouncing.mp3';
import loose from '../../assets/media/loose.mp3';
import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const winSound = new Howl({
  src: [win],
});

const looseSound = new Howl({
  src: [loose],
});

const placeSound = new Howl({
  src: [place],
});

const spinningSound = new Howl({
  src: [spinning],
});

const bouncingSound = new Howl({
  src: [bouncing],
});

export { betSound, spinningSound, winSound, bouncingSound, placeSound, looseSound };
