<template>
  <div class="py-2 md:py-4">
    <div v-if="reloadStatus">
      <form @submit.stop.prevent="claimReload">
        <div class="flex flex-col text-secondary-text/60 justify-center items-center">
          <span class="text-center">Next Reload Available in:</span>
        </div>

        <div
          class="flex flex-row bg-tertsiary justify-center items-center font-bold text-3xl px-4 py-2 text-slate-300 rounded-2xl">
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.hours }}
            <span class="text-xs text-secondary-text mt-2">HOURS</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.minutes }}
            <span class="text-xs text-secondary-text mt-2">MINUTES</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.seconds }}
            <span class="text-xs text-secondary-text mt-2">SECONDS</span>
          </div>
        </div>

        <div class="flex flex-col items-center mt-4" v-if="!reloadStatus.cooldown">
          <div class="w-1/2 max-[480px]:w-auto">
            <label
              class="block my-2 mx-0.5 text-sm font-medium text-text-dim-color"
              for="sweeps-coins">
              Sweeps Coins
            </label>
            <div class="relative">
              <div
                class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img class="h-6 w-6" :src="getBalanceIcon('SC')" />
              </div>
              <input
                id="sweeps-coins"
                :value="reloadStatus.amount"
                class="input-with-icon font-bold bg-secondary w-full"
                placeholder="Enter Amount"
                required
                readonly
                disabled
                step="any"
                type="number" />
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-center mt-4">
          <button
            :disabled="!canClaim"
            class="btn-bet hover:opacity-75 disabled:opacity-30 text-white rounded-2xl px-4 py-3 my-4 w-1/2 -align-center max-[480px]:w-auto">
            {{ loading ? 'Please wait...' : 'Claim Reload' }}
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <p>Loading reload status...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getBalanceIcon } from '@/lib/icons';

export default {
  name: 'Reload',
  data() {
    return {
      loading: false,
      reloadStatus: null,
      remainingTime: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      interval: null,
      nextClaimTime: null,
    };
  },
  computed: {
    canClaim() {
      return this.reloadStatus && !this.reloadStatus.cooldown && !this.loading;
    },
  },
  mounted() {
    this.fetchReloadStatus();
  },
  methods: {
    getBalanceIcon,
    async fetchReloadStatus() {
      try {
        const response = await axios.get('/reload/get-status');
        this.reloadStatus = response.data;
        this.setNextClaimTime();
        this.startTimer();
      } catch (error) {
        console.error('Error fetching reload status:', error);
        this.$toast.error('Error fetching reload status');
      }
    },
    setNextClaimTime() {
      if (this.reloadStatus.cooldown) {
        this.nextClaimTime = new Date(Date.now() + this.reloadStatus.timer * 1000);
      } else {
        this.nextClaimTime = null;
      }
    },
    startTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      const updateTimer = () => {
        if (this.nextClaimTime) {
          const currentTime = new Date();
          let duration = this.nextClaimTime.getTime() - currentTime.getTime();

          if (duration <= 0) {
            clearInterval(this.interval);
            this.remainingTime = { hours: '00', minutes: '00', seconds: '00' };
            this.interval = null;
            return;
          }

          let seconds = Math.floor((duration / 1000) % 60);
          let minutes = Math.floor((duration / (1000 * 60)) % 60);
          let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

          this.remainingTime = {
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            seconds: seconds.toString().padStart(2, '0'),
          };
        } else {
          this.remainingTime = { hours: '00', minutes: '00', seconds: '00' };
        }
      };

      updateTimer();
      this.interval = setInterval(updateTimer, 1000);
    },
    async claimReload() {
      this.loading = true;
      try {
        await axios.post('/reload/claim');
        this.$toast.success('Reload claimed successfully');
        await this.fetchReloadStatus(); // Fetch updated status after claiming
      } catch (error) {
        this.$toast.error(error.response?.data?.error || 'Error claiming reload');
      } finally {
        this.loading = false;
      }
    },
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>