import { find as n, get as e, keyBy as r } from 'lodash';
const t = [
  { label: 'T', value: 1e12 },
  { label: 'B', value: 1e9 },
  { label: 'M', value: 1e6 },
  { label: 'K', value: 1e3 },
  { label: '', value: 1 },
];
const l = (t) => t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const o = t.filter((t) => t.value <= 1e12);
export class Formatter {
  constructor(t) {
    this.t = t;
  }
  format(t = null) {
    let e = t || l;
    return e(this.t);
  }
}
export class Converter {
  constructor(t) {
    this.o = t.map((t) => ({ ...t, label: t.label.toUpperCase() }));
    this.l = r(t, 'label');
  }
  u(t) {
    return e(this.l, t.toUpperCase());
  }
  i(t, e) {
    const r = Math.abs(t);
    return n(this.o, (t) => {
      return t.value >= e && t.value <= r;
    });
  }
  format(t, e = 1, r = 3) {
    if (!t || t === 0) {
      return '0';
    }
    const n = this.i(t, e);
    const o = l((t / n.value).toFixed(r));
    return o.replace(/\.?0+$/, '') + n.label;
  }
  normalize(t) {
    let e = t.indexOf(',');
    let r = t.indexOf('.');
    if (e > r) {
      return t.replace(/,/g, '.').trim().toUpperCase();
    }
    return t.trim().toUpperCase();
  }
  parse(t) {
    t = this.normalize(t || '');
    if (t === null || t === undefined || t.length === 0) {
      return new Formatter(0);
    }
    let e = Math.round(parseFloat(t));
    const r = this.u(t[t.length - 1]);
    if (r) {
      const n = t.substring(0, t.length - 1);
      if (n.length === 0) {
        return new Formatter(0);
      }
      e = Math.round(n * r.value);
    }
    return new Formatter(e);
  }
}
const a = new Converter(o);
export const parse = function (t) {
  return a.parse(t).t;
};
export const format = function (t) {
  return a.format(t);
};
