<template>
  <div class="bg-secondary p-2 rounded-lg">
    <span class="text-lg bold font-bold">Privacy</span>
    <br />
    <span class="text-sm"
      >User privacy is one of the core values of RuneBet. These settings allow you to be
      completely anonymous from the rest of the players.</span
    >
    <div class="mt-4">
      <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
    </div>
    <div class="my-4">
      <SwitchGroup>
        <div class="flex items-center py-2">
          <Switch
            v-model="enableGhostMode"
            :class="enableGhostMode ? ' bg-[#F02665]' : 'bg-primary'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="enableGhostMode ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300"
            >Enable Ghost Mode</SwitchLabel
          >
        </div>
      </SwitchGroup>
      <SwitchGroup>
        <div class="flex items-center py-2">
          <Switch
            v-model="hideAllStats"
            :class="hideAllStats ? ' bg-[#F02665]' : 'bg-primary'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="hideAllStats ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300"
            >Hide all your statistics</SwitchLabel
          >
        </div>
      </SwitchGroup>
      <SwitchGroup>
        <div class="flex items-center py-2">
          <Switch
            v-model="hideRaceStats"
            :class="hideRaceStats ? 'bg-[#F02665]' : 'bg-primary'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="hideRaceStats ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300">
            Hide all your race statistics</SwitchLabel
          >
        </div>
      </SwitchGroup>
    </div>
    <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
    <ul>
      <li>
        <span class="text-xs text-gray-400 py-2"
          >Please allow up to 30 seconds for update to take effect.</span
        >
      </li>
      <li><button type="submit" class="btn-bet">Save</button></li>
    </ul>

    <span class="flex h-[1px] mt-4 opacity-50 bg-line-color-bright" />

    <div class="mt-6">
      <span class="text-lg bold font-bold">Self Exclusion</span>
      <br />
      <span class="text-sm"
        >Need a break from RuneBet? To start the automated self exclusion process, please
        click the button below to receive confirmation instructions via email.</span
      >
      <div class="mt-4">
        <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
      </div>

      <button type="submit" class="btn-bet">Request Self Exclusion</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preferences',
  data() {
    return {
      enableGhostMode: false,
      hideAllStats: false,
      hideRaceStats: false,
    };
  },
};
</script>
