<template>
  <div class="bac-container">
    <div
      v-if="profit !== null"
      :class="profitTypeClasses"
      class="game-result scale-90 md:scale-100">
      <div class="flex flex-col">
        <!--  style="margin-top: 10px;font-size: 2em;font-weight: bolder"      -->
        <span class="mt-2 md:mt-4 font-bold text-lg">{{ this.multiplier }}</span>
        <span class="mt-2 md:mt-4 flex flex-row items-center justify-center gap-2">
          {{ balS.exchangeAndFormatAmount(this.profit) }}
          <img :src="getBalanceIcon(game.game_mode)" class="h-4 w-4 inline" />
        </span>
      </div>
    </div>
    <div class="bac-deck absolute -top-20 right-0 opacity-0 mx-4 md:opacity-100">
      <div class="bac-deck-table">
        <div class="deck p-2">
          <img alt="RuneBet" src="../../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="bac-inner">
      <div
        class="flex flex-row justify-evenly items-center is-mobile banker-player-decks"
        style="width: 100%">
        <div class="md:w-1/2">
          <div v-if="playerCards.length > 0" class="bc-tag-container">
            <span class="px-4 text-base rounded-2xl bg-slate-600">{{ playerTotal }}</span>
          </div>
          <div class="flex flex-row items-center justify-center">
            <div class="bac-card-wrap bac-player-hand relative">
              <card
                v-for="(card, i) in playerCards"
                :key="i"
                :all-flipped="allFlipped"
                :class="{ 'in-deck-player': card.decked }"
                :result="game.result"
                :revealed="card.revealed"
                :style="`z-index:${i + 4} !important;${getCardStackStyle(i)}`"
                :suit="card.suit"
                :value="card.rank"
                class="absolute top-0 left-0"
                type="PLAYER" />
            </div>
          </div>
        </div>
        <div class="md:w-1/2">
          <div v-if="playerCards.length > 0" class="bc-tag-container">
            <span class="px-4 text-base rounded-2xl bg-slate-600">{{ bankerTotal }}</span>
          </div>
          <div class="flex flex-row items-center justify-center">
            <div class="bac-card-wrap bac-player-hand relative">
              <card
                v-for="(card, i) in bankerCards"
                :key="i"
                :all-flipped="allFlipped"
                :class="{ 'in-deck-banker': card.decked }"
                :result="game.result"
                :revealed="card.revealed"
                :style="getCardStackStyle(i)"
                :suit="card.suit"
                :value="card.rank"
                type="BANKER" />
            </div>
          </div>
        </div>
      </div>
      <!-- Bottom -->
      <div>
        <img
          src="../../assets/svg/bac_tie_pays.svg"
          class="scale-90 md:scale-100 opacity-80"
          style="height: 50px" />
      </div>
      <div class="flex gap-2 md:gap-4">
        <div class="flex scale-90 md:scale-100">
          <button
            :disabled="currentState === 'inProgress' || autoBetInProgress"
            class="flex flex-col px-6 md:items-center md:justify-center justify-end pb-2 items-start h-20 md:px-12 rounded-lg bg-side-color hover:brightness-125 relative ring-2 ring-slate-300/50"
            v-on:click.prevent="onTileClicked('PLAYER')">
            <p class="font-bold text-left md:text-lg text-sm">Player</p>
            <div class="flex">
              <span class="flex gap-1 text-xs md:font-semibold">
                {{ balS.formatCrypto(formatAmount(wagers.player), true) }}
                <img :src="getBalanceIcon(balS.mainCurrency)" class="h-4 w-4 inline" />
              </span>
            </div>
            <div class="absolute top-5 md:bottom-0 md:top-8 right-1 z-50">
              <div class="wrap">
                <div
                  v-for="(stack, index) in buildStack(wagers.player)"
                  :key="index"
                  :style="getStackStyle(index)">
                  <bac-chip
                    key="stack."
                    :color="stack.color"
                    :display="stack.displayValue"
                    :size="bacsize"
                    :value="stack.value" />
                </div>
              </div>
            </div>
          </button>
        </div>
        <div class="flex scale-90 md:scale-100">
          <button
            :disabled="currentState === 'inProgress' || autoBetInProgress"
            class="flex flex-col px-6 md:items-center md:justify-center justify-end pb-2 items-start h-20 md:px-12 rounded-lg bg-side-color hover:brightness-125 relative ring-2 ring-slate-300/50"
            v-on:click.prevent="onTileClicked('TIE')">
            <p class="font-bold text-left md:text-lg text-sm">Tie</p>
            <div class="flex">
              <span
                class="flex gap-1 items-center justify-center text-xs md:font-semibold">
                {{ balS.formatCrypto(formatAmount(wagers.tie), true) }}
                <img :src="getBalanceIcon(balS.mainCurrency)" class="h-4 w-4 inline" />
              </span>
            </div>
            <div class="absolute top-5 md:bottom-0 md:top-8 right-1 z-50">
              <div class="wrap">
                <div
                  v-for="(stack, index) in buildStack(wagers.tie)"
                  :key="index"
                  :style="getStackStyle(index)">
                  <bac-chip
                    :color="stack.color"
                    :display="stack.displayValue"
                    :size="bacsize"
                    :value="stack.value" />
                </div>
              </div>
            </div>
          </button>
        </div>
        <div class="bac-tile scale-90 md:scale-100">
          <button
            :disabled="currentState === 'inProgress' || autoBetInProgress"
            class="flex flex-col px-6 md:items-center md:justify-center justify-end pb-2 items-start h-20 md:px-12 rounded-lg bg-side-color hover:brightness-125 relative ring-2 ring-slate-300/50"
            v-on:click.prevent="onTileClicked('BANKER')">
            <p class="font-bold text-left md:text-lg text-sm">Banker</p>
            <div class="flex">
              <span
                class="flex gap-1 items-center justify-center text-xs md:font-semibold">
                {{ balS.formatCrypto(formatAmount(wagers.banker), true) }}
                <img :src="getBalanceIcon(balS.mainCurrency)" class="h-4 w-4 inline" />
              </span>
            </div>
            <div class="absolute top-5 md:bottom-0 md:top-8 right-1 z-50">
              <div class="wrap">
                <div
                  v-for="(stack, index) in buildStack(wagers.banker)"
                  :key="index"
                  :style="getStackStyle(index)">
                  <bac-chip
                    :color="stack.color"
                    :display="stack.displayValue"
                    :size="bacsize"
                    :value="stack.value" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="pt-4 mt-6 md:mt-0">
      <div class="items-center flex absolute bottom-0 mx-2 gap-2 mb-2 z-50 left-0">
        <button
          v-on:click.prevent="undoWager"
          class="bg-secondary-light p-2 py-1 h-6 w-8 md:p-3 md:h-10 md:w-12 md:pl-4 md:py-0 rounded-2xl items-center hover:bg-[#335B70]">
          <img src="../../assets/svg/roulette/undo.svg" />
        </button>
        <span>Undo</span>
      </div>
      <div class="items-center flex absolute bottom-0 gap-2 mb-2 mx-2 z-50 right-0">
        <span>Clear</span>
        <button
          v-on:click.prevent="clearWager"
          class="bg-secondary-light p-2 py-0 h-6 w-8 md:p-3 md:h-10 md:w-12 md:pl-4 md:py-0 rounded-2xl items-center hover:bg-[#335B70]">
          <img src="../../assets/svg/roulette/delete.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { convertGameMode, defaultConvertor } from '@/lib/rsgp';
import { abbrNumber, formatFloat } from '@/lib/utils';
import debounce from 'lodash/debounce';
import Card from './Card.vue';
import BacChip from './BacChip.vue';
import { CHIPS, DEFAULT_CHIP } from './PlayingCards';
import { dealSound, flipSound, loseSound, winSound } from './sounds';
import { mapGetters } from 'vuex';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'BaccaratGrid',
  components: {
    BacChip,
    Card,
  },
  setup() {
    const bacsize = {
      width: '35',
      height: '35',
    };

    if (isMobile) {
      bacsize.width = '32';
      bacsize.height = '32';
    } else {
      bacsize.width = '35';
      bacsize.height = '35';
    }

    return { formatFloat, abbrNumber, balS: balanceStore(), getBalanceIcon, bacsize };
  },
  props: {
    currentState: {
      default: 'notStarted',
    },
    me: {},
    wagers: {},
    onTileClicked: {},
    game: {},
    setGameListeners: {},
    changeState: {},
    undoWager: {},
    clearWager: {},
    autoBetInProgress: {},
  },
  created() {
    this.setGameListeners(this.onGameCompleted, this.onGameStarted);
    this.debouncedPlaySound = debounce(this.playSound, 100, {
      leading: true,
      trailing: false,
    });
  },
  data: () => ({
    debouncedPlaySound: null,
    playerTotal: 0,
    bankerTotal: 0,
    profit: null,
    multiplier: null,
    allFlipped: false,
    playerCards: [],
    bankerCards: [],
    profitTypeClasses: '',
  }),

  methods: {
    async setup() {},
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    formatChips(x) {
      return defaultConvertor.formatChips(x);
    },
    formatAmount(val) {
      return (val * 1) / 1e8;
    },
    buildStack(value) {
      let amount = Math.floor(value);
      let stack = [];
      Array.from(CHIPS)
        .reverse()
        .forEach((chip) => {
          let no = Math.floor(amount / chip.value);
          for (let i = 0; i < no; i++) {
            stack.push(chip);
          }
          amount = amount % chip.value;
        });

      // fix value
      let rsgp = (x) => {
        let res = '';
        for (let i = 3; i >= 0; i--) {
          res = abbrNumber(x, 1, i);
          if (res.length <= 5) break;
        }
        return res;
      };
      if (stack.length > 0) {
        stack.push(
          Object.assign({}, stack.pop(), { displayValue: rsgp(Math.floor(value)) }),
        );
      }

      if (stack.length === 0 && amount > 0) {
        stack.push(
          Object.assign({}, DEFAULT_CHIP, { displayValue: rsgp(Math.floor(amount)) }),
        );
      }

      if (stack.length > 5) {
        let newStack = stack.slice(0, 4);
        newStack.push(stack.pop());
        return newStack;
      }

      return stack;
    },

    getStackStyle(index) {
      return index === 0
        ? ''
        : `position: absolute; top: 0; left: 0; transform: translate(0%, -${
            index * 15
          }%)`;
    },
    getCardStackStyle(index) {
      const transForm = index * 20;
      return index === 0
        ? ''
        : `position: absolute; top: 0; left: 0; transform: translate(${
            transForm * 2
          }%, ${transForm}%)`;
    },

    wait(period) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, period);
      });
    },
    reset() {
      this.allFlipped = false;
      this.profit = null;
      this.multiplier = '';
      this.playerCards = [];
      this.bankerCards = [];
      this.playerTotal = 0;
      this.bankerTotal = 0;
    },
    async dealCards() {
      this.reset();
      if (this.game === null) return;
      const plays = this.game.plays;
      const sum = (currentSum, rank) => (currentSum + (rank > 9 ? 0 : rank)) % 10;
      for (let i = 0; i < 8; i++) {
        if (i % 2 === 0) {
          if (plays.player.length > 0) {
            let card = { ...plays.player.shift(), decked: true, revealed: false };
            await this.wait(400);
            card.decked = false;
            this.playSound(dealSound);
            await this.wait(350);
            this.playSound(flipSound);
            card.revealed = true;
            this.playerTotal = sum(this.playerTotal, card.rank);
            this.playerCards.push(card);
            await this.wait(350);
          }
        } else {
          if (plays.banker.length > 0) {
            let card = { ...plays.banker.shift(), decked: true, revealed: false };

            await this.wait(150);
            card.decked = false;
            this.playSound(dealSound);
            await this.wait(250);
            this.playSound(flipSound);
            card.revealed = true;
            this.bankerTotal = sum(this.bankerTotal, card.rank);
            this.bankerCards.push(card);
            await this.wait(800);
          }
        }

        if (plays.player.length === 0 && plays.banker.length === 0) {
          break;
        }
      }

      this.bankerTotal = plays.banker_total;
      this.playerTotal = plays.player_total;

      // compute profit
      let netProfit = this.game.profit + this.game.wager;
      if (netProfit > 0) {
        this.profit = `${this.rsgp(netProfit)} ${convertGameMode(this.game.game_mode)}`;
        this.multiplier = `${(netProfit / this.game.wager).toFixed(2)}x`;
        this.profitTypeClasses =
          this.game.profit > 0 ? 'game-result-win' : 'game-result-partial';
      }
      if (this.game.profit !== 0) {
        this.playSound(this.game.profit > 0 ? winSound : loseSound);
      }
      this.allFlipped = true;
      this.changeState('notStarted');
    },
    onGameStarted() {
      this.profit = null;
    },
    onGameCompleted() {},
  },
  computed: {
    stacks() {
      const value = this.value;
      let amount = Math.floor(value);
      let stack = [];
      Array.from(CHIPS)
        .reverse()
        .forEach((chip) => {
          let no = Math.floor(amount / chip.value);
          for (let i = 0; i < no; i++) {
            stack.push(chip);
          }
          amount = amount % chip.value;
        });

      // fix value
      let rsgp = (x) => {
        let res = '';
        for (let i = 3; i >= 0; i--) {
          res = abbrNumber(x, 1, i);
          if (res.length <= 5) break;
        }
        return res;
      };
      if (stack.length > 0) {
        stack.push(
          Object.assign({}, stack.pop(), { displayValue: rsgp(Math.floor(value)) }),
        );
      }

      if (stack.length === 0 && amount > 0) {
        stack.push(
          Object.assign({}, DEFAULT_CHIP, { displayValue: rsgp(Math.floor(amount)) }),
        );
      }

      if (stack.length > 5) {
        let newStack = stack.slice(0, 4);
        newStack.push(stack.pop());
        return newStack;
      }

      return stack;
    },
    ...mapGetters('Sound', ['isSoundMuted']),
  },
  watch: {
    game: {
      handler: async function () {
        await this.dealCards();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.banker-player-decks {
  min-height: 200px !important;
  width: 100%;
}

@media (min-width: 500px) and (max-width: 1369px) {
  .banker-player-decks {
    min-height: 270px !important;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .banker-player-decks {
    min-height: 180px;
  }
}

.bc-tag-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bac-chip-stack {
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.bac-tile-button-label {
  color: #d5dceb;
  font-size: 1.4rem;
}

.bac-tile-button:hover:not([disabled]) {
  color: #fff;
  background: #26444f;
}

.bac-tile-button {
  color: #d5dceb;
  background: #1b323a;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.25rem;
  text-align: center;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 2em;
  transition: var(--transition-fast);
  font-size: 1em;
}

.bac-tiles {
  position: relative;
  max-width: 700px;
  width: 100%;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(3, 1fr);
}

.bac-card-wrap {
  position: relative;
  display: flex;
  justify-content: space-around;
  perspective: 800px;

  width: auto;
  min-height: 200px;
  margin-top: 30px;
  z-index: 2;

  .bac-playing-card.in-deck-banker {
    top: -8.85rem;
    right: -12.5rem;
    opacity: 0;
  }

  .bac-playing-card.in-deck-player {
    top: -8.85rem;
    right: -12.5rem;
    opacity: 0;
  }

  .banker-player-decks {
    min-height: 350px !important;
    width: 100%;
  }

  .bank-tile-wager {
    font-size: 1.5em;
  }

  @media (max-width: 500px) {
    .bank-tile-wager {
      font-size: 2em;
    }

    .banker-player-decks {
      min-height: 180px;
    }

    .bac-playing-card.in-deck-player {
      top: -8.85rem;
      right: -5.5rem;
      opacity: 0;
    }

    .bac-playing-card.in-deck-banker {
      top: -8.85rem;
      right: -1.5rem;
      opacity: 0;
    }
  }

  .bac-playing-card {
    transition:
      position 400ms linear,
      top 400ms linear,
      right 400ms ease-in,
      tansform 500ms ease-in;

    transform: translateX(0);

    top: 0;
    right: 0;
  }
}
</style>

<style lang="scss" scoped>
.bac-tag {
  margin-top: 2px;
  min-width: 50px;
  background-color: #2f4553;
  color: white;
}

.card-label {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.54;
}

.bac-card-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .bac-inner {
    min-height: 250px;
    font-size: 0.4em;
  }
}

@media (max-width: 768px) {
  .bac-inner {
    min-height: 350px;
    font-size: 0.4em;
  }
}

@media (min-width: 769px) and (max-width: 1369px) {
  .bac-inner {
    min-height: 550px;
    font-size: 0.6em;
  }
}

@media (min-width: 1370px) {
  .bac-inner {
    min-height: 550px;
    font-size: 1em;
  }
}

.game-result-content {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  animation-name: animate_game_result;
  -moz-animation-name: animate_game_result;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.game-result {
  padding: 15px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background: rgb(26, 44, 56, 0.9) !important;
  opacity: 0.8;
  border-radius: 0.5rem;
  z-index: 15;
  text-align: center;
  position: absolute;
  font-weight: 800;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  max-width: 100%;
  min-width: 150px;
  min-height: 35px;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
}

.game-result-win {
  color: #1ee563;
  border: 4px solid #1ee563;
}

.game-result-partial {
  color: #b1bad3;
  border: 4px solid #557086;
}

.bac-options {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 780px;
  grid-template-columns: repeat(4, auto);
  gap: 1em;
}

@media (max-width: 500px) {
  .bac-options {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }
}

.bac-container {
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  user-select: none;
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

.bac-inner {
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 1em;
  gap: 1em;
}

.main-content {
  display: flex;
  flex-direction: column;

  > .is-fullwidth {
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .bac-deck {
    flex-direction: column-reverse;
    display: flex;
  }

  .bac-deck-table {
    border-radius: 1rem 1rem 0 0;
  }
}

@media (min-width: 769px) {
  .bac-deck-table {
    border-radius: 0 1rem 1rem 0;
  }
}

.bac-deck-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  transform: rotate(0deg);

  .deck {
    width: 76px;
    height: 120px;
    border-radius: 0.5rem;
    background-color: #3579f6;
    border: 0.25rem solid #ffffff;

    display: flex;
    justify-content: center;
    flex-direction: column;

    box-shadow:
      0 0.02rem 0 0 #d6d6d6,
      0 0.1rem 0 0 #ffffff,
      0 0.12rem 0 0 #d6d6d6,
      0 0.24rem 0 0 #ffffff,
      0 0.26rem 0 0 #d6d6d6,
      0 0.34rem 0 0 #ffffff,
      0 0.36rem 0 0 #d6d6d6,
      0 0.44rem 0 0 #ffffff,
      0 0.46rem 0 0 #d6d6d6,
      0 0.54rem 0 0 #ffffff,
      0 0.56rem 0 0 #d6d6d6;
  }

  .deck:before,
  .deck:after {
    content: '';
    position: absolute;
    width: 76px;
    height: 120px;
    top: 0px;
    margin-left: 20px;
    margin-right: 20px;

    background-image: radial-gradient(circle at center, white 3px, transparent 3px),
      radial-gradient(circle at center, white 3px, transparent 3px);
    background-size: 25px 25px;
    background-position:
      top center,
      bottom center;
    background-repeat: no-repeat;
  }

  .deck:before {
    left: 0px;
  }

  .deck:after {
    right: 0px;
  }
}
</style>
