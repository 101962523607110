import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

export const GiveawayStores = defineStore('giveawaystore', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async createNew(params) {
      return await axios.post('giveaways/new', params);
    },
    async fetchAll(params) {
      return await axios.get('giveaways/active', { params });
    },
    async joinGiveaway(id) {
      return await axios.post('giveaways/join/' + id);
    },
    async fetch(id) {
      return await axios.get('giveaways/' + id);
    },
    async fetchSubscriber(id, params) {
      return await axios.get('giveaways/subscriber/' + id, { params });
    },
    async fetchWinners(id, params) {
      return await axios.get('giveaways/winner/' + id, { params });
    },
    async rollit(id) {
      return await axios.get('giveaways/rollit/' + id);
    },
  },
});
