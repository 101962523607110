const hex = {
  0: ['#ffc000', '#997300'],
  1: ['#ffa808', '#a16800'],
  2: ['#ffa808', '#a95b00'],
  3: ['#ff9010', '#a95b00'],
  4: ['#ff7818', '#914209'],
  5: ['#ff6020', '#b93500'],
  6: ['#ff4827', '#c01d00'],
  7: ['#ff302f', '#c80100'],
  8: ['#ff1837', '#91071c'],
  9: ['#ff003f', '#990026'],
};

const colors = {
  8: [hex[9], hex[7], hex[4], hex[2], hex[0], hex[2], hex[4], hex[7], hex[9]],
  9: [hex[9], hex[7], hex[6], hex[5], hex[2], hex[2], hex[5], hex[6], hex[7], hex[9]],
  10: [
    hex[9],
    hex[8],
    hex[7],
    hex[5],
    hex[4],
    hex[1],
    hex[4],
    hex[5],
    hex[7],
    hex[8],
    hex[9],
  ],
  11: [
    hex[9],
    hex[8],
    hex[7],
    hex[5],
    hex[4],
    hex[2],
    hex[2],
    hex[4],
    hex[5],
    hex[7],
    hex[8],
    hex[9],
  ],
  12: [
    hex[9],
    hex[8],
    hex[7],
    hex[6],
    hex[5],
    hex[4],
    hex[1],
    hex[4],
    hex[5],
    hex[6],
    hex[7],
    hex[8],
    hex[9],
  ],
  13: [
    hex[9],
    hex[8],
    hex[7],
    hex[6],
    hex[5],
    hex[4],
    hex[2],
    hex[2],
    hex[4],
    hex[5],
    hex[6],
    hex[7],
    hex[8],
    hex[9],
  ],
  14: [
    hex[9],
    hex[8],
    hex[7],
    hex[6],
    hex[5],
    hex[4],
    hex[3],
    hex[2],
    hex[3],
    hex[4],
    hex[5],
    hex[6],
    hex[7],
    hex[8],
    hex[9],
  ],
  15: [
    hex[9],
    hex[8],
    hex[7],
    hex[6],
    hex[5],
    hex[4],
    hex[3],
    hex[2],
    hex[2],
    hex[3],
    hex[4],
    hex[5],
    hex[6],
    hex[7],
    hex[8],
    hex[9],
  ],
  16: [
    hex[9],
    hex[8],
    hex[7],
    hex[6],
    hex[5],
    hex[4],
    hex[3],
    hex[2],
    hex[1],
    hex[2],
    hex[3],
    hex[4],
    hex[5],
    hex[6],
    hex[7],
    hex[8],
    hex[9],
  ],
};

export { colors };
