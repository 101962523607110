<template>
  <div class="bac-playing-card bac-card-inserting">
    <div
      class="bac-playing-card-inner bac-card-size"
      :class="{
        'is-revealed': revealed,
        'bac-card-black': color === 0,
        'bac-card-red': color === 1,
      }">
      <div
        class="bac-card--face bac-card--front bac-card-size"
        v-if="shouldReveal"
        :class="{ 'bac-playing-card-result': shouldReveal }"
        :style="`border-width:${borderWith}rem !important;border-radius:${
          allFlipped ? borderWith * 1.5 : 0.5
        }rem !important; border-color:${borderColor}!important`">
        <span class="value">{{ valueDisplay }}</span>
        <span class="suit">{{ suitDisplay }}</span>
      </div>
      <div
        v-else
        class="card--face card--back bac-card-size p-3"
        :style="`border-radius:${allFlipped ? borderWith * 1.5 : 0.5}rem !important;`">
        <img src="../../assets/logo.png" alt="RuneBet" />
      </div>
    </div>
  </div>
</template>

<script>
import { SUIT_COLORS, SUITS } from './PlayingCards';

export default {
  name: 'Card',
  props: {
    suit: {},
    value: {},
    type: {},
    result: {},
    revealed: {},
    allFlipped: { default: false },
  },
  data() {
    return {
      shouldReveal: false,
    };
  },
  computed: {
    color() {
      let suit = parseInt(this.suit);
      switch (suit) {
        case SUITS.CLUBS:
          return SUIT_COLORS.CLUBS;

        case SUITS.DIAMONDS:
          return SUIT_COLORS.DIAMONDS;

        case SUITS.HEARTS:
          return SUIT_COLORS.HEARTS;

        case SUITS.SPADES:
          return SUIT_COLORS.SPADES;
      }
    },
    valueDisplay() {
      let value = parseInt(this.value);
      if (value >= 2 && value <= 10) {
        return value;
      }

      switch (value) {
        case 1:
          return 'A';
        case 11:
          return 'J';
        case 12:
          return 'Q';
        case 13:
          return 'K';
      }
    },
    suitDisplay() {
      let suit = parseInt(this.suit);
      switch (suit) {
        case SUITS.CLUBS:
          return '♣';

        case SUITS.DIAMONDS:
          return '♦';

        case SUITS.HEARTS:
          return '♥';

        case SUITS.SPADES:
          return '♠';
      }
    },
    loaded() {
      return true; // this.player.user !== null
    },
    won() {
      return this.result === this.type;
    },
    draw() {
      return this.result === 'TIE';
    },
    borderWith() {
      return this.allFlipped ? 0.3 : 0;
    },
    borderColor() {
      return this.allFlipped
        ? this.won
          ? 'lime'
          : this.draw
            ? '#FDDE00'
            : '#FFF'
        : '#FFF';
    },
  },
  watch: {
    revealed: {
      handler: async function () {
        setTimeout(
          () => {
            this.shouldReveal = this.revealed;
          },
          this.revealed ? 50 : 0,
        );
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bac-playing-card-result {
  border-style: solid !important;
}

.bac-card-size {
  min-width: 101.25px;
  min-height: 162px;
  max-width: 101.25px;
  max-height: 162px;
}

.bac-playing-card {
  min-width: 101.25px;
  min-height: 162px;
  max-width: 101.25px;
  max-height: 162px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 2px;
  box-shadow: none;

  .bac-playing-card-inner {
    position: relative;
    padding: 0 !important;
    transition: transform 400ms;
    transform-style: preserve-3d;
    transform-origin: center right;
    background: none;
    border-radius: 0rem;
    box-shadow:
      0 4px 3px rgba(10, 10, 10, 0.1),
      0 0 4px 3px rgba(10, 10, 10, 0.1);

    .bac-card--face {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      backface-visibility: hidden;
      -webkit-font-smoothing: subpixel-antialiased;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 0.5rem;
    }

    .card--back {
      background-color: #3579f6;
      border: 0.25rem solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bac-card--front {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.25rem;
      font-size: 1.8rem;
      font-weight: bold;
      > .value {
        display: inline-block;
        width: 1.5rem;
        text-align: center;
        line-height: 3.5rem;
      }
      .suit {
        font-size: 2rem;
        line-height: 0.75rem;
      }

      @media (min-width: 768px) {
        > .value {
          font-size: 3rem;
          line-height: 3.75rem;
        }
        .suit {
          font-size: 2.5rem;
          line-height: 3.75rem;
        }
      }
    }

    .bac-card--back {
      background-color: #3579f6;
      border: 0.25rem solid #ffffff;
    }

    &.is-revealed {
      perspective: 1000px;
      animation: rotating 0.2s ease-out 1;
    }

    @keyframes rotating {
      /*0% {
          transform: translateZ(0) translateX(0%) rotateY(-0deg);
        }*/
      30% {
        transform: translateZ(0) translateX(-100%) rotateY(-180deg);
        transform-style: preserve-3d;
        opacity: 0;
      }
      100% {
        transform: translateZ(0) translateX(0%) rotateY(0deg);
        opacity: 1;
      }
    }

    &.bac-card-black {
      color: black !important;
    }

    &.bac-card-red {
      color: rgb(255, 33, 71) !important;
    }
  }
}

@media (min-width: 500px) and (max-width: 1369px) {
  .bac-playing-card {
    min-width: 84.375px;
    min-height: 135px;
    max-width: 84.375px;
    max-height: 135px;
  }
  .bac-card-size {
    min-width: 84.375px;
    min-height: 135px;
    max-width: 84.375px;
    max-height: 135px;
  }
}

@media (max-width: 500px) {
  .bac-playing-card {
    min-width: 56px;
    min-height: 90px;
    max-width: 56px;
    max-height: 90px;
  }
  .bac-card-size {
    min-width: 56px;
    min-height: 90px;
    max-width: 56px;
    max-height: 90px;
  }
}
</style>
