<template>
  <!--    <span class="chip"
            :class="{ 'stack-2': value == 2, 'stack-3': value == 3, 'stack-x': value > 3 }">
          {{ value }}
      </span>-->
  <div class="">
    <div class="bac-chip-stack z-20 is-hidden-mobile">
      <div class="wrap">
        <div v-for="(stack, index) in stacks" :key="index" :style="getStackStyle(index)">
          <span
            :style="`appearance: none; margin: 2px;padding: 2px; height: 33px; width:33px; background-color: ${stack.color};`"
            class="button bac-chip-item bg-chip-bg transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110">
            <span
              class="bac-chip-item-content text-center text-white text-xs rounded-full brightness-[80%] bg-inherit h-[20px] w-[20px]">
              {{ abbrNumber(total, 0) }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CHIPS, DEFAULT_CHIP } from '@/components/Roulette/PlayingCards';
import { abbrNumber } from '@/lib/utils';
import RolChip from './RoulChip.vue';

export default {
  name: 'Chip',
  components: { RolChip },
  setup() {
    return { abbrNumber };
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    total() {
      return this.value;
    },
    stacks() {
      const value = this.value;
      let amount = Math.floor(value);
      let stack = [];
      Array.from(CHIPS)
        .reverse()
        .forEach((chip) => {
          let no = Math.floor(amount / chip.value);
          for (let i = 0; i < no; i++) {
            stack.push(chip);
          }
          amount = amount % chip.value;
        });

      // fix value
      let rsgp = (x) => {
        let res = '';
        for (let i = 3; i >= 0; i--) {
          res = abbrNumber(x, 1, i);
          if (res.length <= 5) break;
        }
        return res;
      };
      if (stack.length > 0) {
        stack.push(
          Object.assign({}, stack.pop(), { displayValue: rsgp(Math.floor(value)) }),
        );
      }

      if (stack.length === 0 && amount > 0) {
        stack.push(
          Object.assign({}, DEFAULT_CHIP, { displayValue: rsgp(Math.floor(amount)) }),
        );
      }

      if (stack.length > 5) {
        let newStack = stack.slice(0, 4);
        newStack.push(stack.pop());
        return newStack;
      }

      return stack;
    },
  },
  methods: {
    getStackStyle(index) {
      return index === 0
        ? ''
        : `position: absolute; top: 0; left: 0; transform: translate(0%, -${
            index * 15
          }%)`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables';

.bac-chip-stack {
  position: absolute;
  bottom: -5%;
  right: 0;
}

.chip {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;

  margin-top: -0.8rem;

  text-align: center;
  line-height: 1.5rem;
  font-weight: 700;
  font-size: 0.8rem;

  border: 0.25px solid $chip-shadow;
  border-radius: 50%;
  background-color: $chip-gold;
  box-shadow: 0 0.2rem 0 0 $chip-shadow;
  user-select: none;

  color: #2e2e2e;

  &.stack-2 {
    transform: translateY(-0.3rem);
    box-shadow:
      0 0.2rem 0 0 $chip-shadow,
      0 0.4rem 0 0 $chip-gold,
      0 0.6rem 0 0 $chip-shadow;
  }

  &.stack-3 {
    transform: translateY(-0.5rem);
    box-shadow:
      0 0.2rem 0 0 $chip-shadow,
      0 0.4rem 0 0 $chip-gold,
      0 0.6rem 0 0 $chip-shadow,
      0 0.8rem 0 0 $chip-gold,
      0 1rem 0 0 $chip-shadow;
  }

  &.stack-x {
    transform: translateY(-0.5rem);
    box-shadow:
      0 0.2rem 0 0 $chip-shadow,
      0 0.4rem 0 0 $chip-gold,
      0 0.6rem 0 0 $chip-shadow,
      0 0.8rem 0 0 $chip-gold,
      0 1rem 0 0 $chip-shadow,
      -0.75rem 0rem 0 0 $chip-gold,
      -0.75rem 0.2rem 0 0 $chip-shadow,
      -0.75rem 0.4rem 0 0 $chip-gold,
      -0.75rem 0.6rem 0 0 $chip-shadow;
  }
}
</style>
