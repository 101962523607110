<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex-1 px-2 md:px-4 py-2">
      <div class="grid grid-cols-8 grid-rows-5 gap-2 md:gap-3 place-content-around">
        <button
          v-for="i in 40"
          v-bind:key="i"
          v-on:click.prevent="selectNumber(i)"
          :disabled="
            currentState === 'inProgress' || (numberOfSelected >= 10 && !selected[i])
          "
          class="option-button aspect-1"
          :class="
            (selected[i] ? 'selected' : '') + ' ' + (shown.includes(i) ? 'result' : '')
          "
          :ref="'button' + i">
          <div class="button-content">
            <span class="button-text font-bold text-xs md:text-lg">{{ i }}</span>
          </div>
        </button>
      </div>
    </div>
    <div class="flex-none w-full px-1 md:px-4 py-2 rounded-lg mt-6">
      <div
        class="keno-payouts grid gap-1 pb-1 justify-items-stretch !rounded-lg"
        :style="columnsStyle">
        <div
          v-for="j in numberOfSelected + 1"
          v-bind:key="j + '_' + numberOfSelected + '_payout'"
          class="payout-text text-[0.5rem] md:text-xs rounded md:rounded-lg justify-self-auto"
          :style="numberOfSelected ? '' : 'opacity:0'">
          {{ Number.parseFloat(riskPayments[risk][numberOfSelected][j - 1]).toFixed(2) }}
        </div>
      </div>
      <div class="keno-info px-1 mt-2" :style="columnsStyleExtended">
        <div v-if="!numberOfSelected" class="info-text">
          Select 1 - 10 numbers to play
        </div>
        <div
          v-else
          v-for="j in numberOfSelected + 1"
          v-bind:key="j + '_' + numberOfSelected + '_info'"
          class="info-payout-text text-[0.5rem] md:text-md"
          :style="
            currentState !== 'notStarted' &&
            currentState !== 'picking' &&
            numberOfCorrect == j - 1
              ? 'background: #1B323A; color: rgb(0, 177, 0)'
              : ''
          "
          @mouseover="hover = j"
          @mouseleave="hover = false">
          {{ j - 1 }}×
        </div>
        <div
          v-if="hover"
          class="chance-info drop-shadow-lg backdrop-grayscale flex flex-col col-span-2 rounded-t-xl">
          <div class="flex flex-row items-center justify-start gap-2">
            <div class="flex flex-col w-1/2">
              <span>Target Payout</span>
              <div class="flex flex-row items-end">
                <input
                  :value="
                    Number.parseFloat(
                      riskPayments[risk][numberOfSelected][hover - 1],
                    ).toFixed(2)
                  "
                  type="text"
                  class="bg-secondary h-10 placeholder:text-text-dim-color border border-secondary text-slate-300 text-sm rounded-xl font-semibold focus:ring-green-600 focus:border-green-600 p-2.5 w-full"
                  disabled />
                <span class="-m-5 mb-2">×</span>
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <span>Win Chance</span>
              <div class="flex flex-row items-end">
                <input
                  class="bg-secondary h-10 placeholder:text-text-dim-color border border-secondary text-slate-300 text-sm rounded-xl font-semibold focus:ring-green-600 focus:border-green-600 p-2.5 w-full"
                  :value="chances[numberOfSelected][hover - 1].toFixed(8)"
                  type="text"
                  disabled />
                <span class="-m-5 mb-2">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { winSound, hitSound, missSound } from './sounds';
export default {
  name: 'KenoGrid',
  props: {
    currentState: {
      default: 'notStarted',
    },
    selected: {},
    selectNumber: {},
    soundHandler: {},
    stateHandler: {},
    result: {},
    riskPayments: {
      default: () => ({ classic: [0] }),
    },
    risk: {
      default: 'classic',
    },
    numberOfSelected: {
      default: 0,
    },
  },
  data: () => ({
    hover: false,
    shown: [],
    chances: [
      [0],
      [75, 25],
      [55.76923077, 38.46153846, 5.76923077],
      [41.09311741, 44.02834008, 13.66396761, 1.2145749],
      [29.98686946, 44.42499179, 21.41919247, 3.93916183, 0.22978444],
      [21.6571835, 41.64842981, 27.76561987, 7.93303425, 0.95743517, 0.03829741],
      [
        15.46941679, 37.12660028, 32.12878871, 12.6928548, 2.37991027, 0.19695809,
        0.00547106,
      ],
      [
        10.91958832, 31.84879926, 34.3967032, 17.63933498, 4.57316092, 0.58797783,
        0.03379183, 0.00064365,
      ],
      [
        7.61062216, 26.47172926, 34.74414465, 22.23625258, 7.48335423, 1.33037409,
        0.1187834, 0.00468112, 0.00005851,
      ],
      [
        5.23230274, 21.40487483, 33.50328234, 26.05810849, 10.94440557, 2.52563205,
        0.31180643, 0.01909019, 0.00049371, 0.00000366,
      ],
      [
        3.54446314, 16.87839592, 31.07159249, 28.82002782, 14.71022253, 4.23654409,
        0.67893335, 0.05747584, 0.0023093, 0.00003539, 0.00000012,
      ],
    ],
  }),
  methods: {},
  computed: {
    numberOfCorrect() {
      let res = 0;
      for (let i = 1; i <= 40; ++i) {
        res += this.selected[i] && this.shown.includes(i);
      }

      return res;
    },
    columnsStyle() {
      return this.numberOfSelected
        ? 'grid-template-columns: repeat(' + (this.numberOfSelected + 1) + ', 1fr);'
        : '';
    },
    columnsStyleExtended() {
      return 'grid-template-columns: repeat(' + (this.numberOfSelected + 1) + ', 1fr);';
    },
  },
  watch: {
    result: {
      handler: function (newState, oldState) {
        this.shown = [];
        for (let i = 0; i < this.result.length; ++i) {
          setTimeout(
            function (self, res) {
              if (self.selected[res]) {
                self.soundHandler(hitSound);
              } else {
                self.soundHandler(missSound);
              }
              self.shown.push(res);
            },
            200 * (i + 1),
            this,
            this.result[i],
          );
        }
        if (this.result.length) {
          setTimeout(
            function (self) {
              const payment = self.riskPayments[self.risk];
              if (payment[self.numberOfSelected][self.numberOfCorrect] > 1) {
                self.soundHandler(winSound);
              }
            },
            (this.result.length + 1) * 200 + 150,
            this,
          );
          setTimeout(
            function (self) {
              self.stateHandler('finished');
            },
            (this.result.length + 1) * 200 + 700,
            this,
          );
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .option-button {
    min-height: 20px;
    max-height: 30px;
  }

  .keno-inner {
    font-size: 0.4em;
  }
}

@media (min-width: 769px) and (max-width: 1369px) {
  .keno-inner {
    height: 100%;
    font-size: 0.6em;
  }
}

@media (min-width: 1370px) {
  .keno-inner {
    height: 100%;
    font-size: 1em;
  }
}

.chance-info {
  color: rgb(213, 220, 235);
  font-size: 1rem;
  position: absolute;
  bottom: calc(100% + 4px);
  right: 0px;
  width: 100%;
  padding: 1rem;
  background: rgba(35, 63, 72, 0.98);
  overflow: visible;
  border-radius: 0.25rem;
}

.keno-options {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 780px;
  grid-template-columns: repeat(8, auto);
  gap: 1em;
}

.option-button {
  position: relative;
  color: rgb(255, 255, 255);
  transition-duration: 300ms;
  transition-property: background-color, box-shadow, opacity;
  border-radius: 0.3em;
  outline: none;
  background: #041E10;
}

.option-button.result {
  background-color: rgb(33, 77, 100, 0.5);
  border-color: rgb(33, 77, 100, 0.2);
  color: rgb(245, 128, 147);
}

.option-button.result:not(.selected) .button-content {
  box-shadow: rgb(33, 77, 100, 0.1) 0px 0.4em;
}

.option-button.result.selected .button-content {
  box-shadow: rgb(1, 82, 7) 0px 0.4em;
}

.option-button.selected.result {
  color: rgb(255, 255, 255);
}

.option-button.result.selected .button-content {
  background-color: rgb(0, 177, 0) !important;
}

.option-button.result:not(.selected) .button-content {
  background-color: rgb(33, 77, 100, 0.2) !important;
}

.option-button:not(:disabled) {
  cursor: pointer;
}

.option-button:disabled,
.option-button[disabled] {
  opacity: 0.95;
  cursor: not-allowed;
  pointer-events: none;
}

.button-content {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  animation-fill-mode: forwards;
  transition-duration: 300ms;
  transition-property: background-color, box-shadow, transform;
  box-shadow: #173647 0px 0.4em;
  animation-name: ibUtsH;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  border-radius: 0.3em;
}

.option-button.selected:not(.result):hover {
  border-color: #193b4d;
}

.option-button.selected:not(.result) .button-content {
  background-color: #972eff;
  box-shadow: #7100c7 0px 0.4em;
}

.option-button.selected:hover:not(.result) .button-content {
  opacity: 0.7;
  box-shadow: #7100c7 0px 0.45em;
}

.option-button:hover:not(.result) .button-content {
  box-shadow: #193b4d 0px 0.45em;
  transform: translateY(-0.15em);
  opacity: 0.7;
}

.button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.keno-footer {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  position: relative;
  min-height: 8em;
  max-height: 8em;
  gap: 1em;
}

.keno-payouts {
}

.keno-info {
  position: relative;
  color: rgb(255, 255, 255);
  display: grid;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px -1px;
  border-radius: 0.25rem;
  background: #1b323a;
  gap: 1em;
}

.keno-info > *,
.keno-payouts > * {
  grid-row: 1 / auto;
}

.keno-info > div:first-of-type {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.keno-info > div:last-of-type {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.payout-text {
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px -1px;
  padding: 1em 0px;
  background: #1b323a;
  transition: background 100ms ease 0s;
}

.info-payout-text,
.info-text {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: help;
  line-height: 1;
  white-space: nowrap;
  position: relative;
  padding: 1em 0px;
  font-size: 0.875rem;
  transition: background 100ms ease 0s;
}

@keyframes button-animate {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
