const AMERICAN_SLOT_SEQUENCE = [
  '0',
  '28',
  '9',
  '26',
  '30',
  '11',
  '7',
  '20',
  '32',
  '17',
  '5',
  '22',
  '34',
  '15',
  '3',
  '24',
  '36',
  '13',
  '1',
  '00',
  '27',
  '10',
  '25',
  '29',
  '12',
  '8',
  '19',
  '31',
  '18',
  '6',
  '21',
  '33',
  '16',
  '4',
  '23',
  '35',
  '14',
  '2',
];

const EUROPEAN_SLOT_SEQUENCE = [
  '0',
  '32',
  '15',
  '19',
  '4',
  '21',
  '2',
  '25',
  '17',
  '34',
  '6',
  '27',
  '13',
  '36',
  '11',
  '30',
  '8',
  '23',
  '10',
  '5',
  '24',
  '16',
  '33',
  '1',
  '20',
  '14',
  '31',
  '9',
  '22',
  '18',
  '29',
  '7',
  '28',
  '12',
  '35',
  '3',
  '26',
];

export const GetSlotColor = (i) => {
  i = parseInt(i);

  if (i === 0 || i === 37) {
    return RouletteColors.GREEN;
  }

  let in1to10 = i >= 1 && i <= 10;
  let in11to18 = i >= 11 && i <= 18;
  let in19to28 = i >= 19 && i <= 28;
  let in29to36 = i >= 29 && i <= 36;

  if (in1to10 || in19to28) {
    return i % 2 === 0 ? RouletteColors.BLACK : RouletteColors.RED;
  }

  if (in11to18 || in29to36) {
    return i % 2 === 0 ? RouletteColors.RED : RouletteColors.BLACK;
  }

  return RouletteColors.NONE;
};

export const RouletteColors = Object.freeze({
  BLACK: 2,
  RED: 1,
  GREEN: 0,
  NONE: -1,
});

export const RouletteLayouts = Object.freeze({
  AMERICAN: 1,
  EUROPEAN: 2,
  FRENCH: 3,
});

function RouletteLayout(layout) {
  if (layout === RouletteLayouts.AMERICAN) {
    this.sequence = AMERICAN_SLOT_SEQUENCE;
  } else if (layout === RouletteLayouts.EUROPEAN) {
    this.sequence = EUROPEAN_SLOT_SEQUENCE;
  }

  this.colors = this.sequence.map((i, x) => GetSlotColor(i));
}

export default RouletteLayout;
