<template>
  <div v-if="!allset">
    <div
      class="w-full loading-icon-body bg-jungle-green px-4 py-12 md:px-8 md:py-12 justify-center text-center">
      <img
        src="../../assets/logo.png"
        alt="Punt"
        class="w-250 max-[575px]:w-[200px] m-auto mb-2 mt-10" />
      <p class="big-text text-white">Loading</p>
    </div>
  </div>
  <div v-else>
    <technical-warning
      :wtitle="wtitle"
      v-if="
        !me.can_withdraw /*me.username != 'zangtop1'&& me.username!='stagetester' && me.username != 'Smite' && me.username != 'vishal123' */
      " />
    <verify-account
      v-else-if="me.verification_id === null"
      data-verifid="{me.verification_id}"
      :reverification="me.prev_verification_id"
      :context="me.prev_verification_id == null ? 'redeem' : 'make a redemption.'" />
    <div
      v-else-if="!me.email_verified"
      class="text-sm text-slate-300 border-slate-300 border-2 border-dashed mt-6 p-4 rounded-lg">
      <span> Please verify you email in order to proceed with your redemption.</span>
      <div class="text-text-dim-color hover:text-text-dim-color/70 font-bold">
        <span class="cursor-pointer" v-on:click="$router.push('/settings/general')"
          >Verify now</span
        >
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-1" v-if="rollover.rollover > 0">
        <div class="flex flex-1 flex-col justify-center">
          <div class="mt-6 flex py-2">
            <span class="flex-1 font-bold text-white"> Rollover Play Progress </span>
            <span class="font-bold text-white"> {{ rolloverProgress }}% </span>
          </div>

          <div class="h-2.5 w-full rounded-full bg-secondary-light">
            <div
              :style="{ width: rolloverProgress + '%' }"
              class="h-2.5 rounded-full bg-[#2DE3D1]"></div>
          </div>

          <div class="mb-6 flex py-2">
            <span class="flex-1 text-sm text-slate-300"> </span>
            <span class="inline text-sm text-slate-300">
              {{ Math.ceil(rollover.wager) }}/{{
                Math.ceil(rollover.amount * rollover.rollover)
              }}
            </span>
          </div>
        </div>

        <div
          class="rounded-lg border-2 border-dashed border-slate-300 p-4 text-sm text-slate-300">
          <p>Redemptions are not available while you have an active rollover.</p>
        </div>

        <div
          class="mt-8 rounded-lg border-2 border-dashed border-slate-300 p-4 text-sm text-slate-300">
       

        <P class="mb-3"> All Sweep Coins must be played through at minimum {{ rollover.multipler }}x in order to be eligible for redemption. For example, if you have 50 Sweep Coins you would need to have a playthrough of 150 Sweep Coins in order to redeem them as a prize. Any Sweep Coins that are acquired through giveaways have a 10x rollover/playthrough requirement. This applies only to SC acquired through bonus codes, hourly bonuses, social media contests, or giveaways.
        </P> <p> 
Here is a list of games that are excluded from the rollover calculation: Dice, Roulette, Limbo, Baccarat, Mines, Teen Patti and all Dare2Win games. Kindly note that both the Chanced originals as well the live dealer versions of the game are excluded. The Easy and Medium game modes of “Later Gator” AND “Clover Cross”  count towards your playthrough at 1/4th the play amount. For example, 100 SC played on Easy Mode would result in 25 SC playthrough progress.
      </p>
</div>
      </div>
      <div v-else-if="allset">
        <div class="p-4" v-if="withdrawalFormType == ''">
          <input v-model="withdrawalFormType" type="hidden" />
          <h2 class="mb-[22px] mr-4 text-x text-center font-semibold">
            Select Redemption Method
          </h2>
          <div class="redeem-fome">
            <div class="redeem-method mb-9">
              <button class="redeem-radio-wrap relative"  v-if="me.ach_enabled">
                <input
                  type="radio"
                  class="absolute redeem-radio left-0 top-0 opacity-0"
                  name="redeem"
                 
                  id="ach_radio"
                  v-on:click="onchangeRedemption($event, 'AptPay', 'ACH')" />
                <label for="ach_radio">
                  <img src="/images/bank/ach.png" class="redeem-image" alt="ach Images" />
                  <span class="redeem-radio-span"><span></span></span>
                  Standard ACH</label
                >
              </button>
              <button
                class="redeem-radio-wrap relative"
                v-if="me.ach_instant_card_enabled"
                v-on:click="onchangeRedemption($event, 'AptPay', 'CARD')">
                <input
                  type="radio"
                  class="absolute redeem-radio left-0 top-0 opacity-0"
                  name="redeem"
                  id="instant_card" />
                <label for="instant_card">
                  <img
                    src="/images/bank/instant_transfer.png"
                    class="redeem-image"
                    alt="ach Images" />
                  <span class="redeem-radio-span"><span></span></span>
                  Instant Bank Transfer</label
                >
              </button>
              <button
                class="redeem-radio-wrap relative mb-[34px]"
                v-if="me.crypto_enabled"
                v-on:click="onchangeRedemption($event, 'Crypto', '')">
                <input
                  type="radio"
                  class="absolute redeem-radio left-0 top-0 opacity-0"
                  name="redeem"
                  id="crypto" />
                <label for="crypto">
                  <img
                    src="/images/bank/crypto.png"
                    class="redeem-image"
                    alt="ach Images" />
                  <span class="redeem-radio-span"><span></span></span>
                  Crypto</label
                >
              </button>
            </div>
          </div>
        </div>
        <!-- AptPay form for withdrawal -->
        <div v-if="withdrawalFormType == 'AptPay'">
          <div class="w-full flex flex-col items-center justify-center">
            <button
              class="text-secondary-text px-4 py-3 flex flex-col items-center justify-center hover:opacity-75"
              v-on:click="back">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Back to Redeem</span>
            </button>
          </div>

          <AptPayWithdraw :type="AptPayType" v-if="me.ach_flow == 2" />
          <AptPayWithdrawOld :type="AptPayType" v-else />
        </div>

        <!-- Crypto form for withdrawal -->
        <div v-if="withdrawalFormType == 'Crypto'">
          <div class="w-full flex flex-col items-center justify-center">
            <button
              class="text-secondary-text px-4 py-3 flex flex-col items-center justify-center hover:opacity-75"
              v-on:click="back">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Back to Redeem</span>
            </button>
          </div>

          <div v-if="!loading && me" class="py-2 md:py-4">
            <verify-account
              v-if="me.verification_id === null"
              :reverification="me.prev_verification_id" />
            <div v-else>
              <bio-auth-verify
                v-if="isCryptoFormValid && cryptoBioAuthValidationUrl"
                :bioAuthVerifylink="cryptoBioAuthValidationUrl" />
              <div v-else>
                <form @submit.stop.prevent="() => withdraw()">
                  <div class="text-secondary-text py-4 leading-relaxed">
                    Your Sweeps Coins will be redeemed and converted into your desired
                    crypto currency. The more Sweeps Coins you redeem the more crypto
                    currency you will receive per unit.
                  </div>

                  <div
                    class="mb-4 flex flex-row items-center gap-4 rounded-2xl bg-tertiary px-4 py-2 text-slate-300">
                    <div
                      v-if="balS.selectedCrypto"
                      class="relative flex cursor-pointer flex-row items-center gap-4">
                      <Popover v-slot="{ open }" class="relative max-h-full bg-tertiary">
                        <PopoverButton
                          :class="open ? '' : 'text-opacity-90'"
                          class="min-w-96 flex-inline flex items-center rounded-xl px-4 py-3 font-semibold">
                          <div
                            class="flex flex-row items-center space-x-2 text-slate-300">
                            <img
                              :src="getBalanceIcon(balS.default)"
                              alt="icon"
                              class="ml-2 mr-4 h-8 w-8" />
                            <span v-if="balS.selectedCrypto">{{
                              balS.selectedCrypto.name
                            }}</span>
                            <span class="w-[1px] border-l-2 border-side-color" />
                            <img
                              :class="{ 'rotate-180': open }"
                              alt="icon"
                              class="ml-2"
                              src="../../assets/svg/chevron.svg" />
                          </div>
                        </PopoverButton>

                        <transition
                          enter-active-class="transition duration-200 ease-out"
                          enter-from-class="translate-y-1 opacity-0"
                          enter-to-class="translate-y-0 opacity-100"
                          leave-active-class="transition duration-150 ease-in"
                          leave-from-class="translate-y-0 opacity-100"
                          leave-to-class="translate-y-1 opacity-0">
                          <PopoverPanel
                            class="absolute left-3/4 z-[500] mt-3 w-[180px] -translate-x-1/2 transform bg-tertiary px-2 sm:px-0">
                            <div
                              class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <perfect-scrollbar class="rounded-md">
                                <div
                                  class="relative max-h-[450px] overflow-y-auto bg-secondary py-4">
                                  <PopoverButton
                                    v-for="item in balS.cryptos"
                                    :key="item.name"
                                    class="flex w-full cursor-pointer flex-col rounded px-4 py-2 transition duration-150 ease-in-out hover:bg-tertiary focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                    v-on:click="selectCrypto(item)">
                                    <div
                                      class="mls-2 flex flex-row items-center justify-start space-x-2">
                                      <img :src="item.icon" class="h-6 w-6" />
                                      <p class="text-sm font-bold text-slate-500">
                                        {{ item.name }}
                                      </p>
                                    </div>
                                  </PopoverButton>
                                </div>
                              </perfect-scrollbar>
                            </div>
                          </PopoverPanel>
                        </transition>
                      </Popover>
                      <div
                        v-if="balS.networks.length > 0"
                        class="flex flex-row items-center">
                        <Popover v-slot="{ open }" class="relative max-h-full">
                          <PopoverButton
                            :class="open ? '' : 'text-opacity-90'"
                            class="bg-seconsdary-light min-w-96 flex-inline flex items-center rounded-xl px-4 py-3 font-semibold">
                            <div
                              class="flex flex-row items-center space-x-2 text-slate-300">
                              <span class="text-secondary-text mr-2 font-bold"
                                >Network:</span
                              >
                              {{ balS.selectedNetwork.label }}
                              <span class="w-[1px] border-l-2 border-[#32647E]" />
                              <img
                                alt="icon"
                                class="ml-2"
                                src="../../assets/svg/chevron.svg" />
                            </div>
                          </PopoverButton>

                          <transition
                            enter-active-class="transition duration-200 ease-out"
                            enter-from-class="translate-y-1 opacity-0"
                            enter-to-class="translate-y-0 opacity-100"
                            leave-active-class="transition duration-150 ease-in"
                            leave-from-class="translate-y-0 opacity-100"
                            leave-to-class="translate-y-1 opacity-0">
                            <PopoverPanel
                              class="absolute left-1/2 z-[500] mt-3 w-[200px] -translate-x-1/2 transform px-2 sm:px-0">
                              <div
                                class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <perfect-scrollbar class="rounded-md">
                                  <div
                                    class="relative max-h-[450px] overflow-y-auto bg-slate-100 py-4">
                                    <PopoverButton
                                      v-for="item in balS.networks"
                                      :key="item.name"
                                      class="cursor-pointer rounded-lg px-4 py-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                      v-on:click="selectNetwork(item)">
                                      <div class="ml-2 flex flex-row">
                                        <p class="text-sm font-bold text-slate-500">
                                          {{ item.label }}
                                        </p>
                                      </div>
                                    </PopoverButton>
                                  </div>
                                </perfect-scrollbar>
                              </div>
                            </PopoverPanel>
                          </transition>
                        </Popover>
                      </div>
                    </div>
                    <div class="flex grow flex-row justify-end">
                      <div
                        class="m-4 flex min-w-[100px] flex-row justify-center rounded-3xl bg-secondary p-4">
                        {{ balS.formatCrypto(balS.cashBalance, true) }}
                      </div>
                    </div>
                  </div>

                  <label
                    class="mx-0.5 mb-2 inline text-sm font-medium text-text-dim-color"
                    for="address">
                    Your {{ balS.default }}
                    <img
                      :src="getBalanceIcon(balS.default)"
                      alt="Currency Icon"
                      class="inline h-4 w-4" />
                    Redemption Address
                    <span class="text-red-500">*</span>
                  </label>
                  <div class="relative mt-2">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
                          fill="white" />
                        <path
                          d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
                          fill="white" />
                      </svg>
                    </div>
                    <input
                      id="address"
                      v-model="form.address"
                      class="input-with-icon"
                      placeholder="Enter Address"
                      required
                      type="text" />
                  </div>
                  <label v-if="errors.address" class="text-sm text-red-500">{{
                    Array.isArray(errors.address) ? errors.address[0] : errors.address
                  }}</label>

                  <div v-if="balS.selectedCrypto && balS.selectedCrypto.tag_label">
                    <label
                      class="mb-2 mt-4 block text-sm font-medium text-text-dim-color"
                      for="tag">
                      {{ balS.selectedCrypto.tag_label
                      }}<span class="ml-1 text-red-500">*</span>
                    </label>
                    <div class="relative">
                      <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                        <svg
                          class="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                            fill-rule="evenodd" />
                        </svg>
                      </div>
                      <input
                        id="tag"
                        v-model="form.tag"
                        :placeholder="`Enter ${balS.selectedCrypto.tag_label}`"
                        class="input-with-icon"
                        required
                        type="number" />
                    </div>
                    <label v-if="errors.tag" class="text-sm text-red-500"
                      >{{ Array.isArray(errors.tag) ? errors.tag[0] : errors.tag }}
                    </label>
                  </div>

                  <div class="mt-2 flex flex-row items-center justify-between text-xs">
                    <label
                      class="mx-0.5 my-2 block text-sm font-medium text-text-dim-color"
                      for="email-address">
                      Sweeps Coins Amount <span class="text-red-500">*</span>
                    </label>
                    <span class="text-secondary-text">
                      {{ cryptoAmount }} {{ balS.default }}
                    </span>
                  </div>
                  <div class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <img :src="balS.getIcon('SC')" class="h-6 w-6" />
                    </div>
                    <input
                      id="email-address"
                      v-model="form.amount"
                      class="input-with-icon"
                      placeholder="Enter Amount"
                      required
                      step="any"
                      type="number" />
                  </div>
                  <label v-if="errors.amount" class="my-2 text-sm text-red-500">{{
                    Array.isArray(errors.amount) ? errors.amount[0] : errors.amount
                  }}</label>

                  <label
                    class="mx-0.5 my-2 mt-4 block text-sm font-medium text-text-dim-color"
                    for="email-address">
                    Estimated Redemption
                  </label>
                  <div class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <img :src="icon" class="h-6 w-6" />
                    </div>
                    <input
                      :value="cryptoWithdrawalAmount"
                      class="input-with-icon bg-secondary text-slate-300"
                      disabled
                      placeholder="Enter Amount"
                      readonly
                      required
                      step="any"
                      type="number" />
                  </div>

                  <div class="mt-4 flex flex-row justify-center">
                    <button
                      :disabled="!canWithdraw || loading || !me.email_verified"
                      class="btn-bet -align-center my-4 w-1/3 rounded-2xl px-4 py-3 hover:opacity-75 disabled:opacity-50">
                      Redeem
                    </button>
                  </div>

                  <div
                    v-if="!me.email_verified"
                    class="mt-6 rounded-lg border-2 border-dashed border-slate-300 p-4 text-sm text-slate-300">
                    <span>
                      Please verify you email in order to proceed with your redemption.
                    </span>
                    <div
                      class="font-bold text-text-dim-color hover:text-text-dim-color/70">
                      <span
                        class="cursor-pointer"
                        v-on:click="$router.push('/settings/general')"
                        >Verify now</span
                      >
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="inline text-sm text-slate-300/70">
                      <span class="inline">
                        Minimum redeem amount is {{ formatedMinWithdrawal }}
                        <img
                          :src="getBalanceIcon(balS.default)"
                          alt="Currency Icon"
                          class="inline h-4 w-4" />.
                      </span>
                      <span>
                        Your redeemed amount will have {{ formatedMiningFee }}
                        <img
                          :src="getBalanceIcon(balS.default)"
                          alt="Currency Icon"
                          class="inline h-4 w-4" />
                        subtracted from your remaining balance to cover the fee required
                        to process the transaction.
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            v-else
            class="min-h-40 flex h-full flex-grow flex-row items-center justify-center rounded-md p-8">
            <svg
              class="-ml-1 mr-3 h-10 w-10 animate-spin text-white"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"></path>
            </svg>
          </div>
        </div>

        <!--    Paysafe form for withdrawal-->
        <div v-if="withdrawalFormType == 'PaySafe'">
          <div v-if="!loading && me" class="py-2 md:py-4">
            <verify-account
              v-if="me.verification_id === null"
              :reverification="me.prev_verification_id"
              :context="
                me.prev_verification_id == null ? 'redeem' : 'make a redemption.'
              " />
            <div v-else>
              <div v-if="paysafeError.length < 1 && paysafeSuccess.length < 1">
                <form @submit.stop.prevent="() => withdrawPaysafe()">
                  <div v-if="paysafeWithdrawalStep == 1">
                    <div class="text-secondary-text py-4 leading-relaxed">
                      Your Sweeps Coins will be redeemed and converted into USD currency
                      which will be transferred straight to your credit/debit card.
                    </div>
                    <div
                      class="mb-4 flex flex-row items-center gap-4 rounded-2xl bg-tertiary px-4 py-2 text-slate-300">
                      <div
                        class="relative flex cursor-pointer flex-row items-center gap-4">
                        <Popover
                          v-slot="{ open }"
                          class="relative max-h-full bg-tertiary">
                          <PopoverButton
                            :class="open ? '' : 'text-opacity-90'"
                            class="min-w-96 flex-inline flex items-center rounded-xl px-4 py-3 font-semibold">
                            <div
                              class="flex flex-row items-center space-x-2 text-slate-300">
                              <img
                                :src="getBalanceIcon('SC')"
                                alt="icon"
                                class="ml-2 mr-4 h-8 w-8" />
                              <span>{{ paysafeSelectedCurrency }}</span>
                              <span class="w-[1px] border-l-2 border-side-color" />
                              <img
                                :class="{ 'rotate-180': open }"
                                alt="icon"
                                class="ml-2"
                                src="../../assets/svg/chevron.svg" />
                            </div>
                          </PopoverButton>

                          <transition
                            enter-active-class="transition duration-200 ease-out"
                            enter-from-class="translate-y-1 opacity-0"
                            enter-to-class="translate-y-0 opacity-100"
                            leave-active-class="transition duration-150 ease-in"
                            leave-from-class="translate-y-0 opacity-100"
                            leave-to-class="translate-y-1 opacity-0">
                            <PopoverPanel
                              class="absolute left-3/4 z-[500] mt-3 w-[180px] -translate-x-1/2 transform bg-tertiary px-2 sm:px-0">
                              <div
                                class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <perfect-scrollbar class="rounded-md">
                                  <div
                                    class="relative max-h-[450px] overflow-y-auto bg-secondary py-4">
                                    <PopoverButton
                                      v-for="item in paysafeWithdrawalCurrencies"
                                      :key="item"
                                      v-on:click="selectCurrency(item)"
                                      class="flex w-full cursor-pointer flex-col rounded px-4 py-2 transition duration-150 ease-in-out hover:bg-tertiary focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                      <div
                                        class="mls-2 flex flex-row items-center justify-start space-x-2">
                                        <p class="text-sm font-bold text-slate-500">
                                          {{ item }}
                                        </p>
                                      </div>
                                    </PopoverButton>
                                  </div>
                                </perfect-scrollbar>
                              </div>
                            </PopoverPanel>
                          </transition>
                        </Popover>
                        <div
                          v-if="paysafeSelectedCurrency > 0"
                          class="flex flex-row items-center">
                          <Popover v-slot="{ open }" class="relative max-h-full">
                            <PopoverButton
                              :class="open ? '' : 'text-opacity-90'"
                              class="bg-seconsdary-light min-w-96 flex-inline flex items-center rounded-xl px-4 py-3 font-semibold">
                              <div
                                class="flex flex-row items-center space-x-2 text-slate-300">
                                <span class="text-secondary-text mr-2 font-bold"
                                  >Network:</span
                                >
                                {{ paysafeSelectedCurrency }}
                                <span class="w-[1px] border-l-2 border-[#32647E]" />
                                <img
                                  alt="icon"
                                  class="ml-2"
                                  src="../../assets/svg/chevron.svg" />
                              </div>
                            </PopoverButton>

                            <transition
                              enter-active-class="transition duration-200 ease-out"
                              enter-from-class="translate-y-1 opacity-0"
                              enter-to-class="translate-y-0 opacity-100"
                              leave-active-class="transition duration-150 ease-in"
                              leave-from-class="translate-y-0 opacity-100"
                              leave-to-class="translate-y-1 opacity-0">
                              <PopoverPanel
                                class="absolute left-1/2 z-[500] mt-3 w-[200px] -translate-x-1/2 transform px-2 sm:px-0">
                                <div
                                  class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                  <perfect-scrollbar class="rounded-md">
                                    <div
                                      class="relative max-h-[450px] overflow-y-auto bg-slate-100 py-4">
                                      <PopoverButton
                                        v-for="item in paysafeWithdrawalCurrencies"
                                        :key="item"
                                        v-on:click="selectCurrency(item)"
                                        class="cursor-pointer rounded-lg px-4 py-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                        <div class="ml-2 flex flex-row">
                                          <p class="text-sm font-bold text-slate-500">
                                            {{ item }}
                                          </p>
                                        </div>
                                      </PopoverButton>
                                    </div>
                                  </perfect-scrollbar>
                                </div>
                              </PopoverPanel>
                            </transition>
                          </Popover>
                        </div>
                      </div>
                      <div class="flex grow flex-row justify-end">
                        <div
                          class="m-4 flex min-w-[100px] flex-row justify-center rounded-3xl bg-secondary p-4">
                          {{ balS.cashBalance }}
                        </div>
                      </div>
                    </div>

                    <div class="mt-2 flex flex-row items-center justify-between text-xs">
                      <label
                        class="mx-0.5 my-2 block text-sm font-medium text-text-dim-color"
                        for="email-address">
                        Sweeps Coins Amount <span class="text-red-500">*</span>
                      </label>
                    </div>
                    <div class="relative">
                      <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img :src="balS.getIcon('SC')" class="h-6 w-6" />
                      </div>
                      <input
                        v-model="paysafeForm.amount"
                        class="input-with-icon"
                        placeholder="Enter Amount"
                        required
                        step="any"
                        type="number" />
                    </div>
                    <label v-if="errors.amount" class="my-2 text-sm text-red-500">{{
                      Array.isArray(errors.amount) ? errors.amount[0] : errors.amount
                    }}</label>

                    <label
                      class="mx-0.5 my-2 mt-4 block text-sm font-medium text-text-dim-color"
                      for="email-address">
                      Estimated Redemption
                    </label>
                    <div class="relative">
                      <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <img :src="balS.getIcon('SC')" class="h-6 w-6" />
                      </div>
                      <input
                        :value="paySafeWithdrawalAmount"
                        class="input-with-icon bg-secondary text-slate-300"
                        disabled
                        placeholder=""
                        readonly
                        required
                        step="any"
                        type="number" />
                    </div>
                  </div>

                  <div v-if="paysafeWithdrawalStep == 2 && paysafeError.length < 1">
                    <div class="mt-2 flex flex-row items-center justify-between text-xs">
                      <label
                        class="mx-0.5 my-2 block text-sm font-medium text-text-dim-color"
                        for="email-address">
                        Select Your Saved Debit Card
                      </label>
                    </div>
                    <div class="relative">
                      <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                      <select
                        id="paysafeDebitCard"
                        v-model="selectedPaysafeDebitCard"
                        class="input-bet">
                        <option
                          v-for="card in paysafeDebitCard"
                          :key="card.paymentHandleToken"
                          :value="card.paymentHandleToken">
                          {{ card.card.cardBin }}....{{ card.card.lastDigits }} ({{
                            card.card.holderName
                          }})
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="mt-4 flex flex-row justify-center">
                    <div class="mt-4 flex flex-row justify-center">
                      <button
                        class="btn-bet -align-center my-4 rounded-2xl px-4 py-3 hover:opacity-75 disabled:opacity-50"
                        type="button"
                        :disabled="paysafeWithdrawalStep == 1"
                        style="margin-right: 20px"
                        @click="backPaysafeStep()">
                        BACK
                      </button>
                    </div>
                    <hr />
                    <div
                      v-if="paysafeWithdrawalStep == 2"
                      class="mt-4 flex flex-row justify-center">
                      <button
                        class="btn-bet -align-center my-4 rounded-2xl px-4 py-3 hover:opacity-75 disabled:opacity-50"
                        style="margin-left: 20px">
                        SUBMIT
                      </button>
                    </div>
                    <div v-else class="mt-4 flex flex-row justify-center">
                      <button
                        class="btn-bet -align-center my-4 rounded-2xl px-4 py-3 hover:opacity-75 disabled:opacity-50"
                        type="button"
                        :disabled="!canWithdrawPaySafe"
                        style="margin-left: 20px"
                        @click="nextPaysafeStep()">
                        NEXT
                      </button>
                    </div>
                  </div>

                  <br />
                  <div
                    v-if="!me.email_verified"
                    class="mt-6 rounded-lg border-2 border-dashed border-slate-300 p-4 text-sm text-slate-300">
                    <span>
                      Please verify you email in order to proceed with your redemption.
                    </span>
                    <div
                      class="font-bold text-text-dim-color hover:text-text-dim-color/70">
                      <span
                        class="cursor-pointer"
                        v-on:click="$router.push('/settings/general')"
                        >Verify now</span
                      >
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="inline text-sm text-slate-300/70">
                      <span class="inline">
                        Minimum redeem amount is {{ paysafeSelectedCurrency
                        }}{{ paysafeMinWithdrawal }}
                        <img
                          :src="getBalanceIcon('SC')"
                          alt="Currency Icon"
                          class="inline h-4 w-4" />.
                      </span>
                      <span>
                        Your redeemed amount will have {{ paysafeSelectedCurrency
                        }}{{ paySafeWithdrawalCharge }}
                        <img
                          :src="getBalanceIcon('SC')"
                          alt="Currency Icon"
                          class="inline h-4 w-4" />
                        subtracted from your remaining balance to cover the fee required
                        to process the transaction.
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            v-else
            class="min-h-40 flex h-full flex-grow flex-row items-center justify-center rounded-md p-8">
            <div v-if="paysafeError.length > 0" class="payment-success">
              <div class="success-text">Redemption not successful</div>
              <img
                src="../../assets/paymentmethods/payment_error.svg"
                alt="Payment Error"
                class="success-image" />
              <h3 class="text-3xl font-bold mb-8">{{ paysafeError }}</h3>
            </div>
            <div v-else-if="paysafeSuccess.length > 0" class="payment-success">
              <div class="success-text">Redemption was successful</div>
              <img
                src="../../assets/paymentmethods/payment_done.svg"
                alt="Payment Success"
                class="success-image" />
              <p class="success-text">{{ paysafeSuccess }}</p>
            </div>
            <div v-else>
              <svg
                class="-ml-1 mr-3 h-10 w-10 animate-spin text-white"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { ordersStore } from '@/store/pinia/ordersStore';
import { mapActions, mapGetters } from 'vuex';
import { clamp, isUndefined } from 'lodash';
import { getErrorCodeMessage } from '@/utils';

import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { userStore } from '@/store/userStore';

import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { computed } from '@vue/reactivity';

import axios from '@/lib/myAxios';
import $store from '@/store';
import TechnicalWarning from '@/components/TechnicalWarning.vue';
import AptPayWithdrawOld from '@/components/Bank/AptPayWithdrawOld.vue';
import AptPayWithdraw from '@/components/Bank/AptPayWithdraw.vue';

import PuntModal from '../Shared/PuntModal.vue';
import BioAuthVerify from '@/components/Bank/BioAuthVerify.vue';
import SsnVerification from './SsnVerification.vue';

export default {
  name: 'Withdraw',
  components: {
    SsnVerification,
    BioAuthVerify,
    VerifyAccount,
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    AptPayWithdrawOld,
    AptPayWithdraw,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    TechnicalWarning,
    PuntModal,
  },
  setup() {
    const balS = balanceStore();
    const orderS = ordersStore();
    const userS = userStore();

    const card_number = null;

    return {
      balS,
      getBalanceIcon,
      orderS,
      userS,
    };
  },
  data() {
    return { 
      paymentData: null,
      paysafeWithdrawalCurrencies: ['USD', 'CAD'],
      paysafeSelectedCurrency: 'USD',
      paysafeWithdrawalStep: 1,
      paysafeDebitCard: null,
      selectedaysafeDebitCard: null,
      rollover: {
        amount: 0,
        rollover: 0,
        wager: 0,
        currency: 'BTC',
        multipler: 3,
      },
      showCurDrop: false,
      form: { address: '', tag: '', amount: '' },
      paysafeForm: { amount: 0 },
      paysafeWithdrawalStaticCharge: 0.9,
      paysafeMinWithdrawal: 5,
      paysafeError: '',
      paysafeSuccess: '',
      loading: true,
      errors: {},
      AptPayType: 'ACH',
      isLoading: false,
      withdrawalFormType: '',
      shouldRender: false,
      wtitle: 'Redemptions are currently down for maintenance!',
      isAptpayenabled: false,
      allset: false,
      isCryptoFormValid: false,
      cryptoBioAuthValidationUrl: '',
    };
  },
  async created() {
    if (this.me && !this.me.verification_id) {
      await this.fetchUser();
    }
    window.toast = this.$toast;

    await this.balS.init();
    await this.fetchProgress();
  },
  watch: {
    'balS.default': {
      handler: function (selected, old) {
        if (old === null)
          return;
        //this.fetchProgress()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    icon() {
      return getBalanceIcon(this.currency);
    },
    ...mapGetters(['me']),
    currency() {
      return this.balS.displayInFiat ? this.balS.defaultFiat : this.balS.default;
    },
    paySafeWithdrawalAmount() {
      const amount = this.paysafeForm.amount;
      const charge = amount - this.paysafeWithdrawalStaticCharge;
      return charge < 0 ? 0 : charge;
    },
    paySafeWithdrawalCharge() {
      const amount = this.paysafeForm.amount - this.paySafeWithdrawalAmount;
      return amount.toFixed(2);
    },
    canWithdrawPaySafe() {
      let canWithdraw = !isNaN(this.paysafeForm.amount);

      if (!canWithdraw) {
        this.paysafeError = 'Invalid amount';
        return false;
      }

      canWithdraw = this.paysafeForm.amount >= this.paysafeMinWithdrawal;

      if (!canWithdraw) {
        //    this.paysafeError = "Amount less than the minimum withdrawal amount " + this.paysafeSelectedCurrency + this.paysafeMinWithdrawal
        return false;
      }

      canWithdraw = this.paysafeForm.amount <= this.balS.cashBalance;

      if (!canWithdraw) {
        //   this.paysafeError = "Withdrawal can't be greater than SC" + this.balS.cashBalance + " which is your available balance"
        return false;
      }

      this.paysafeError = '';
      return true;
    },
    cryptoAmount() {
      const amount = this.balS.fiatToCrypto('USD', this.balS.default, this.form.amount);
      return this.balS.formatCrypto(amount);
    },
    cryptoWithdrawalAmount() {
      const amount = this.balS.fiatToCrypto('USD', this.balS.default, this.form.amount);
      return this.balS.formatCrypto(Math.max(0, amount - this.miningFee));
    },
    rolloverProgress() {
      if (!this.rollover || this.rollover.rollover === 0) return 0;
      const percentage =
        (this.rollover.wager / (this.rollover.rollover * this.rollover.amount)) * 100;
      return clamp(parseFloat(percentage.toFixed(2)), 0, 10000);
    },
    minWithdrawal() {
      let min = this.balS.fiatToCrypto('USD', this.balS.default, 10);
      const defaultMin = this.selectedCurrency.min_withdrawal ?? 0;
      return Math.max(min, defaultMin);
    },
    miningFee() {
      return this.selectedCurrency.mining_fee ?? 0;
    },
    selectedCurrency() {
      if (this.balS.selectedNetwork) {
        return this.balS.selectedNetwork;
      }
      return this.balS.selectedCrypto;
    },
    canWithdraw() {
      return (
        !isNaN(this.form.amount) /*&& this.form.amount >= this.minWithdrawal*/ &&
        this.form.address.length > 0
      );
    },
    formatedMinWithdrawal() {
      return this.balS.exchangeAndFormatAmount(this.minWithdrawal, this.balS.default);
    },
    formatedMiningFee() {
      return this.balS.exchangeAndFormatAmount(this.miningFee, this.balS.default);
    },
  },
  methods: {
    ...mapActions(['fetchUser']),
    back($event) {
      this.withdrawalFormType = '';
    },

    onchangeRedemption($event, value = false, transactionType) {
      if (value !== false) {
        this.withdrawalFormType = value;
      }

      if (this.withdrawalFormType == 'AptPay') {
        this.AptPayType = transactionType;
      }
    },
    async withdrawPaysafe() {
      this.loading = true;

      try {
        axios
          .get(
            '/payments/paysafe/withdraw?currencyCode=' +
              this.paysafeSelectedCurrency +
              '&paymentHandleToken=' +
              this.selectedPaysafeDebitCard +
              '&amount=' +
              this.paysafeForm.amount +
              '&charge=' +
              this.paysafeWithdrawalStaticCharge +
              '&api_key=' +
              import.meta.env.VITE_PAY_SAFE_API_KEY,
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            const balS = balanceStore();
            balS.init();

          if (response.data.currencyCode == 'USD') {
            if (
              response.data.gatewayResponse.status == 'PAID' ||
              response.data.gatewayResponse.status == 'COMPLETED'
            ) {
              this.paysafeSuccess = response.data.gatewayResponse.status;
              if(import.meta.env.VITE_NODE_ENV == 'production')
              {
                // tracking redemption event on GTM
                this.$gtm.trackEvent({
                  event: 'redemption',
                  value: this.paysafeForm.amount,
                  email: this.me.email,
                });
              }
              
            } else {
              this.paysafeError =
                'Error. Status is '.response.data.gatewayResponse.status;
            }
          } else if (response.data.currencyCode == 'CAD') {
            if (response.data.status == 'PAID' || response.data.status == 'COMPLETED') {
              this.paysafeSuccess = response.data.status;
              if(import.meta.env.VITE_NODE_ENV == 'production')
              {
                // tracking redemption event on GTM
                this.$gtm.trackEvent({
                  event: 'redemption',
                  value: this.paysafeForm.amount,
                  email: this.me.email,
                });
              }
              
            } else {
              this.paysafeError = 'Error. Status is '.response.data.status;
            }
          }
        })
        .catch((e) => {
          const { message } = getErrorCodeMessage(e, 'Unable to process paysafe withdrawal!');
          this.paysafeError = 'Error ' + message;
        });
      } catch(error) {
        console.log('some error with paysafe')
      }
    },
    async fetchProgress() {
      this.loading = true;
      this.errors = {};
      this.rollover = {
        amount: 0,
        rollover: 0,
        wager: 0,
        currency: 'USD',
      };
      try {
        const { data } = await this.orderS.rolloverProgress();
        this.rollover = { ...data };
        this.allset = true;
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to process withdrawal!');
        window.swal('Uh-oh', 'Something went wrong: ' + message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async loadCustomerDebitCards() {
      this.loading = true;
      try {
        axios
        .get('/payments/paysafe/customer')
        .then((response) => {
          // JSON responses are automatically parsed.
          this.paysafeDebitCard = response.data.paymentHandles;
          if (isUndefined(response.data.paymentHandles)) {
            this.paysafeError = 'No saved debit cards found';
            return;
          }
          if (response.data.paymentHandles.length == 0) {
            this.paysafeError = 'No saved debit cards found';
            return;
          }
          if (!isUndefined(this.paysafeDebitCard[0])) {
            this.selectedPaysafeDebitCard = this.paysafeDebitCard[0].paymentHandleToken;
          }
          this.loading = false;
        })
        .catch((e) => {
          const { message } = getErrorCodeMessage(e, 'Unable to load debit cards!');
          this.paysafeError = 'Error ' + message;
        });
      } catch(error) {
        console.log('failed to load debitcard');
      }
    },
    nextPaysafeStep() {
      this.paysafeWithdrawalStep++;

      if (this.paysafeWithdrawalStep == 2) {
        this.loadCustomerDebitCards();
      }
    },
    backPaysafeStep() {
      this.paysafeWithdrawalStep--;
    },
    startTimer: function () {
      this.timer = setInterval(() => {
        var data = localStorage.getItem('result');
        if (data !== 'null') {
          clearInterval(this.timer);
          if (data == 'true') {
            this.paymentSuccessful = true;
            const balS = balanceStore();
            balS.init();
          } else {
            this.loading = false;
            this.paysafeError = data;
          }
        }
      }, 1000);
    },
    toggleMenu(show) {
      this.showCurDrop = show;
    },
    selectCrypto(cur) {
      this.balS.default = cur.name;
    },
    selectCurrency(cur) {
      this.paysafeSelectedCurrency = cur;
    },
    async selectNetwork(currency) {
      this.balS.network = currency;
    },
    async withdraw() {
      this.loading = true;
      this.errors = {};
      try {
        let amount = parseFloat(this.form.amount);
        const withdrawResponse = await this.orderS.withdraw(
          this.balS.default,
          amount,
          this.form.address,
          this.form.tag,
          this.balS.selectedNetwork,
        );

        this.form.amount = '';
        if (withdrawResponse.data.message === 'success') {
          this.isCryptoFormValid = true;
          this.cryptoBioAuthValidationUrl = withdrawResponse.data.result.url;
        }
      } catch (e) {
        const { code, message } = getErrorCodeMessage(e, 'Unable to process withdrawal!');
        if (code === 400 || code === 422) {
          this.errors = message.split(',');
        } else {
          window.swal('Uh-oh', 'Something went wrong: ' + message, 'error');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.animate-spin {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  to {
    opacity: 0.3;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    opacity: 0.3;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.credit-card {
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.card-number {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-holder,
.card-cvv-expiry {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.card-cvv,
.card-expiry {
  width: 48%;
}
.redeem-method .redeem-radio-wrap label {
  padding: 28px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid transparent;
  background: #112021;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.redeem-method .redeem-radio-wrap {
  display: block;
  width: 100%;
}
.redeem-method .redeem-radio-wrap .redeem-radio-span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a3a3a;
  border-radius: 50%;
  border: 1px solid #415858;
}
.redeem-method .redeem-radio-wrap .redeem-radio-span span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.redeem-method .redeem-radio-wrap .redeem-image {
  position: absolute;
  right: 38px;
  top: 0;
  height: 100%;
  /* object-fit: contain; */
  height: 150%;
  top: calc(50% + 10px);
  object-fit: cover;
  transform: translateY(-50%);
}
.redeem-method .redeem-radio-wrap img {
  opacity: 0.6;
}
.redeem-method .redeem-radio-wrap:hover label {
  background-color: #042723;
  border:1px solid #00FFBA;
  opacity: 1;
}
.redeem-method .redeem-radio-wrap:hover img {
  opacity: 1;
}
.redeem-method .redeem-radio-wrap:hover label .redeem-radio-span {
  border: 1px solid #00ffba;
}
.redeem-method .redeem-radio-wrap:hover label .redeem-radio-span span {
  @apply bg-onprimary;
}
@media screen and (max-width: 768px) {
  .redeem-method .redeem-radio-wrap label {
    padding: 12px 24px 79px;
  }
  .redeem-method .redeem-radio-wrap .redeem-image {
    height: fit-content;
    right: 17px;
    top: 31px;
    width: 129px;
    transform: unset;
  }
}
</style>
