export const COLORS = {
  BLACK: 0,
  RED: 1,
};

export const SUITS = {
  DIAMONDS: 0,
  HEARTS: 1,
  SPADES: 2,
  CLUBS: 3,
};

export const SUIT_COLORS = {
  DIAMONDS: COLORS.RED,
  HEARTS: COLORS.RED,
  SPADES: COLORS.BLACK,
  CLUBS: COLORS.BLACK,
};

export const VALUES = {
  ACE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  JACK: 11,
  QUEEN: 12,
  KING: 13,
};
