<template>
  <div style="margin-top: 30px">
    <BenefitsItem
      :icon="getRankIcon('copper')"
      title="Copper"
      :benefitsShown="true"
      :benefits="['Rakeback Enabled' /*'Weekly Bonuses','Monthly Bonuses'*/]" />

    <BenefitsItem
      :icon="getRankIcon('bronze')"
      title="Bronze"
      :benefits="['Level Up Bonus' /*'Weekly Bonuses','Monthly Bonuses'*/]" />

    <BenefitsItem
      :icon="getRankIcon('silver')"
      title="Silver"
      :benefits="['Level Up Bonus' /*,'Weekly and Monthly Bonuses increased'*/]" />

    <BenefitsItem
      :icon="getRankIcon('gold')"
      title="Gold"
      :benefits="['Level Up Bonus' /*'Weekly and Monthly Bonuses increased'*/]" />

    <BenefitsItem
      :icon="getRankIcon('platinum')"
      title="Platinum I - V"
      :benefits="['Level Up Bonus' /*'Weekly and Monthly Bonuses increased'*/]" />

    <BenefitsItem
      :icon="getRankIcon('diamond')"
      title="Diamond I - II"
      :benefits="[
        'Level Up Bonus',
        /*'Weekly Bonuses and Monthly Bonuses',*/ 'Exclusive Benefits',
      ]" />
  </div>
</template>

<script>
import BenefitsItem from './BenefitsItem.vue';
export default {
  components: { BenefitsItem },
  name: 'Benefits',
  data: () => {
    return {
      basePath: '/images/ranks/',
    };
  },
  methods: {
    getRankIcon(rank) {
      return `${this.basePath}${rank}.png`;
    },
  },
};
</script>
