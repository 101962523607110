<template>
  <div class="club-banefit">
    <SectionTitle title="Punt VIP Club Benefits" />
    <swiper
      v-if="width < 992"
      class="benefit-cards"
      :slides-per-view="1.1"
      :space-between="10"
      :breakpoints="{
        300: { slidesPerView: 1.1 },
        650: { slidesPerView: 1.1 },
        992: { slidesPerView: 4.5 },
      }">
      <swiper-slide v-for="item in ClubBanefitData"
        ><ClubBanefitItem :item="item"
      /></swiper-slide>
    </swiper>
    <div class="club-banefit-items" v-if="width > 992">
      <ClubBanefitItem v-for="item in ClubBanefitData" :item="item" />
    </div>
  </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import ClubBanefitItem from './ClubBanefitItem.vue';
import { ClubBanefitData } from './ClubBanefit';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: 'VipClubBanefit',
  data() {
    return {
      ClubBanefitData,
      width: 0,
    };
  },
  components: {
    SectionTitle,
    ClubBanefitItem,
    Swiper,
    SwiperSlide,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.club-banefit .section-title {
  margin-bottom: 107px;
}
.club-banefit {
  margin-bottom: 40px;
  .club-banefit-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 19px;
  }
}
@media screen and (max-width: 992px) {
  .club-banefit {
    padding-top: 50px;
    margin-bottom: 30px;
    .section-title {
      text-align: center;
      margin-bottom: 27px;
    }
    .club-banefit-items {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
}
</style>
