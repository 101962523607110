<template>
    <div class="club-banefit-item">
        <div class="left-part">
            <img :src="'/images/ranks/'+item.img" alt="Item Image" v-if="item.img" >
        </div>
        <div class="right-part">
            <h3 class="item-title" v-if="item.title" >{{ item.title }}</h3>
            <p class="item-desc" v-if="item.desc" >{{ item.desc }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'VipClubBanefitItem',
  props : ['item'],
}
</script>

<style lang="scss" scoped>
    .club-banefit-item{
        @apply bg-corbeau;
        border-radius: 18px;
        background-color: #112021;
        padding: 46px 20px 38px 20px;
        text-align: center;
        align-items: center;
        position: relative;
        .left-part{
            width: 100%;
            max-width: 103px;
            margin: auto;
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
            img{
                margin: auto;
            }
        }
        .item-title{
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 11px;
        }
        .item-desc{
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            color:#BEBEBE !important;
            @apply text-pango-black;
        }
        &::before{
            background-image: url('../../assets/vip\ arrow.png');
            position: absolute;
            right: -33px;
            top:50%;
            transform: translateY(-50%);
            content: '';
            width:46px;
            height:46px;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 10;
        }
        &:last-child::before{
            content: unset;
        }
    }
    @media screen and (max-width:992px){
        .club-banefit-item{
            flex-direction: column;
            padding: 23px 26px 35px;
            align-items:flex-start;
            .left-part{
              margin-bottom: 25px;
            }
          }
          .club-banefit-item::before{
            content:unset;
          }
          .club-banefit-item .left-part{
            position: unset;
            transform: unset;
          }
    }
</style>
