<style lang="sass">
.dark section.modal-card-body p, .dark section.modal-card-body .subtitle
  color: black
</style>

<template>
  <punt-modal show-close="true" size="max-w-3xl" class="rules_title">
    <template #title>
      <h1 class="text-lg font-bold">Game Rules and Limits</h1>
    </template>
    <template #body>
      <div class="modal is-active">
        <div class="modal-background" v-on:click.stop.prevent="closeModal"></div>
        <div class="modal-card">
          <section class="modal-card-body text-slate-300">
            <div v-if="!limits" class="has-text-centered">Loading...</div>
            <div v-else>
              <div class="flex flex-col space-y-2">
                <span class="mt-1 inline text-sm text-white gap-2">
                  <strong style="color: white">Minimum bet</strong> is
                  <strong style="color: white">{{
                    balS.exchangeAndFormatAmount(limits.min_wager)
                  }}</strong>
                  <img
                    :src="balS.getIcon()"
                    alt="Currency Icon"
                    class="inline h-4 w-4 ml-1" />
                </span>

                <span class="mt-1 inline text-sm text-white gap-2">
                  <strong style="color: white">Max payout</strong> is
                  <strong style="color: white">{{
                    balS.exchangeAndFormatAmount(limits.max_payout)
                  }}</strong>
                  <img
                    :src="balS.getIcon()"
                    alt="Currency Icon"
                    class="inline h-4 w-4 ml-1" />
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { defaultConvertor as rsgp } from '../../lib/rsgp';
import PuntModal from '@/components/Shared/PuntModal.vue';
import { BigNumber } from 'bignumber.js';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  components: {
    PuntModal,
  },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
  },
  setup() {
    return { balS: balanceStore() };
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
      options: ['Rules'],
      active: 'Rules',
    };
  },
  created() {
    this.fetchLimits();
  },
  methods: {
    closeModal() {
      switch (this.onClose) {
        case 'back':
          this.$router.go(-1);
          break;
        case 'close':
          this.$emit('close');
          break;
      }
    },
    setActive(option) {
      this.active = option;
    },
    fetchLimits() {
      this.fetching = true;
      this.$http
        .get('clovercross')
        .then(({ data }) => {
          let min_wager = new BigNumber(data.result.limits.min_wager);
          let max_payout = new BigNumber(data.result.limits.max_payout);
          this.fetching = false;

          data.result.limits.min_wager = min_wager.toFixed();
          data.result.limits.max_payout = max_payout.toFixed();
          this.limits = data.result.limits;

          this.fetching = false;
        })
        .catch((err) => {
          this.fetching = false;
          this.error = err.toString();
        });
    },
    rsgp(x) {
      return rsgp.format(x);
    },
  },
};
</script>
