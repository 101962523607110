<template>
  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" :r="radius"></circle>
        <path
          id="base-timer-path-remaining"
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="pathColorClass"
          :d="`
          M 50, 50
          m -${radius}, 0
          a ${radius},${radius} 0 1,0 ${radius * 2},0
          a ${radius},${radius} 0 1,0 -${radius * 2},0`"></path>
      </g>
    </svg>
    <span id="base-timer-label" class="base-timer__label">{{ remainingTime }}</span>
  </div>
</template>

<script>
const RADIUS = 20;
const FULL_DASH_ARRAY = ((RADIUS * 22) / 7) * 2;
const WARNING_THRESHOLD = 25;
const ALERT_THRESHOLD = 7;

const COLOR_CODES = {
  info: {
    color: 'green',
  },
  warning: {
    color: 'orange',
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: 'red',
    threshold: ALERT_THRESHOLD,
  },
};

export default {
  name: 'WarCountDown',
  props: {
    timeLimit: { default: 0 },
  },
  data() {
    return {
      timerInterval: null,
      timeLeft: 0,
      timePassed: 0,
      radius: RADIUS,
    };
  },
  watch: {
    timeLimit: {
      handler: function (newW) {
        if (newW) {
          this.startTimer();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onTimesUp() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },

    startTimer() {
      this.onTimesUp();
      this.timePassed = 0;
      this.timerInterval = setInterval(() => {
        this.timePassed = this.timePassed += 1;
        this.timeLeft = this.timeLimit - this.timePassed;
        if (this.timeLeft <= 0) {
          this.onTimesUp();
        }
      }, 1000);
    },
  },
  computed: {
    remainingTime() {
      if (this.timeLeft <= 0) this.timeLeft = 0;
      const minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    },
    pathColorClass() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      }
      return info.color;
    },
    circleDasharray() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      let timeFraction = rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
      return `${(timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-timer {
  position: relative;
  width: 200px;
  height: 200px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 3px;
  stroke: #cacaca;
}

.base-timer__path-remaining {
  stroke-width: 3px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(0, 177, 0);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
}
</style>
