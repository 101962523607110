<template>
  <div class="flex flex-row w-full">
    <TabGroup
      disabled
      :selectedIndex="selectedTab"
      @change="changeTab"
      as="div"
      class="flex flex-col-reverse md:flex-col w-full lg:max-w-[650px]">
      <div class="flex flex-col w-full justify-start">
        <TabList
          class="flex flex-row px-2 mt-2 md:mt-0 w-full md:w-auto space-x-1 sm:space-x-2 bg-primary rounded-xl">
          <Tab
            :disabled="inProgress || autoBet"
            v-for="tab in tabs"
            :key="tab"
            v-slot="{ selected }"
            as="template">
            <button
              :class="[
                selected ? 'bg-secondary-light text-white' : 'bg-primary text-slate-600',
                tab === '' ? 'flex-1 border-b-0 pointer-events-none' : '',
              ]"
              class="text-white focus:outline-none flex-1 font-medium rounded-lg py-2 my-2  hover:bg-opacity-70 disabled:opacity-80">
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels class="mt-1">
        <TabPanel key="1">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="mt-1">
              <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
            </div>
            <div class="flex flex-col h-full grow justify-between">
              <div class="grow">
                <div
                  class="flex flex-row gap-4 mt-2 md:mt-0 max-[480px]:items-end max-[767px]:flex-col">
                  <div class="flex-grow max-[767px]:w-full">
                    <label
                      for="bet-manual-amount"
                      class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                      Play Amount
                    </label>
                    <div class="relative">
                      <div
                        class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <img class="h-5 w-5" :src="balS.mainIcon" alt />
                      </div>
                      <input
                        type="number"
                        id="bet-manual-amount"
                        v-model="wager"
                        class="input-bet-amount"
                        placeholder="0.0000" />
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                        <button
                          type="button"
                          v-on:click="scaleWager(0.5)"
                          :disabled="autoBet"
                          class="btn-multiplier">
                          1/2
                        </button>
                        <button
                          type="button"
                          v-on:click="scaleWager(2.0)"
                          :disabled="autoBet"
                          class="btn-multiplier">
                          2x
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    :disabled="buttonDisabled || currentState === 'inprogress'"
                    v-on:click="initAutoBet(1)"
                    class="btn-bet flex-1 md:hidden block !mt-0 max-[480px]:py-[0.5rem]">
                    Play
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-none mt-1 hidden md:block">
              <button
                :disabled="buttonDisabled || currentState === 'inprogress'"
                v-on:click="initAutoBet(1)"
                class="btn-bet">
                Play
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel key="2">
          <div class="flex flex-col-reverse md:flex-col">
            <div class="flex flex-col h-full">
              <div class="mt-1">
                <span class="flex h-[1px] opacity-50 bg-line-color-bright" />
              </div>
              <div class="flex-none">
                <label
                  for="bet-manual-amount"
                  class="mt-1 flex justify-between mb-1 text-sm font-medium text-text-dim-color">
                  Play Amount
                </label>
                <div class="relative">
                  <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <img class="h-4 w-4" :src="balS.mainIcon" alt />
                  </div>
                  <input
                    type="number"
                    id="bet-manual-amount"
                    v-model="wager"
                    class="input-bet-amount"
                    placeholder="0.0000" />
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-2 space-x-2 pointer-events-auto">
                    <button
                      type="button"
                      v-on:click="scaleWager(0.5)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      1/2
                    </button>
                    <button
                      type="button"
                      v-on:click="scaleWager(2.0)"
                      :disabled="autoBet"
                      class="btn-multiplier">
                      2x
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Number of Plays</label
                  >
                  <div class="relative">
                    <input
                      v-model="numOfBets"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Number of Plays"
                      type="text" />
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Win</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="inProgress"
                      :class="[
                        onWinSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-sm text-slate-300 shadow rounded hover:opacity-70"
                      v-on:click="setOnWin('Reset')">
                      Reset
                    </button>
                    <button
                      :class="[
                        onWinSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-sm text-slate-300 hover:opacity-70"
                      v-on:click="setOnWin('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1 rounded-xl">
                      <input
                        v-model="onWinPercent"
                        :disabled="inProgress || onWinSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >On Loss</label
                  >
                  <div class="flex flex-row w-full bg-primary p-0.5 space-x-1 rounded">
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected === 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 text-slate-300 shadow rounded text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Reset')">
                      Reset
                    </button>
                    <button
                      :disabled="autoBet"
                      :class="[
                        onLossSelected !== 'Reset' ? 'bg-secondary ' : 'bg-secondary/0',
                      ]"
                      class="px-2 shadow rounded text-slate-300 text-sm hover:opacity-70"
                      v-on:click="setOnLoss('Inc')">
                      IncreaseBy:
                    </button>
                    <div class="relative flex-1">
                      <input
                        v-model="onLossPercent"
                        :disabled="autoBet || onLossSelected === 'Reset'"
                        class="input-bet-winloss"
                        max="1000000"
                        min="0"
                        type="text" />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md m-0.5 box-context">
                        <button class="btn-winloss">%</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Profit</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnProfit"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Profit"
                      type="text" />
                  </div>
                </div>
                <div class="py-0.5 md:py-1">
                  <label class="mt-1 block MBX text-sm font-medium text-text-dim-color"
                    >Stop On Loss</label
                  >
                  <div class="relative">
                    <input
                      v-model="stopOnLoss"
                      :disabled="autoBet"
                      class="input-bet"
                      placeholder="Stop On Loss"
                      type="text" />
                  </div>
                </div>
                <div class="mt-1">
                  <span class="mt-1 text-slate-300 inline">
                    Profit: {{ balS.formatCrypto(profit, true) }}
                    <img class="h-4 w-4 inline" :src="balS.mainIcon" alt />
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-none mt-1">
              <button
                :disabled="
                  buttonDisabled ||
                  !numOfBets ||
                  Number.parseInt(numOfBets) <= 0 ||
                  (currentState === 'inprogress' && !this.autoBet)
                "
                v-on:click="initAutoBet()"
                class="btn-bet"
                type="button">
                {{ inProgress || autoBet ? 'Stop Autoplay' : 'Start Autoplay' }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defaultConvertor } from '@/lib/rsgp';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
const TABS = ['Manual', 'Auto'];
import { ref } from 'vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { formatCryptoRaw } from '@/lib/utils';

export default {
  name: 'LimboBets',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    currentState: {},
    playerProfit: {},
    gameId: {
      default: 0,
    },
    playHandler: {},
    autoBetChanged: { default: () => {} },
  },
  data() {
    return {
      wager: '1',
      active: 'Manual',
      numOfBets: 0,
      selectedTab: 0,
      autoBet: false,
      autoBetAmount: 0,
      onWinPercent: 0.0,
      onLossPercent: 0.0,
      onWinSelected: 'Reset',
      onLossSelected: 'Reset',
      stopOnLoss: '0',
      stopOnProfit: '0',
      profit: 0,
      buttonDisabled: false,
    };
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    return { tabs: TABS, balS };
  },
  mounted() {
    this.scaleWager(1);
  },
  deactivated() {
    this.autoBet = false;
  },
  watch: {
    autoBet() {
      this.autoBetChanged(this.autoBet);
    },
    currentState: function (newState, oldState) {
      this.buttonDisabled = false;
      if (this.autoBet) {
        if (newState === 'finished' || newState === 'failed') {
          if (this.active === 'Manual' || newState === 'failed') {
            this.autoBet = false;
            return;
          }
          if (this.numOfBets <= 0) {
            this.numOfBets = 0;
            this.autoBet = false;
          }

          this.profit += this.playerProfit;
          if (this.playerWonBet) {
            if (this.onWinSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onWinPercent) / 100.0);
            }
          } else if (this.playerLostBet) {
            if (this.onLossSelected === 'Reset') {
              this.wager = this.rsgp(this.autoBetAmount);
            } else {
              this.scaleWager(1 + Number.parseFloat(this.onLossPercent) / 100.0);
            }
          }

          if (this.stopOnProfitAmount !== 0 && this.profit >= this.stopOnProfitAmount) {
            this.autoBet = false;
          } else if (
            this.stopOnLossAmount !== 0 &&
            this.profit <= -this.stopOnLossAmount
          ) {
            this.autoBet = false;
          }

          if (this.autoBet && this.numOfBets > 0) {
            setTimeout(() => this.bet(this.betAmount), 500);
          } else {
            this.autoBet = false;
            this.numOfBets = 0;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    betAmount() {
      return this.balS.reverseExchangeCurrency(this.wager);
    },
    balanceIcon() {
      return getBalanceIcon(this.balS.default);
    },
    stopOnProfitAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnProfit);
    },
    stopOnLossAmount() {
      return this.balS.reverseExchangeCurrency(this.stopOnLoss);
    },
    playerLostBet() {
      return Number.parseFloat(this.playerProfit) < 0;
    },
    playerWonBet() {
      return !this.playerLostBet;
    },
    inProgress() {
      return this.currentState.toLowerCase() === 'inprogress';
    },
  },
  methods: {
    rsgp(x) {
      return this.balS.formatCrypto(x, true);
    },
    changeTab(index) {
      this.selectedTab = index;
      this.active = this.tabs[index];
    },
    scaleWager(x) {
      try {
        this.wager = this.balS.formatCryptoRaw(
          x * (isNaN(this.wager) ? 0 : this.wager),
          true,
        );
      } catch (e) {}
    },
    setOnWin(selected) {
      this.onWinSelected = selected;
    },
    setOnLoss(selected) {
      this.onLossSelected = selected;
    },
    setActive(active) {
      this.active = active;
      this.autoBet = false;
    },
    initAutoBet(cnt) {
      if (this.autoBet) {
        this.autoBet = false;
      } else if ((cnt || this.numOfBets) > 0) {
        if (cnt) {
          this.numOfBets = cnt;
        }
        this.profit = 0;
        this.autoBet = true;
        this.autoBetAmount = this.betAmount;
        this.bet(this.betAmount);
      }
    },
    bet(amount) {
      this.buttonDisabled = true;
      this.playHandler(amount);
      this.numOfBets -= 1;
    },
  },
};
</script>
