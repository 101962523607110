import Matter from 'matter-js';
// import Color from 'color'
import { colors } from './PlinkoColors';
// import decomp from 'poly-decomp'
// window.decomp = decomp
const { Bodies, Body, Composite, Engine, Events, World, Render, Runner } = Matter;
const frontendUrl = import.meta.env.VITE_FRONTEND_URL;
var sw = window.innerWidth;
class PlinkoService {
  pegSize = 4;
  ballRadius = 20;
  offsetX = 47;
  offsetY = 60;
  countX = 14;
  countY = 8;
  canvas = null;
  engine = null;
  w = 0;
  h = 0;
  ballColor = '#F75B00';
  pegColor = '#efefef';
  dom = null;
  render = null;
  frame = 0;
  ballTexture = null;
  pegs = [];
  punch = null;
  bang = null;
  light = false;
  bucket = -1;
  bucketTarget = -1;
  buckets = [];
  activeBuckets = {};
  timePassed = 0;
  timePassedInterval = null;
  svgToPng(svg, width, height) {
    return new Promise((resolve) => {
      if (typeof width === 'undefined') width = 300;
      if (typeof height === 'undefined') height = 300;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      canvas.style.width = width;
      canvas.style.height = height;
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
      img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
    });
  }

  addToActiveBuckets(gameId, bucket) {
    this.activeBuckets[gameId] = bucket;
  }

  removeFromActiveBuckets(gameId) {
    delete this.activeBuckets[gameId];
  }

  isBucketActive(bucket) {
    for (const [key, value] of Object.entries(this.activeBuckets)) {
      if (value === bucket && key !== null) return true;
    }
    return false;
  }

  async setup(dom, paytable, size) {
    this.countX = paytable.length + 1;
    this.countY = paytable.length - 1;
    this.w = size;
    this.h = size;
    this.dom = dom;
    this.engine = Engine.create();
    this.engine.timing.timeScale = size < 350 ? 0.6 : 1; // paytable.length > 13 ? 0.95 : 0.9
    this.engine.world.gravity.y = 3;
    this.render = Render.create({
      element: this.dom,
      engine: this.engine,
      options: {
        width: this.w * 0.9,
        height: this.h * 0.8,
        showAxes: false,
        showCollisions: false,
        showConvexHulls: false,
        showAngleIndicator: false,
        wireframes: false,
        background: 'transparent',
        pixelRatio: 3.5,
      },
    });
    Render.setPixelRatio(this.render, this.render.options.pixelRatio);
    Render.run(this.render);
    this.runner = Runner.create({
      delta: 1000 / 60,
      isFixed: false,
      enabled: true,
    });
    Runner.run(this.runner, this.engine);

    Events.on(this.engine, 'collisionStart', (...args) => this.collisionStart(...args));
    Events.on(this.engine, 'collisionActive', (...args) => this.collisionActive(...args));
    Events.on(this.engine, 'collisionEnd', (...args) => this.collisionEnd(...args));
    Events.on(this.render, 'beforeRender', () => this.draw());

    const offset = 0.9;
    this.pegSize = ((this.w * 1) / 5 / this.countX / 2) * 0.8;
    this.offsetX = (this.w * offset) / this.countX - this.pegSize * 2;
    this.offsetY = (this.h * offset) / this.countX - this.pegSize * 2.5;
    this.ballRadius = ((this.w * 1) / 3 / this.countX / 2) * 1.2;

    let xy = this.countY;
    let xStart = this.w / 2 - (this.pegSize * xy) / 2 - (this.offsetX * (xy - 1)) / 2;
    let cnt = this.countX;

    // const pegLightSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1165.712" height="1165.712" viewBox="0 0 308.428 308.428"><defs><radialGradient xlink:href="#a" id="b" cx="154.214" cy="154.214" fx="154.214" fy="154.214" r="154.214" gradientUnits="userSpaceOnUse"/><linearGradient id="a"><stop offset="0" stop-color="${
    //   this.light ? this.pegColor : '#fff'
    // }" stop-opacity="${
    //   this.light ? '0.5' : '0.35'
    // }"/><stop offset="1" stop-color="${
    //   this.light ? this.pegColor : '#fff'
    // }" stop-opacity="0"/></linearGradient></defs><g fill-rule="evenodd"><circle cx="154.214" cy="154.214" r="154.214" fill="url(#b)"/><g transform="translate(115.66 115.66)"><circle cx="38.554" cy="38.554" r="38.554" fill="${Color(
    //   this.pegColor
    // ).lighten(
    //   0
    // )}"/><path d="M38.422 0A38.554 38.554 0 000 38.554a38.554 38.554 0 003.193 15.361 38.554 38.554 0 0015.36 3.192 38.554 38.554 0 0038.554-38.554 38.554 38.554 0 00-3.192-15.36A38.554 38.554 0 0038.554 0a38.554 38.554 0 00-.132 0z" fill="${Color(
    //   this.pegColor
    // ).lighten(0.5)}"/></g></g></svg>`
    // const pegLightTexture = await this.svgToPng(
    //   pegLightSvg,
    //   this.pegSize * 8,
    //   this.pegSize * 8
    // )

    for (let y = this.countY - 1; y >= 0; y--) {
      let xy = y + 3;
      xStart = this.w / 2 - (this.pegSize * xy) / 2 - (this.offsetX * (xy - 1)) / 2;
      for (let x = 0; x < cnt; x++) {
        const peg = this.addCircle({
          x: x * this.offsetX + xStart + this.pegSize * x,
          y: y * this.offsetY + this.offsetY + this.pegSize * y,
          r: this.pegSize,
          options: {
            isStatic: true,
            density: 10,
            friction: 0,
            restitution: 0, // 0.9
            collisionFilter: { category: 1 },
            label: `peg-${y}-${x}`,
            activePeg: null,
            render: {
              zIndex: 0,
              fillStyle: '#fff',
            },
          },
        });
        peg.activePeg = this.addCircle({
          x: x * this.offsetX + xStart + this.pegSize * x,
          y: y * this.offsetY + this.offsetY + this.pegSize * y,
          r: this.pegSize * 2,
          options: {
            isStatic: true,
            friction: 0,
            collisionFilter: { category: 4, mask: 0, group: -1 },
            render: {
              visible: false,
              zIndex: 1,
              opacity: 0,
              fillStyle: '#fff',
            },
          },
        });
        this.pegs.push(peg);
      }
      cnt--;
      xStart += this.offsetX / 2;
    }

    let width = this.offsetX;
    let height = this.offsetY * 0.75;
    xy = this.countY + 3;
    let startXPos = this.w / 2 - (this.pegSize * xy) / 2 - (this.offsetX * (xy - 1)) / 2;

    let payTableColors = colors[this.countY];
    paytable.forEach(async (pay, i) => {
      pay = (
        (`${pay}`.indexOf('.') === -1 ? pay : Number(parseFloat(pay).toFixed(2))) + 'x'
      ).substr(0, 4);
      pay = pay[pay.length - 1] === '.' ? pay.substr(0, pay.length - 1) + 'x' : pay;
      const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="30"  >
        <defs>
          <filter id="shadow">
           <feDropShadow dx="1.5" dy="1.5" stdDeviation="0"
          flood-color="${payTableColors[i][0]}" flood-opacity="0.5"/>
          </filter>
         </defs>
            <rect  width="48" height="28" fill="${payTableColors[i][0]}" rx="6" ry="6" x="0" y="0" style="filter:url(#shadow);" />
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Verdana" font-size="14"  fill="#ffffff}">${pay}</text>
        </svg>
      `;
      const svgA = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="30"  >
        <defs>
          <filter id="shadow">
           <feDropShadow dx="1.5" dy="1.5" stdDeviation="0"
          flood-color="${payTableColors[i][1]}" flood-opacity="0.5"/>
          </filter>
         </defs>
            <rect  width="48" height="28" fill="${payTableColors[i][1]}" rx="6" ry="6" x="0" y="0" style="filter:url(#shadow);" />
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Verdana" font-size="14"  fill="#ffffff}">${pay}</text>
        </svg>
      `;

      const payTexture = await this.svgToPng(svg, width, height);
      const payATexture = await this.svgToPng(svgA, width, height);
      let xPos = startXPos + this.offsetX + (i + 1) * this.pegSize + i * this.offsetX;
      let yPos =
        this.offsetY * this.countY + this.offsetY * 0.9 + this.pegSize * this.countY;

      const body = Bodies.rectangle(
        xPos,
        yPos,
        this.offsetX - this.pegSize * 2,
        this.offsetY * 0.5,
        {
          isStatic: true,
          label: 'bucket-' + i,
          render: { zIndex: 4, sprite: { texture: payTexture } },
        },
      );
      this.addBody(body);
      body.bodyActive = Bodies.rectangle(
        xPos,
        yPos, // + this.offsetY * 0.1,
        this.offsetX - this.pegSize * 2,
        this.offsetY * 0.5,
        {
          isStatic: true,
          label: 'bucket-active' + i,
          render: {
            visible: false,
            opacity: 0,
            zIndex: 45,
            sprite: { texture: payATexture },
          },
        },
      );
      this.addBody(body.bodyActive);
      this.buckets.push(body);
    });

    this.handleVisibilityChanged();
  }

  addBody(...bodies) {
    World.add(this.engine.world, ...bodies);
  }

  removeBody(body) {
    Matter.Composite.remove(this.engine.world, body);
  }

  addCircle({ x = 0, y = 0, r = 20, options = {} } = {}) {
    const body = Bodies.circle(x, y, r, options);
    this.addBody(body);
    return body;
  }

  pull(game, callback) {
    this.bucketTarget = game.bucket;

    const pegs = [1];
    game.path.forEach((d) => pegs.push(pegs[pegs.length - 1] + d));
    this.addCircle({
      x: this.w * 0.5,
      y: 0,
      r: this.ballRadius,
      options: {
        callback,
        density: 1,
        friction: 0.7,
        restitution: 1, // 1
        torque: 0.1,
        label: 'ball',
        path: game.path,
        bucket: game.bucket,
        game,
        pegs,
        collisionFilter: { category: 4, mask: 1 },
        render: {
          zIndex: 3,
          fillStyle: this.ballColor,
          sprite: {
            // texture:
            //   `${frontendUrl}/images/tennisBall/tennis-ball-${sw <768?'mobile':sw>768 && sw<1026?'tablet':'tablet'}.png`,
          },
        },
      },
    });

    const allBodies = Composite.allBodies(this.engine.world);
    allBodies.sort((a, b) => {
      const zIndexA =
        a.render && typeof a.render.zIndex !== 'undefined' ? a.render.zIndex : 0;
      const zIndexB =
        b.render && typeof b.render.zIndex !== 'undefined' ? b.render.zIndex : 0;
      return zIndexA - zIndexB;
    });
  }

  draw() {
    this.frame++;
    this.pegs.forEach((peg) => {
      if (peg.activePeg && peg.activePeg.render.visible) {
        peg.activePeg.render.opacity -= 0.04;
        if (peg.activePeg.render.opacity <= 0) {
          peg.activePeg.render.opacity = 0;
          peg.activePeg.render.visible = false;
        }
      }
    });

    this.buckets.forEach((bucket) => {
      const x = parseInt(bucket.label.split('-')[1]);
      if (this.isBucketActive(x)) {
        bucket.render.visible = false;
        bucket.bodyActive.render.opacity = 1;
        bucket.bodyActive.render.visible = true;

        if (bucket.bodyActive.position.y < bucket.position.y + this.offsetY * 0.1) {
          Body.setPosition(bucket.bodyActive, {
            x: bucket.bodyActive.position.x,
            y: bucket.bodyActive.position.y + 1,
          });
        }
      } else {
        if (bucket.bodyActive.position.y > bucket.position.y) {
          Body.setPosition(bucket.bodyActive, {
            x: bucket.bodyActive.position.x,
            y: bucket.bodyActive.position.y - 1,
          });
        } else {
          bucket.render.visible = true;
          bucket.bodyActive.render.opacity = 0;
          bucket.bodyActive.render.visible = false;
        }
      }

      if (this.isBucketActive(x) && bucket.bodyActive.render.opacity < 1) {
        bucket.bodyActive.render.opacity += 0.1;
        if (bucket.bodyActive.render.opacity > 1) {
          bucket.bodyActive.render.opacity = 1;
        }
        if (!bucket.bodyActive.render.visible) {
          bucket.bodyActive.render.visible = true;
        }
        if (bucket.render.visible) bucket.render.visible = false;
      } else if (!this.isBucketActive(x) && bucket.bodyActive.render.visible) {
        bucket.bodyActive.render.opacity -= 0.1;
        if (bucket.bodyActive.render.opacity <= 0) {
          bucket.bodyActive.render.opacity = 0;
          bucket.bodyActive.render.visible = false;
          bucket.render.visible = true;
        }
      }
    });
    const bodies = Composite.allBodies(this.engine.world);
    bodies.forEach((n) => {
      const render = n.render;
      if (!render || !render.visible) {
        return;
      }
      if (n.label === 'ball') {
        n.angle = 0;
        if (
          n.pegs.length &&
          n.position.y >
            (n.pegs.length - 1) * this.offsetY +
              this.offsetY * 0.2 +
              this.pegSize * (n.pegs.length - 1)
        ) {
          var move = this.offsetX * 0.5;
          if (n.path[n.pegs.length]) {
            if (n.path[n.pegs.length] === 0 && n.velocity.x > 0) {
              Body.setVelocity(n, { x: move * -1, y: n.velocity.y });
            } else if (n.path[n.pegs.length] === 1 && n.velocity.x < 0) {
              Body.setVelocity(n, { x: move, y: n.velocity.y });
            }
          }
        }
        let yPos =
          this.offsetY * this.countY + this.offsetY * 0.2 + this.pegSize * this.countY;
        if (n.position.y >= yPos) {
          this.removeBody(n);
          this.addToActiveBuckets(n.game.id, n.game.bucket);
          if (typeof this.bang === 'function') this.bang();
          if (n.callback) {
            n.callback(
              n.game.id,
              colors[this.countY][n.game.bucket][0],
              parseFloat(n.game.multiplier).toFixed(2),
            );
          }
          setTimeout(() => this.removeFromActiveBuckets(n.game.id), 500);
        }
      }
    });
  }

  collisionStart({ pairs }) {
    pairs.forEach(({ bodyA, bodyB }) => {
      if (bodyA.label === 'ball' && bodyB.label.substr(0, 3) === 'peg') {
        if (typeof this.punch === 'function') this.punch();
        bodyB.activePeg.render.opacity = 0.6;
        bodyB.activePeg.render.visible = true;
      } else if (bodyB.label === 'ball' && bodyA.label.substr(0, 3) === 'peg') {
        if (typeof this.punch === 'function') this.punch();
        bodyA.activePeg.render.opacity = 0.6;
        bodyA.activePeg.render.visible = true;
      }
    });
  }

  collisionActive({ pairs }) {
    pairs.forEach(({ bodyA, bodyB }) => {
      let ball, peg;
      if (bodyA.label === 'ball' && bodyB.label.substr(0, 3) === 'peg') {
        // bodyB.activePeg.render.opacity = 1
        ball = bodyA;
        peg = bodyB;
      } else if (bodyB.label === 'ball' && bodyA.label.substr(0, 3) === 'peg') {
        // bodyA.activePeg.render.opacity = 1
        ball = bodyB;
        peg = bodyA;
      } else {
        return;
      }
      const y = parseInt(peg.label.split('-')[1]);
      const x = parseInt(peg.label.split('-')[2]);
      if (y === ball.last_y) {
        if (x === ball.pegs[y]) {
          if (
            ball.path[y] === 0 &&
            ball.position.x > peg.position.x + this.ballRadius * 0.25
          ) {
            Body.applyForce(ball, ball.position, { x: -0.15, y: 1.5 });
          } else if (
            ball.path[y] === 1 &&
            ball.position.x < peg.position.x - this.ballRadius * 0.25
          ) {
            Body.applyForce(ball, ball.position, { x: 0.15, y: 1.5 });
          } else if (
            ball.path[y] === 0 &&
            ball.velocity.x > 0 &&
            ball.position.x > peg.position.x - this.ballRadius * 0.25
          ) {
            // Body.setVelocity(body, { x: -(body.position.y > bodyPeg.position.y ? ((body.position.y - bodyPeg.position.y) / 10) : 0), y: body.velocity.y })
            Body.applyForce(ball, ball.position, {
              x:
                -0.15 -
                (ball.position.y > peg.position.y
                  ? (ball.position.y - peg.position.y) / 2
                  : 0),
              y: 0,
            });
          } else if (
            ball.path[y] === 1 &&
            ball.velocity.x < 0 &&
            ball.position.x < peg.position.x + this.ballRadius * 0.25
          ) {
            // Body.setVelocity(body, { x: +(body.position.y < bodyPeg.position.y ? ((bodyPeg.position.y - body.position.y) / 10) : 0), y: body.velocity.y })
            Body.applyForce(ball, ball.position, {
              x:
                +0.15 +
                (ball.position.y < peg.position.y
                  ? (peg.position.y - ball.position.y) / 2
                  : 0),
              y: 0,
            });
          }
        }
      }
    });
  }

  collisionEnd({ pairs }) {
    pairs.forEach(({ bodyA, bodyB }) => {
      let ball, peg;
      if (bodyA.label === 'ball' && bodyB.label.substr(0, 3) === 'peg') {
        ball = bodyA;
        peg = bodyB;
      } else if (bodyB.label === 'ball' && bodyA.label.substr(0, 3) === 'peg') {
        ball = bodyB;
        peg = bodyA;
      } else {
        return;
      }
      const y = parseInt(peg.label.split('-')[1]);
      const x = parseInt(peg.label.split('-')[2]);
      if (typeof ball.last_y === 'undefined') ball.last_y = -1;

      if (y > ball.last_y) {
        ball.last_y = y;
        let vx = 0;
        if (ball.path[y] === 0) {
          vx =
            -1 *
            (0.15 +
              0.5 * Math.random() +
              (ball.position.x > peg.position.x
                ? (ball.position.x - peg.position.x) / 2
                : 0));
        } else {
          vx =
            +1 *
            (0.15 +
              0.5 * Math.random() +
              (ball.position.x < peg.position.x
                ? (peg.position.x - ball.position.x) / 2
                : 0));
        }
        Body.setVelocity(ball, { x: vx, y: ball.velocity.y });
      } else if (y < ball.last_y) {
        Body.setVelocity(ball, { x: 0, y: ball.velocity.y });
      } else if (y === ball.last_y) {
        if (x === ball.pegs[y]) {
          if (ball.path[y] === 0 && ball.velocity.x > 0) {
            Body.setVelocity(ball, {
              x:
                (ball.path[y] ? 1 : -1) *
                (0.15 +
                  (ball.position.x > peg.position.x
                    ? (ball.position.x - peg.position.x) / 2
                    : 0) +
                  0.5 * Math.random()),
              y: ball.velocity.y < 1.5 ? 1.5 : ball.velocity.y,
            });
          } else if (ball.path[y] === 1 && ball.velocity.x < 0) {
            Body.setVelocity(ball, {
              x:
                (ball.path[y] ? 1 : -1) *
                (0.15 +
                  (ball.position.x < peg.position.x
                    ? (peg.position.x - ball.position.x) / 2
                    : 0) +
                  0.5 * Math.random()),
              y: ball.velocity.y < 1.5 ? 1.5 : ball.velocity.y,
            });
          } else if (ball.path[y] === 0 && ball.velocity.x < -2) {
            Body.setVelocity(ball, { x: -2, y: ball.velocity.y });
          } else if (ball.path[y] === 0 && ball.velocity.x < -2) {
            Body.setVelocity(ball, { x: 2, y: ball.velocity.y });
          }
        }
        if (x > ball.pegs[y] && ball.velocity.x > 0) {
          Body.setVelocity(ball, {
            x: -0.1,
            y: ball.velocity.y < 0.1 ? 0.15 : ball.velocity.y,
          });
        } else if (x < ball.pegs[y] && ball.velocity.x < 0) {
          Body.setVelocity(ball, {
            x: 0.1,
            y: ball.velocity.y < 0.1 ? 0.15 : ball.velocity.y,
          });
        } else if (x > ball.pegs[y] && ball.velocity.x < -2) {
          Body.setVelocity(ball, { x: -2, y: ball.velocity.y });
        } else if (x < ball.pegs[y] && ball.velocity.x > 2) {
          Body.setVelocity(ball, { x: 2, y: ball.velocity.y });
        }
      }
    });
  }

  handleVisibilityChanged() {
    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      // do nothing
    } else {
      // Handle page visibility change

      document.addEventListener(
        visibilityChange,
        () => {
          if (this.timePassedInterval === null) {
            const updateTime = 1000 / 60;
            this.timePassedInterval = setInterval(() => {
              if (document[hidden]) {
                Engine.update(this.engine, updateTime);
                this.draw();
              } else {
                // Engine.update(this.engine, this.timePassed, 0)
                this.timePassed = 0;
                clearInterval(this.timePassedInterval);
                this.timePassedInterval = null;
              }
            }, updateTime);
          }
        },
        false,
      );
    }
  }

  destroy() {
    try {
      if (this.timePassedInterval != null) {
        clearInterval(this.timePassedInterval);
      }
      if (this.runner) {
        Runner.stop(this.runner);
      }
      if (this.engine) {
        if (this.engine.events) {
          this.engine.events = {};
        }
        if (this.engine.world) {
          World.clear(this.engine.world);
        }
        Engine.clear(this.engine);
      }
    } catch (e) {}
  }
}

export default PlinkoService;
