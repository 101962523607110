<template>
  <punt-modal show-close="true" size="max-w-2xl" class="fairness_title">
    <template #title>
      <div class="flex flex-row items-center gap-2 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="text-xl font-bold text-onprimary max-[767px]:text-lg">
          {{ game }} Fairness
        </h1>
      </div>
    </template>
    <template #body>
      <div>
        <div class="flex flex-col justify-center">
          <label
            class="mt-4 inline mb-2 text-sm font-medium text-text-dim-color"
            for="client">
            Active Client Seed
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 right-2 items-center pl-3">
              <button
                class="text-white/90 hover:text-slate-400/60 bg-secondary p-[5px] pr-0"
                v-on:click="copyClient">
                <svg
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                  <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                </svg>
              </button>
            </div>
            <input
              id="client"
              :value="clientSeed"
              class="input-bet"
              readonly
              required
              type="text" />
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <label
            class="mt-4 inline mb-2 text-sm font-medium text-text-dim-color"
            for="server">
            Active Server Seed Hash
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 right-2 items-center pl-3">
              <button
                class="text-white/90 hover:text-slate-400/60 bg-secondary p-[5px] pr-0"
                v-on:click="copyServer">
                <svg
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                  <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                </svg>
              </button>
            </div>
            <input
              id="server"
              :value="serverSeed"
              class="input-bet"
              readonly
              required
              type="text" />
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <label
            class="mt-4 inline mb-2 text-sm font-medium text-text-dim-color"
            for="nonce">
            Total plays made with pair
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 right-2 items-center pl-3">
              <button
                class="text-white/90 hover:text-slate-400/60 bg-secondary p-[5px] pr-0"
                v-on:click="copyNonce">
                <svg
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                  <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                </svg>
              </button>
            </div>
            <input
              id="nonce"
              :value="nonce"
              class="input-bet"
              readonly
              required
              type="text" />
          </div>
        </div>
      </div>
      <div class="md:px-4 md:py-8 md:mt-8 p-3 mt-5 bg-secondary-light/90 rounded-md">
        <h2 class="text-slate-300 text-center font-bold md:text-lg text-md">
          Rotate Seed Pair
        </h2>
        <div class="flex flex-col justify-center">
          <label
            class="mt-4 inline mb-2 text-sm font-medium text-slate-300/90"
            for="newSeed">
            New Client Seed
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 right-0 items-center">
              <button
                class="flex justify-center items-center gap-2 text-dark/90 inline bg-onprimary hover:bg-onprimary/70 h-full rounded-r-md px-4 max-[480px]:px-[10px]"
                v-on:click="generateSeed">
                <span class="max-[480px]:hidden">Change</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <input
              id="newSeed"
              v-model="newClientSeed"
              class="h-12 bg-primary/50 placeholder:text-text-dim-color border-2 border-slate-400/60 text-slate-300 text-sm rounded-lg font-semibold focus:ring-green-600 focus:border-green-600 block w-full p-2.5 max-[480px]:h-[2.5rem]"
              required
              type="text" />
          </div>
        </div>

        <button
          :disabled="loading"
          v-on:click="changeSeed"
          class="bg-onprimary disabled:cursor-not-allowed disabled:bg-onprimary/60 disabled:hover:bg-onprimary/60 hover:opacity-80 disabled:hover:scale-100 w-full mt-8 p-3 text-dark/90 font-semibold rounded-xl">
          Rotate
        </button>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { createRandomString } from '@/lib/random';
import { copyToClipboard } from '@/lib/utils';
export default {
  name: 'Fairness.vue',
  components: { PuntModal },
  props: {
    game: {
      required: true,
    },
    serverSeed: {
      default: '',
    },
    clientSeed: {
      default: 'Loading...',
    },
    nonce: {
      default: '',
    },
    fetchSeed: {
      type: Function,
    },
    updateSeed: {
      type: Function,
    },
  },
  data() {
    return { newClientSeed: '', loading: false };
  },
  async created() {
    this.generateSeed();
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      try {
        if (this.fetchSeed !== null && typeof this.fetchSeed === 'function') {
          await this.fetchSeed();
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    copyClient() {
      copyToClipboard(this.clientSeed);
    },
    copyServer() {
      copyToClipboard(this.serverSeed);
    },
    copyNonce() {
      copyToClipboard(this.nonce);
    },
    generateSeed() {
      this.newClientSeed = createRandomString(30);
    },
    async changeSeed() {
      if (!this.newClientSeed || this.newClientSeed.length === 0) {
        this.$toast.error('The new client seed cannot be empty!');
        return;
      }
      this.loading = true;
      try {
        if (this.updateSeed !== null && typeof this.updateSeed === 'function') {
          await this.updateSeed(this.newClientSeed);
          this.$toast.success('Seed pair updated!');
          this.newClientSeed = createRandomString(30);
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
