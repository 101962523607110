export function doRecaptcha(action) {
  const grecaptcha = window.grecaptcha;
  const sitekey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

  return new Promise((resolve, reject) => {
    grecaptcha.enterprise.ready(async () => {
      grecaptcha.enterprise.execute(sitekey, { action }).then(resolve).catch(reject);
    });
  });
}
