<template>
  <div
    :class="
      (hidden ? 'hidden-' : '') +
      'symbol-container' +
      (full ? '-full' : '') +
      ' ' +
      (blury ? 'animation' + reel : '')
    "
    :style="getStyle()">
    <div class="middle" v-if="type === 0">
      <SlotsWild />
    </div>
    <div class="middle" v-else-if="type === 1">
      <SlotsGreenRuby />
    </div>
    <div class="middle" v-else-if="type === 2">
      <SlotsBlueSpade />
    </div>
    <div class="middle" v-else-if="type === 3">
      <SlotsYellowTriangle />
    </div>
    <div class="middle" v-else-if="type === 4">
      <SlotsBlueCoin />
    </div>
    <div class="middle" v-else-if="type === 5">
      <SlotsGreenDiamond />
    </div>
    <div class="middle" v-else-if="type === 6">
      <SlotsRedSquare />
    </div>
    <div class="middle" v-else-if="type === 7">
      <SlotsRedHeart />
    </div>
    <div class="middle" v-else-if="type === 8">
      <SlotsYellowCoin />
    </div>
    <div class="middle" v-else-if="type === 9">
      <SlotsPurplePolygon />
    </div>
    <div class="middle" v-else-if="type === 10">
      <SlotsPurpleClub />
    </div>
    <div class="middle" v-else-if="type === 11">
      <SlotsGreyCat />
    </div>
    <div class="middle" v-else-if="type === 12">
      <SlotsScatter />
    </div>
    <div class="middle" v-else>NO</div>
  </div>
</template>

<script>
import SlotsWild from './SlotsWild.vue';
import SlotsGreenRuby from './SlotsGreenRuby.vue';
import SlotsBlueSpade from './SlotsBlueSpade.vue';
import SlotsYellowTriangle from './SlotsYellowTriangle.vue';
import SlotsBlueCoin from './SlotsBlueCoin.vue';
import SlotsGreenDiamond from './SlotsGreenDiamond.vue';
import SlotsRedSquare from './SlotsRedSquare.vue';
import SlotsRedHeart from './SlotsRedHeart.vue';
import SlotsYellowCoin from './SlotsYellowCoin.vue';
import SlotsPurplePolygon from './SlotsPurplePolygon.vue';
import SlotsPurpleClub from './SlotsPurpleClub.vue';
import SlotsGreyCat from './SlotsGreyCat.vue';
import SlotsScatter from './SlotsScatter.vue';

// ../../../assets/new/icons/slots/bag.png

export default {
  name: 'SlotsSymbol',
  components: {
    SlotsWild,
    SlotsGreenRuby,
    SlotsBlueSpade,
    SlotsYellowTriangle,
    SlotsBlueCoin,
    SlotsGreenDiamond,
    SlotsRedSquare,
    SlotsRedHeart,
    SlotsYellowCoin,
    SlotsPurplePolygon,
    SlotsPurpleClub,
    SlotsGreyCat,
    SlotsScatter,
  },
  props: {
    type: {
      type: Number,
    },
    hidden: {
      type: Boolean,
    },
    currentOffset: {
      type: Number,
    },
    reel: {
      type: Number,
      default: 1,
    },
    full: {
      default: false,
    },
    blury: {
      default: false,
    },
  },
  computed: {},
  methods: {
    getStyle() {
      const y = this.blury ? 50 : -50 + -10 * this.currentOffset;
      return (
        '-webkit-transform: translate(-50%, ' +
        y +
        '%); -webkit-transition: none; transform: translate(-50%, ' +
        y +
        '%); transition: none;'
      );
    },
  },
};
</script>

<style scoped>
.symbol-container {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 10px;
  height: 33.333333333%;
  max-height: 33.333333333%;
  width: 100%;
  margin: 0 1% 0 1%;
}

.hidden-symbol-container {
  opacity: 0.2;
  position: relative;
  top: 50%;
  left: 50%;
  height: 33.333333333%;
  max-height: 33.333333333%;
  width: 100%;
  margin: 0 1% 0 1%;
}

.symbol-container-full {
  position: relative;
  top: 50%;
  left: 50%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  margin: 0 1% 0 1%;
}

.hidden-symbol-container-full {
  opacity: 0.2;
  position: relative;
  top: 50%;
  left: 50%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  margin: 0 1% 0 1%;
}

.middle {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.animation1 {
  animation: spins 0.6s linear 0s infinite alternate-reverse;
}
.animation2 {
  animation: spins 0.6s linear 0s infinite alternate-reverse;
}
.animation3 {
  animation: spins 0.6s linear 0s infinite alternate-reverse;
}
.animation4 {
  animation: spins 0.6s linear 0s infinite alternate-reverse;
}
.animation5 {
  animation: spins2 0.6s linear 0s infinite alternate-reverse;
}

@keyframes spins {
  from {
    top: -50%;
  }
  to {
    top: -1020%;
  }
}

@keyframes spins2 {
  from {
    top: -50%;
  }
  to {
    top: -1420%;
  }
}
</style>
