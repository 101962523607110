<template>
  <div class="p-4">
    <div class="">
      <h5 class="mb-5 text-2xl font-bold tracking-tight text-white-900 dark:text-white">
        Self-Assessment
      </h5>

      <p class="mt-6 mb-4 font-normal text-white-700 dark:text-white">
        social gaming can be an enjoyable pastime for many people, providing
        entertainment. However, for some individuals, social gaming can become more than
        just a harmless activity. It can develop into a serious problem that affects
        various aspects of their lives. Recognizing the signs is the first step towards
        addressing this issue. This self-assessment tool is designed to help you reflect
        on your social gaming behaviour and determine whether it may be causing problems
        in your life. It is essential to approach this assessment with honesty and
        openness to gain a clear understanding of your social gaming habits.
      </p>

      <p class="mb-3 font-normal text-white-700 dark:text-white">
        Ask yourself the following questions:
      </p>
      <ol class="list-decimal ml-8 mb-3">
        <li>
          Does your social gaming interfere with responsibilities in your life like tasks,
          work, chores or projects?
        </li>
        <li>
          Has your studies or employment suffered because of the amount of time you spend
          social gaming?
        </li>
        <li>
          Are there problems in your life for which social gaming provides a welcome sense
          of escape?
        </li>
        <li>
          Have you failed to submit work or projects on time because of the amount of time
          you spend social gaming?
        </li>
        <li>Have you ever borrowed funds for social gaming?</li>
        <li>
          Do you become agitated or ill-tempered when trying to cut down your social
          gaming?
        </li>
        <li>
          Have you ever found yourself in financial difficulty as a result of your social
          gaming?
        </li>
        <li>
          Do you find yourself spending more time on social gaming than you're comfortable
          with?
        </li>
        <li>Do you find yourself lying about your social gaming activity?</li>
        <li>
          Has your social gaming activities ever caused serious or repeated problems in
          your relationships with any of your family members or friends?
        </li>
      </ol>

      <p class="mb-3 font-normal text-white-700 dark:text-white">
        In the event you feel that your social gaming activities has been affecting your
        personal wellbeing, and / or your activities has reached the point where you have
        lost control, find our self-exclusion options here.
      </p>

      <p class="mb-3 font-normal text-white-700 dark:text-white">
        Alternatively, if you feel the need to take a break, find our timeout options
        here.
      </p>

      <p class="mb-3 font-normal text-white-700 dark:text-white">
        If your social gaming may have had, or is at risk of having, a negative impact on
        your mental health, finances or relationships with friends or family, we encourage
        you to get in touch with the following help and support organizations:
      </p>

      <ul class="list-disc ml-8 mb-3">
        <li>
          <a
            target="_blank"
            href="https://www.gamingaddictsanonymous.org/"
            class="underline"
            >Gaming Addicts Anonymous (GAA)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a fellowship of people who support each other in recovering from the problems
          resulting from excessive game playing.
        </li>
        <li>
          <a target="_blank" href="https://fcaa.org/" class="underline"
            >Financial Counseling Association of America (FCAA)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a professional association of financial counseling agencies that assist
          consumers with financial counseling services of all kinds, as well as debt
          management plans for the repayment of unsecured debts.
        </li>
        <li>
          <a target="_blank" href="https://www.nfcc.org/" class="underline"
            >National Foundation for Credit Counseling (NFCC)
            <svg
              class="pb-1 inline-flex"
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="15"
              fill="white"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
          is a non-profit financial counseling agency. The NFCC helps people to defeat
          their debt and look forward with confidence.
        </li>
      </ul>

      <p class="mt-6 text-lg font-bold">
        *REMINDER: The self-assessment will help you figure out if there is, or you are
        developing a problem. If you answered yes to one or more of the above questions,
        you should seek help.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelfAssessment.vue',
};
</script>

<style scoped></style>
