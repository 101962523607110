<template>
  <fairness
    game="Blackjack"
    :client-seed="clientSeed"
    :server-seed="serverSeedHash"
    :nonce="seedPairNonce"
    :fetch-seed="updateSeedInfo"
    :update-seed="changeSeed" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Fairness from '@/components/Shared/Fairness.vue';

export default {
  name: 'FairnessModal',
  components: { Fairness },
  computed: {
    ...mapGetters('Blackjack', ['clientSeed', 'serverSeedHash', 'seedPairNonce']),
  },
  methods: {
    ...mapActions('Blackjack', ['setNewClientSeed', 'updateSeedInfo']),
    changeSeed(seed) {
      return this.setNewClientSeed({ seed });
    },
  },
};
</script>
