<template>
  <router-view></router-view>
  <div
    class="flex h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px]">
      <div
        class="flex h-auto flex-col-reverse justify-center md:min-h-[auto] lg:flex-row">
        <!-- Side Menu -->
        <game-actions
          :game-data="{ name: 'Limbo', id: null }"
          :pre-extras="gameActions"
          class="block rounded-bl-xl rounded-br-xl bg-tertiary md:hidden" />

        <div
          class="w-full min-w-[300px] bg-side-color px-2 pb-4 md:w-full lg:rounded-bl-xl lg:rounded-br-none lg:rounded-tl-xl rounded-xl md:px-3 md:py-3 lg:w-[300px]">
          <limbo-bets
            :autoBetChanged="autoBetChanged"
            :playHandler="play"
            :playerProfit="profit"
            :currentState="currentState" />
        </div>
        <div
          class="flex flex-col items-center bg-tertiary md:h-auto md:min-h-[auto] md:w-full md:rounded-tr-xl">
          <div class="h-full w-full bg-primary/60 lg:p-5 bg-common bg-no-repeat bg-cover bg-center ">
            <!-- The Game Grid -->
            <div class="column" style="padding: 0px">
              <div class="grid-wrap">
                <div
                  class="box"
                  style="padding-bottom: 0px; margin-bottom: 0px; min-height: 30px">
                  <div class="h-full w-full xl:pt-5">
                    <div class="flex flex-col items-center">
                      <div class="flex-1">
                        <!-- <span>Grid Here</span> -->
                        <div class="mt-2 flex w-full items-center gap-2 md:h-12">
                          <button
                            v-for="(game, index) in previousGames"
                            :key="game.id"
                            :class="[
                              game.won
                                ? '!bg-onprimary text-gray-800'
                                : 'bg-secondary-light',
                            ]"
                            class="min-w-8 rounded-3xl px-2 py-1 text-sm text-xs font-bold md:px-4 md:py-3 md:text-sm"
                            :style="
                              'opacity: ' +
                              (5 - previousGames.length + 1 + index) * 0.2 +
                              ';'
                            ">
                            <router-link
                              class="label is-unselectable no-underline"
                              :to="{
                                name: 'LimboGameInfo',
                                params: { gameId: game.id },
                              }">
                              {{ game.roll }}
                            </router-link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tabs is-right is-small hidden">
                    <ul class="flex flex-row justify-end gap-2">
                      <li>
                        <div
                          class="label is-unselectable"
                          style="
                            opacity: 0;
                            border-radius: 999px;
                            margin: 2px;
                            text-align: center;
                            vertical-align: middle;
                            height: 32px;
                          ">
                          0.00×
                        </div>
                      </li>
                      <li v-for="(game, index) in previousGames" :key="game.id">
                        <div
                          class="label is-unselectable flex h-6 items-center justify-center px-3 py-4"
                          :style="
                            'opacity: ' +
                            (5 - previousGames.length + 1 + index) * 0.2 +
                            ';' +
                            lastGameStyle(game)
                          ">
                          <span class="label is-unselectable no-underline">
                            {{ game.roll }}
                          </span>
                          <!--                          <span class="label is-unselectable no-underline"
                                                      :to="{ name: 'LimboGameInfo', params: { gameId: game.id } }">{
                                                      { game.roll }}
                                                    </span>-->
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="columns">
                    <div class="column" id="js-canvas-root">
                      <div class="game-wrap">
                        <div class="centralizer min-h-[290px] md:min-h-[400px]">
                          <number
                            ref="number"
                            :to="result"
                            :format="theFormat"
                            :duration="duration"
                            :class="'is-unselectable result-show ' + resultClass"
                            easing="Power1.easeOut" />
                        </div>
                        <!-- Floating actions -->
                        <div class="flex-none">
                          <div
                            class="mb-2 flex flex-none flex-row justify-between gap-2 rounded-xl bg-side-color p-2 shadow-md md:p-3">
                            <div class="flex-1">
                              <label
                                for="payout"
                                class="mb-2 block text-sm font-medium text-text-dim-color"
                                >Multiplier</label
                              >
                              <div class="relative">
                                <input
                                  type="text"
                                  id="payout"
                                  v-model="multiplier"
                                  @change="changeMultiplier(multiplier)"
                                  :disabled="autoBetRunning"
                                  class="input-bet"
                                  placeholder="0" />
                                <div
                                  class="pointer-events-auto absolute inset-y-0 right-0 flex items-center space-x-2 pr-2">
                                  <button type="button" class="btn-multiplier">X</button>
                                </div>
                              </div>
                            </div>

                            <div class="flex-1">
                              <label
                                for="payout"
                                class="mb-2 block text-sm font-medium text-text-dim-color"
                                >Win Chance</label
                              >
                              <div class="relative">
                                <input
                                  type="text"
                                  v-model="chance"
                                  @change="changeWinChance(chance)"
                                  :disabled="autoBetRunning"
                                  class="input-bet"
                                  placeholder="0" />
                                <div
                                  class="pointer-events-auto absolute inset-y-0 right-0 flex items-center space-x-2 pr-2">
                                  <button type="button" class="btn-multiplier">%</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label class="label is-unselectable" style="height: 1px"></label>
                </div>
              </div>
            </div>
          </div>
          <game-actions
            :game-data="{ name: 'Limbo', id: null }"
            :pre-extras="gameActions"
            class="hidden rounded-br-xl bg-tertiary md:block" />
        </div>
      </div>

      <game-details
        game="Limbo"
        edge="1"
        provider="Punt Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { betSound, winSound, looseSound, limboSound } from './sounds';
import { clamp, round } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import BetButton from './BetButton.vue';
import { defaultConvertor } from '@/lib/rsgp';
import axios from '@/lib/myAxios';
import ProvablyFairCard from './ProvablyFairCard.vue';
import EditableNumberInput from './EditableNumberInput.vue';

import LimboBets from './LimboBets.vue';
import LimboFairness from './LimboFairness.vue';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import NumberAnimation from '../Shared/NumberAnimation.vue';
import { delay } from '@/lib/utils';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'Limbo',
  components: {
    BetButton,
    GameDetails,
    EditableNumberInput,
    ProvablyFairCard,
    LimboBets,
    LimboFairness,
    GameHistoryTable,
    GameActions,
    NumberAnimation,
  },
  data() {
    return {
      betAmount: 5000,
      wager: '0',
      form: {
        editing: '',
      },
      state: {
        pot: 10e3,
        placingBet: false,
      },
      target: 50,
      chance: null,
      multiplier: null,
      over: true,
      autoBetRunning: false,

      result: 1,
      gameId: 0,
      profit: 0,

      resultStyle: 'result-span',
      showStyle: 'result-wrap',

      previousGames: [],
      currentState: 'notStarted',

      houseEdgePercent: null,
      maxMultiplier: null,

      showFairness: false,
      duration: 0,
      gameActions: [
        ['/casino/limbo/rules', 'Rules'],
        // ['/casino/limbo/fairness', 'Fairness'],
      ],
      resultClass: '',
    };
  },
  setup() {
    const theFormat = function (number) {
      return Number(number).toFixed(2) + 'x';
    };
    return { theFormat };
  },
  created() {
    try {
      axios
        .get(`limbo/limits`)
        .then((res) => res.data)
        .then((limits) => {
          this.houseEdgePercent = limits.houseEdge;
          this.maxMultiplier = limits.maxPayout;
          this.changeMultiplier(2);
        })
        .catch((e) => {
          const { data, status } = e;
          if (typeof data.error != 'undefined' && data.error === 'Unauthenticated.') {
          }
        });
    } catch (error) {
      console.log('error while checking limits');
    }
  },
  computed: {
    ...mapGetters(['me', 'userBalance', 'clientSeed']),
    ...mapGetters('Sound', ['isSoundMuted']),
    leftStyle() {
      return this.over ? 'left-over-style' : 'left-under-style';
    },
    rightStyle() {
      return this.over ? 'right-over-style' : 'right-under-style';
    },
    resultValue() {
      return this.currentState === 'notStarted'
        ? 100
        : parseFloat(this.result).toFixed(2);
    },
  },
  watch: {
    userBalance(x, last) {},
    multiplier(x) {
      this.state.pot = x * this.betAmount;
    },
    wager(x) {
      this.state.pot = this.multiplier * defaultConvertor.parse(x);
    },
    tab() {
      this.page = 1;
    },
  },
  methods: {
    formatResult(value) {
      return (Number.parseFloat(value) / 100).toFixed(2) + '×';
    },
    autoBetChanged(running) {
      this.autoBetRunning = running;
    },
    lastGameStyle(game) {
      const backgroundColor =
        'background-color: #' + (game.won ? '1EE563' : '293C49') + ';';
      const color = 'color: #' + (game.won ? '000000' : 'ffffff') + ';';
      return (
        'border-radius: 999px; margin: 2px; text-align: center; vertical-align: middle; ' +
        color +
        backgroundColor
      );
    },
    changeTarget(newTarget) {
      this.target = newTarget;
      this.chance = round(
        clamp(this.over ? 100 - this.target : this.target, 0.01, 99.999999999),
        2,
      );
      this.multiplier = round(
        clamp((100.0 - this.houseEdgePercent) / this.chance, 1.01, this.maxMultiplier),
        8,
      );
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.999999999),
        8,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 8);
    },
    toggleDirection() {
      this.over = !this.over;
      this.target = round(100 - this.target, 2);
    },
    changeWinChance(newChance) {
      this.chance = round(clamp(newChance, 0.01, 99.999999999), 8);
      this.multiplier = round(
        clamp((100.0 - this.houseEdgePercent) / this.chance, 1.01, this.maxMultiplier),
        2,
      );
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.999999999),
        8,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 8);
    },
    changeMultiplier(newMultiplier) {
      this.multiplier = round(clamp(newMultiplier, 1.01, this.maxMultiplier), 2);
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.999999999),
        8,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 8);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    async play(wager) {
      this.gameId = null;
      this.duration = 0;
      this.result = 0;
      this.resultClass = '';

      try {
        const guess = this.multiplier;
        this.playSound(betSound);
        this.currentState = 'inprogress';
        const { data } = await axios.post(`limbo`, { wager, guess });

        this.duration = 0.3;
        this.gameId = data.gameId;
        this.profit = data.profit;
        this.result = data.result.toFixed(2);
        this.$refs.number.play();
        this.playSound(limboSound);
        await delay(300);
        if (this.result >= guess) {
          this.playSound(winSound);
          this.resultClass = 'won';
        } else {
          this.playSound(looseSound);
          this.resultClass = 'lost';
        }
        this.showStyle = 'result-wrap-show';
        this.previousGames.push({
          roll: this.result,
          id: this.gameId,
          won: this.result >= guess,
        });
        if (this.previousGames.length > 5) {
          this.previousGames.shift();
        }
        this.currentState = 'finished';
        this.resultStyle = 'result-span';
        this.showStyle = 'result-wrap';
      } catch (e) {
        const { data, status } = e;
        this.currentState = 'failed';
        if (status === 403) {
          window.swal(
            'Uh-oh',
            'Not allowed to play: ' +
              (typeof data.error != 'undefined' ? data.error : ''),
            'error',
          );
        } else {
          if (typeof data.error != 'undefined' && data.error === 'Unauthenticated.') {
            this.$router.replace('?tab=login');
          } else {
            window.swal(
              'Uh-oh',
              'Something went wrong when placing your play: ' +
                (typeof data.error != 'undefined' ? data.error : ''),
              'error',
            );
          }
        }
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    ...mapActions('Limbo', ['updateNonce']),
    ...mapActions('Sound', ['toggleSound']),
    scaleWager(x) {
      const desiredWager = x * this.betAmount;
      if (desiredWager <= this.userBalance) {
        this.wager = this.rsgp(desiredWager);
      } else {
        this.wager = this.rsgp(this.userBalance);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-start {
  justify-content: flex-start;
}

.result-show {
  font-size: 6em;
  font-weight: 700;
}

.lost {
  color: #ff2147 !important;
}

.won {
  color: #01e405 !important;
}

.centralizer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 1em;
}

.dicing-tables {
  flex: none;
  overflow-x: scroll;
  width: 100%;
}

.dicing-tables .tabs ul li:not(.is-active) a {
  color: #ffffff;
}

.control.has-text-centered {
  text-align: center;
}

.is-uppercase {
  text-transform: uppercase;
}

.has-pointer-cursor {
  cursor: pointer;
}

.has-south-east-stickiness {
  align-self: flex-end;
}

.panel table {
  margin-bottom: 0px;
}
</style>

<style type="text/css" scoped>
.no-underline {
  border-bottom: none;
}
</style>

<style type="text/css">
.text-label {
  fill: #363636;
}

.dark .text-label {
  fill: #fff;
}

.game-canvas {
  position: relative;
  width: 100%;
  max-width: 730px;
  text-align: left;
  border-radius: 100px;
  padding: 1.5em;
  background: #002722;
  margin: 4em 0px;
}

.helper-container {
  position: absolute;
  bottom: 100%;
  left: calc(1.5em + 4px);
  right: calc(1.5em + 4px);
}

.number-wrap {
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0px);
}

.number-wrap::after {
  content: '';
  bottom: 0px;
  position: absolute;
  transform: translate(-50%, 0px);
  width: 0px;
  height: 0px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #1a2b32;
  border-left-color: transparent;
  border-width: 0px 4px 5px;
  border-style: solid;
}

.number-span {
  position: absolute;
  font-weight: 600;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  font-family: proxima-nova, sans-serif;
}

.range-container {
  position: relative;
  height: 8px;
}

.result-container {
  position: absolute;
  display: flex;
  bottom: 50%;
  left: 0px;
  right: 0px;
  z-index: 5;
  pointer-events: none;
  will-change: transform;
  transition: transform 800ms ease 0s;
}

.result-wrap {
  position: relative;
  transform-origin: center bottom;
  transform: translateX(-50%) scale(0.8);
  opacity: 0;
  transition: all 0.3s ease 0s;
  animation: 0s ease 0s 1 normal none running none;
}

.result-wrap-show {
  position: relative;
  transform: translateX(-50%);
  transform-origin: center bottom;
  transition: all 0.3s ease 0s;
  animation: 500ms ease 0s 1 normal none running gGjlxm;
}

.svg-img {
  width: 7ch;
  height: auto;
  filter: drop-shadow(rgba(25, 25, 25, 0.1) 0px 0px 3px);
}

.result-span {
  color: rgb(183, 191, 214);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.result-span-win {
  color: rgb(0, 228, 3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.result-span-loss {
  color: rgb(255, 34, 71);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.background-wrap {
  pointer-events: none;
  height: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  box-shadow: rgb(4, 29, 39) 0px 0px 0px 10px;
  border-radius: 100px;
  transition: opacity 300ms ease 0s;
  overflow: hidden;
}

.link-buttons {
  -webkit-animation: 500ms ease 0s normal forwards 1 fadein;
  animation: 500ms ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
