<template>
  <div
    :class="showBenefits ? 'bg-secondary' : 'bg-side-color'"
    class="p-2 rounded-xl mt-2">
    <header class="">
      <div class="flex gap-2 text-slate-300 p-2 items-center">
        <img :src="icon" class="h-10 w-10" />
        <p class="flex-1">
          {{ title }}
        </p>
        <span v-on:click="toggle">
          <span class="h-[18px] w-[18px]">
            <img :src="chevIcon()" />
          </span>
        </span>
      </div>
    </header>
    <div v-if="showBenefits" class="text-[#76b4d3] text-sm px-4">
      <div>
        <ul class="list-disc px-4">
          <li v-for="benefit in benefits" :key="benefit">{{ benefit }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import chevup from '../../assets/svg/vip/benefits/chevron-compact-up.svg';
import chevdown from '../../assets/svg/vip/benefits/chevron-compact-down.svg';
export default {
  name: 'BenefitsItem',
  props: {
    title: {},
    benefits: {},
    icon: {},
    benefitsShown: {
      default: false,
    },
  },
  created() {
    this.showBenefits = this.benefitsShown;
  },
  data() {
    return {
      showBenefits: false,
    };
  },
  methods: {
    toggle() {
      this.showBenefits = !this.showBenefits;
    },
    chevIcon() {
      return this.showBenefits ? chevup : chevdown;
    },
  },
};
</script>
