<template>
  <div class="flex flex-col" @mouseleave="hovering = false" @mouseenter="hovering = true">
    <div
      :class="{ 'hover:cursor-pointer': isStaff }"
      class="mx-2 my-1 bg-secondary-light flex flex-col items-start justify-start rounded-lg p-2"
      v-on:click="toggleMessage">
      <div class="flex flex-row justify-between w-full">
        <!--v-tooltip.bottom-center="message.sender.vipLevel.name"-->
        <div class="flex flex-row items-center text-sm">
          <img
            v-if="vipIcon"
            :src="vipIcon"
            alt=""
            class="self-center w-5 h-5 rounded-full mx-1" />
          <span :style="{ color: chatTitleStyle(message.sender) }" class="font-semibold">
            <span v-if="staffTile">{{ staffTile }} </span>
            <span v-if="chatTitle(message.sender)"
              >[{{ chatTitle(message.sender) }}]
            </span>
            {{ message.sender.username }}:
          </span>
        </div>
        <div v-if="hovering && isStaff" class="px-4 flex flex-row items-end gap-1">
          <button
            v-tooltip="'Delete'"
            class="hover:opacity-70 flex flex-col justify-center items-center text-slate-300/60"
            v-on:click="hideMessage">
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                fill-rule="evenodd" />
            </svg>
            <span class="text-center hidden w-full text-xs">Delete</span>
          </button>
          <button
            v-tooltip="'Ban'"
            v-if="!isHelper"
            class="hover:opacity-70 flex flex-col justify-center items-center text-slate-300/60"
            v-on:click="banUser">
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                fill-rule="evenodd" />
            </svg>
            <span class="text-center hidden text-xs">Ban</span>
          </button>
          <button
            v-tooltip="'Mute'"
            class="flex flex-col justify-center items-center text-slate-300/60"
            v-on:click="muteUser">
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
                fill-rule="evenodd" />
            </svg>
            <span class="text-center hidden text-xs">Mute</span>
          </button>
          <button
            v-tooltip="'Kick'"
            class="flex flex-col justify-center items-center text-slate-300/60"
            v-on:click="
              postChatAction('kick', message.sender.username, { reason: 'n/a' })
            ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>
            <span class="text-center hidden text-xs">Kick</span>
          </button>
        </div>
      </div>
      <div class="text-sm text-slate-300 mt-0.5">
        <p class=" ">
          {{ message.message.body }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import { mapActions, mapGetters } from 'vuex';
import bronze from '../../assets/svg/vip/benefits/bronze.svg';
import silver from '../../assets/svg/vip/benefits/silver.svg';
import gold from '../../assets/svg/vip/benefits/gold.svg';
import platinum1 from '../../assets/svg/vip/benefits/platinum.svg';
import platinum2 from '../../assets/svg/vip/benefits/platinum.svg';
import platinum3 from '../../assets/svg/vip/benefits/platinum.svg';
import platinum4 from '../../assets/svg/vip/benefits/platinum.svg';
import platinum5 from '../../assets/svg/vip/benefits/platinum.svg';
import diamond from '../../assets/svg/vip/benefits/diamond.svg';
import { chatStore } from '@/store/chatStore';
import { upperFirst } from 'lodash';

export default {
  name: 'ChatMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const chatS = chatStore();
    return { chatS };
  },
  data() {
    return {
      hovering: false,
    };
  },
  computed: {
    ...mapGetters([
      'me',
      'iAmStaff',
      'iAmAdmin',
      'iAmMod',
      'activeRoom',
      'chatTitles',
      'activeRoom',
    ]),
    isStaff() {
      return this.iAmStaff;
    },
    isHelper() {
      return this.iAmHelper;
    },
    staffTile() {
      try {
        const rank = this.message.sender.rank;
        if (rank && rank !== 'user') {
          let title = rank.charAt(0).toUpperCase() + rank.substring(1);
          return `[${title}]`;
        }
      } catch (e) {}
      return '';
    },
    vipIcon() {
      if (
        typeof this.message.sender.vipLevel === 'undefined' ||
        this.message.sender.vipLevel === null
      ) {
        return null;
      }
      switch (this.message.sender.vipLevel.icon) {
        case 'bronze':
          return bronze;
        case 'silver':
          return silver;
        case 'gold':
          return gold;
        case 'platinum1':
          return platinum1;
        case 'platinum2':
          return platinum2;
        case 'platinum3':
          return platinum3;
        case 'platinum4':
          return platinum4;
        case 'platinum5':
          return platinum5;
        case 'platinum6':
          return platinum5;
        case 'diamond':
          return diamond;
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapActions(['deleteMessage', 'setRosterVisibility', 'ignoreUser']),
    chatTitleStyle(rank) {
      if (!rank.chatTitle || typeof rank.chatTitle === 'undefined') {
        return '#5289A6';
      }
      return rank.chatTitle.text;
    },
    chatTitle(rank) {
      if (!rank.chatTitle || typeof rank.chatTitle === 'undefined') {
        return null;
      }
      return rank.chatTitle.name;
    },
    getTimeStamp() {
      return dateFormat(new Date(this.message.message.sent_at * 1000), 'hh:mm:ss');
    },
    toggleMessage() {
      //if (this.isStaff && this.message.sender.rank === 'user' && this.me.id !== this.message.sender.id) {
      this.chatS.toggleMessage(this.message.message.id);
      //}
    },

    muteUser() {
      let action = 'mute';
      const username = this.message.sender.username;
      window
        .swal({
          icon: 'info',
          title: `Duration`,
          text: `How long should ${username} be muted for? (minutes)`,
          input: 'number',
          inputValue: 10,
        })
        .then((duration) =>
          this.postChatAction(action, `${username} ${duration.value}`, { reason: 'n/a' }),
        )
        .catch(() => {});
    },
    kickUser() {
      const username = this.message.sender.username;
      this.$http.post('chat_actions', { action: 'kick', username, meta: {} });
    },
    postChatAction(action, username, meta = {}) {
      this.$http
        .post('chat_actions', { action, username, meta })
        .then((response) => {
          this.$swal('Done', `${response.data.message}`, 'success');
        })
        .catch((response) => {
          this.$swal('Error', `${response.data.message}`, 'error');
        });
    },
    hideMessage() {
      this.deleteMessage({
        roomId: this.activeRoom,
        messageId: this.message.message.id,
      });
    },
    title(s) {
      return upperFirst(s);
    },
    banUser() {
      window
        .swal({
          title: `Ban User`,
          text: `Are you sure you want to ban ${this.message.sender.username}? Add reason..`,
          icon: 'info',
          input: 'textarea',
          placeholder: 'Enter reason...',
          showCancelButton: true,
        })
        .then(
          (confirm) => {
            if (confirm.isConfirmed) {
              if (confirm.value && confirm.value.length > 0) {
                this.postChatAction('ban', this.message.sender.username, {
                  reason: confirm.value,
                });
                return;
              }
              this.$toast.info('Ban reason is required');
            }
          },
          (cancelSource) => window.swal.noop,
        );
    },
  },
};
</script>
