/**
 * @name VueJS vChatScroll (vue-chat-scroll)
 * @description Monitors an element and scrolls to the bottom if a new child is added
 * @author Theodore Messinezis <theo@theomessin.com>
 * @file v-chat-scroll  directive definition
 */

const scrollToBottom = (el) => {
  el.scrollTop = el.scrollHeight;
};

const vChatScroll = {
  bind: (el, binding) => {
    let timeout;
    let scrolled = false;

    const scrollHandler = (e) => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
      timeout = window.setTimeout(function () {
        scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      }, 200);
    };

    el.addEventListener('scroll', scrollHandler);

    const observer = new MutationObserver((e) => {
      // const config = binding.value || {}
      const pause = window.locked === false && scrolled;
      if (!(pause || e[e.length - 1].addedNodes.length !== 1)) {
        scrollToBottom(el);
      }
    });
    observer.observe(el, { childList: true });
  },
  inserted: scrollToBottom,
};

export default vChatScroll;
