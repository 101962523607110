<template>
  <div class="control is-grouped">
    <a
      class="button is-success is-fullwidth is-expanded is-large"
      :class="{ 'is-loading': !canPlaceBet }"
      v-on:click.prevent="placeWager($event)">
      <img class="image is-24x24" :src="diceSrc" />
      &nbsp;Roll
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    diceSrc() {
      return require('../../assets/dice.png');
    },
    ...mapGetters(['canPlaceBet']),
  },
  methods: {
    placeWager(e) {
      this.$emit('click', e);
    },
  },
};
</script>
