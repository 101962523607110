<template>
  <aside>
    <div
      class="mb-0 md:mx-2 flex h-[calc(100vh_-_130px)] min-w-[60px] flex-col gap-2 md:mb-2 md:h-full"
      :class="
        generalS.showLeftMenu ? 'px-2 max-[767px]:pb-3 max-[767px]:pt-2' : 'h-full px-0'
      ">
      <div>
        <div class="mx-2 flex hidden flex-row items-center justify-between space-x-2 p-2">
          <p v-if="generalS.showLeftMenu" class="font-normal">Casino</p>
          <div v-on:click="toggleCasino" :class="generalS.showLeftMenu ? '' : 'mx-2'">
            <svg
              v-if="showCasino"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
          </div>
        </div>
      </div>

      <div v-if="showCasino" class="max-[767px]:pb-[30px]">
        <div
          class="bg-secondary-light rounded-md mt-2 sidebar_menu_wrapper"
          :class="generalS.showLeftMenu ? '' : 'items-center justify-center '">
          <div
            v-for="option in casinoOptions"
            v-on:click="onCasinoOptionClicked(option), closeMenu()"
            :key="option.name"
            :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="mt-1 flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 delay-0 rounded-lg md:transition md:duration-150 md:ease-in-out md:hover:scale-105 hover:bg-[#012220]">
            <img
              v-tooltip="generalS.showLeftMenu ? '' : option.name"
              class="w-[1.6rem] max-[575px]:h-5"
              :src="option.icon" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              {{ option.name }}
            </p>
          </div>
          <div v-on:click="$router.push({ path: $route.path, query: { tab: 'weeklyRaffle' } })"
            :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : ''" class="h-[1.6rem] max-[575px]:h-5"
              src="../../assets/puntTicket.png" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              5,000 SC Weekly Raffle
            </p>
          </div>
        </div>
        <div class="new-sidebar-line"></div>

        <span
          v-if="generalS.showLeftMenu"
          class="mx-4 border-b border-line-color hidden"></span>

        <div
          class="bg-secondary-light rounded-md mt-2 new-sidebar"
          :class="generalS.showLeftMenu ? '' : 'items-center justify-center'">
          <router-link
            v-for="option in gameOptions"
            v-on:click="closeMenu()"
            :to="'/casino/group' + option.route"
            :key="option.name"
            :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 p-2 px-3 py-3 text-sm max-[575px]:py-2 rounded-lg transition delay-0 duration-150 ease-in-out hover:scale-105 hover:bg-[#012220]">
            <img
              class="w-[1.6rem] max-[575px]:h-5"
              v-tooltip="generalS.showLeftMenu ? '' : option.name"
              :src="option.icon" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              {{ option.name }}
            </p>
          </router-link>
        </div>
      </div>

      <span class="mx-4 border-b border-line-color hidden" />

      <div
        class="bg-secondary-light rounded-md mt-2"
        :class="generalS.showLeftMenu ? '' : 'items-center justify-center '">
        <div v-if="showPromotions">
           <div
              v-on:click="showLiveChat(this.accountDeleteStatus)"
              :class="generalS.showLeftMenu ? '' : 'justify-center'"
              class="flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 md:hover:scale-105 hover:bg-[#012220] ">
              <img
                v-tooltip="generalS.showLeftMenu ? '' : 'Live Support'"
                class="h-6 max-[575px]:h-5"
                :class="generalS.showLeftMenu ? '' : ''"
                src="../../assets/sidebar/1.png"
              />
              <p v-if="generalS.showLeftMenu" class="font-normal">Live Support</p>
            </div> 
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { generalStore } from '@/store/pinia/generalStore';
import { icons } from '../Shared/icons';
import { isMobileOnly } from 'mobile-device-detect';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { duelStore } from '@/store/pinia/duelStore';
export default {
  name: 'LeftSideBar',

  setup() {
    const gameOptions = [
      { icon: icons.Live, name: 'Live Dealers', route: '/live' },
      { icon: icons.Originals, name: 'Punt Originals', route: '/originals' },
      { icon: icons.Slots, name: 'Slot Games', route: '/slots' },

      // { icon: icons.GameShows, name: 'Game Shows', route: '/game-shows' },
      { icon: icons.NewReleases, name: 'New Releases', route: '/new-releases' },
      { icon: icons.Trending, name: 'Trending Slots', route: '/trending-slots' },
      { icon: icons.Lucky, name: 'Lucky Slots', route: '/lucky-slots' },
      // { icon: icons.TableGames, name: 'Table Games', route: '/table-games' },
      // { icon: icons.Blackjack, name: 'Blackjack', route: '/blackjack' },
      // { icon: icons.Baccarat, name: 'Baccarat', route: '/baccarat' },
      // { icon: icons.Roulette, name: 'Roulette', route: '/roulette' },
    ];
    const casinoOptions = [
      {
        icon: icons.Favourites,
        name: 'Favorites',
        route: '/casino/group/favorites',
        auth: true,
      },
      {
        icon: icons.Recent,
        name: 'Recent',
        route: '/casino/group/recent',
        auth: true,
      },
      {
        icon: icons.Challenges,
        name: 'Challenges',
        auth: true,
        route: '/challenges/all',
      },
      {
        icon: icons.ReferFriend,
        name: 'Refer A Friend',
        route: '/affiliates/new-referral-bonus',
        auth: true,
      },
      {
        icon: icons.giveaway,
        name: 'Giveaways',
        auth: true,
        route: '/giveaways/active',
      },
      { 
        icon: icons.mybets,
        name: 'My Game Play',
        auth: true,
        route: '/my-game-play',
      },
    ];
    const generalS = generalStore();
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;

    const showLiveChat = function (isRestoredAccount) {
      if (isRestoredAccount == 3) {
        window.swal({
          icon: 'info',
          title: `Support`,
          text: `For support related inquiries please contact support@punt.com`,
        });
      } else {
        window.Intercom('showMessages');
      }
    };
    const showPromotions = ref(true);
    const showCasino = ref(true);

    const toggleCasino = function () {
      showCasino.value = !showCasino.value;
    };
    const togglePromotions = function () {
      showPromotions.value = !showPromotions.value;
    };
    const duelS = duelStore();

    return {
      duelS,
      icons,
      gameOptions,
      casinoOptions,
      generalS,
      isLandscape,
      showLiveChat,
      showPromotions,
      showCasino,
      toggleCasino,
      togglePromotions,
    };
  },
  data() {
    return {
      wars: [],
      accountDeleteStatus: 0,
    };
  },
  created() {
    // if (isMobileOnly) {
    //   this.generalS.showLeftMenu = true;
    //   this.generalS.showChat = false;
    // }
    // this.updateWars();
    this.accountDeleteStatus = this.me.delete_status;
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'iAmStaff']),
    pvpCount() {
      return this.duelS.duels.length + this.wars.length;
    },
  },
  methods: {
    onCasinoOptionClicked(option) {
      if (option.auth && !this.isLoggedIn) {
        this.$router.push(this.$route.path + '?tab=login');
        return;
      }

      if (!option.route) {
        this.$toast.default('Coming soon');
      }

      this.$router.push(option.route);
    },
    updateWars() {
      // new war created event
      window.socket.on('war-games-lobby', (game) => {
        this.wars.push(game);
      });

      window.socket.on('war-game-ended', ({ gameId }) => {
        let game = this.lobby.find((g) => g.id === gameId);
        if (game) {
          this.wars.splice(this.wars.indexOf(game), 1);
        }
      });
    },
    playResponsibly() {
      this.$router.push({ path: '/responsible-gaming' });
    },
    closeMenu() {
      if (window.innerWidth <= 1024 && window.innerWidth > 767) {
        this.generalS.showLeftMenu = false;
      }
    },
  },
};
</script>

<style scoped>
.new-sidebar-line{
  width: 100%;
  height: 1px;
  background: #004C43;
  margin:12px 0;
}
 /*.sidebar_menu_wrapper img,
.wbd-sidebar img{
  width: 20px;
  height: 20px;
}*/
</style>
