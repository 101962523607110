<template>
  <div
    class="max-w-[35px] flex items-center md:max-w-[70px] max-h-[35px] md:max-h-[70px]">
    <img :src="src" />
  </div>
</template>

<script>
import Img from '../../../assets/new/icons/slots/trophy.png';
export default {
  name: 'SlotsBlueCoin',
  setup() {
    return {
      src: Img,
    };
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #367bf1;
}
.cls-2 {
  fill: #296fe6;
}
.cls-3 {
  fill: #2567d9;
}
.cls-4 {
  fill: #96bdff;
}
.cls-5 {
  fill: #6ea3ff;
}
.cls-6 {
  fill: #b0cdff;
}
.cls-7 {
  fill: #4887f1;
}
</style>
