<template>
  <svg
    id="frame"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
    width="500"
    height="300px">
    <defs />
    <title>frame</title>
    <!--    <path
      d="M763.61,14a.38.38,0,0,1,.39.39V413.61a.38.38,0,0,1-.39.39H14.39a.38.38,0,0,1-.39-.39V14.39a.38.38,0,0,1,.39-.39H763.61m0-14H14.39A14.39,14.39,0,0,0,0,14.39V413.61A14.39,14.39,0,0,0,14.39,428H763.61A14.39,14.39,0,0,0,778,413.61V14.39A14.39,14.39,0,0,0,763.61,0Z"
    />-->
  </svg>
</template>

<script>
export default {
  name: 'SlotsFrame',
};
</script>

<style type="text/css" scoped>
svg {
  display: block;
  height: auto;
  user-select: none;
  fill: #234b5b;
  position: relative;
}
@media screen and (max-width: 768px) {
  svg {
    width: 100%;
    height: 290px;
  }
}
@media screen and (min-width: 768px) {
  svg {
    width: 100%;
    height: 400px;
  }
}

@media screen and (min-width: 1380px) {
  svg {
    width: 100%;
    height: 400px;
  }
}
</style>
