<template>
  <punt-modal size="max-w-xl" :show-close="true">
    <template #title>
      <div
        class="text-sm font-medium text-center text-gray-500 border-b border-[#193B4E]">
        <ul class="flex flex-wrap -mb-px space-x-2">
          <li class="text-white">
            <span style="font-size: 24px">Freebies Contributors </span>
            <span class="inline" style="font-size: 1em; opacity: 0.54">
              - {{ balS.formatRaw(total) }}
              <img alt="icon" :src="balS.getIcon()" class="h-4 w-4 inline" />
            </span>
          </li>
        </ul>
        <br />
      </div>
    </template>
    <template #body>
      <div class="flex flex-col text-slate-300">
        <section class="min-h-[480px] flex flex-col">
          <div class="flex flex-col justify-between items-center">
            <div class="mt-4 z-50">
              <button class="delete" v-on:click.stop.prevent="close"></button>
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div
              class="flex flex-col flex-grow text-center items-center justify-center"
              v-if="loading || contributions.length === 0">
              <p v-if="loading" class="has-text-centered">Loading...</p>
              <p v-else="contributions.length === 0" class="has-text-centered">
                No users have added to the Freebie yet
              </p>
            </div>
            <div v-else>
              <table class="table w-full">
                <thead>
                  <tr class="is-odd">
                    <th class="is-hidden-touch is-hidden-desktop-only">
                      <label class="label has-text-centered">
                        <strong>#</strong>
                      </label>
                    </th>
                    <th>
                      <label class="label has-text-centered">
                        <strong>Username</strong>
                      </label>
                    </th>
                    <th class="is-hidden-touch">
                      <label class="label has-text-centered">
                        <strong>Amount</strong>
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in contributions"
                    v-bind:key="idx"
                    :class="`${idx % 2 === 0 ? 'is-even' : 'is-odd'}`"
                    :style="rowStyle(idx)">
                    <td class="has-text-centered">{{ idx + 1 }}</td>
                    <td class="has-text-centered" style="color: #cacaca">
                      {{ item.username }}
                    </td>
                    <td class="has-text-centered inlinene" style="color: #1ee563">
                      {{ balS.exchangeAndFormatAmount(item.amount) }}
                      <img
                        alt="icon"
                        :src="balS.getIcon(item.game_mode)"
                        class="h-4 w-4 inline" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { defaultConvertor, convertGameMode } from '../../lib/rsgp';
const pages = ['jackportcontributors'];
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  PopoverOverlay,
} from '@headlessui/vue';

import { ordersStore } from '@/store/pinia/ordersStore';

import { mapGetters } from 'vuex';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'JackpotContributors',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    PuntModal,
  },
  setup() {
    const orderS = ordersStore();
    const balS = balanceStore();
    return { orderS, balS };
  },
  data() {
    return {
      activeTab: 'jackportcontributors',
      amount: 0,
      contributions: [],
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn']),
    gameMode() {
      return this.isLoggedIn !== undefined && this.isLoggedIn && this.me !== undefined
        ? this.me.game_mode
        : 'osrs';
    },
    getAmount() {
      return this.contributions.length > 0
        ? `- Total ${this.rsgp(this.total)} ${convertGameMode(this.gameMode)}`
        : '';
    },
    total() {
      if (this.balS.displayInFiat) {
        const btcAmount = this.balS.fiatToCrypto('USD', 'BTC', this.amount);
        return this.balS.cryptoToFiat('BTC', this.balS.defaultFiat, btcAmount);
      } else {
        return this.balS.fiatToCrypto('USD', this.balS.default, this.amount);
      }
    },
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    currency(mode) {
      return convertGameMode(mode);
    },
    fetch() {
      this.loading = true;
      this.$http
        .get('jackpot/contribute')
        .then((response) => response.data)
        .then(({ amount, contributions }) => {
          this.amount = amount;
          this.contributions = contributions;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    rowStyle(idx) {
      if (idx === this.pageLength - 1) {
        return 'animation-name: last-row';
      } else {
        return '';
      }
    },
    rowEvent(idx) {
      if (idx === this.pageLength - 1) {
        return '';
      } else {
        return 'click';
      }
    },
  },
  watch: {
    '$route.query.action': {
      handler: function (newState) {
        this.fetch();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
