<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex flex-col p-4 md:p-8 gap-4 w-full max-w-[1200px]">
      <div
        class="flex flex-row justify-start items-center text-slate-300 font-bold gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
            clip-rule="evenodd" />
        </svg>
        <h1 class="font-bold text-lg">Licenses</h1>
      </div>
      <div class="bg-primary mt-4 rounded-lg">
        <div class="flex flex-col p-4">
          <div class="flex flex-row justify-start p-4 items-center gap-4">
            <div
              class="!h-12 !w-12 !md:h-16 !md:w-16"
              id="apg-5fa1ace5-c16b-4584-bc84-7f906a04797a"
              data-apg-seal-id="5fa1ace5-c16b-4584-bc84-7f906a04797a"
              data-apg-image-size="256"
              data-apg-image-type="basic-light-large"></div>
            <h1 class="font-bold text-lg">Curacao</h1>
          </div>
          <p class="text-slate-300 text-sm">
            RuneBet is licensed in Curacao and operates under License No. 8048/JAZ issued
            to Antillephone.
          </p>
        </div>
      </div>
      <div class="bg-primary mt-4 rounded-lg">
        <div class="flex flex-col p-4">
          <div class="flex flex-row justify-start p-4 items-center gap-4">
            <img class="h-12 w-12 md:h-16 md:w-16" src="../../assets/svg/stop.svg" />
            <h1 class="font-bold text-lg">No US Plays (and others)</h1>
          </div>
          <p class="text-slate-300 text-sm">
            Next to our licenses, there are countries where we don't accept customers due
            to international policies or local legislation. Those countries would either
            be mentioned in our terms of services or be geo IP blocked on our site(s)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Licenses',
  mounted() {
    const interval = setInterval(() => {
      if (
        window.apg_5fa1ace5_c16b_4584_bc84_7f906a04797a ||
        apg_5fa1ace5_c16b_4584_bc84_7f906a04797a
      ) {
        apg_5fa1ace5_c16b_4584_bc84_7f906a04797a.init();
        clearInterval(interval);
      }
    }, 1000);
  },
};
</script>
