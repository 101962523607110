<template>
  <router-view></router-view>
  <div class="p-0 md:p-8 h-auto md:min-h-[auto] flex flex-row justify-center">
    <div class="w-full max-w-[1200px]">
      <div
        class="flex flex-col-reverse xl:flex-row justify-center h-auto md:min-h-[auto]">
        <!-- Side Menu -->
        <div
          class="w-full md:w-full px-2 py-2 md:px-3 min-w-[300px] xl:w-[300px] md:py-3 pb-4 rounded-0 md:rounded-tl-lg bg-side-color rounded-bl-xl rounded-tl-xl">
          <plinko-bets
            :currentState="currentState"
            :playerProfit="profit"
            :startGame="playGame"
            :placeAutoBet="placeAutoBet"
            :limits="limits"
            :loading="loading"
            :initialWager="wager"
            :rows="rows"
            :risk="risk"
            :setActiveMode="setActiveMode"
            :totalProfit="totalProfit"
            :autoBetsCount="autoBetsCount"
            :stopAutoBet="stopAutoBet"
            :updateRows="setRows"
            :updateRisk="setRisk" />
          <game-actions
            :pre-extras="gameActions"
            class="bg-secondary-light rounded-br-xl rounded-bl-xl !block md:!hidden mt-4" />
        </div>
        <div
          class="flex flex-col items-center w-full h-full md:min-h-[auto] bg-secondary md:rounded-tr-xl">
          <div class="h-full w-full bg-hilo bg-no-repeat bg-cover bg-center">
            <!-- The Game Grid -->
            <plinko-grid
              :currentState="currentState"
              :stateHandler="changeState"
              :soundHandler="playSound"
              :rows="rows"
              :risk="risk.toLowerCase()"
              :playGame="playGame"
              :profit="profit"
              :game_mode="game ? game.game_mode : ''"
              :multipliers="multipliers"
              :setOnClick="setOnClick"
              :playHitSound="playHitSound"
              :isManualMode="isManualMode" />
          </div>
          <game-actions
            :game-data="{ name: 'Plinko', id: null }"
            :pre-extras="gameActions"
            class="bg-secondary-light rounded-br-xl" />
        </div>
      </div>
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { defaultConvertor } from '../../lib/rsgp';
import { mapActions, mapGetters } from 'vuex';
import PlinkoGrid from './PlinkoGrid.vue';
import PlinkoBets from './PlinkoBets.vue';
import PlinkoFairness from './PlinkoFairness.vue';
import { betSound, hitSound } from './sounds';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import debounce from 'lodash/debounce';
import GameActions from '../Shared/GameActions.vue';
import axios from '@/lib/myAxios';

export default {
  components: {
    PlinkoGrid,
    PlinkoBets,
    PlinkoFairness,
    GameHistoryTable,
    GameActions,
  },
  props: {},
  created() {
    this.loadGame();
    this.playHitSound = () => this.playSound(hitSound);
    this.debouncedBalanceUpdate = debounce(this.updateBalance, 50, {
      leading: true,
      trailing: false,
    });
  },
  data: () => ({
    showFairness: false,
    currentState: 'notStarted',
    activeMode: 'Manual',
    rows: 12,
    risk: 'MEDIUM',
    game: null,
    seed: null,
    limits: [],
    result: [],
    gameActions: [
      ['/casino/plinko/rules', 'Rules'],
      // ['/casino/plinko/fairness', 'Fairness'],
    ],
    profit: 0,
    loading: false,
    multipliers: null,
    wager: 0,
    totalProfit: 0,
    cancelAutoBet: false,
    autoBetsCount: 0,
    onClick: null,
    autoBetInterval: null,
    playHitSound: () => this.playSound(hitSound),
    debouncedBalanceUpdate: null,
  }),
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Sound', ['isSoundMuted']),
    isManualMode() {
      return this.activeMode === 'Manual';
    },
  },
  methods: {
    ...mapActions('Sound', ['toggleSound']),
    setActiveMode(mode) {
      this.activeMode = mode;
      // reset auto bet stuff
      this.totalProfit = 0;
      this.autoBetsCount = 0;
    },
    setOnClick(onClick) {
      this.onClick = onClick;
    },
    setRows(rows) {
      this.rows = parseInt(rows);
    },
    setRisk(risk) {
      this.risk = risk;
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.cloneNode().play();
      }
    },
    loadGame() {
      try {
        axios
          .get(`plinko`)
          .then((res) => res.data)
          .then((body) => {
            this.initGame(body);
          });
      } catch (error) {
        console.log('issue while loading');
      }
    },

    initGame({ seed, limits, multipliers }) {
      this.multipliers = multipliers;
      // limits
      this.limits = limits;
      // seed
      this.seed = seed != null ? seed : {};
    },

    // :D
    verify2bo(period) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, period);
      });
    },

    playGame(betAmount) {
      this.wager = betAmount;
      if (this.onClick !== null) {
        try {
          this.placeBet()
            .then((body) => {
              this.onClick(body, () => this.debouncedBalanceUpdate());
            })
            .catch(({ data, status }) => this.onBetException(data, status));
        } catch (error) {
          console.log('issue while play bet');
        }
      }
    },
    placeAutoBet(betAmount, noOfBets) {
      // do validate
      let validationErrorMessage = null;
      if (!Number.isInteger(parseInt(noOfBets)) || noOfBets <= 0) {
        validationErrorMessage = 'No of plays must be numeric and greater than 0.';
      }

      if (betAmount < this.limits.min_wager) {
        validationErrorMessage = `The minimum play is ${this.rsgp(
          this.limits.min_wager,
        )}.`;
      }

      if (validationErrorMessage !== null) {
        window.swal('Uh-oh', validationErrorMessage, 'error');
        return;
      }

      this.autoBetsCount = 0;
      this.totalBets = 0;
      this.totalProfit = 0;
      this.wager = betAmount;
      this.noOfAutoBets = noOfBets;
      this.currentState = 'inProgress';
      this.autoBetInterval = setInterval(() => {
        this.placeBet()
          .then((body) => {
            this.onClick(body, () => {
              this.debouncedBalanceUpdate();
              this.totalProfit += body.profit;
              this.autoBetsCount += 1;
            });
          })
          .catch(({ data, status }) => this.onBetException(data, status));
        this.totalBets += 1;
        if (this.totalBets === parseInt(noOfBets)) this.stopAutoBet();
      }, 400);
    },

    onBetException(data, status) {
      let message = '';
      if (status > 412) {
        message = '';
      } else if (status >= 400 && status <= 412) {
        message += ' : ' + (typeof data.error != 'undefined' ? data.error : '');
        if (!message.toLowerCase().includes('undefined')) {
          this.stopAutoBet();
        }
      } else {
        message += ' : ' + (typeof data.error != 'undefined' ? data.error : '');
      }

      if (typeof data.error != 'undefined' && data.error === 'Unauthenticated.') {
        this.$router.replace('?tab=login');
      } else {
        window.swal(
          'Uh-oh',
          'Something went wrong when placing your play: ' +
            (typeof data.error != 'undefined' ? data.error : ''),
          'error',
        );
      }
    },

    placeBet() {
      this.playSound(betSound);
      return axios
        .post(`plinko`, {
          rows: this.rows,
          risk: this.risk.toLowerCase(),
          wager: this.wager,
        })
        .then((res) => {
          return res.data;
        });
    },

    updateBalance() {
      // return axios
      //   .post(`user/balanceUpdate`)
    },
    stopAutoBet() {
      if (this.autoBetInterval) {
        clearInterval(this.autoBetInterval);
        this.currentState = 'notStarted';
      }
    },
    changeState(newState) {
      this.currentState = newState;
    },
  },
};
// test
</script>
