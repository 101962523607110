<template>
  <div class="bg-side-color p-2">
    <div class="flex flex-col w-full">
      <div class="flex-1 flex gap-2 justify-center">
        <div v-if="me.verification_id == null">
          <a
            :disabled="canNotVerify"
            target="_blank"
            :href="verifylink"
            class="text-center btn-bet ch-button hover:opacity-75 disabled:opacity-50 min-w-[200px] text-black/90">
            Get verified to chat
          </a>
        </div>
        <textarea
          v-else
          :disabled="!me.can_chat"
          :readonly="!isLoggedIn"
          v-model="form.message"
          @keyup.enter="sendMessage"
          :placeholder="
            !isLoggedIn
              ? 'Login to chat...'
              : me.can_chat
                ? 'Type a message...'
                : 'Your account must be 48 hours old'
          "
          class="placeholder:text-slate-300/60 placeholder:font-semibold no-resize flex-1 hover:border-0 active:border-0 focus:border-0 w-full rounded-lg bg-primary border-0 text-sm" />
      </div>

      <div
        class="w-full text-text-dim-color flex flex-row py-1 items-center space-x-2 px-2 text-xs pt-2">
        <chat-connection-status :show-roster="toggle" />
        <div class="flex-grow flex flex-row justify-end items-center gap-2">
          <button
            v-tooltip="'Chat Rules'"
            v-on:click="showChatRules"
            class="bg-secosndary-light p-2 hover:opacity-70 rounded-xl font-semibold flex flex-inline items-center transition ease-in-out delay-0 hover:scale-105 duration-150">
            <img alt="icon" class="h-6 md:h-6" src="../../assets/svg/book.svg" />
          </button>
          <p class="text-right is-danger">{{ messageLength }}/200</p>
          <button
            :disabled="messageLength > 200 || messageLength <= 0"
            v-on:click="sendMessage"
            class="py-3 px-4 items-center justify-center flex flex-col bg-success-green text-black/80 rounded-xl object-fill hover:opacity-80 disabled:opacity-50">
            <img
              alt="icon"
              class="h-5 md:h-5 mr-2 inline-block"
              src="../../assets/svg/arrow.svg" />
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ChatConnectionStatus from '@/components/Chat/ChatConnectionStatus.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { authStore } from '@/store/pinia/authStore';
export default {
  name: 'ChatFooter',
  components: {
    ChatConnectionStatus,
    VerifyAccount,
  },
  setup() {
    return { balS: balanceStore(), authS: authStore() };
  },
  data() {
    return {
      form: {
        message: '',
      },
      loading: false,
      session: null,
      verifylink: null,
    };
  },
  async created() {
    await this.fetchVerification();
    this.getVerificationLink();
  },
  computed: {
    ...mapGetters([
      'me',
      'isLoggedIn',
      'iAmStaff',
      'iAmAdmin',
      'iAmMod',
      'activeRoom',
      'chatTitles',
    ]),
    ...mapState({
      profile: (state) =>
        state.user.id !== undefined ? `/gravatars/${state.user.id}.png` : false,
    }),
    canNotVerify() {
      return (
        this.loading ||
        (this.session &&
          ['approved', 'review', 'submitted'].includes(this.session.status))
      );
    },
    isAdmin() {
      return this.me && this.me.rank === 'admin';
    },
    isMod() {
      return this.sender.rank === 'mod';
    },
    messageLength() {
      return (this.form.message || '').length;
    },
    isHelper() {
      return !this.iAmStaff && this.me.chatTitle && this.me.chatTitle === 'helper';
    },
  },
  methods: {
    showChatRules() {
      this.$router.push(this.$route.path + '?tab=chatRules');
    },
    toggle() {
      this.$emit('toggle-roster');
    },
    clearFields() {
      this.form.message = '';
    },
    ...mapActions(['sendChatMessage', 'setBzFlag']),
    handleChatAction(action, username) {
      this.clearFields();
      if (action === 'tip') {
        let parts = username.split(' ');
        if (parts.length !== 2 || !parts[0].match(/^[0-9a-zA-Z_-]+$/)) {
          return;
        }

        let amount = parts[1];
        let curr = this.balS.displayInFiat ? this.balS.defaultFiat : this.balS.default;

        this.$swal({
          title: 'Confirm tipping',
          html: `You are sending <strong style="color: grey">${amount} ${curr}</strong> to <strong style="color: grey">${parts[0]}</strong>, are you sure that you have spelled the Recipient's name correctly! <br>This transfer is <strong style="color: grey">NOT</strong> reversable.`,
          showCancelButton: true,
          animation: 'slide-from-top',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
          .then((result) => {
            if (result.isConfirmed) {
              let form = { ...this.form };
              const rate = this.balS.displayInFiat
                ? this.balS.exchangeRate(this.balS.default)
                : 1;
              let amount = parseFloat(parts[1] / rate);
              this.postChatAction(action, `${parts[0]} ${amount}`);
            }
          })
          .catch((error) => {});
      }

      if (!(this.iAmStaff || this.isHelper)) return;

      if (action === 'ban') {
        this.$swal({
          title: `Do You want to Ban "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (action === 'unban') {
        this.$swal({
          title: `Do You want to UnBan "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (action === 'ip') {
        this.$swal({
          title: `Do You want to Ip "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (action === 'mute') {
        this.$swal({
          title: `Do You want to Mute "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (action === 'slow') {
        this.$swal({
          title: `Do You want to Enable Slow  Mode for "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (['unmute', 'ipunban'].includes(action)) {
        this.postChatAction(action, username, { reason: '-' });
      }
      if (action === 'pmute') {
        this.$swal({
          title: `Do You want to Mute "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
      if (action === 'ipban') {
        this.$swal({
          title: `Do You want to Ban ip for "${username}"`,
          text: 'If Yes please Enter a reason below',
          input: 'textarea',
          showCancelButton: true,
          animation: 'slide-from-top',
          inputPlaceholder: 'Reason...',
          inputValue: '',
        })
          .then((result) => {
            if (result.isConfirmed) {
              var reason = result.value;
              this.postChatAction(action, username, { reason });
            }
          })
          .catch(() => {});
      }
    },
    postChatAction(action, username, meta = {}) {
      this.$http
        .post('chat_actions', { action, username, meta })
        .then((response) => {
          this.$swal('', `${response.data.message}`, 'success');
        })
        .catch((response) => {
          this.$swal({
            text: response.data.message,
            type: 'error',
            showCancelButton: false,
          });
        });
    },
    sendMessage() {
      let message = this.form.message.trim();
      if (!message) {
        return;
      }
      if (message.length <= 200) {
        if (message.charAt(0) === '/') {
          let matches = message.match(/^\/(?<action>\w+)\s*(?<username>.+)\s*$/);

          if (matches) {
            this.handleChatAction(matches[1], matches[2]);
          }

          return;
        }

        this.sendChatMessage(message);
        this.clearFields();
      }
    },
    banUser() {},
    muteUser(username) {
      let action = 'mute';
      window
        .swal({
          text: `How long should ${username} be muted for? (minutes)`,
          input: 'number',
          inputValue: 10,
        })
        .then((duration) => this.postChatAction(action, username, duration))
        .catch(() => {});
    },

    async fetchVerification() {
      try {
        this.loading = true;
        if (!this.session) {
          const { data } = await this.authS.fetchVerify();
          if (data.session) {
            this.session = data.session;
          }
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getVerificationLink() {
      try {
        this.loading = true;
        const { data } = await this.authS.verify();
        this.session = data;
        if (this.session.status === 'approved') {
          this.$router.replace({
            path: this.$route.path,
            query: { tab: 'bank', action: 'redeem' },
          });
          window.location.reload();
        } else {
          this.verifylink = this.session.url;
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.no-resize {
  resize: none;
}
</style>
