import { sampleSize } from 'lodash';
import { defaultConvertor } from './lib/rsgp';

const ALPHA_NUM = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function* clientSeedGenerator(alpha, length) {
  while (true) {
    yield sampleSize(alpha, length).join('');
  }
}

export function clientSeedGeneratorFactory(
  opts = {
    alpha: ALPHA_NUM,
    length: 12,
  },
) {
  const { length, alpha } = opts;

  return clientSeedGenerator(alpha, length);
}

export function formatRsgp(amount) {
  return defaultConvertor.format(amount);
}
export function formatBlockChainUrl(coin, address) {
  // if (typeof url !== 'undefined' || url===null) {
  //    return "https://blockchair.com/bitcoin/transaction/";
  // }
  // return "https://blockchair.com/bitcoin/transaction/"+url;

  let currency = coin.toUpperCase();
  switch (currency) {
    case 'DOGE':
      var path = 'https://blockchair.com/dogecoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'ETH':
      var path = 'https://blockchair.com/ethereum/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BTC':
      var path = 'https://blockchair.com/bitcoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'XRP':
      var path = 'https://blockchair.com/ripple/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'TRX':
      var path = 'https://tronscan.org/#/blockchain/transactions/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'EOS':
      var path = 'https://bscscan.com/token/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BCH':
      var path = 'https://blockchair.com/bitcoin-cash/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
  }
}

export default {
  clientSeedGeneratorFactory,
}

export function checkIsSet(variable) {
  if (typeof variable !== 'undefined' || variable === null) {
    return false;
  }
  return true;
}

export function getErrorCodeMessage(e, defaultMessage = 'Unable to process your request at this time') {
  if (e.response && e.response.data && (e.response.data.errors || e.response.data.error || e.response.data.message)) {
    return {
      code: e.response.data.code || e.response.data.status || 'UNKNOWN_ERROR',
      message: Array.isArray(e.response.data.errors) ? e.response.data.errors.join(', ') : e.response.data.error || e.response.data.errors || e.response.data.message,
    };
  } 
  
  if (e.data && (e.data.errors || e.data.error || e.data.message)) {
    return {
      code: e.data.code || e.data.status || 'UNKNOWN_ERROR',
      message: Array.isArray(e.data.errors) ? e.data.errors.join(', ') : e.data.error || e.data.errors || e.data.message,
    };
  }
  
  return {
    code: 'UNKNOWN_ERROR',
    message: defaultMessage,
  };
}