<template>
  <div
    class="max-w-[35px] flex items-center md:max-w-[60px] max-h-[35px] md:max-h-[70px]">
    <img src="../../../assets/new/icons/slots/bomb.png" />
  </div>
</template>

<script>
export default {
  name: 'SlotsRedSquare',
};
</script>
