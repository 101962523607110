//import Crash from "@/assets/svg/tiles/png/crash.png";
import Dice from '@/assets/svg/tiles/png/dice.png';
import Roulette from '@/assets/svg/tiles/png/roulette.png';
import Slots from '@/assets/svg/tiles/png/slots.png';
import Mines from '@/assets/svg/tiles/png/mines.png';
import Duels from '@/assets/svg/tiles/png/duels.png';
import War from '@/assets/svg/tiles/png/war.png';
import Plinko from '@/assets/svg/tiles/png/plinko.png';
import Blackjack from '@/assets/svg/tiles/png/blackjack.png';
import Keno from '@/assets/svg/tiles/png/keno.png';
import Baccarat from '@/assets/svg/tiles/png/baccarat.png';
import Limbo from '@/assets/svg/tiles/png/limbo.png';

const puntOriginals = [
  //{img: Crash, link: '/casino/crash',name: 'Crash'},
  { img: Dice, link: '/casino/dice', name: 'Dice' },
  { img: Roulette, link: '/casino/roulette', name: 'Roulette' },
  { img: Slots, link: '/casino/slots', name: 'Slots' },
  { img: Mines, link: '/casino/mines', name: 'Mines' },
  { img: Duels, link: '/casino/duels', name: 'Duels' },
  { img: War, link: '/casino/war', name: 'War' },
  { img: Plinko, link: '/casino/plinko', name: 'Plinko' },
  { img: Blackjack, link: '/casino/blackjack', name: 'Blackjack' },
  { img: Keno, link: '/casino/keno', name: 'Keno' },
  { img: Baccarat, link: '/casino/baccarat', name: 'Baccarat' },
  { img: Limbo, link: '/casino/limbo', name: 'Limbo' },
];

const originals = puntOriginals.map(function (original) {
  return {
    img: original.img,
    link: original.link,
    name: original.name,
    title: original.name,
    identifier: 'runebet:' + original.name,
    identifier2: original.name,
    provider: 'runebet',
    producer: 'runebet',
    category: 'originals',
    theme: 'other',
    has_freespins: false,
    feature_group: 'basic',
    devices: ['desktop', 'mobile'],
    payout: 94.74,
    description: '',
    hd: true,
    restrictions: {
      default: {
        blacklist: [],
      },
    },
  };
});
export default originals;
