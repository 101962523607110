<template>
  <div
    class="max-w-[32px] flex items-center md:max-w-[60px] max-h-[35px] md:max-h-[60px]">
    <img src="../../../assets/new/icons/slots/coin.png" />
  </div>
</template>

<script>
export default {
  name: 'SlotsYellowCoin',
};
</script>
