<template>
    <div class="section section-work-info">
        <div class="top">
            <h3 class="section-title">How does it work</h3>
            <p>You start your gaming way from the 1st Rank. The more you play – the higher your Rank is. It goes up according to the wagered amount while playing on Punt. Use a simplified login system to enjoy all gaming experience on Punt!</p>
        </div>
        <div class="middle">
            <div class="item">
                <div class="image">
                    <img src="../../assets/work-1.png" alt="work Image">
                </div>
                <h4 class="title">Login on Punt</h4>
                <p>Simplified login system to enjoy all gaming experience on the platform</p>
            </div>
            <div class="item">
                <div class="image">
                    <img src="../../assets/work-2.png" alt="work Image">
                </div>
                <h4 class="title">Login on Punt</h4>
                <p>Simplified login system to enjoy all gaming experience on the platform</p>
            </div>
            <div class="item">
                <div class="image">
                    <img src="../../assets/work-3.png" alt="work Image">
                </div>
                <h4 class="title">Login on Punt</h4>
                <p>Simplified login system to enjoy all gaming experience on the platform</p>
            </div>
        </div>
        <div class="bottom">
            <a href="/">Play Now</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Work',
}
</script>

<style lang="scss" scoped>
    .section-work-info{
        padding: 100px 0;
        .top {
            .section-title{
                font-size: 36px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 26px;
                text-align: center;
            }
            p{
                max-width: 967px;
                margin:auto;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #BEBEBE;
            }
        }
        .top,
        .middle{
            margin-bottom: 68px;
        }
        .middle{
            display: grid;
            grid-template-columns:repeat(3,1fr);
            column-gap: 100px;
            text-align: center;
            max-width:1144px;
            margin: auto;
            margin-bottom: 70px;
            .item{
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 200px;
                    height: 150px;
                    background-image: url('../../assets/work-bg.png');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                }
                .image{
                    display: flex;
                    justify-content: center;
                    margin-bottom: 18px;
                }
                .title{
                    font-size: 24px;
                    margin-bottom: 12px;
                    font-weight: 600;
                }
                p{
                    max-width: 325px;
                    margin: auto;
                    color: #BEBEBE;
                }
            }
        }
        .bottom{
            text-align: center;
            a{
                font-size: 17px;
                color:#001011;
                padding: 12.5px 28px;
                background-color: #00FFBA;
                border-radius: 10px;
                font-weight: 700;
            }
        }
    } 
    @media only screen and (max-width: 768px){
        .section-work-info {
            .top .section-title{
                font-size: 20px;
            }
            .middle{
                grid-template-columns:repeat(1, 1fr);
                row-gap: 30px;
                .item .title{
                    font-size: 18px
                }
            }
        }
    }

</style>