import bet from '../../assets/media/bet.mp3';
import win from '../../assets/media/win.mp3';
import lose from '../../assets/media/loose.mp3';
import limbo from '../../assets/media/limbo.mp3';
import { Howl, Howler } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const winSound = new Howl({
  src: [win],
});

const looseSound = new Howl({
  src: [lose],
});

const limboSound = new Howl({
  src: [limbo],
});

export { betSound, winSound, looseSound, limboSound };
