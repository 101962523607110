import bet from '../../assets/media/start_plinko.mp3';
import hit from '../../assets/media/hit_plinko_bucket.mp3';
import lose from '../../assets/media/loose.mp3';
import flip from '../../assets/media/flip.mp3';
import roll from '../../assets/media/dice_roll.mp3';
import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const hitSound = new Howl({
  src: [hit],
});

const loseSound = new Howl({
  src: [lose],
});

const flipSound = new Howl({
  src: [flip],
});

const rollSound = new Howl({
  src: [roll],
});

export { betSound, hitSound, loseSound, flipSound, rollSound };
