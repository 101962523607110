<template>
  <div  class="page-wrapper">
    <Profile :percentage="percentage" :lowerimg="getLowerBoundImage()" :upperimg="getUpperBoundImage()" :lowername="lowerBound" :uppername="upperBound"  v-if="isLoggedIn"/>
    <VipClub  v-if="!isLoggedIn"/>
    <ClubBanefit />
    <work />
      <Ranking />
      <faq />
    </div>
    
</template>

<script>
import Profile from './Profile.vue';
import VipClub from './VipClub.vue';
import Ranking from './Ranking.vue';
import ClubBanefit from './ClubBanefit.vue'
import work from './work.vue'
import faq from './faq.vue'
import { mapActions, mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
// import faq from './faq.vue'
export default {
  name: 'VipProfile',
  components: {
    Profile,
    VipClub,
    Ranking,
    ClubBanefit,
    work,
    faq
  },
  data() {
    return {
      lowerBound: 'Copper',
      upperBound: 'Bronze',
      percentage: 0,
      rakeback: 0,
      isRakebackDisable: false,
      selectedTab: 'wager',
      basePath: '/images/ranks/',
      lowerBoundImageCode: 'copper',
      upperBoundImageCode: 'bronze',
      tabs: [
        {
          name: 'Progress',
          code: 'wager',
          href: '#',
          current: true,
          isVisible: true,
        },
        {
          name: 'Rakeback',
          code: 'rakeback',
          href: '#',
          current: false,
          isVisible: false,
        },
        {
          name: 'Benefits',
          code: 'benefits',
          href: '#',
          current: false,
          isVisible: true,
        },
      ],
    };
  },
  mounted() {
    if (!this.email) {
      this.fetchUser();
    }
    this.fetchUserRanks();
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    getLowerBoundImage() {
      return `${this.basePath}${this.lowerBoundImageCode}.png`;
    },
    getUpperBoundImage() {
      return `${this.basePath}${this.upperBoundImageCode}.png`;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    handleTabSelect(tab) {
      this.selectedTab = tab.code;
      if (this.selectedTab === 'rakeback') {
        this.getUserRakeback(this.me.id).then((res) => {
          this.rakeback = res.data;
        });
      }
      this.tabs = this.tabs.map((i) => {
        if (i.code === tab.code) {
          i.current = true;
        } else {
          i.current = false;
        }
        return i;
      });
    },
    fetchUserRanks() {
      this.getUserRanks(this.me.id).then((res) => {
        this.lowerBoundImageCode = res.data.lower_bound_code;
        this.upperBoundImageCode = res.data.upper_bound_code;
        this.lowerBound = res.data.lower_bound;
        this.upperBound = res.data.upper_bound;
        this.percentage = res.data.percentage;
        if (res.data.lower_bound !== 'none') {
          this.tabs = this.tabs.map((i) => {
            if (i.code === 'rakeback') {
              i.isVisible = true;
            }
            return i;
          });
        }
      });
    },
    handleClaim() {
      if (this.isRakebackDisable === false) {
        this.isRakebackDisable = true;
        this.claimRakeback(this.me.id).then((res) => {
          this.toggleProgressModal();
          this.$toast.success(`${this.rakeback} SC Rakeback successfully claimed!`);
        });
      }
    },

    toggleProgressModal() {
      this.isWagerProgressVisible = !this.isWagerProgressVisible;
      this.selectedTab = 'wager';
      this.isRakebackDisable = false;
      this.tabs = this.tabs.map((i) => {
        if (i.code === 'wager') {
          i.current = true;
        } else {
          i.current = false;
        }
        return i;
      });
      this.$emit('onClose');
    },
    ...mapActions(['getUserRanks', 'getUserRakeback', 'claimRakeback']),
  },
}
</script>

<style lang="scss" scoped>
.page-wrapper{
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
  padding: 0 40px;
  width: 100%;
}
@media screen and (max-width:992px){
  .page-wrapper{
    padding: 0 20px;
  }
}
</style>
