<template>
  <Challenges />
  <punt-modal
    size="md:max-w-xl max-w-[92%] p-4 "
    :showClose="true"
    v-if="showmodal"
    :onClose="closeModal">
    <template #title>
      <div class="flex flex-col">
        <span class="font-bold text-lg">Create New Challenge</span>
      </div>
    </template>
    <template #body>
      <div
        class="modal is-active flex min-h-[300px] flex-col text-left space-y-2 text-slate-300">
        <form
          id="add-new-challenges"
          class="new-challenges p-6 left"
          @submit.prevent="submitnewchallenge">
          <div class="mb-4 relative">
            <label for="game_title" class="block text-white font-medium">Games</label>
            <input
              v-model="searchText"
              title="Start typing game name"
              type="text"
              id="game_title"
              name="game_title"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300"
              v-on:change="debouncedSearch"
              autocomplete="off" />
            <ul
              v-if="showsuggestion"
              class="game-suggestion-list absolute"
              style="width: 100%">
              <li v-for="(game, i) in games" :key="i">
                <a href="#" @click="gameSelected(i, game)">{{ game }}</a>
              </li>
            </ul>
          </div>
          <div class="mb-4">
            <label for="multiplier" class="block text-white font-medium"
              >Multiplier</label
            >
            <input
              v-model="formData.multiplier"
              title="Please enter a valid last name"
              type="text"
              id="multiplier"
              name="multiplier"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
          </div>
          <div class="mb-4">
            <label for="min_play" class="block text-white font-medium">Min Play</label>
            <input
              v-model="formData.min_play"
              title="Minimum play amount"
              type="text"
              id="min_play"
              name="min_play"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
          </div>
          <div class="mb-4">
            <label for="coin" class="block text-white font-medium">Coin</label>
            <select
              v-model="formData.coin"
              title="Coin type of play"
              type="text"
              id="coin"
              name="coin"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">
              <option value="SC" selected>SC</option>
              <option value="GC">GC</option>
            </select>
          </div>
          <div class="mb-4">
            <label for="prize" class="block text-white font-medium">Prize</label>
            <input
              v-model="formData.prize"
              title="Prize amunt"
              type="text"
              id="prize"
              name="prize"
              required
              class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
          </div>
          <div class="flex justify-center mt-6">
            <button
              type="submit"
              class="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
              :disabled="isLoading">
              <span v-if="isLoading" class="animate-spin">Loading...</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';

import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { challengeStore } from '@/store/pinia/challengeStore';
import Challenges from '@/components/Challenges/Challenges.vue';

export default {
  name: 'ChallengesModal',
  components: { PuntModal, Challenges },
  props: {},
  data() {
    return {
      showmodal: true,
      searchText: null,
      formData: {
        game: null,
        multiplier: '',
        min_play: '',
        coin: '',
        prize: '',
      },
      games: [],
      errors: {},
      showsuggestion: false,
      isLoading: false,
      debouncedSearch: null,
      not_debounce: false,
    };
  },
  setup() {
    return { challenges: challengeStore() };
  },
  async created() {
    this.debouncedSearch = debounce(this.search, 300, {
      leading: false,
      trailing: true,
    });
    if (!this.me.can_create_challenges) this.closeModal();
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    gameSelected(key, game) {
      this.not_debounce = true;
      this.formData.game = key;
      this.searchText = game;
      this.showsuggestion = false;
    },
    setGamelabel(game) {
      return game.name;
    },
    async search() {
      // your search method
      this.isLoading = true;
      if (this.searchText && this.searchText.length > 3 && !this.not_debounce) {
        this.showsuggestion = true;
        try {
          const { data } = await this.challenges.searchGame({
            search: this.searchText,
          });

          this.games = data;
        } catch(error) {
          console.log("search game failed");
        }
      }
      if (this.not_debounce) {
        this.not_debounce = false;
      }
      this.isLoading = false;
      // now `items` will be showed in the suggestion list
    },
    async submitnewchallenge() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { data } = await this.challenges.createChallenge({
            game: this.formData.game,
            multiplier: this.formData.multiplier,
            min_play: this.formData.min_play,
            coin: this.formData.coin,
            prize: this.formData.prize,
          });

          if (data.success == true) {
            this.closeModal();
            this.$toast.success('Challenge created successfully!');
          } else {
            this.$toast.warning('Some error please try again');
          }
        } catch (e) {
          if (typeof e.data.errors != 'undefined') {
            Object.keys(e.data.errors).forEach((key) => {
              this.$toast.error(e.data.errors[key][0]);
            });
          }
        }
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$router.push({ path: '/challenges/all' }).then(() => {
        // this.$router.go();
      });
    },
  },
  watch: {
    searchText() {
      if (!this.not_debounce) this.debouncedSearch();
    },
  },
};
</script>
