import bet from '../../assets/media/mines_place_bet.mp3';
import win from '../../assets/media/mines_bet_success.mp3';
import cashout from '../../assets/media/mines_cashout.mp3';
import miss from '../../assets/media/mines_bet_failed.mp3';
import { Howl } from 'howler';

const betSound = new Howl({
  src: [bet],
});

const winSound = new Howl({
  src: [win],
});

const cashoutSound = new Howl({
  src: [cashout],
});

const missSound = new Howl({
  src: [miss],
});

export { betSound, winSound, cashoutSound, missSound };
