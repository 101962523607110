import { find, get, isNumber, keyBy } from 'lodash';

const denoms = [
  { label: 'T', ratio: 1e12 },
  { label: 'B', ratio: 1e9 },
  { label: 'M', ratio: 1e6 },
  { label: 'K', ratio: 1e3 },
  { label: '', ratio: 1 },
];

const getGameMode = (mode) => {
  if (!mode) return null;
  return ''; // mode.toUpperCase() === 'OSRS' ? '07' : mode.toUpperCase()
};

const withCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const enabledDenoms = denoms.filter((x) => x.ratio <= 1e12);

export class MoneyPouch {
  constructor(amount) {
    this.amount = amount;
  }

  format(formatter = null) {
    let fn = formatter || withCommas;

    return fn(this.amount);
  }
}

export class Conversions {
  constructor(denominations) {
    this.denominations = denominations.map((x) => ({
      ...x,
      label: x.label.toUpperCase(),
    }));
    this.labelIndex = keyBy(denominations, 'label');
  }

  findLabel(label) {
    return get(this.labelIndex, label.toUpperCase());
  }

  matchDenomination(amount, max) {
    const x = Math.abs(amount);
    return find(this.denominations, (denom) => {
      return denom.ratio >= max && denom.ratio <= x;
    });
  }

  format(amount, max = 1, precision = 3) {
    return amount;
    if (isNumber(amount) && amount > 0 && amount < 1) {
      return amount;
    }

    if (amount === null || amount === undefined || isNaN(amount)) {
      return '0';
    }

    const denom = this.matchDenomination(amount, max);
    const final = withCommas((amount / denom.ratio).toFixed(precision));
    return final.replace(/\.?0+$/, '') + denom.label;
  }
  formatChips(amount, max = 1, precision = 3) {
    if (isNumber(amount) && amount > 0 && amount < 1) {
      return amount;
    }

    if (amount === null || amount === undefined || isNaN(amount)) {
      return '0';
    }

    const denom = this.matchDenomination(amount, max);
    const final = withCommas((amount / denom.ratio).toFixed(precision));
    return final.replace(/\.?0+$/, '') + denom.label;
  }

  normalize(s) {
    let comma = s.indexOf(',');
    let decimal = s.indexOf('.');

    if (comma > decimal) {
      return s.replace(/,/g, '.').trim().toUpperCase();
    }

    return s.trim().toUpperCase();
  }

  parse(s) {
    return isNaN(s) ? 0 : parseFloat(s);
    s = this.normalize(s || '');
    if (s === null || s === undefined || s.length === 0) {
      return new MoneyPouch(0);
    }

    let amount = Math.round(parseFloat(s));
    const denomination = this.findLabel(s[s.length - 1]);
    if (denomination) {
      const payload = s.substring(0, s.length - 1);
      if (payload.length === 0) {
        return new MoneyPouch(0);
      }

      amount = Math.round(payload * denomination.ratio);
    }

    return new MoneyPouch(amount);
  }
}

export const defaultConvertor = new Conversions(enabledDenoms);

export const convertGameMode = getGameMode;
