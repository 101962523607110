<template>
  <punt-modal 
    v-if="!bankDisabled" 
    show-close="true" 
    :size="`${modalWidth} punt-bank-modal min-h-[650px] my-3`"
  >
    <template #title>
      <div
        class="main-title flex flex-col-2 w-full items-center p-6 bg-cover bg-[top_left] bg-no-repeat rounded-tl-[10px]"
        style="background-image: url('/images/bank/bundlemodelbg.png')">
        <img src="../../assets/bank/coinwallet.png" class="w-8 mr-2" alt="Wallet Icon" />
        <span class="font-bold text-lg"> Gold coins wallet</span>
      </div>
    </template>
    <template #body>
      <div class=" pt-0 p-2 sm:p0  bank-model-body">
        <div class="text-sm font-medium text-center text-gray-500 w-full">
          <ul :class="`${navWidth} m-auto flex -mb-px space-x-2 w-full p-2 bg-jungle-green rounded-full bank-model-nav`">
            <li v-for="page in pages" class="min-[481px]:flex-grow bank-model-navlink">
              <button
                :class="{
                  '!text-prim-color bg-onprimary ': isActive(page.page),
                }"
                class="tab-title inline-block px-1 py-2 sm:p-4 font-semibold text-white flex justify-center rounded-full w-full items-center gap-x-[12px] max-[480px]:text-[12px] max-[480px]:gap-[6px] max-[480px]:p-[5px]"
                v-on:click="
                  $router.replace({
                    path: $route.path,
                    query: { page: page.page, tab: 'bank' },
                  })
                ">
                <img
                  :src="isActive(page.page) ? page.logoDark : page.logo"
                  alt="Image"
                  class="max-[480px]:w-[16px]" />
                {{ page.label }}
              </button>
            </li>
          </ul>
        </div>
        <div class="flex flex-col p-4 max-[480px]:p-0">
          <section class="min-h-[480px] flex flex-col text-slate-300">
            <div class="flex flex-col mt-2 flex-grow">
              <template v-if="isActive('deposit') || isActive('buy')">
                <bundles v-if="action === 'bundles'" :is-on-break="isOnBreak" />
                <!--<deposit
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'crypto' && me.can_purchase" />-->
              <payment-form
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'paymentForm'" />
              <PaySafeIdentityVerificationForm
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'paysafe'" />
            </template>
            <withdraw v-if="isActive('redeem') || action === 'redeem'" />
            <daily-bonus :is-on-break="isOnBreak" v-if="isActive('bonus')" />
            <top-up :is-on-break="isOnBreak" v-if="isActive('topup')" />
            <reload v-if="isActive('reload')" />
          </div>
        </section>
      </div>
      </div>
    </template>
  </punt-modal>
  <punt-modal v-else show-close="true" size="max-w-[400px] punt-bank-modal min-h-[150px]">
    <template #title>
      <div
        class="main-title flex flex-col-2 w-full items-center p-6 bg-cover bg-[top_left] bg-no-repeat rounded-tl-[10px]"
        style="background-image: url('/images/bank/bundlemodelbg.png')">
        <img src="../../assets/bank/coinwallet.png" class="w-8 mr-2" alt="Wallet Icon" />
        <span class="font-bold text-lg">Wallet Not Available</span>
      </div>
    </template>
    <template #body>
      <div class="bank-model-body">
        <div class="text-md text-white p-6">
          <p>
            Punt.com is currently under development.<br>
            Our expected launch date is: May 27th, 2024.<br>
            We look forward to seeing you all then!
          </p>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import Deposit from '@/components/Bank/Deposit.vue';
import Bundles from '@/components/Bank/Bundles.vue';
import Withdraw from '@/components/Bank/Withdraw.vue';
import Tip from '@/components/Bank/Tip.vue';
import Balance from '@/components/Bank/Balance.vue';
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ordersStore } from '@/store/pinia/ordersStore';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { mapGetters } from 'vuex';
import DailyBonus from './DailyBonus.vue';
import TopUp from './TopUp.vue';
import PaymentForm from '@/components/Bank/PaymentForm.vue';
import PaySafeIdentityVerificationForm from '@/components/Bank/PaySafeIdentityVerificationForm.vue';
import Reload from '@/components/Bank/Reload.vue';
import axios from 'axios';

export default {
  name: 'Bank',
  components: {
    PaySafeIdentityVerificationForm,
    Balance,
    Popover,
    PopoverButton,
    TopUp,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    Tip,
    Withdraw,
    Deposit,
    Bundles,
    PuntModal,
    DailyBonus,
    PaymentForm,
    Reload,
  },
  setup() {
    const orderS = ordersStore();
    const balS = balanceStore();

    return { orderS, balS };
  },
  data() {
    return {
      activeTab: 'deposit',
      action: 'bundles',
      isOnBreak:false,
      bankDisabled: false, // hard coded hotfix
      reloadStatus: null,
    };
  },
  mounted() {
    // Check if the customer is on break
    if (this.me) {
      if (this.me.break_type && this.me.break_type == 1) {
        this.isOnBreak = true;
      }
    }
    this.checkReloadStatus();

    const promoCode = this.$route.query.promo_code;
    if (promoCode) {
      this.claimPromotion(promoCode);
    }
  },
  async created() {
    await this.balS.init();
    this.orderS.selected = {
      name: this.balS.default,
      icon: getBalanceIcon(this.balS.default),
    };
  },
  computed: {
    ...mapGetters(['me', 'iAmStaff']),
    depositCurrencies() {
      return this.balS.currencies
        .filter((cur) => cur.is_crypto && !cur.parent_currency_id)
        .sort((a, b) => parseFloat(b.rank) - parseFloat(a.rank));
    },
    pages()
    {
      let pages = [
        {
          page: 'deposit',
          label: 'Buy Bundle',
          logo: '/images/bank/wallet.png',
          logoDark: '/images/bank/wallet-dark.png',
        },
        {
          page: 'redeem',
          label: 'Redeem',
          logo: '/images/bank/redeem.png',
          logoDark: '/images/bank/redeem-dark.png',
        },
        {
          page: 'bonus',
          label: 'Daily Bonus',
          logo: '/images/bank/clock.png',
          logoDark: '/images/bank/clock-dark.png',
        },
        {
          page: 'topup',
          label: 'Top-Up',
          logo: '/images/bank/clock.png',
          logoDark: '/images/bank/clock-dark.png',
        },
      ];

      if (this.hasReload) {
        pages.push({
          page: 'reload',
          label: 'Reload',
          logo: '/images/bank/clock.png',
          logoDark: '/images/bank/clock-dark.png',
        });
      }
       
      return pages;
    },
    hasReload() {
      return this.reloadStatus && this.reloadStatus.reload;
    },
    modalWidth() {
      return  'max-w-[764px]';
    },
    navWidth() {
      return this.hasReload ? 'max-w-[716px]' : 'max-w-[616px]';
    },
  },
  methods: {
    isActive(tab) {
      if (['buy', 'deposit'].includes(tab.toLowerCase())) {
        return ['buy', 'deposit'].includes(this.activeTab.toLowerCase());
      }
      return this.activeTab.toLowerCase() === tab.toLowerCase();
    },
    async selectCurrency(currency) {
      try {
        await this.balS.updateDefault(currency.name);
        this.orderS.selected = currency;
      } catch (e) {}
    },
    async selectNetwork(currency) {
      this.balS.network = currency;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    async checkReloadStatus() {
      try {
        const response = await axios.get('/reload/get-status');
        this.reloadStatus = response.data;
      } catch (error) {
        console.error('Error checking reload status:', error);
        this.reloadStatus = null;
      }
    },
    async claimPromotion(promoCode) {
      const response = await axios.post(`/claim-promotion/${promoCode}`);
      if (response.data.success) {
        this.$toast.success('Promotion claimed successfully');
      } else {
        this.$toast.error(response.data.message || 'Failed to claim promotion');
      }
    },
  },
  watch: {
    '$route.query.tab': {
      handler: function (newState) {
        /*if (newState && pages.some(x => x.page.toLowerCase() === newState.toLowerCase())) {
          this.activeTab = newState
        } else {
          this.activeTab = 'deposit'
        }*/
      },
      deep: true,
      immediate: true,
    },
    activeTab: {
      handler: function (newState) {},
      deep: true,
      immediate: true,
    },
    '$route.query.page': {
      handler: function (newState) {
        this.activeTab = newState ? newState : 'deposit';
      },
      deep: true,
      immediate: true,
    },
    '$route.query.action': {
      handler: function (newState) {
        if (
          newState &&
          ['bundles', 'crypto', 'redeem', 'buy', 'paymentForm', 'paysafe'].some(
            (x) => x.toLowerCase() === newState.toLowerCase(),
          )
        ) {
          this.action = newState;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.punt-bank-modal {
  background:
    linear-gradient(
        rgb(0 16 17 / var(--tw-bg-opacity)),
        rgb(0 16 17 / var(--tw-bg-opacity))
      )
      padding-box,
    linear-gradient(180deg, #00ffba -3%, rgba(255, 255, 255, 0.3) 31%) border-box;
  border: 2px solid transparent;
  border-radius: 20px !important;
}
</style>

<style scoped>
.bank-model-body {
  background: linear-gradient(112deg, #03221e -2%, transparent 24%);
}
.main-title span{
  font-size: 24px;
}
.tab-title{
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tab-title.bg-onprimary{
  box-shadow: 0px 4px 20px -1px rgba(0, 255, 186, 0.4);
}
@media screen and (max-width: 768px) {
  .bankwallet {
    background-size: 100% 270%;
  }
  .bank-model-body .bank-model-nav{
    justify-content: space-between;
  }
  .tab-title{
    font-size: 12px;
    padding: 4px;
  }
  
  
}
@media screen and (max-width: 500px) {
  .walletnav li img {
    max-width: 13px;
  }
  .walletnav li button {
    column-gap: 5px;
    font-size: 12px;
    list-style: 14px;
  }
  .bank-model-body .bank-model-nav{
    background: transparent;
    padding:6px 0;
  }
  .bank-model-body .bank-model-nav .bank-model-navlink{
    margin-left:4px;
    flex-grow: 1
  }
  .bank-model-nav .bank-model-navlink:first-child{
    margin-left: 0
  }
  .bank-model-nav .bank-model-navlink img{
    display:none
  }
}
</style>

