const SoundTypes = {
  TOGGLE_SOUND: 'sound/TOGGLE_SOUND',
  SET_SOUND: 'sound/SET_SOUND',
};

export default {
  namespaced: true,
  state: {
    muted: false,
  },
  actions: {
    initializeSound({ commit }) {
      commit(SoundTypes.SET_SOUND, JSON.parse(localStorage.SOUND_MUTED || 'false'));
    },
    toggleSound({ commit, state }) {
      commit(SoundTypes.TOGGLE_SOUND);
    },
  },
  getters: {
    isSoundMuted: (state) => state.muted,
  },
  mutations: {
    [SoundTypes.TOGGLE_SOUND](state) {
      state.muted = !state.muted;
      localStorage.SOUND_MUTED = state.muted;
    },
    [SoundTypes.SET_SOUND](state, value) {
      state.muted = value;
    },
  },
};
