<template>
  <div class="flex flex-col items-center">
    <div class="flex w-full">
      <div class="h-full w-full" ref="plinkoOptions"></div>
      <div class="w-20 pr-[20px] plinko_mul_main">
        <div class="mul_child rounded-[10px]">
          <div
            v-for="item in history"
            :key="item.id"
            :style="{ background: item.color }"
            class="px-2 py-1 text-sm plinko_mul">
            {{ item.multiplier + 'x' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultConvertor } from '../../lib/rsgp';
import PlinkoService from './PlinkoService';
import debounce from 'lodash/debounce';
// import * as Tone from 'tone'
// import hit from '../../assets/media/hit_plinko_bucket.mp3'

export default {
  name: 'PlinkoGrid',
  components: {},
  props: {
    currentState: {
      default: 'notStarted',
    },
    risk: {},
    rows: {},
    playGame: {},
    profit: {},
    multipliers: {},
    game_mode: {},
    isManualMode: {},
    setOnClick: {},
    stateHandler: {},
    playHitSound: {},
  },
  created() {
    this.setOnClick(this.onClick);
    this.debouncedPlayHitSound = debounce(this.playHitSound, 100, {
      leading: true,
      trailing: false,
    });
  },
  async mounted() {
    this.computeHeight();
    await this.setup();
  },
  data: () => ({
    hover: false,
    debouncedPlay: {},
    canvasHeight: '',
    plinkoService: null,
    history: [],
    debouncedPlayHitSound: null,
  }),
  methods: {
    async setup() {
      if (this.plinkoService !== null) {
        this.plinkoService.destroy();
      }
      this.plinkoService = new PlinkoService();
      if (this.plinkoService && this.multipliers) {
        this.$refs.plinkoOptions.innerHTML = '';
        await this.plinkoService.setup(
          this.$refs.plinkoOptions,
          this.multipliers[this.risk][`${this.rows}`],
          this.canvasHeight,
        );
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    computeHeight() {
      this.canvasHeight = this.$refs.plinkoOptions.clientWidth;
    },
    onClick(game, onComplete) {
      this.plinkoService.pull(game, (id, color, multiplier) => {
        this.debouncedPlayHitSound();
        if (onComplete) onComplete();
        this.history.unshift({ id, color, multiplier });
        if (this.history.length > 5) this.history.pop();
      });
    },
    //todo : to be implemented
    // async playSound() {
    //   await Tone.start();
    //   window.play
    //   const player = new Tone.Player({
    //     hit,
    //     loop: true,
    //     autostart: false,
    //   });
    //   await Tone.loaded();
    //   const pitchShift = new Tone
    //     .PitchShift({ pitch: -8 })
    //     .toDestination()
    //     ;
    //   player.connect(pitchShift);
    //   player.start();
    // },
  },
  computed: {
    plinkoHeight() {
      return this.canvasHeight + 'px';
    },
  },
  watch: {
    rows: {
      handler: async function () {
        await this.setup();
      },
      immediate: true,
    },
    risk: {
      handler: async function () {
        await this.setup();
      },
      immediate: true,
    },
    multipliers: {
      handler: async function () {
        await this.setup();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.plinko_mul_main {
  grid-area: LastBets;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 17rem;
  float: right;
  top: 50%;
  transform: translateY(-50%);
}
.mul_child {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3.2em;
  height: 11.2em;
  overflow: hidden;
  /* border-radius: 0.7em; */
  animation-duration: 500ms;
}

.plinko_mul {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.5em;
  height: 3.2em;
  transition-property: background-color;
  transition-duration: 0.2s;
  background-color: var(--bg-color);
  flex-shrink: 0;
  color: #000c;
  font-weight: 700;
  animation: down 1s;
}

.plinko_mul:nth-child(even) {
  -webkit-animation-name: svelte-1a90w5o-slideDownEven;
  animation-name: svelte-1a90w5o-slideDownEven;
}
.plinko_mul:nth-child(odd) {
  -webkit-animation-name: svelte-1a90w5o-slideDownOdd;
  animation-name: svelte-1a90w5o-slideDownOdd;
}
.plinko_mul:first-child {
  -webkit-animation-name: svelte-1a90w5o-slideDownFadeIn;
  animation-name: svelte-1a90w5o-slideDownFadeIn;
}

@-webkit-keyframes svelte-1a90w5o-slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes svelte-1a90w5o-slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@-webkit-keyframes svelte-1a90w5o-slideDownOdd {
  0% {
    transform: translateY(-100%);
  }
}
@keyframes svelte-1a90w5o-slideDownOdd {
  0% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes svelte-1a90w5o-slideDownEven {
  0% {
    transform: translateY(-100%);
  }
}
@keyframes svelte-1a90w5o-slideDownEven {
  0% {
    transform: translateY(-100%);
  }
}
@media (max-width: 460px) {
  .plinko_mul_main {
    margin-top: 9rem;
    float: right;
    top: 50%;
    transform: translateY(-50%);
  }
  .mul_child {
    height: 8.9em;
  }
  .plinko_mul {
    font-size: 0.7rem !important;
  }
}
@media (max-width: 768px) {
  .option-button {
    min-height: 20px;
    max-height: 30px;
  }

  .plinko-inner {
    font-size: 0.4em;
  }
}

@media (min-width: 769px) and (max-width: 1369px) {
  .plinko-inner {
    height: 100%;
    font-size: 0.6em;
  }
}

@media (min-width: 1370px) {
  .plinko-inner {
    height: 100%;
    font-size: 1em;
  }
}

.plinko-wrap {
  height: 100%;
}

.plinko-options {
  position: relative;
  display: block;
  width: 98%;
  max-width: 780px;
}

.plinko-container {
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  user-select: none;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.plinko-inner {
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 1em;
  gap: 1em;
}

.plinko-history {
  position: absolute;
  left: 90%;
  top: 35%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  z-index: 50;
}

.plinko-history-item {
  padding: 1.1em;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.plinko-history div {
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
}

.plinko-history div:nth-child(2n) {
  animation-name: even-row;
}

.plinko-history div:nth-child(2n + 1) {
  animation-name: odd-row;
}

.plinko-history div:first-child {
  animation-name: first-row;
}

@keyframes first-row {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  60% {
    opacity: 0;
    transform: translateY(-40%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes even-row {
  0% {
    transform: translate(0px, -100%);
  }
}

@keyframes odd-row {
  0% {
    transform: translateY(-100%);
  }
}

@keyframes last-row {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0;
    transform: translateY(0%);
  }
}
</style>
