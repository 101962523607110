export const COLORS = {
  BLACK: 0,
  RED: 1,
};

export const SUITS = {
  DIAMONDS: 0,
  HEARTS: 1,
  SPADES: 2,
  CLUBS: 3,
};

export const SUIT_COLORS = {
  DIAMONDS: COLORS.RED,
  HEARTS: COLORS.RED,
  SPADES: COLORS.BLACK,
  CLUBS: COLORS.BLACK,
};

export const VALUES = {
  ACE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  JACK: 11,
  QUEEN: 12,
  KING: 13,
};

export const CHIPS = [
  /*{color: '#AE1438', value: 1, displayValue: '1'},
  {color: '#7331C5', value: 10, displayValue: '10'},
  {color: '#DE432B', value: 100, displayValue: '100'},
  {color: '#1A8CD8', value: 1000, displayValue: '1K'},
  {color: '#38BB7F', value: 10000, displayValue: '10K'},
  {color: '#1DC0B0', value: 100000, displayValue: '100K'},*/
  { color: '#CF496C', value: 1000000, displayValue: '1M' },
  { color: '#FC5404', value: 10000000, displayValue: '10M' },
  { color: '#6F9283', value: 100000000, displayValue: '100M' },
  { color: '#20A4F3', value: 1000000000, displayValue: '1B' },
  { color: '#941C2F', value: 10000000000, displayValue: '10B' },
  { color: '#424B54', value: 100000000000, displayValue: '1T' },
];

export const DEFAULT_CHIP = { color: '#AE1438', value: 5000, displayValue: '1' };
