import Originals from '../../assets/sidebar/originals.png';
import Slots from '../../assets/sidebar/slots.png';
import Live from '../../assets/sidebar/live-1.png';
import GameShows from '../../assets/sidebar/game-shows.png';
import NewReleases from '../../assets/sidebar/2.png';
import TableGames from '../../assets/sidebar/table-games.png';
import Blackjack from '../../assets/sidebar/13.png';
import Roulette from '../../assets/sidebar/roulette-250.png';
import Baccarat from '../../assets/sidebar/11.png';
import Poker from '../../assets/sidebar/poker.png';

import Favourites from '../../assets/sidebar/6.png';
import Recent from '../../assets/sidebar/5.png';
import Challenges from '../../assets/sidebar/4.png';
import Pvp from '../../assets/sidebar/pvp.png';
import giveaway from '../../assets/sidebar/giveaway.webp';
import mybets from '../../assets/sidebar/mybets.png';
import ReferFriend from '../../assets/sidebar/refer-a-friend.png';
import Trending from '../../assets/sidebar/trendingwebp.webp';
import Lucky from '../../assets/sidebar/lucky.png';

export const icons = {
  Originals,
  Slots,
  Pvp,
  Live,
  GameShows,
  NewReleases,
  TableGames,
  Blackjack,
  Roulette,
  Baccarat,
  Poker,
  Favourites,
  Recent,
  Challenges,
  giveaway,
  mybets,
  ReferFriend,
  Trending,
  Lucky,
};
