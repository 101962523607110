<template>
  <Popover v-slot="{ open }" class="relative max-h-full">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="bg-secondary-light/0 min-w-96 px-4 py-3 rounded-xl font-semibold flex flex-inline items-center">
      <div class="flex flex-row space-x-2 text-slate-300 items-center text-sm">
        <span v-if="showBalanceLabel">Balance in</span>
        <img alt="icon" :src="getIcon(balS.defaultFiat)" class="h-4 w-4" />
        <span>{{ balS.defaultFiat }}</span>
        <span class="border-l-2 border-[#32647E] w-[1px]" />
        <img alt="icon" src="../../assets/svg/chevron.svg" class="" />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        class="absolute z-50 w-[150px] px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <perfect-scrollbar class="rounded-md">
            <div class="max-h-[500px] overflow-y-auto relative bg-slate-100 py-4">
              <PopoverButton
                v-for="item in balS.currencies.filter((x) => !x.is_crypto)"
                :key="item.name"
                v-on:click="selectCurrency(item)"
                class="hover: grid grid-cols-1 px-4 py-2 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div class="ml-2 flex flex-row space-x-2">
                  <img class="h-4 w-4" :src="item.icon" />
                  <p class="text-sm font-bold text-slate-500">
                    {{ item.name }}
                  </p>
                </div>
              </PopoverButton>
            </div>
          </perfect-scrollbar>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { balanceStore } from '@/store/pinia/balanceStore';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  PopoverOverlay,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { getBalanceIcon } from '@/lib/icons';

export default {
  name: 'FiatDropdown',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
  },
  props: {
    showBalanceLabel: {
      required: false,
      default: true,
    },
  },
  setup() {
    return { balS: balanceStore() };
  },
  methods: {
    selectCurrency(cur) {
      this.balS.defaultFiat = cur.name;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
  },
};
</script>
