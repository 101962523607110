<template>
  <router-view></router-view>
  <div
    class="flex h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px]">
      <div
        class="flex h-auto flex-col-reverse justify-center md:min-h-[auto] xl:flex-row">
        <!-- Side Menu -->
        <game-actions
          :pre-extras="gameActions"
          :game-data="{ name: 'Baccarat', id: null }"
          class="block rounded-bl-xl rounded-br-xl bg-tertiary md:hidden" />
        <div
          class="mt-2 w-full min-w-[300px] bg-side-color px-2 py-2 md:mt-0 md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl md:px-3 md:py-4 xl:w-[300px]">
          <baccarat-bets
            :currentState="currentState"
            :autoBetInProgress="autoBetInProgress"
            :limits="limits"
            :loading="loading"
            :initialWager="wager"
            :wagers="wagers"
            :scaleWager="scaleWager"
            :setActiveMode="setActiveMode"
            :updateSelectedChipValue="updateSelectedChipValue"
            :placeBet="placeBet"
            :totalProfit="totalProfit"
            :remainingAutoBets="remainingAutoBets"
            :stopAutoBet="stopAutoBet"
            :placeAutoBet="placeAutoBet" />
        </div>
        <div
          class="flex flex-col items-center bg-tertiary md:h-auto md:min-h-[auto] md:w-full md:rounded-tr-xl">
          <div class="h-full w-full bg-common bg-no-repeat bg-cover bg-center">
            <!-- The Game Grid -->
            <baccarat-grid
              :currentState="currentState"
              :autoBetInProgress="autoBetInProgress"
              :me="me"
              :game="game"
              :onTileClicked="onTileClicked"
              :setGameListeners="setGameListeners"
              :wagers="wagers"
              :changeState="changeState"
              :undoWager="undoWager"
              :clearWager="clearWager" />
          </div>
          <game-actions
            :pre-extras="gameActions"
            :game-data="{ name: 'Baccarat', id: null }"
            class="hidden rounded-br-xl bg-tertiary md:block" />
        </div>
      </div>
      <game-details
        game="Baccarat"
        edge="1.1"
        provider="Punt Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { defaultConvertor } from '@/lib/rsgp';
import { formatFloat } from '@/lib/utils';
import { mapActions, mapGetters } from 'vuex';
import BaccaratGrid from './BaccaratGrid.vue';
import BaccaratBets from './BaccaratBets.vue';
import BaccaratFairness from './BaccaratFairness.vue';
import { betSound, placeSound } from './sounds';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import axios from '@/lib/myAxios';
import { balanceStore } from '@/store/pinia/balanceStore';

const CHIP_VALUE = 0.00000001;
export default {
  components: {
    BaccaratGrid,
    BaccaratBets,
    BaccaratFairness,
    GameHistoryTable,
    GameActions,
    GameDetails,
  },
  props: {},
  setup() {
    return {
      balS: balanceStore(),
    };
  },
  data: () => ({
    showFairness: false,
    currentState: 'notStarted',
    privacy: 'PUBLIC',
    selectedChip: 1e6,
    game: null,
    seed: null,
    wager: 5000,
    wagers: {
      tie: 0,
      banker: 0,
      player: 0,
    },
    limits: [],
    loading: false,
    cancelAutoBet: false,
    onGameCompleted: null,
    onGameStarted: null,
    remainingAutoBets: 0,
    totalProfit: 0,
    wagerHistory: [],
    autoBetInProgress: false,
    gameActions: [
      ['/casino/baccarat/rules', 'Rules'],
      // ['/casino/baccarat/fairness', 'Fairness'],
    ],
  }),
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Sound', ['isSoundMuted']),
    isManualMode() {
      return this.activeMode === 'Manual';
    },
    totalWagered() {
      return (this.wagers.tie + this.wagers.player + this.wagers.banker) * CHIP_VALUE;
    },
  },
  methods: {
    ...mapActions('Sound', ['toggleSound']),
    updateSelectedChipValue(value) {
      this.selectedChip = value;
    },
    onTileClicked(tile) {
      this.wagerHistory.push({ ...this.wagers });
      switch (tile) {
        case 'TIE':
          this.wagers.tie += this.selectedChip;
          break;
        case 'BANKER':
          this.wagers.banker += this.selectedChip;
          break;
        case 'PLAYER':
          this.wagers.player += this.selectedChip;
          break;
        default:
          break;
      }

      this.playSound(placeSound);
    },
    scaleWager(x) {
      this.wagerHistory.push({ ...this.wagers });
      let scale = (val) => (val < 1 ? 0 : val);
      this.wagers.player = scale(x * this.wagers.player);
      this.wagers.banker = scale(x * this.wagers.banker);
      this.wagers.tie = scale(x * this.wagers.tie);
      this.playSound(placeSound);
    },
    undoWager() {
      if (this.wagerHistory.length > 0 && !this.autoBetInProgress) {
        this.wagers = { ...this.wagerHistory.pop() };
        this.playSound(placeSound);
      }
    },
    clearWager() {
      if (this.autoBetInProgress) return;
      this.wagerHistory.push({ ...this.wagers });
      this.wagers = { tie: 0, banker: 0, player: 0 };
      this.playSound(placeSound);
    },
    setActiveMode(mode) {
      this.activeMode = mode;
      // reset auto bet stuff
      this.totalProfit = 0;
      this.remainingAutoBets = 0;
    },
    setGameListeners(onGameCompleted, onGameStarted) {
      this.onGameCompleted = onGameCompleted;
      this.onGameStarted = onGameStarted;
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },

    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },

    onBetException(data, status) {
      let message = '';
      if (status > 412) {
        message = '';
      } else if (status >= 400 && status <= 412) {
        message += ' : ' + (typeof e.data.error !== 'undefined' ? data.error : '');
      } else {
        message += ' : ' + (typeof e.data.error !== 'undefined' ? data.error : '');
      }

      if (typeof e.data.error !== 'undefined' && data.error === 'Unauthenticated.') {
        this.$router.replace('?tab=login');
      } else {
        this.$swal(
          'Uh-oh',
          'Something went wrong when placing your play: ' +
            (typeof e.data.error !== 'undefined' ? data.error : ''),
          'error',
        );
      }
    },
    async placeAutoBet(
      noOfBets,
      stopOnProfit,
      stopOnLoss,
      increaseOnProfit,
      decreaseOnLoss,
      increaseOnWin,
      increaseOnLoss,
    ) {
      if (this.totalWagered <= 0) {
        this.$toast.error('Total played must be greater than zero');
        return;
      }

      let initialWager = { ...this.wagers };

      // get fields
      this.totalProfit = 0;
      this.cancelAutoBet = false;
      let autoBetsCount = 0;
      this.remainingAutoBets = 0;
      this.autoBetInProgress = true;

      const currency = this.balS.default;
      while (!this.cancelAutoBet) {
        try {
          this.currentState = 'inProgress';
          const wagers = {
            tie: this.wagers.tie * CHIP_VALUE,
            banker: this.wagers.banker * CHIP_VALUE,
            player: this.wagers.player * CHIP_VALUE,
          };
          let response = await axios.post(`baccarat`, wagers);
          this.game = response.data;

          await this.verify2bo(
            1000 + (this.game.plays.player.length + this.game.plays.banker.length) * 1000,
          );

          // wager
          this.wager = this.game.wager;
          // update profit & multiplier
          this.totalProfit += this.game.profit;
          autoBetsCount += 1;

          // cancel on profit/loss
          if (
            (this.totalProfit >= stopOnProfit && stopOnProfit > 0) ||
            (stopOnLoss > 0 && this.totalProfit <= Math.abs(stopOnLoss) * -1)
          ) {
            this.cancelAutoBet = true;
          }

          if (autoBetsCount >= noOfBets) {
            this.cancelAutoBet = true;
          }

          // change wager on profit or loss
          if (increaseOnWin && this.game.profit > 0 && increaseOnProfit > 0) {
            this.scaleWager(1 + increaseOnProfit / 100);
          }

          // reset wager if not increase on win
          if (!increaseOnWin && this.game.profit > 0) {
            this.wagers = { ...initialWager };
          }

          if (this.game.profit < 0 && decreaseOnLoss > 0) {
            this.scaleWager(1 + decreaseOnLoss / 100);
          }

          // reset on loss
          if (!increaseOnLoss && this.game.profit < 0) {
            this.wagers = { ...initialWager };
          }
          this.remainingAutoBets = noOfBets - autoBetsCount;
        } catch ({ data, status }) {
          this.cancelAutoBet = true;
          if (typeof data.error !== 'undefined') this.$toast.error(data.error);
        }
      }
      await this.verify2bo(1000);
      await this.stopAutoBet();
    },
    async stopAutoBet() {
      this.cancelAutoBet = true;
      this.currentState = 'notStarted';
      this.autoBetInProgress = false;
      await this.verify2bo(1000);
    },
    verify2bo(period) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, period);
      });
    },
    placeBet() {
      if (this.totalWagered <= 0) {
        this.$toast.error('Total played must be greater than zero');
        return;
      }
      const wagers = {
        tie: this.wagers.tie * CHIP_VALUE,
        banker: this.wagers.banker * CHIP_VALUE,
        player: this.wagers.player * CHIP_VALUE,
      };
      this.changeState('inProgress');
      this.playSound(betSound);
      try {
        axios
          .post(`baccarat`, wagers)
          .then((res) => res.data)
          .then((game) => {
            this.game = game;
          })
          .catch(({ data, status }) => {
            this.onBetException(data, status);
            this.changeState('notStarted');
          });
      } catch (error) {
        console.log('issue with placing bets');
      }
    },
    changeState(newState) {
      this.currentState = newState;
    },
  },
};
</script>

<style scoped>
.warBox {
  height: 100%;
}
</style>
