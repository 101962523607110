<template>
  <div class="box has-text-centered">
    <label class="label is-unselectable" v-text="label"></label>
    <p v-if="!editing" class="control has-text-centered">
      <a class="button is-medium is-inverted" v-on:click="editing = true"
        >{{ amount.toFixed(2) }}{{ unitLabel }}</a
      >
    </p>
    <p v-else class="control has-text-centered has-addons">
      <input
        type="number"
        :min="min"
        :max="max"
        step="1"
        class="input is-expanded is-medium"
        v-model.lazy="amount"
        ref="field"
        @keyup.enter="editing = false"
        @keyup.esc="editing = false"
        number />
      <a class="button is-bordered is-success is-medium" v-on:click="editing = false"
        >&check;</a
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clamp } from 'lodash';

export default {
  props: ['value', 'unitLabel', 'label', 'min', 'max'],
  data() {
    return {
      amount: 0,
      editing: false,
      lastEmitted: 0,
    };
  },
  computed: {
    ...mapGetters({
      multiplier: 'dicingMultiplier',
      winChance: 'dicingWinChance',
    }),
    _amount() {
      return this.label === 'Multiplier' ? this.multiplier : this.winChance;
    },
    shouldEmit() {
      return this.amount !== this.lastEmitted;
    },
  },
  mounted() {
    if (this.value) {
      this.amount = this.value;
    }
    this.lastEmitted = this.amount;
  },
  methods: {
    clampInput(x) {
      return clamp(x, this.min, this.max);
    },
  },
  watch: {
    _amount(x) {
      this.amount = x;
    },
    amount(x) {
      const clamped = this.clampInput(x);
      if (x !== clamped) {
        this.amount = clamped;
      }
      this.$emit('input', clamped);
    },
    editing(flag) {
      if (flag) {
        this.$nextTick(() => {
          this.$refs.field.focus();
        });
      } else if (this.shouldEmit) {
        this.$emit('change', this.amount);
        this.lastEmitted = this.amount;
      }
    },
  },
};
</script>
