<template>
  <div
    class="h-screen flex items-center justify-center ">
    <Loader height="30px" :showProgress="true" />
  </div>
</template>

<script>
import Loader from '@/components/Shared/Loader.vue';

export default {
  name: 'FullScreenLoader',
  components: { Loader },
};
</script>
