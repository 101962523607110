<template>
  <div class="war-container" style="background: #11222e; padding-bottom: 40px">
    <div v-if="profit !== null" class="game-result">
      <div class="game-result-content">
        <span style="margin-top: 10px">{{ this.multiplier }}</span>
        <span style="margin-top: 10px">{{ this.profit }}</span>
      </div>
    </div>
    <div class="war-deck" style="padding: 0px">
      <div class="war-deck-table">
        <div class="deck !bg-chat-top-color">
          <img src="../../assets/logo.png" alt="RuneBet" />
        </div>
      </div>
    </div>
    <div class="war-inner">
      <div class="war-options">
        <div
          class="war-card-item flex flex-col items-center justify-center"
          v-for="(player, index) in gamePlayers"
          :key="index">
          <Card :player="player" :me="me" :allFlipped="allFlipped" />
          <span
            class="text-xs md:text-sm font-bold opacity-70 font-semibold mt-1 md:mt-2"
            v-if="!isUsernameHidden(player)">
            <p
              class="relative bg-primary"
              :style="`opacity:${player.user !== null ? 0.54 : 0}`">
              {{
                `${
                  player.user !== null
                    ? player.user.username !== null
                      ? player.user.username
                      : player.user.id === me.id
                        ? me.username
                        : 'Hidden'
                    : 'Loading'
                }${player.user !== null && player.is_host ? ' (H)' : ''}`
              }}
            </p>
          </span>
          <span
            v-else
            class="inline"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              opacity: 0.54;
              margin-top: 10px;
            ">
            Hidden
            <img
              class="inline my-1 opacity-50 w-4 h-4"
              src="../../assets/svg/ic_ghost_mode.svg"
              alt="incognito logo" />
            {{ player.user !== null && player.is_host ? ' (H)' : '' }}
          </span>
        </div>
      </div>
      <div v-if="false" class="war-options">
        <div class="war-card-item" v-for="(player, index) in gamePlayers" :key="index">
          <Card :player="player" :me="me" :allFlipped="allFlipped" />
          <span v-if="!isUsernameHidden(player)">
            <p class="card-label" :style="`opacity:${player.user !== null ? 0.54 : 0}`">
              {{
                `${
                  player.user !== null
                    ? player.user.username !== null
                      ? player.user.username
                      : player.user.id === me.id
                        ? me.username
                        : 'Hidden'
                    : 'Loading'
                }${player.user !== null && player.is_host ? ' (H)' : ''}`
              }}
            </p>
          </span>
          <span
            v-else
            class="label has-text-centered"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              opacity: 0.54;
              margin-top: 10px;
            ">
            Hidden
            <img
              style="width: 16px; margin-left: 2px; margin-right: 2px; opacity: 0.54"
              src="../../assets/svg/ic_ghost_mode.svg"
              alt="incognito logo" />
            {{ player.user !== null && player.is_host ? ' (H)' : '' }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="false"
    class="hidden war-container relative"
    style="background: transparent; padding-bottom: 40px">
    <div v-if="profit !== null" class="game-result">
      <div class="game-result-content">
        <span style="margin-top: 10px">{{ this.multiplier }}</span>
        <span style="margin-top: 10px">{{ this.profit }}</span>
      </div>
    </div>
    <div class="flex w-full flex-row justify-end">
      <div class="war-deck" style="padding: 0px">
        <div class="war-deck-table">
          <div class="deck">
            <img src="../../assets/logo.png" alt="RuneBet" />
          </div>
        </div>
      </div>
    </div>

    <div class="war-inner flex-1">
      <div class="war-options">
        <div class="war-card-item" v-for="(player, index) in gamePlayers" :key="index">
          <Card :player="player" :me="me" :allFlipped="allFlipped" />
          <span v-if="!isUsernameHidden(player)">
            <p
              class="relative -m-9 bg-primary px-2 py-1 rounded-2xl"
              :style="`opacity:${player.user !== null ? 0.54 : 0}`">
              {{
                `${
                  player.user !== null
                    ? player.user.username !== null
                      ? player.user.username
                      : player.user.id === me.id
                        ? me.username
                        : 'Hidden'
                    : 'Loading'
                }${player.user !== null && player.is_host ? ' (H)' : ''}`
              }}
            </p>
          </span>
          <span
            v-else
            class="label has-text-centered"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              opacity: 0.54;
              margin-top: 10px;
            ">
            Hidden
            <!--            <img style="width: 16px;margin-left: 2px;margin-right: 2px; opacity: 0.54" src="../../../assets/svg/ic_ghost_mode.svg" alt="incognito logo"/>-->
            {{ player.user !== null && player.is_host ? ' (H)' : '' }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="hidden war-deck bg-transparent flex flex-col items-center justify-center h-full">
      <div class="flex flex-col items-center pb-10">
        <span class="text-2xl font-bold">{{ warStartTimeCounter }}</span>
        <span class="text-text-dim-color text-sm">To Start</span>
      </div>
      <div class="war-deck-table">
        <div class="deck p-3">
          <img src="../../assets/logo.png" alt="RuneBet" />
        </div>
      </div>
      <div class="flex flex-col items-center pt-10">
        <span class="text-2xl font-bold">{{ players.length }}/12</span>
        <span class="text-text-dim-color text-sm">Players</span>
      </div>
    </div>
  </div>
</template>

<script>
import { convertGameMode, defaultConvertor } from '../../lib/rsgp';
import debounce from 'lodash/debounce';
import Card from './Card.vue';

export default {
  name: 'WarGrid',
  components: {
    Card,
  },
  props: {
    currentState: {
      default: 'notStarted',
    },
    privacy: {},
    noOfPlayers: {
      default: 12,
    },
    initialNoOfPlayers: {},
    warStartTimeCounter: {},
    me: {},
    players: {},
    host: {},
    game: {},
    setGameListeners: {},
    playSound: {},
    playGame: {},
  },
  created() {
    this.setGameListeners(this.onGameCompleted, this.onGameStarted);
    this.debouncedPlaySound = debounce(this.playSound, 100, {
      leading: true,
      trailing: false,
    });
  },
  data: () => ({
    gamePlayers: [],
    debouncedPlaySound: null,
    profit: null,
    multiplier: null,
    allFlipped: false,
  }),
  methods: {
    async setup() {},
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    onClick(game, onComplete) {},
    isUsernameHidden(player) {
      return (
        player.user !== null &&
        player.user.username === null &&
        player.user.id !== this.me.id
      );
    },
    onGameStarted() {
      this.profit = null;
    },
    onGameCompleted() {
      this.allFlipped = false;
      setTimeout(
        () => {
          this.allFlipped = true;
          if (this.game == null || this.game.result === null) {
            this.profit = null;
          } else {
            let player = this.game.players.find((p) => p.user_id === this.me.id);
            if (player && player.profit !== null) {
              if (player.profit >= 0) {
                if (player.profit > 0) {
                  this.profit = `${this.rsgp(player.profit)} ${convertGameMode(
                    player.game_mode,
                  )}`;
                  const multiplier =
                    (parseFloat(player.profit) + parseFloat(player.wager)) /
                    parseFloat(player.wager);
                  this.multiplier = `${multiplier.toFixed(2)}x`;
                  this.debouncedPlaySound(true);
                }
              } else {
                this.debouncedPlaySound(false);
              }
            } else {
              this.profit = null;
            }
          }
        },
        this.players.length * 500 + 1000,
      );
    },
    initGrid() {
      for (let i = 0; i < this.noOfPlayers; i++) {
        if (this.players.length > i) {
          let player = this.players[i];
          player.is_host = this.players[i].user_id === this.host.id;
          this.gamePlayers[i] = player;
        } else {
          this.gamePlayers[i] = {
            war: null,
            user: null,
            result: null,
            is_host: false,
          };
        }
      }
    },
  },
  computed: {
    remainingTime() {
      if (this.warStartTimeCounter <= 0) this.warStartTimeCounter = 0;
      const minutes = Math.floor(this.warStartTimeCounter / 60);
      let seconds = this.warStartTimeCounter % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    },
  },
  watch: {
    noOfPlayers: {
      handler: function (newState) {
        this.gamePlayers = [];
        for (let i = 0; i < newState; i++) {
          this.gamePlayers.push({
            war: null,
            user: null,
            result: null,
            is_host: false,
          });
        }
      },
      immediate: true,
    },
    players: {
      handler: async function () {
        this.initGrid();
      },
      immediate: true,
      deep: true,
    },
    game: {
      handler: async function () {
        this.initGrid();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-label {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.54;
}

.war-card-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .war-inner {
    min-height: 400px;
    font-size: 0.4em;
  }
}

@media (min-width: 769px) and (max-width: 1369px) {
  .war-inner {
    min-height: 600px;
    font-size: 0.6em;
  }
}

@media (min-width: 1370px) {
  .war-inner {
    min-height: 650px;
    font-size: 1em;
  }
}

.game-result-content {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  opacity: 0.4;
  animation-name: animate_game_result;
  -moz-animation-name: animate_game_result;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.game-result {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ee563;
  font-size: 1rem;
  background: rgb(26, 44, 56) !important;
  opacity: 0.8;
  border-radius: 0.5rem;
  z-index: 2;
  text-align: center;
  position: absolute;
  font-weight: 800;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  max-width: 100%;
  min-width: 150px;
  min-height: 70px;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  border: 4px solid #1ee563;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
}

.war-options {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 780px;
  grid-template-columns: repeat(4, auto);
  gap: 1em;
}

@media (max-width: 500px) {
  .war-options {
    width: 100%;
    grid-template-columns: repeat(4, auto);
  }
}

.war-container {
  background-color: rgb(18, 34, 46);
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  user-select: none;
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

.war-inner {
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 1em;
  gap: 1em;
}

.main-content {
  display: flex;
  flex-direction: column;

  > .is-fullwidth {
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .war-deck {
    flex-direction: column-reverse;
    display: flex;
  }

  .war-deck-table {
    border-radius: 1rem 1rem 0 0;
  }
}
@media (min-width: 769px) {
  .war-deck-table {
    border-radius: 0 1rem 1rem 0;
  }
}

.war-deck {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  border-radius: 1rem;

  .war-deck-table {
    background: rgb(18, 34, 46);
    padding: 4rem 0 1rem;
    margin: 0 20px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transform: rotate(0deg);

    .deck {
      position: absolute;
      top: -4.75rem;
      right: 2rem;

      width: 76px;
      height: 120px;

      border-radius: 0.5rem;

      background-color: rgb(41, 109, 130);
      border: 0.25rem solid #ffffff;

      display: flex;
      justify-content: center;
      flex-direction: column;

      box-shadow:
        0 0.02rem 0 0 #d6d6d6,
        0 0.1rem 0 0 #ffffff,
        0 0.12rem 0 0 #d6d6d6,
        0 0.24rem 0 0 #ffffff,
        0 0.26rem 0 0 #d6d6d6,
        0 0.34rem 0 0 #ffffff,
        0 0.36rem 0 0 #d6d6d6,
        0 0.44rem 0 0 #ffffff,
        0 0.46rem 0 0 #d6d6d6,
        0 0.54rem 0 0 #ffffff,
        0 0.56rem 0 0 #d6d6d6;
    }
  }
}
</style>
