<template>
  <!-- site-footer -->
  <section class="site-footer">
    <img src="/images/puntlandingpage/f1.png" alt="img" class="footer-bottom-img1">
      <img src="/images/puntlandingpage/f2.png" alt="img" class="footer-bottom-img2">
    <div class="cta-section">
      <img src="/images/puntlandingpage/f3.png" alt="img" class="left-sidecoin-img">
      <img src="/images/puntlandingpage/mobilectacoin.png" alt="img" class="mobileleft-sidecoin-img">

      <div class="container">
        <h2 class="cta-title">
          No purchase necessary. Claim your <span>free</span> coins today!
        </h2>
        <button
          href="#"
          class="btn-primary"
          v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
          <span class="top"> Play now </span>
          <span class="arr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M17.6568 6.34323H9.17154M17.6568 6.34323V14.8285M17.6568 6.34323L6.5 17.5001"
                stroke="#031618"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <div class="footer-top">
      <div class="container flex m-auto gap-x-[36px] max-[400px]:gap-x-[25px]">
        <div class="md:w-6/12 footer-widget-description">
          <img
            class="w-auto mb-[52px]"
            :src="getImage('footer-logo.svg')"
            alt="Site Logo" />
            <div class="discription mb-10"><p>18+ only</p>
              <p>
                NO PURCHASE IS NECESSARY to play. PROMOTIONS ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see
                <a class="text-slate-100 hover:opacity-70" href="https://punt.com/docs/Terms-And-Conditions.pdf">Terms of Use</a> &amp;
                <a href="/sweep-rules" class="flex-0 text-right text-slate-100 hover:opacity-70 max-[767px]:text-center">
                  Sweepstakes Rules.
                </a> Punt.com Social Gaming Platform is a PLAY-FOR-FUN website intended for AMUSEMENT PURPOSES ONLY. Punt.com Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry.
              </p>
            </div>
        </div>
<!--        <div class="md:w-2/12 footer-widget-menu very-small one">-->
<!--          <div class="footer-menu max-w-[150px] m-auto">-->
<!--            <h3 class="footer-menu-title">Website</h3>-->
<!--            <ul>-->
<!--              <li class="active" ><button @click="scrollToElement('main')">Home</button></li>-->
<!--              <li><button  @click="scrollToElement('games')">Games</button></li>-->
<!--              <li><button  @click="scrollToElement('latestWinner')">Winners</button></li>-->
<!--              &lt;!&ndash; <li><a href="" @click="scrollToElement('main')">Reactions</a></li>&ndash;&gt;-->
<!--              <li><button  @click="scrollToElement('testimonials')">Testimonials</button></li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
        <div class="md:w-3/12 sm:w-6/12 footer-widget-menu one">
          <div class="footer-menu max-w-[300px] footer-widget-menu m-auto">
            <h3 class="footer-menu-title">Hyperlinks</h3>
            <ul>
              <li>
                <a
                  href="https://punt.com/docs/Privacy-Policy.pdf"
                  target="_blank"
                  class="menu"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  href="https://punt.com/docs/Terms-And-Conditions.pdf"
                  target="_blank"
                  class="menu"
                  >Terms and Conditions</a
                >
              </li>
              <li>
                <a href="/sweep-rules" target="_blank" class="menu">Sweeps Rules</a>
              </li>
              <li>
                <a
                  href="https://punt.com/docs/Customer-Acceptance-Policy.pdf"
                  target="_blank"
                  class="menu"
                  >Customer Acceptance Policy</a
                >
              </li>
              <li>
                <a
                  href="https://punt.com/docs/Responsible-Social-Gameplay.pdf"
                  target="_blank"
                  class="menu"
                  >Responsible Social Gameplay</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="md:w-3/12 sm:w-6/12 footer-widget-menu two">
          <div class="footer-menu max-w-[350px] footer-widget-menu ml-auto">
            <h3 class="footer-menu-title">Contact</h3>
            <h4 class="title">Partners:</h4>
            <a href="mailto:partners@punt.com" class="footer-link">partners@punt.com</a>
            <h4 class="title">Support:</h4>
            <a href="mailto:support@punt.com" class="footer-link">support@punt.com</a>
            <h4 class="title">Payment Related Queries (24/7):</h4>
            <a href="tel:+1(855)342-2919" class="footer-link">+1 (855) 342-2919</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">

      <div class="container flex">
        <div class="sm:block hidden md:w-1/3 col-1">
          <h3 class="title">{{ getcurrentyear() }} PUNT.</h3>
        </div>
        <div class="md:w-1/3 col-2">
          <div class="inner social-media-link">
          <a href="https://www.instagram.com/punt" target="_blank" class="social-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
                fill="#23F4F4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.87937 3.28246C10.2827 2.90585 13.7173 2.90585 17.1207 3.28246C18.98 3.48968 20.479 4.95099 20.6973 6.81403C21.1009 10.2593 21.1009 13.7397 20.6973 17.1849C20.479 19.048 18.98 20.5093 17.1217 20.7175C13.718 21.0942 10.283 21.0942 6.87937 20.7175C5.02007 20.5093 3.52108 19.048 3.30274 17.1859C2.89909 13.7403 2.89909 10.2596 3.30274 6.81403C3.52108 4.95099 5.02007 3.48968 6.87937 3.28246ZM16.8955 6.1347C16.6358 6.1347 16.3868 6.23768 16.2032 6.42099C16.0195 6.6043 15.9164 6.85292 15.9164 7.11216C15.9164 7.3714 16.0195 7.62002 16.2032 7.80333C16.3868 7.98664 16.6358 8.08962 16.8955 8.08962C17.1552 8.08962 17.4042 7.98664 17.5878 7.80333C17.7714 7.62002 17.8746 7.3714 17.8746 7.11216C17.8746 6.85292 17.7714 6.6043 17.5878 6.42099C17.4042 6.23768 17.1552 6.1347 16.8955 6.1347ZM7.34933 11.9995C7.34933 10.7681 7.83931 9.58713 8.71149 8.71641C9.58366 7.84569 10.7666 7.35653 12 7.35653C13.2335 7.35653 14.4164 7.84569 15.2886 8.71641C16.1607 9.58713 16.6507 10.7681 16.6507 11.9995C16.6507 13.2309 16.1607 14.4118 15.2886 15.2825C14.4164 16.1533 13.2335 16.6424 12 16.6424C10.7666 16.6424 9.58366 16.1533 8.71149 15.2825C7.83931 14.4118 7.34933 13.2309 7.34933 11.9995Z"
                fill="#23F4F4" />
            </svg>
          </a>
          <!--<a href="#" class="social-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M13 13.35H15.1429L16 9.75H13V7.95C13 7.023 13 6.15 14.7143 6.15H16V3.126C15.7206 3.0873 14.6654 3 13.5511 3C11.224 3 9.57143 4.4913 9.57143 7.23V9.75H7V13.35H9.57143V21H13V13.35Z"
                fill="#23F4F4" />
            </svg>
          </a>-->
          <a href="https://t.me/+0CNGr3dUmMk0MDEx" target="_blank" class="social-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M20.2955 3.15892C20.2955 3.15892 22.1456 2.37978 21.9908 4.27183C21.9399 5.05097 21.4775 7.77822 21.1175 10.7276L19.8841 19.4653C19.8841 19.4653 19.7813 20.7454 18.856 20.968C17.9312 21.1902 16.5436 20.1889 16.2865 19.9662C16.0807 19.7991 12.4321 17.2945 11.1474 16.0705C10.7874 15.7362 10.3759 15.0687 11.1988 14.2896L16.286 8.65712C16.9027 7.98855 18.0695 6.60744 15.5 8.5L8.06353 13.6765C8.06353 13.6765 7.24114 14.2335 5.6997 13.7326L2.35871 12.6192C2.35871 12.6192 1.12537 11.7845 3.23253 10.9498C8.37211 8.33416 14.6941 5.66348 20.2955 3.15892Z"
                fill="#23F4F4" />
            </svg>
          </a>
          <a href="https://x.com/puntsocial" target="_blank" class="social-link"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M17.7512 3H20.818L14.1179 10.6246L22 21H15.8275L10.9936 14.7074L5.46266 21H2.39401L9.56038 12.8446L2 3H8.32735L12.6967 8.75169L17.7493 3H17.7512Z"
                fill="#23F4F4" /></svg
          ></a>
          <!--<a href="#" class="social-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M16.6939 5.82C15.9964 5.03962 15.612 4.03743 15.6122 3H12.4592V15.4C12.4349 16.071 12.1458 16.7066 11.6528 17.1729C11.1599 17.6393 10.5015 17.8999 9.81633 17.9C8.36735 17.9 7.16327 16.74 7.16327 15.3C7.16327 13.58 8.85714 12.29 10.602 12.82V9.66C7.08163 9.2 4 11.88 4 15.3C4 18.63 6.81633 21 9.80612 21C13.0102 21 15.6122 18.45 15.6122 15.3V9.01C16.8908 9.90985 18.4259 10.3926 20 10.39V7.3C20 7.3 18.0816 7.39 16.6939 5.82Z"
                fill="#23F4F4" />
            </svg>
          </a>-->
        </div>
        </div>
        <div class="sm:block hidden md:w-1/3 col-3">
          <h3 class="title text-right">© All rights reserved.</h3>
        </div>
      </div>
    </div>

    <div class="sm:hidden flex justify-between px-3">
      <h3 class="title">2023 PUNT.</h3>
      <h3 class="title">© All rights reserved.</h3>
    </div>
  </section>
  <!-- site-footer -->
</template>

<script>
import { mapGetters } from 'vuex';
import lady_background from '@/assets/home/lady.webp';

export default {
  name: 'PFooter',
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  data() {
    return {
      lady_background,
    };
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    getImage(image) {
      return `${location.protocol}//${location.host}/images/puntlandingpage/${image}`;
    },
    getcurrentyear() {
      return new Date().getFullYear();
    },
    scrollToElement(id) {
      const container = document.getElementById(id);
      container.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
