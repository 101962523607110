import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';
import { generalStore } from '@/store/pinia/generalStore';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const duelStore = defineStore('duels', {
  state: () => {
    return {
      duels: [],
      loading: false,
      current: null,
      currentState: 'notStarted',
      generalS: generalStore(),
      activeMode: 'Lobby',
      user: null,
    };
  },
  getters: {
    joined() {
      return this.current && this.user && this.current.host_id === this.user.id;
    },
    hasGuest() {
      return (
        this.current &&
        this.current.guest_id !== null &&
        this.current.host_id === this.user.id
      );
    },
  },
  actions: {
    async fetch() {
      const { data } = await axios.get('casino/duel');
      this.duels = data;
      return data;
    },
    async create({ wager, seed }) {
      return await axios.post('casino/duel', { wager, seed });
    },
    async join({ duelId, seed }) {
      return await axios.patch(`casino/duel/${duelId}`, { seed });
    },
    async cancel(duelId) {
      return await axios.delete(`casino/duel/${duelId}`);
    },
    async show(duelId) {
      return await axios.get(`casino/duel/${duelId}`);
    },
    remove(duel) {
      this.duels = this.duels.filter((dice) => dice.id !== duel.id);
      if (this.current && duel.id === this.current.id) {
        this.current = null;
        this.currentState = 'notStarted';
      }
    },
    onResult(duel) {
      this.duels = this.duels.filter((duel) => duel.id !== duel.id);
      if (this.current && duel.id === this.current.id) {
        this.current = duel;
      }
    },
    add(duel) {
      if (this.current && duel.id === this.current.id) {
        this.current = duel;
      }
      if (this.duels.filter((d) => d.id === duel.id).length > 0) {
        return;
      }
      this.duels.push(duel);
    },
  },
});
