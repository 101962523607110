<template>
  <punt-modal size="max-w-xl" show-close="true" :on-close="onClose">
    <template #title>
      <h1 class="font-bold">Select Fiat</h1>
    </template>
    <template #body>
      <div class="flex flex-col text-slate-300">
        <p class="text-center text-sm mt-4 opacity-80">
          Please note that these are currency approximations.
        </p>
        <p class="text-center text-sm mt-2 opacity-80">
          All bets & transactions will be settled in the crypto equivalent. For further
          details feel free to contact our live support.
        </p>
        <div class="grid grid-cols-3 mt-4 gap-2">
          <div
            v-for="cur in balS.currencies.filter((x) => !x.is_crypto)"
            class="flex flex-row gap-2 text-sm font-semibold">
            <input
              :id="cur.name"
              @change="onFiatChanged(cur)"
              :checked="cur.name === balS.defaultFiat"
              type="radio"
              :value="cur.name"
              name="cur.name"
              class="w-4 h-4 text-green-600 bg-gray-500 focus:border-none border-primary focus:ring-primary" />
            <label :for="cur.name">{{ cur.name }}</label>
          </div>
        </div>
        <div class="flex flex-row justify-center">
          <button class="btn-bet" v-on:click="onClose">Play Now</button>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  PopoverOverlay,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'SelectFiat',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    PuntModal,
  },
  setup() {
    const balS = balanceStore();
    return { balS };
  },
  data() {
    return {
      activeTab: 'deposit',
    };
  },
  methods: {
    onClose() {
      this.balS.showSelectFiatModal = false;
    },
    onFiatChanged(cur) {
      this.balS.defaultFiat = cur.name;
    },
  },
};
</script>
